import React, { useState } from 'react';
import Router from 'next/router';
import useIaClubCtaAction from '@app/web/src/ia/club/useIaClubCTAAction';
import { TextEditorCheck as TextEditorCheckIcon } from '@front/icon';
import { Button } from '@front/ui';
import { useLatestNotifications } from '@lib/web/apis';
import { getTime, startOfDay } from 'date-fns';

type AcceptClubInviteProps = {
  clubSlug: string;
  inviterId: string;
};
export default function AcceptClubInvite({
  clubSlug,
  inviterId,
}: AcceptClubInviteProps) {
  const [loading, setLoading] = useState(false);
  const [time] = useState(getTime(startOfDay(new Date())));
  const { mutate: latestNotificationsMutate } = useLatestNotifications({
    time,
    topicSlug: clubSlug,
  });
  const { club, clubCta, clubCtaAction } = useIaClubCtaAction(clubSlug, {
    onJoined: async () => {
      await latestNotificationsMutate();
    },
  });

  const handleClick = async () => {
    if (!club || !clubCta) return;
    setLoading(true);

    if (!clubCtaAction) {
      Router.push(clubCta.value);
    } else {
      clubCtaAction.action({
        id: club.clubSlug,
        title: club.clubName,
        metadata: {
          inviterId,
        },
      });
    }

    setLoading(false);
  };

  if (!clubCta) return null;

  return (
    <Button
      prefixIcon={<TextEditorCheckIcon />}
      onClick={handleClick}
      loading={loading}
    >
      {clubCta.text}
    </Button>
  );
}
