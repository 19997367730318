import { createContext, ReactNode, useCallback, useState } from 'react';
import Router from 'next/router';
import { Theme } from '@mui/material';
import { useMediaQuery } from '@mui/system';
import { useBaseRightPanel } from '@front/ui';
import { useFollowingStatusMutate } from '@lib/web/hooks';
import { useThreadViewDetails } from '@lib/web/thread/hooks/view/useThreadViewDetails';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';
import {
  FloatingProfile,
  FloatingProfileExtension,
  FloatingProfileType,
} from '@lib/web/ui';

import { SuggestionUser } from '../components/SuggestionPanel/types';
import { GlobalPanelKeys, GlobalPanelParams } from '../types/panel';

type FloatingProfileOptions = {
  spacing?: number;
};

export type FloatingProfileContextValue = {
  showUserProfile: (data: {
    userProfile: UserProfileObj;
    anchorEl: Element;
    extension?: FloatingProfileExtension;
    options?: FloatingProfileOptions;
  }) => void;
  showUserIdProfile: (data: {
    userId: string;
    anchorEl: Element;
    extension?: FloatingProfileExtension;
    options?: FloatingProfileOptions;
  }) => void;
  showAhaProfile: (data: {
    anchorEl: Element;
    extension?: FloatingProfileExtension;
    options?: FloatingProfileOptions;
  }) => void;
  hideProfile: () => void;
};

const initialValue: FloatingProfileContextValue = {
  showUserProfile: () => {},
  showUserIdProfile: () => {},
  showAhaProfile: () => {},
  hideProfile: () => {},
};

export const FloatingProfileContext =
  createContext<FloatingProfileContextValue>(initialValue);

/**
 * This provider should be placed inside BaseLayoutProvider,
 * because some functions will not work without it: openRightPanel
 */
export const FloatingProfileContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [anchorEl, setAnchorEl] = useState<Element>();
  const [profile, setProfile] = useState<FloatingProfileType>();
  const [extension, setExtension] = useState<FloatingProfileExtension>();
  const [options, setOptions] = useState<FloatingProfileOptions>();

  const { mutate: followingStatusMutate } = useFollowingStatusMutate();

  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const { views } = useDirectMessageViews();
  const { getThreadViewDetail } = useThreadViewDetails();

  const showUserProfile = (data: {
    userProfile: UserProfileObj;
    anchorEl: Element;
    extension?: FloatingProfileExtension;
    options?: FloatingProfileOptions;
  }) => {
    if (!mdUp) return;
    setAnchorEl(data.anchorEl);
    setProfile({
      type: 'userProfile',
      userProfile: data.userProfile,
    });
    setExtension(data.extension);
    setOptions(data.options);
  };

  const showUserIdProfile = (data: {
    userId: string;
    anchorEl: Element;
    extension?: FloatingProfileExtension;
    options?: FloatingProfileOptions;
  }) => {
    if (!mdUp) return;
    setAnchorEl(data.anchorEl);
    setProfile({
      type: 'userId',
      userId: data.userId,
    });
    setExtension(data.extension);
    setOptions(data.options);
  };

  const showAhaProfile = (data: {
    anchorEl: Element;
    extension?: FloatingProfileExtension;
    options?: FloatingProfileOptions;
  }) => {
    if (!mdUp) return;
    setAnchorEl(data.anchorEl);
    setProfile({
      type: 'aha',
    });
    setExtension(data.extension);
    setOptions(data.options);
  };

  const hideProfile = () => {
    setAnchorEl(undefined);
  };

  const prepareChallengeSource = useCallback(
    (user: UserProfileObj): SuggestionUser[] => {
      return [
        {
          id: user.userId,
          display: user.displayName || user.distinctName || '',
          avatar: user.nftAvatar || user.avatar || '',
          memberInfo: user,
        },
      ];
    },
    []
  );

  const handleMessageClick = useCallback(
    (userProfile: UserProfileObj) => {
      const memberId = userProfile.memberId;
      const existingView = views.find((view) => {
        const memberIds = getThreadViewDetail(view).viewMemberIds;
        return memberIds.length === 2 && memberIds.includes(memberId);
      });

      if (existingView) {
        void Router.push(`/direct-messages/view/${existingView.id}`);
      } else {
        void Router.push(`/direct-messages/view/${memberId}`);
      }
    },
    [getThreadViewDetail, views]
  );

  const handleChallengeClick = useCallback(
    (userProfile: UserProfileObj) => {
      const challengerSource = prepareChallengeSource(userProfile);
      openRightPanel(GlobalPanelKeys.GlobalStartChallenge, {
        challengerSource,
      });
    },
    [prepareChallengeSource, openRightPanel]
  );

  const handleToggleFollow = (userId: string, data: Promise<any>) => {
    followingStatusMutate(userId, data);
  };

  return (
    <FloatingProfileContext.Provider
      value={{
        showUserProfile,
        showUserIdProfile,
        showAhaProfile,
        hideProfile,
      }}
    >
      {children}
      <FloatingProfile
        anchorEl={anchorEl}
        {...profile}
        {...options}
        extension={extension}
        onClose={() => hideProfile()}
        onChallengeClick={handleChallengeClick}
        onMessageClick={handleMessageClick}
        onToggleFollow={handleToggleFollow}
      />
    </FloatingProfileContext.Provider>
  );
};
