import { useContext } from 'react';

import { EmojiPicker } from '../../EmojiPickerPanel';
import FilterContext from '../context/FilterContext';
import useFilterActions from '../hooks/useFilterActions';

type FilterEmojiPickerContentProps = {
  onEmojiSelected?: (ev: GetMarkEmojiRes | null) => void;
  visible: boolean;
};

export default function FilterEmojiPicker({
  onEmojiSelected,
  visible,
}: FilterEmojiPickerContentProps) {
  const { goPage } = useFilterActions();
  const [settings] = useContext(FilterContext);
  const { selectedCondition } = settings;

  const backToConditionDetail = () => {
    goPage({
      page: 'conditionDetail',
      selectedCondition,
      showEmojiPicker: false,
    });
  };

  const handleEmojiSelected = (emoji: GetMarkEmojiRes | null) => {
    onEmojiSelected?.(emoji);
    backToConditionDetail();
  };

  if (!visible) return null;
  return <EmojiPicker onChange={handleEmojiSelected} />;
}
