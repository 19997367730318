import { useCallback, useState } from 'react';
import Router from 'next/router';
import { useBaseRightPanel } from '@front/ui';
import { apis } from '@lib/web/apis';
import { useThreadViewDetails } from '@lib/web/thread/hooks/view/useThreadViewDetails';
import { useDirectMessageViews } from '@lib/web/thread/hooks/views/useDirectMessageViews';
import { ProfileButton } from '@lib/web/ui';
import { call } from '@lib/web/utils';

import { GlobalPanelKeys, GlobalPanelParams } from '../../types/panel';

type SendButtonProps = {
  userId: string;
  memberId: string;
  preloadedUserProfile?: UserProfileObj;
  size?: 'sm' | 'md';
  buttonType?: 'iconButton' | 'emphasizeButton' | 'button';
  messageDisabled?: boolean;
  challengeDisabled?: boolean;
};

export default function SendButton({
  memberId,
  userId,
  preloadedUserProfile,
  size,
  buttonType,
  messageDisabled,
  challengeDisabled,
}: SendButtonProps) {
  const [loading, setLoading] = useState(false);
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const { views } = useDirectMessageViews();
  const { getThreadViewDetail } = useThreadViewDetails();

  const handlePrepareChallengeSource = useCallback(async () => {
    if (preloadedUserProfile) {
      return [
        {
          id: preloadedUserProfile.userId,
          display:
            preloadedUserProfile.displayName ||
            preloadedUserProfile.distinctName ||
            '',
          avatar:
            preloadedUserProfile.nftAvatar || preloadedUserProfile.avatar || '',
          memberInfo: preloadedUserProfile,
        },
      ];
    }

    const [res] = await call(apis.member.getSocialProfile(userId));
    const socialData = res?.data;
    if (!socialData) return [];

    return [
      {
        id: socialData.userId,
        display: socialData.fullName || socialData.userName || '',
        avatar: socialData.avatarUrl || socialData.photoUrl || '',
        memberInfo: {
          userId: socialData.userId,
          avatar: socialData.photoUrl || '',
          displayName: socialData.fullName,
          distinctName: socialData.userName,
          nftAvatar: socialData.avatarUrl,
          indicator: socialData.indicator,
        },
      },
    ];
  }, [preloadedUserProfile, userId]);

  const handleMessageClick = useCallback(() => {
    setLoading(true);
    const existingView = views.find((view) => {
      const memberIds = getThreadViewDetail(view).viewMemberIds;
      return memberIds.length === 2 && memberIds.includes(memberId);
    });

    if (existingView) {
      void Router.push(`/direct-messages/view/${existingView.id}`);
    } else {
      void Router.push(`/direct-messages/view/${memberId}`);
    }
    setLoading(false);
  }, [getThreadViewDetail, memberId, views]);

  const handleChallengeClick = useCallback(async () => {
    setLoading(true);
    const challengerSource = await handlePrepareChallengeSource();
    setLoading(false);
    openRightPanel(GlobalPanelKeys.GlobalStartChallenge, {
      challengerSource,
    });
  }, [handlePrepareChallengeSource, openRightPanel]);

  return (
    <ProfileButton.Send
      loading={loading}
      size={size}
      buttonType={buttonType}
      onMessageClick={handleMessageClick}
      onChallengeClick={handleChallengeClick}
      messageDisabled={messageDisabled}
      challengeDisabled={challengeDisabled}
    />
  );
}
