import { useMemo } from 'react';

import { OrganizedTagType } from './types';

export default function useQuizOrganizedTags(
  data?: GetAnalyticsWeaknessTopicRes
) {
  return useMemo(() => {
    if (!data) {
      return [];
    }

    const result: OrganizedTagType[] = [];

    const updateResult = (
      tag: GetAnalyticsWeaknessTopicItemRes,
      type: 'mistake' | 'overtime' | 'notPracticedYet'
    ) => {
      const total = tag.totalCount;
      let currentResult = result.find((item) => item.id === tag.tagId);

      if (!currentResult) {
        currentResult = {
          id: tag.tagId,
          name: tag.tagName,
          code: tag.tagCode,
          total,
          mistake: { count: 0, total: 0 },
          overtime: { count: 0, total: 0 },
          notPracticedYet: { count: 0, total: 0 },
        };
        result.push(currentResult);
      }

      currentResult[type] = {
        count: tag.questionCount,
        total,
      };

      if (total > currentResult.total) {
        currentResult.total = total;
      }
    };

    data.mistake.forEach((tag) => updateResult(tag, 'mistake'));
    data.overtime.forEach((tag) => updateResult(tag, 'overtime'));
    data.notPracticedYet.forEach((tag) => updateResult(tag, 'notPracticedYet'));

    return result;
  }, [data]);
}
