import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateQuizPanelSimpleButton from '@app/web/src/components/CreateQuiz/CreateQuizPanelSimpleButton';
import { SuggestionUser } from '@app/web/src/components/SuggestionPanel/types';
import { MainChallenge as MainChallengeIcon } from '@front/icon';
import { IconButton, SimpleTooltip } from '@front/ui';
import { apis } from '@lib/web/apis';
import { call } from '@lib/web/utils';

export type DisplayTableChallengeButtonProps = {
  userId: string;
  clubSlug?: string;
};

export default function DisplayTableChallengeButton({
  userId,
  clubSlug,
}: DisplayTableChallengeButtonProps) {
  const { t } = useTranslation();
  const [challengerSource, setChallengerSource] = useState<SuggestionUser[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  if (!userId || !clubSlug) return null;

  const handleChallengeClick = async () => {
    setLoading(true);
    const [res] = await call(apis.member.getSocialProfile(userId));
    const socialData = res?.data;
    setLoading(false);

    if (!socialData) return;

    setChallengerSource([
      {
        id: socialData.userId,
        display: socialData.fullName || socialData.userName || '',
        avatar: socialData.avatarUrl || socialData.photoUrl || '',
        memberInfo: {
          userId: socialData.userId,
          avatar: socialData.photoUrl || '',
          displayName: socialData.fullName,
          distinctName: socialData.userName,
          nftAvatar: socialData.avatarUrl,
          indicator: socialData.indicator,
        },
      },
    ]);
  };

  return (
    <CreateQuizPanelSimpleButton
      withPanel
      challengerSource={challengerSource}
      defaultValues={{ challengers: [userId] }}
      onBeforeOpenPanel={handleChallengeClick}
      panelKeyPrefix={userId}
      clubSlug={clubSlug}
      renderButton={({ onClick }) => (
        <SimpleTooltip title={t('Challenge')} disableInteractive>
          <IconButton onClick={onClick} loading={loading} customSize={24}>
            <MainChallengeIcon />
          </IconButton>
        </SimpleTooltip>
      )}
    />
  );
}
