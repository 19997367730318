import { RefObject, useEffect } from 'react';

type BottomScrolledParams = {
  scrollRef: RefObject<HTMLDivElement>;
  onScrolledBottom?: () => void;
};

export default function useBottomScrolled({
  scrollRef,
  onScrolledBottom,
}: BottomScrolledParams) {
  useEffect(() => {
    const scrollTarget = scrollRef.current;
    const handleScroll = (ev: Event) => {
      const target = ev.target as HTMLDivElement;
      if (target) {
        const isBottom =
          target.scrollTop + target.offsetHeight >= target.scrollHeight;
        if (isBottom) {
          onScrolledBottom?.();
        }
      }
    };

    if (scrollTarget && onScrolledBottom) {
      scrollTarget.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollTarget)
        scrollTarget.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScrolledBottom]);

  useEffect(() => {
    let child: HTMLDivElement | null = null;

    if (scrollRef.current && onScrolledBottom) {
      child = (scrollRef.current.children[0] as HTMLDivElement) || null;

      if (child) {
        const parentHeight = scrollRef.current.offsetHeight;
        const childHeight = child.offsetHeight;
        if (parentHeight && childHeight <= parentHeight) {
          onScrolledBottom?.();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScrolledBottom]);
}
