import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Theme, useMediaQuery } from '@mui/material';
import { toast } from '@front/ui';
import { apis } from '@lib/web/apis';
import { parseFilterConfig } from '@lib/web/thread/utils/viewUtils';
import { callWithToast } from '@lib/web/utils';

import { useAppDispatch } from '../../hooks/redux';
import { updateLayoutType } from '../../reducers/iaLayoutReducer';

const getAgentUrl = ({ clubSlug, id }: { clubSlug?: string; id: string }) => {
  if (!clubSlug) console.warn(`Cannot find agent id club : ${id}`);
  return clubSlug ? `/club/${clubSlug}/agent/form/${id}/settings` : '/';
};

export const useIaAgentActions = () => {
  const router = useRouter();
  const { t } = useTranslation('agent');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const dispatch = useAppDispatch();

  const addMyAgent = ({
    id,
    toastAnchorEl,
  }: {
    id: string;
    toastAnchorEl?: HTMLElement | null;
  }) => {
    return callWithToast(() => apis.agent.addMyAgent({ agentId: id }), {
      errorMsg: 'Add Failed',
      anchorEl: toastAnchorEl || undefined,
    });
  };

  return useMemo(
    () => ({
      openEditAgentPage: ({
        id,
        clubSlug,
      }: {
        id: string;
        clubSlug: string;
      }) => {
        void router.push(getAgentUrl({ clubSlug, id }));
      },

      duplicateAgent: ({
        id,
        clubSlug,
      }: {
        id: string;
        clubSlug: string;
        toastAnchorEl?: HTMLElement | null;
      }) => {
        void router.push(
          `/club/${clubSlug}/agent/form/settings?&duplicateFrom=${id}`
        );
      },

      addMyAgent,

      removeMyAgent: ({ id }: { id: string }) => {
        return callWithToast(() => apis.agent.removeMyAgent({ agentId: id }), {
          errorMsg: 'Unadd Failed',
        });
      },

      directMessage: async ({
        views,
        agentId,
      }: {
        views: GetThreadViewRes[];
        agentId: string;
      }) => {
        dispatch(updateLayoutType('channel'));
        const [res] = await addMyAgent({ id: agentId });

        if (res) {
          const openAgentMemberId = `agent_${agentId}`;
          const existingView = views.find((view) => {
            const { memberIds } = parseFilterConfig(
              view.threadViewUsers[0].filterConfig
            );
            return (
              memberIds.length === 2 && memberIds.includes(`agent_${agentId}`)
            ); // only me and target agent
          });
          if (existingView) {
            void router.push({
              pathname: `/direct-messages/view/${existingView.id}`,
              query: { openAgentMemberId },
            });
          } else {
            if (!mdUp) {
              toast.info(
                t(
                  'agent::detail.message.toast',
                  'Messages sent to a not followed AI will automatically follow it.'
                )
              );
            }
            void router.push({
              pathname: `/direct-messages/view/${openAgentMemberId}`,
              query: { openAgentMemberId },
            });
          }
        }
      },
    }),
    [dispatch, mdUp, router, t]
  );
};
