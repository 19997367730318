import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useFilter } from '@app/web/src/hooks/utils/useFilter';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { Button, Scrollbar, useBaseLayout } from '@front/ui';
import FilterCondition from '@lib/ia/src/filter/FilterCondition';
import { FilterConditionHandler } from '@lib/ia/src/filter/FilterCondition/FilterCondition';
import {
  FilterConditionChangedEvent,
  FilterConditionConfig,
} from '@lib/ia/src/filter/types';
import { apis } from '@lib/web/apis';
import { call } from '@lib/web/utils';
import { v4 } from 'uuid';

import FilterEmojiPicker from './FilterEmojiPicker';

const styles = {
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: '60px',
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  action: {
    display: { xs: 'flex', md: 'block' },
    textAlign: { md: 'right' },
    flexDirection: 'column',
    gap: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    p: 1.5,
  },
  hidden: {
    display: 'none',
  },
};

export default function FilterConditionContent() {
  const { t } = useTranslation();
  const { openRightPanel, getRightParams } = useBaseLayout<GlobalPanelParams>();
  const { upsertCondition } = useFilter();
  const filterConditionRef = useRef<FilterConditionHandler>(null);

  const [data, setData] = useState<FilterConditionChangedEvent>();

  const { selectedCondition, showEmojiPicker } = getRightParams(
    GlobalPanelKeys.GlobalFilter
  );

  const handleAddFilter = () => {
    if (!data || !data.isValid || !data.operator || !data.field) {
      return;
    }
    const currentCondition: FilterConditionConfig = {
      id: data.id || v4(),
      field: data.field,
      operator: data.operator,
      values: data.values,
      disabled: false,
    };
    upsertCondition(currentCondition);
    openRightPanel(GlobalPanelKeys.GlobalFilter, { page: 'querySummary' });
  };

  const handleChanged = (event: FilterConditionChangedEvent) => {
    setData(event);
  };

  const handleOpenEmojiPicker = () => {
    openRightPanel(GlobalPanelKeys.GlobalFilter, {
      page: 'conditionDetail',
      selectedCondition,
      showEmojiPicker: true,
    });
  };

  const handleEmojiSelected = (emoji: GetMarkEmojiRes | null) => {
    if (emoji) {
      filterConditionRef?.current?.addEmoji({
        name: emoji.name,
        label: emoji.code,
        value: emoji.code,
      });
    }
  };

  const searchUsers = async ({ keyword }: { keyword: string }) => {
    const [res] = await call(apis.member.searchMember({ keyword }));
    return (
      res?.data?.items.map((user) => ({
        label: user.displayName,
        value: user.userId,
        memberId: user.memberId,
        avatarUrl: user.nftAvatar || user.avatar,
      })) || []
    );
  };

  if (!selectedCondition) return null;

  return (
    <>
      <FilterEmojiPicker
        onEmojiSelected={handleEmojiSelected}
        visible={!!showEmojiPicker}
      />
      <Scrollbar sx={[styles.scroll, !!showEmojiPicker && styles.hidden]}>
        <FilterCondition
          {...selectedCondition}
          onChange={handleChanged}
          onOpenEmojiPicker={handleOpenEmojiPicker}
          onEnter={handleAddFilter}
          searchUsers={searchUsers}
          ref={filterConditionRef}
        />
      </Scrollbar>
      <Box sx={[styles.action, !!showEmojiPicker && styles.hidden]}>
        <Button onClick={handleAddFilter} disabled={!data?.isValid}>
          {!data?.id
            ? t('filter.buttons.addFilter')
            : t('filter.buttons.updateFilter')}
        </Button>
      </Box>
    </>
  );
}
