import { useContext } from 'react';

import SortContext from '../context/SortContext';

import SortCriteriaContent from './SortCriteriaContent';
import SortFieldsContent from './SortFieldsContent';
import SortSummaryContent from './SortSummaryContent';

export default function FilterPanelContent() {
  const [settings] = useContext(SortContext);
  const { page } = settings;

  if (!page || page === 'sortSummary') return <SortSummaryContent />;
  if (page === 'criteriaDetail') return <SortCriteriaContent />;
  if (page === 'fieldList') return <SortFieldsContent />;
}
