import { MutableRefObject, useContext, useMemo, useRef } from 'react';
import { useLatestValueRef } from '@front/helper';
import { FormLayoutMethodsContext } from '@lib/ia/src/layouts/FormLayout';
import { format } from 'date-fns';

import {
  SetGoalFormFields,
  SetGoalFormValuesTypes,
  SetGoalParams,
} from './config';

export default function useSetGoalActions({
  defaultValues,
  fullScore,
  bottomScore,
  onSubmit,
}: {
  defaultValues: SetGoalFormValuesTypes;
  fullScore: number;
  bottomScore: number;
  onSubmit: (ev: SetGoalParams) => void;
}) {
  const prevLastScore = useRef<number>(
    defaultValues[SetGoalFormFields.TargetScore]
  );
  const { methods } = useContext(FormLayoutMethodsContext);
  const methodsRef = useLatestValueRef(methods);
  const submitRef = useLatestValueRef(onSubmit);
  const lastScoreRef = useRef<number>(
    defaultValues[SetGoalFormFields.TargetScore]
  );
  const lastSetMaxScoreRef = useRef<boolean>(
    defaultValues[SetGoalFormFields.SetMaxScore]
  );

  return useMemo(() => {
    function updateValues<V>(key: string, ref: MutableRefObject<V>, value: V) {
      methodsRef.current.setValue?.(key, value, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
      ref.current = value;
    }
    return {
      submitSettings: {
        // inputType = number will return string type
        action: (newValues: MappingNumberToString<SetGoalFormValuesTypes>) => {
          submitRef.current({
            targetDate: format(
              newValues[SetGoalFormFields.TargetDate],
              'yyyy-MM-dd'
            ),
            targetScore: +newValues[SetGoalFormFields.TargetScore],
            mockExamRoundCount: +newValues[SetGoalFormFields.DailyMocks],
            practiceTimeMin: +newValues[SetGoalFormFields.DailyTimes],
            questionNumbers: +newValues[SetGoalFormFields.DailyQuestions],
          });
        },
      },
      [`${SetGoalFormFields.TargetScore}Changed`]: {
        action: ({ value }: { value: string }) => {
          lastScoreRef.current = +value;
          prevLastScore.current = lastScoreRef.current;

          // If the value in the Target Score field == as maximum score, the toggle will be turned on
          if (
            lastScoreRef.current === fullScore &&
            lastSetMaxScoreRef.current === false
          ) {
            updateValues(
              SetGoalFormFields.SetMaxScore,
              lastSetMaxScoreRef,
              true
            );
          } else if (
            lastScoreRef.current !== fullScore &&
            lastSetMaxScoreRef.current === true
          ) {
            updateValues(
              SetGoalFormFields.SetMaxScore,
              lastSetMaxScoreRef,
              false
            );
          }
        },
      },
      [`${SetGoalFormFields.SetMaxScore}Changed`]: {
        action: ({ value }: { value: boolean }) => {
          lastSetMaxScoreRef.current = value;
          if (
            lastSetMaxScoreRef.current === false &&
            lastScoreRef.current === fullScore
          ) {
            // If user click to turned off the toggle, the score will be back to the previous recorded score
            // If there is no previous recorded score, the score will be half of the maximum score
            if (prevLastScore.current === fullScore || !prevLastScore.current) {
              updateValues(
                SetGoalFormFields.TargetScore,
                lastScoreRef,
                bottomScore + (fullScore - bottomScore) / 2
              );
            } else {
              updateValues(
                SetGoalFormFields.TargetScore,
                lastScoreRef,
                prevLastScore.current
              );
            }
          } else if (
            lastSetMaxScoreRef.current === true &&
            lastScoreRef.current !== fullScore
          ) {
            updateValues(
              SetGoalFormFields.TargetScore,
              lastScoreRef,
              fullScore
            );
          }
        },
      },
    };
  }, [methodsRef, submitRef, fullScore, bottomScore]);
}
