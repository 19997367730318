import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { ThreadSearchUserIconListLayoutItemObj } from '@app/web/src/components/ThreadSearchUsers';
import { useThreadSearchUserLayoutConfig } from '@app/web/src/components/ThreadSearchUsers/hooks/useThreadSearchUserLayoutConfig';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { getSearchState, SearchState } from '@app/web/src/utils/search';
import {
  LightbulbCard,
  SearchBar,
  SquareAvatar,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import { useSearchStatus } from '@lib/web/hooks';
import { useInView } from '@react-spring/web';

import { useSearchAgents } from './hooks/useSearchAgents';
import { useSearchGroups } from './hooks/useSearchGroups';
import { useSearchUsers } from './hooks/useSearchUsers';

const styles = {
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    position: 'relative',
  },
};

export type ThreadSearchUsersProps = {
  sx?: BoxProps['sx'];
  selected: ThreadSearchUserIconListLayoutItemObj[];
  setSelected: Dispatch<
    SetStateAction<ThreadSearchUserIconListLayoutItemObj[]>
  >;
  preselectedMemberIds?: string[];
  preselectedDescription?: string;
  forceAllSelected?: boolean;
  onSelected?: () => void;
  enableSearchThreadGroups?: boolean;
  enableSearchClubHelpAgent?: boolean;
  searchPlaceholder?: string;
  lightbulbTip?: string;
};

export default function ThreadSearchUsers({
  sx,
  selected,
  setSelected,
  preselectedMemberIds,
  preselectedDescription,
  forceAllSelected,
  onSelected,
  enableSearchThreadGroups = true,
  enableSearchClubHelpAgent = false,
  searchPlaceholder,
  lightbulbTip,
}: ThreadSearchUsersProps) {
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const [searchInputRef, inView] = useInView();

  const {
    focused,
    search,
    isSearching,
    debouncedSearch,
    setSearch,
    onChange,
    onBlur,
    onFocus,
  } = useSearchStatus();

  const listState = getSearchState({
    isSearching,
    isFocused: focused,
    hasSelected: !forceAllSelected && selected.length > 0, // if forceAllSelected is true, we don't need to show selected users
  });

  const searchUsers = useSearchUsers({
    isSearching,
    debouncedSearch,
    preselectedMemberIds,
    preselectedDescription,
  });
  const searchAgents = useSearchAgents({
    isSearching,
    debouncedSearch,
    preselectedMemberIds,
    preselectedDescription,
    enableSearchClubHelpAgent,
  });
  const searchGroups = useSearchGroups({
    debouncedSearch,
    preselectedMemberIds,
    preselectedDescription,
    enable: enableSearchThreadGroups,
  });

  const showSkeleton =
    searchUsers.initialApiLoading || searchAgents.initialApiLoading;

  const selectedDataset: ThreadSearchUserIconListLayoutItemObj[] =
    useMemo(() => {
      return selected.map((result) => ({
        id: result.id,
        title: result.title,
        titleAction: result.titleAction,
        description: result.description,
        avatarTitle: result.avatarTitle,
        avatarUrl: result.avatarUrl,
        avatarBadgeCount: result.avatarBadgeCount,
        indicators: result.indicators,
        actionMap: {
          select: {
            value: 'itemSelected',
          },
        },
        label: result.label,
        metadata: result.metadata,
      }));
    }, [selected]);

  const showLightbulbCard = useMemo(() => {
    return !focused && !isSearching && selectedDataset.length === 0;
  }, [focused, isSearching, selectedDataset.length]);

  const displayDataset: ThreadSearchUserIconListLayoutItemObj[] =
    useMemo(() => {
      if (listState === SearchState.Searching) {
        if (showSkeleton) {
          return [];
        }

        return [
          ...searchAgents.searchingDataset,
          ...searchGroups.searchingDataset,
          ...searchUsers.searchingDataset,
        ];
      }
      if (listState === SearchState.Initial) {
        return [
          ...searchAgents.initialDataset,
          ...searchGroups.initialDataset,
          ...searchUsers.initialDataset,
        ];
      }

      return [];
    }, [
      listState,
      searchAgents.initialDataset,
      searchAgents.searchingDataset,
      searchGroups.initialDataset,
      searchGroups.searchingDataset,
      searchUsers.initialDataset,
      searchUsers.searchingDataset,
      showSkeleton,
    ]);

  const config = useThreadSearchUserLayoutConfig({
    listState,
    selectedDataset,
    displayDataset,
  });

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );

  const selectUser = () => {
    setSearch('');
    searchInputRef.current?.focus();
    onSelected?.();
  };

  const availableActions = {
    itemSelected: {
      action: (value: ThreadSearchUserIconListLayoutItemObj) => {
        setSelected((st) =>
          st.some((item) => item.id === value.id)
            ? st.filter((item) => item.id !== value.id)
            : [...st, value]
        );

        selectUser();
      },
    },
    itemHovered: {
      action: updateSuggestItem,
    },
    userTitleClick: {
      action: (value: IconListLayoutItemObj) => {
        openRightPanel(GlobalPanelKeys.GlobalProfile, {
          userId: value.id,
        });
        setSearch('');
      },
    },
    agentTitleClick: {
      action: (value: IconListLayoutItemObj) => {
        openRightPanel(GlobalPanelKeys.GlobalAgentProfile, {
          agentId: value.id,
        });
        setSearch('');
      },
    },
  };

  const getItemStatus = ({
    id,
    metadata,
  }: ThreadSearchUserIconListLayoutItemObj) => {
    const preselected = metadata?.threadMemberIds.every((memberId) =>
      preselectedMemberIds?.includes(memberId)
    );

    return {
      selected:
        forceAllSelected ||
        preselected ||
        selected.some((item) => item.id === id),
      focused: id === suggestItem?.id,
      disabled: preselected,
    };
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (
      event.key === 'Enter' &&
      suggestItem &&
      !!suggestItem.actionMap?.select
    ) {
      event.preventDefault();

      const status = getItemStatus(suggestItem);
      if (status.disabled || status.selected) return;

      setSelected((st) => [...st, suggestItem]);
      selectUser();
    }
  };

  useEffect(() => {
    if (inView) {
      searchInputRef.current?.focus();
    }
  }, [inView, searchInputRef]);

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <Box sx={[styles.main, ...sxProps]}>
          <Box className="search-bar">
            <SearchBar
              inputRef={searchInputRef}
              placeholder={searchPlaceholder}
              value={search}
              loading={
                searchUsers.searchingApiLoading ||
                searchAgents.searchingApiLoading
              }
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyDown={handleKeyDown}
              suggestText={suggestItem?.title}
              prefixIcon={
                suggestItem && (
                  <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                    {suggestItem.title}
                  </SquareAvatar>
                )
              }
            />
            {showLightbulbCard && lightbulbTip && (
              <LightbulbCard>{lightbulbTip}</LightbulbCard>
            )}
          </Box>
          <Box sx={styles.content}>
            {config && <IaLayouts layouts={config} />}
          </Box>
        </Box>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
