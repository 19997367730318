import CreateQuizByPanel from '@app/web/src/components/CreateQuiz/CreateQuizByPanel';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';

export default function StartChallengePanel() {
  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();
  const { challengerSource } = getRightParams(
    GlobalPanelKeys.GlobalStartChallenge
  );

  const challengers = challengerSource?.map((challenger) => challenger.id);

  return (
    <CreateQuizByPanel
      panelKeyPrefix={GlobalPanelKeys.GlobalStartChallenge}
      startPanelKey=""
      challengerSource={challengerSource}
      defaultValues={{ challengers }}
    />
  );
}
