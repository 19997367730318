import { useContext } from 'react';
import { PrivatePanelKeys } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';

import CreateQuizContext from '../context';

export default function useClosePanel() {
  const [{ variant, panelKeyPrefix }] = useContext(CreateQuizContext);
  const { openRightPanel, rightPanelHistory } = useBaseRightPanel();

  const createQuizPanelKey = `${panelKeyPrefix}${PrivatePanelKeys.CreateQuiz}`;
  // if the panel is opened from the center page,
  // or open from somewhere not from the `CreateQuiz` panel
  // => then should not open the `CreateQuiz` again
  return variant === 'page' || !rightPanelHistory.contains(createQuizPanelKey)
    ? undefined
    : () => openRightPanel(createQuizPanelKey);
}
