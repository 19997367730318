import { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';

const styles = {
  root: {
    display: 'grid',
    gap: 1,
  },
  title: {
    opacity: 0.5,
  },
  container: {
    minWidth: 0,
  },
};

export default function Section({
  title,
  children,
}: PropsWithChildren<{ title: string }>) {
  return (
    <Box sx={styles.root}>
      <Typography sx={styles.title} variant="body2">
        {title}
      </Typography>
      <Box sx={styles.container}>{children}</Box>
    </Box>
  );
}
