import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePathname } from 'next/navigation';
import { Theme, useMediaQuery } from '@mui/material';
import { ChallengerIconListLayoutItemObj } from '@app/web/src/components/CreateQuiz/type';
import getActiveQuizButtonEl from '@app/web/src/components/CreateQuiz/utils/getActiveQuizButtonEl';
import useAhaInvitation from '@app/web/src/hooks/utils/useAhaInvitation';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { emailRegex } from '@app/web/src/utils/regex';
import { getSearchState, SearchState } from '@app/web/src/utils/search';
import { MainChallenge as MainChallengeIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  SearchBar,
  SquareAvatar,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import {
  apis,
  useAuth,
  useChallengeRank,
  useSearchClubMembers,
  useUserFollow,
} from '@lib/web/apis';
import {
  useClubSlug,
  useInfiniteScroll,
  useSearchStatus,
} from '@lib/web/hooks';
import { call, getIndicators } from '@lib/web/utils';

import useOpenGlobalProfilePanel from '../hooks/useOpenGlobalProfilePanel';

const styles = {
  root: {
    pt: 1,
    '& .ia-icon-list-layout': {
      py: 0,
    },
    '& .ia-icon-list-layout_title': {
      py: 0.5,
    },
  },
  searchBar: {
    '& .search-input-wrap': {
      py: '12px',
    },
  },
};

type TimeoutMap = Record<string, NodeJS.Timeout>;

type InvitationState = 'Inviting' | 'Invited' | 'Added' | 'Sending' | 'Sent';
type InvitationStateMap = Record<string, InvitationState>;

const ADD_DELAY = 3000;
const CLEAR_SENT_DELAY = 3000;

function refKey(id: string, state: InvitationState) {
  return `${id}_${state}`;
}

function getItemClickAction(
  id: string,
  invitationStateMap: InvitationStateMap,
  isChallenger?: boolean
) {
  const state = invitationStateMap[id];
  const addAction = {
    actionType: 'textButton' as const,
    text: 'Invite',
    type: 'event' as const,
    value: 'add',
  };
  const undoAction = {
    actionType: 'textButton' as const,
    text: 'Undo',
    type: 'event' as const,
    value: 'undo',
  };
  const sentAction = {
    actionType: 'textButton' as const,
    text: 'Invite Sent',
    type: 'event' as const,
    value: 'inviteSent',
  };
  if (state === 'Added') return undoAction;
  if (state === 'Sent') return sentAction;
  return isChallenger ? undefined : addAction;
}

const mapToChallengeObj = (
  item: GetChallengeRankingRes,
  invitationStateMap: InvitationStateMap
): ChallengerIconListLayoutItemObj => {
  const clickAction = getItemClickAction(item.userId, invitationStateMap, true);
  return {
    id: item.userId,
    title: item.displayName || item.userName || '',
    avatarUrl: item.nftAvatar || item.avatar || '',
    indicators: getIndicators(item.indicator),
    metadata: {
      userId: item.userId,
      isFollowing: true,
      disabledUpdate: true,
    },
    titleAction: { type: 'event', value: 'titleClick' },
    description: 'Challenger',
    actionMap: {
      hover: {
        value: 'userHovered',
      },
      click: clickAction,
    },
  };
};
export default function ChallengeFriendsPanel() {
  const { t } = useTranslation('quiz');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const pathname = usePathname();

  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();
  const { challengeId } = getRightParams(GlobalPanelKeys.GlobalAddChallenger);

  const { userId, member } = useAuth();
  const { openProfile, defaultSearch } = useOpenGlobalProfilePanel();
  const { inviteToAha, newUserFormatter } = useAhaInvitation();
  const searchInputRef = useRef<HTMLInputElement>();
  const [invitationStateMap, setInvitationStateMap] =
    useState<InvitationStateMap>({});

  const timeoutRefs = useRef<TimeoutMap>({});
  const { data: challengeRankData, mutate } = useChallengeRank(challengeId);

  const setInvitationState = useCallback(
    (id: string, state: InvitationState) => {
      setInvitationStateMap((data) => ({ ...data, [id]: state }));
    },
    []
  );

  const removeInvitationState = useCallback(
    (id: string) => {
      const { [id]: removed, ...rest } = invitationStateMap;
      setInvitationStateMap(rest);
    },
    [invitationStateMap]
  );

  const {
    focused,
    search,
    isSearching,
    debouncedSearch,
    onChange,
    onBlur,
    onFocus,
  } = useSearchStatus(defaultSearch);

  const clubSlug = useClubSlug();

  const userFollowingData = useUserFollow({
    type: 'following',
    userId,
    keyword: debouncedSearch,
    isGetStatus: true,
  });

  const userSearchData = useSearchClubMembers({
    clubSlug,
    keyword: isSearching ? debouncedSearch : '',
    isExcludeMe: true,
  });

  const { dataset: friendDataset, totalCount: friendTotalCount } =
    userFollowingData;

  const {
    dataset: searchDataset,
    isLoading: searchLoading,
    totalCount: searchTotalCount,
  } = userSearchData;

  const listState = getSearchState({
    isSearching,
    isFocused: focused,
    hasSelected: true,
  });

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse:
      listState === SearchState.Initial ? userFollowingData : userSearchData,
  });

  const totalCount =
    listState === SearchState.Initial ? friendTotalCount : searchTotalCount;

  const challengersValue = useMemo(() => {
    return (
      challengeRankData?.data.items
        .filter((item) => item.userId !== member?.userId)
        .map((item) => mapToChallengeObj(item, invitationStateMap)) || []
    );
  }, [challengeRankData?.data.items, member?.userId, invitationStateMap]);

  const displayDataset: ChallengerIconListLayoutItemObj[] = useMemo(() => {
    if (listState === SearchState.Initial) {
      return friendDataset.map((item) => {
        const clickAction = getItemClickAction(item.userId, invitationStateMap);
        return {
          id: item.userId,
          title: item.displayName,
          titleAction: { type: 'event', value: 'titleClick' },
          indicators: getIndicators(item.indicator),
          description: `@${item.userName}`,
          avatarUrl: item.nftAvatar || item.avatar,
          actionMap: {
            click: clickAction,
            hover: {
              value: 'userHovered',
            },
          },
          metadata: {
            userId: item.userId,
            isFollowing: true,
          },
        };
      });
    }
    if (listState === SearchState.Searching) {
      return searchDataset.map((item) => {
        const clickAction = getItemClickAction(item.userId, invitationStateMap);
        return {
          id: item.userId,
          title: item.displayName,
          titleAction: { type: 'event', value: 'titleClick' },
          indicators: getIndicators(item.indicator),
          description: `@${item.userName}`,
          avatarUrl: item.nftAvatar || item.avatar,
          actionMap: {
            click: clickAction,
            hover: {
              value: 'userHovered',
            },
          },
          metadata: {
            userId: item.userId,
            isFollowing: item.isFollowing,
          },
        };
      });
    }

    return [];
  }, [listState, friendDataset, invitationStateMap, searchDataset]);

  const newUserEmail =
    isSearching &&
    !searchDataset.find((d) => d.email === debouncedSearch) &&
    !searchLoading &&
    emailRegex.test(debouncedSearch)
      ? debouncedSearch
      : '';
  const selectedCount = challengersValue.length + 1;

  const isSelected = useCallback(
    (id: string) => {
      return challengersValue.some((challenge) => challenge.id === id);
    },
    [challengersValue]
  );

  const config: IaLayoutConfig[] = useMemo(() => {
    if (!member) {
      return [];
    }

    const myData: ChallengerIconListLayoutItemObj = {
      id: member.userId,
      title: member.displayName,
      titleAction: { type: 'event', value: 'titleClick' },
      titleSuffix: 'Me',
      indicators: getIndicators(member.indicator),
      description: 'Challenger',
      avatarUrl: member.avatarUrl || member.photoUrl,
      actionMap: {
        hover: {
          value: 'userHovered',
        },
      },
    };

    const selectedItems =
      selectedCount > 0
        ? [
            t('## Challenger', { count: selectedCount }),
            myData,
            ...challengersValue,
          ]
        : [];

    if (listState === SearchState.Initial) {
      if (displayDataset.length === 0) {
        return [
          {
            layout: 'icon-list-layout',
            items: selectedItems,
          },
        ];
      }

      const selectedFollowing = challengersValue.filter(
        (c) =>
          c.metadata?.isFollowing || displayDataset.some((d) => d.id === c.id)
      );
      const followingTotalCount = totalCount
        ? totalCount - selectedFollowing.length
        : 0;

      return [
        {
          layout: 'icon-list-layout',
          items: selectedItems.concat(
            [t('## Following', { count: followingTotalCount || 0 })],
            displayDataset.filter((d) => !isSelected(d.id))
          ),
        },
      ];
    }

    if (listState === SearchState.Searching) {
      if (!searchLoading && displayDataset.length === 0 && !newUserEmail)
        return [
          {
            layout: 'icon-list-layout',
            items: [t('No result found')],
          },
        ];

      const newUser = newUserFormatter(newUserEmail);
      if (newUser) {
        return [
          {
            layout: 'icon-list-layout',
            items: [
              {
                id: newUser.email,
                title: newUser.email,
                actionMap: {
                  click: {
                    type: 'event',
                    value: 'inviteToAha',
                    text: t(
                      `challenger.invite.aha.label.${newUser.actionText}`
                    ),
                    actionType: 'textButton',
                    disabled:
                      newUser.actionText === 'accepted' ||
                      newUser.actionText === 'pending',
                  },
                },
              },
            ],
          },
        ];
      }

      const filteredDataset = displayDataset.filter((d) => !isSelected(d.id));
      const filteredTotalCount = totalCount
        ? totalCount - (displayDataset.length - filteredDataset.length)
        : 0;

      return [
        {
          layout: 'icon-list-layout',
          items: selectedItems.concat(
            [t('Results', { count: filteredTotalCount })],
            filteredDataset
          ),
        },
      ];
    }

    return [
      {
        layout: 'icon-list-layout',
        items: [
          t('## Challenger', { count: selectedCount }),
          myData,
          ...challengersValue,
        ],
      },
    ];
  }, [
    member,
    selectedCount,
    t,
    challengersValue,
    listState,
    displayDataset,
    totalCount,
    isSelected,
    searchLoading,
    newUserEmail,
    newUserFormatter,
  ]);

  /**
   * show "Sent" action for item with the id
   * this action will be removed automatically after CLEAR_SENT_DELAY
   * @param id
   */
  const showSentAction = (id: string) => {
    setInvitationState(id, 'Sent');
    timeoutRefs.current[refKey(id, 'Sent')] = setTimeout(() => {
      removeInvitationState(id);
      clearTimeout(timeoutRefs.current[refKey(id, 'Sent')]);
      delete timeoutRefs.current[refKey(id, 'Sent')];
    }, CLEAR_SENT_DELAY);
  };

  const toastOptions = {
    anchorEl: getActiveQuizButtonEl(),
  };

  const addChallengerToExistChallenge = async (id: string) => {
    setInvitationState(id, 'Sending');
    await call(() =>
      apis.challenge.sentChallengeInvitation({
        challengeId: challengeId || '',
        userIds: [id],
        emails: [],
      })
    );
    await mutate();
    showSentAction(id);
  };

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );

  const getItemStatus = ({ id }: ChallengerIconListLayoutItemObj) => {
    const loading =
      invitationStateMap[id] === 'Sending' ||
      invitationStateMap[id] === 'Inviting';

    return {
      selected: false,
      disabled:
        false ||
        challengeRankData?.data.items.some((item) => item.userId === id),
      loading: loading,
      focused: mdUp && suggestItem?.id === id,
    };
  };

  const availableActions = {
    userHovered: {
      action: updateSuggestItem,
    },
    inviteToAha: {
      action: async (value: ChallengerIconListLayoutItemObj) => {
        setInvitationState(value.id, 'Inviting');
        await inviteToAha([value.id], { toastOptions });
        setInvitationState(value.id, 'Invited');
      },
    },
    titleClick: {
      action: (value: IconListLayoutItemObj) => {
        openProfile(value.id, search);
      },
    },
    add: {
      action: (value: ChallengerIconListLayoutItemObj) => {
        searchInputRef?.current?.focus();
        setInvitationState(value.id, 'Added');
        timeoutRefs.current[refKey(value.id, 'Added')] = setTimeout(() => {
          addChallengerToExistChallenge(value.id);
          clearTimeout(timeoutRefs.current[refKey(value.id, 'Added')]);
          delete timeoutRefs.current[refKey(value.id, 'Added')];
        }, ADD_DELAY);
      },
    },
    undo: {
      action: (value: ChallengerIconListLayoutItemObj) => {
        searchInputRef?.current?.focus();
        clearTimeout(timeoutRefs.current[refKey(value.id, 'Added')]);
        delete timeoutRefs.current[refKey(value.id, 'Added')];
        removeInvitationState(value.id);
      },
    },
    inviteSent: {
      action: () => {
        // do nothing
      },
    },
  };

  useEffect(() => {
    const timeouts = timeoutRefs.current || {};
    return () => {
      for (const [key, value] of Object.entries(timeouts)) {
        clearTimeout(value);
        // Add challenger immediately when switch pages
        if (key.endsWith('Added')) {
          const id = key.replace('_Added', '');
          addChallengerToExistChallenge(id);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <BaseLayoutRightPanel
      titleIcon={<MainChallengeIcon width="16" height="16" />}
      title="Challenge"
    >
      <IaItemStatusProvider getItemStatus={getItemStatus}>
        <IaActionContextProvider availableActions={availableActions}>
          <BaseLayoutRightPanel.SearchWrapper>
            <BaseLayoutRightPanel.SearchInput>
              <SearchBar
                inputRef={searchInputRef}
                placeholder={t(
                  'search.placeholder_friends',
                  'Search friends...'
                )}
                value={search}
                loading={searchLoading}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                suggestText={suggestItem?.title}
                prefixIcon={
                  suggestItem && (
                    <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                      {suggestItem.title}
                    </SquareAvatar>
                  )
                }
                sx={styles.searchBar}
              />
            </BaseLayoutRightPanel.SearchInput>
            <BaseLayoutRightPanel.SearchContent>
              <BaseLayoutRightPanel.ScrollArea
                scrollableNodeProps={{ ref: scrollRef }}
              >
                {config && <IaLayouts layouts={config} />}
              </BaseLayoutRightPanel.ScrollArea>
            </BaseLayoutRightPanel.SearchContent>
          </BaseLayoutRightPanel.SearchWrapper>
        </IaActionContextProvider>
      </IaItemStatusProvider>
    </BaseLayoutRightPanel>
  );
}
