import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useBaseRightPanel } from '@front/ui';

import RedoCreateQuizPanels from '../RedoCreateQuizPanels';

import BaseRedoButton from './BaseRedoButton';

type StartRedoButtonProps = {
  tooltip?: {
    icon: string;
    title: string;
    content: ReactNode;
  };
  panelKeyPrefix?: string;
};

export default function StartRedoButton({
  tooltip,
  panelKeyPrefix,
}: StartRedoButtonProps) {
  const { openRightPanel } = useBaseRightPanel();
  const { t } = useTranslation();
  return (
    <BaseRedoButton
      label={t('button.Start')}
      tooltip={tooltip}
      onClick={() =>
        openRightPanel(
          `${panelKeyPrefix}${RedoCreateQuizPanels.PanelKeys.Practice}`
        )
      }
    />
  );
}
