import { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';

import CreateQuizContext from '../../context';
import { Placement } from '../../type';

import IncognitoModeSwitch from './IncognitoModeSwitch';
import Section from './Section';
import TimerSwitch from './TimerSwitch';

export default function TimerVisibilitySection({
  placement,
}: {
  placement: Placement;
}) {
  const { t } = useTranslation('quiz');
  const [createQuizSettings] = useContext(CreateQuizContext);
  const disabled =
    createQuizSettings.disabledParts.includes('all') ||
    createQuizSettings.disabledParts.includes('settings');
  return (
    <Section title={t('createQuiz.settings.timer.title', 'Timer & Visibility')}>
      <Box display="grid" gap={1}>
        <TimerSwitch placement={placement} disabled={disabled} />
        <IncognitoModeSwitch placement={placement} disabled={disabled} />
      </Box>
    </Section>
  );
}
