import React, {
  forwardRef,
  MouseEvent,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import { alpha, useTheme } from '@mui/material/styles';
import { SimpleTooltip } from '@front/ui';
import * as d3Shape from 'd3-shape';

export type GoalRingHandler = {
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
};

export type GoalRingProps = {
  dataset?: number[];
  size?: number;
  thickness?: number;
  gap?: number;
  disableDefault?: boolean;
  enableDataHover?: boolean;
  labels?: string[];
  themeMode?: 'dark' | 'light' | 'reverse';
  onDataHover?: (ev: MouseEvent<SVGElement>, index: number) => void;
  onDataClick?: (ev: MouseEvent<SVGElement>, index: number) => void;
};

const styles = {
  tooltip: {
    pointerEvents: 'none',
    userSelect: 'none',
    '& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop': {
      mb: 0,
      transform: 'translateY(76px) !important',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
};
const { PI } = Math;

const arc = (radius: number, innerRadius: number) =>
  d3Shape.arc().innerRadius(innerRadius).outerRadius(radius).startAngle(0);

const GoalRing = forwardRef<GoalRingHandler, GoalRingProps>(
  (
    {
      dataset = [0, 0, 0],
      labels = [],
      size = 129,
      thickness = 14,
      gap = 7,
      disableDefault = false,
      enableDataHover = true,
      themeMode,
      onDataHover,
      onDataClick,
    },
    ref
  ) => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const radius = size / 2;
    const innerRadius = radius - thickness;
    const theme = useTheme();
    const reverseTheme = theme.palette.mode === 'dark' ? 'light' : 'dark';
    const currentTheme =
      themeMode === 'reverse' ? reverseTheme : themeMode ?? theme.palette.mode;
    const colors = [
      theme.palette.primary.dark,
      theme.palette.primary.light,
      currentTheme === 'dark'
        ? theme.palette.text.primary
        : theme.palette.grey[400],
    ];

    const getCirclePath = useCallback(
      (v: any, i: number) => ({
        d: arc(
          radius - (gap + thickness) * i,
          innerRadius - (gap + thickness) * i
        ).endAngle(2 * v * PI)({} as d3Shape.DefaultArcObject),
      }),
      [gap, innerRadius, radius, thickness]
    );

    const handleMouseEnter = (ev: MouseEvent<SVGElement>, i: number) => {
      if (onDataHover) {
        setActiveIndex(i);
        onDataHover(ev, i);
      }
    };

    const handleMouseLeave = (ev: MouseEvent<SVGElement>) => {
      if (onDataHover) {
        setActiveIndex(-1);
        onDataHover(ev, -1);
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        setActiveIndex,
      }),
      []
    );

    const bgcolor =
      currentTheme === 'dark'
        ? alpha(theme.palette.text.primary, 0.1)
        : 'rgba(8, 8, 8, 0.1)';

    return (
      <svg width={size} height={size}>
        <g>
          {dataset.map((data, i) => {
            const outer = radius - (gap + thickness) * i;
            const endDeg = data * 360;

            return (
              <Box
                key={i}
                component={onDataHover ? SimpleTooltip : 'g'}
                open={activeIndex === i}
                sx={onDataClick && styles.clickable}
                onClick={
                  onDataClick
                    ? (ev: MouseEvent<SVGElement>) => onDataClick(ev, i)
                    : undefined
                }
                {...(onDataHover
                  ? {
                      title: labels[i] || '',
                      placement: 'top',
                      slotProps: { popper: { sx: styles.tooltip } },
                    }
                  : {})}
              >
                <g
                  transform={`translate(${size / 2}, ${size / 2})`}
                  style={{
                    opacity: activeIndex > -1 && activeIndex !== i ? 0.3 : 1,
                  }}
                >
                  <path
                    d={getCirclePath(1, i).d as string}
                    fill={bgcolor}
                    onMouseEnter={(ev) =>
                      enableDataHover ? handleMouseEnter(ev, i) : undefined
                    }
                    onMouseLeave={(ev) =>
                      enableDataHover ? handleMouseLeave(ev) : undefined
                    }
                  />
                  <path
                    d={getCirclePath(data, i).d as string}
                    fill={colors[i]}
                    onMouseEnter={(ev) =>
                      enableDataHover ? handleMouseEnter(ev, i) : undefined
                    }
                    onMouseLeave={(ev) =>
                      enableDataHover ? handleMouseLeave(ev) : undefined
                    }
                  />

                  {(!disableDefault || data > 0) && (
                    <g>
                      <circle
                        cx={0}
                        cy={-outer + thickness / 2}
                        r={thickness / 2}
                        fill={colors[i]}
                        onMouseEnter={(ev) =>
                          enableDataHover ? handleMouseEnter(ev, i) : undefined
                        }
                        onMouseLeave={(ev) =>
                          enableDataHover ? handleMouseLeave(ev) : undefined
                        }
                      />
                    </g>
                  )}
                  {(!disableDefault || data > 0) && (
                    <g transform={`rotate(${endDeg})`}>
                      <circle
                        cx={0}
                        cy={-outer + thickness / 2}
                        r={thickness / 2}
                        fill={colors[i]}
                        onMouseEnter={(ev) =>
                          enableDataHover ? handleMouseEnter(ev, i) : undefined
                        }
                        onMouseLeave={(ev) =>
                          enableDataHover ? handleMouseLeave(ev) : undefined
                        }
                      />
                    </g>
                  )}
                </g>
              </Box>
            );
          })}
        </g>
      </svg>
    );
  }
);

GoalRing.displayName = 'GoalRing';

export default GoalRing;
