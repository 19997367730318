import ChallengeButton from '../PlaylistDetail/components/ActionsToolbar/ChallengeButton';
import RedoCreateQuizPanels from '../PlaylistDetail/components/ActionsToolbar/RedoCreateQuizPanels';
import { PlaylistDetailProvider } from '../PlaylistDetail/context';

type ChallengeButtonRootProps =
  | {
      quizId?: string;
      roundNo: number;
      providerExcluded?: never;
    }
  | {
      providerExcluded: true;
      panelKeyPrefix: string;
    };

export default function ChallengeButtonRoot(props: ChallengeButtonRootProps) {
  /**
   * in case of the button is already inside the PlaylistDetailProvider
   */
  if (props.providerExcluded) {
    return <ChallengeButton panelKeyPrefix={props.panelKeyPrefix} />;
  }

  const { quizId, roundNo } = props;
  return (
    <PlaylistDetailProvider quizId={quizId} roundNo={roundNo}>
      <ChallengeButton />
      <RedoCreateQuizPanels />
    </PlaylistDetailProvider>
  );
}
