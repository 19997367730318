import {
  IconListLayoutItemHoverEvent,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';

import { SuggestionUser, SuggestionUserIconListLayoutItemObj } from './types';

export default function useAvailableActions({
  onToggleItem,
  updateSuggestItem,
  showUserIdProfile,
  openProfile,
  inviteToAha,
  search,
}: {
  onToggleItem?: (value: SuggestionUser) => void;
  updateSuggestItem: (value: SuggestionUser) => void;
  showUserIdProfile: (options: {
    anchorEl: Element;
    userId: string;
    options?: any;
  }) => void;
  openProfile: (userId: string, search: string) => void;
  inviteToAha: (userId: string[]) => void;
  search: string;
}) {
  return {
    selectChanged: {
      action: (value: SuggestionUserIconListLayoutItemObj) => {
        if (value.metadata) {
          onToggleItem?.(value.metadata);
        }
      },
    },
    userHovered: {
      action: updateSuggestItem,
    },
    titleClick: {
      action: (value: SuggestionUserIconListLayoutItemObj) => {
        openProfile(value.id, search);
      },
    },
    titleHover: {
      action: (event: IconListLayoutItemHoverEvent<SuggestionUser>) => {
        if (!event.target.metadata?.memberInfo) return;
        showUserIdProfile({
          anchorEl: event.anchorEl,
          userId: event.target.id,
        });
      },
    },
    avatarHover: {
      action: (event: IconListLayoutItemHoverEvent<SuggestionUser>) => {
        if (!event.target.metadata?.memberInfo) return;

        showUserIdProfile({
          anchorEl: event.anchorEl,
          userId: event.target.id,
          options: {
            spacing: 8,
          },
        });
      },
    },
    inviteToAha: {
      action: async (value: IconListLayoutItemObj<SuggestionUser>) => {
        inviteToAha([value.id]);
      },
    },
  };
}
