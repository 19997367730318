import { useCallback } from 'react';

import { setInitializing as setInitializingFn } from '../../reducers/iaQueryHelperReducer';
import { useAppDispatch } from '../redux';

type Options = {
  scope?: string;
};

export default function useIaQueryHelperActions({
  scope = 'global',
}: Options = {}) {
  const dispatch = useAppDispatch();

  const setInitializing = useCallback(
    (isInitializing: boolean | Record<string, boolean>) => {
      dispatch(setInitializingFn({ isInitializing, scope }));
    },
    [dispatch, scope]
  );

  /**
   * Use this function if you want to force init as completed right away,
   * no need to complete init processes one by one,
   * or when there is only one init process
   */
  const completeInit = useCallback(() => {
    // setTimeout to ensure this action is executed after the latest search query is generated
    setTimeout(() => setInitializing(false));
  }, [setInitializing]);

  /**
   * Use this function if you want to complete init processes one by one,
   * in case of there are multiple init processes
   */
  const completeInitPartial = useCallback(
    (name: string) => {
      // setTimeout to ensure this action is executed after the latest search query is generated
      setTimeout(() => {
        setInitializing({ [name]: false });
      });
    },
    [setInitializing]
  );

  return {
    setInitializing,
    completeInit,
    completeInitPartial,
  };
}
