import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';

type Params = GlobalPanelParams[GlobalPanelKeys.GlobalPlaylistDetail];

/**
 * in the flow of Playlist Detail RHS, will have other RHS panels opened as well: create-quiz, challenger,..
 * when the RHS target is not the Playlist Detail, the right panel params will be overridden,
 * => we have to get from RHS panel history, that easier than passing params through panels
 */
export default function usePlaylistDetailRightPanelParams() {
  const { rightPanelHistory } = useBaseRightPanel();
  const params = rightPanelHistory.getParams<Params>({
    historyIndex: 0, // the PlaylistDetail RHS is always opened first
  });

  return params;
}
