import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import useIaFilterActions from '@app/web/src/hooks/utils/useIaFilterActions';
import usePageSearchFilterSort from '@app/web/src/hooks/utils/usePageSearchFilterSort';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { PropertyType, useBaseRightPanel } from '@front/ui';

import { FILTER_SORT_SCOPE } from '../PlaylistDetailPanel';

export default function useSortFilterConfig() {
  const { getRightParams, rightPanelOpened } =
    useBaseRightPanel<GlobalPanelParams>();
  const { selectedFilter } = getRightParams(
    GlobalPanelKeys.GlobalPlaylistDetail
  );
  const { t } = useTranslation();

  void usePageSearchFilterSort({
    scope: FILTER_SORT_SCOPE,
    type: 'playlistDetailRhs',
  });
  const { setConditions, forceResetFilter } = useIaFilterActions({
    scope: FILTER_SORT_SCOPE,
  });

  useEffect(() => {
    if (!rightPanelOpened) return;
    if (selectedFilter === 'overtime') {
      setConditions([
        {
          id: 'overtime',
          field: {
            label: t('filter.overtimeStatus.label'),
            name: 'overtimeStatus',
            type: PropertyType.Status,
            options: [
              {
                label: t('filter.overtimeStatus.options.overtime'),
                value: -1,
                color: 'warning',
              },
              {
                label: t('filter.overtimeStatus.options.notOvertime'),
                value: 1,
                color: 'default',
              },
            ],
          },
          operator: 'Is',
          values: [
            {
              label: t('filter.overtimeStatus.options.overtime'),
              value: -1,
              color: 'warning',
            },
          ],
        },
      ]);
      return;
    }

    setConditions([
      {
        id: 'mistakes',
        field: {
          label: t('filter.answerResult.label'),
          name: 'practiceResult',
          type: PropertyType.Select,
          customIcon: 'TextEditorCheck',
          options: [
            {
              label: t('filter.answerResult.options.skipped'),
              value: 0,
            },
            {
              label: t('filter.answerResult.options.correct'),
              value: 1,
            },
            {
              label: t('filter.answerResult.options.mistake'),
              value: -1,
            },
          ],
        },
        operator: 'IsSelectIn',
        values: [
          {
            label: t('filter.answerResult.options.mistake'),
            value: -1,
          },
        ],
      },
    ]);
  }, [t, selectedFilter, setConditions, rightPanelOpened]);

  useEffect(() => {
    return () => {
      forceResetFilter();
    };
  }, [forceResetFilter, rightPanelOpened]);
}
