import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { DotLoadingIcon, LoadingIcon, Markdown } from '@front/ui';
import RichText from '@lib/ia/src/components/RichText';
import TypingEffect from '@lib/ia/src/layouts/ChannelLayout/components/ContentItem/MessageItem/components/TypingEffect';
import {
  AgentComposingStatus,
  AgentMaterialProcessStatus,
  MessageItemConfig,
} from '@lib/ia/src/layouts/ChannelLayout/types';
import { useThread } from '@lib/web/thread/hooks/core/useThread';

type MessageItemProps = MessageItemConfig;

const styles = {
  loading: {
    display: 'flex',
    gap: 1,
    '& svg': {
      color: 'primary.light',
    },
  },
};

const LoadingMessage = ({ text }: { text?: string }): JSX.Element => {
  return (
    <Box sx={styles.loading}>
      <LoadingIcon />
      {text}
    </Box>
  );
};

export type ThreadAgentMessageContentProps = {
  content: MessageItemProps['content'];
  messageId: string;
  agentComposingStatus: MessageItemProps['agentComposingStatus'];
  agentMaterialProcessStatus: MessageItemProps['agentMaterialProcessStatus'];
  agentMaterialFileName: MessageItemProps['agentMaterialFileName'];
};

export default function ThreadAgentMessageContent({
  content,
  agentComposingStatus,
  agentMaterialProcessStatus,
  agentMaterialFileName,
  messageId,
}: ThreadAgentMessageContentProps) {
  const { t } = useTranslation('thread');
  const { typingEffectMessageIdsRef } = useThread();

  const onTypingComplete = useCallback((): void => {
    typingEffectMessageIdsRef.current.delete(messageId);
  }, [messageId, typingEffectMessageIdsRef]);

  // Text
  const isLoadingText =
    agentMaterialProcessStatus === AgentMaterialProcessStatus.Paragraph &&
    agentComposingStatus !== AgentComposingStatus.Completed;
  if (isLoadingText) {
    return <DotLoadingIcon />;
  }

  // Image
  const isLoadingImage =
    agentMaterialProcessStatus === AgentMaterialProcessStatus.Image &&
    agentComposingStatus !== AgentComposingStatus.Completed;
  const isReadingImage =
    agentComposingStatus === AgentComposingStatus.NotStarted;
  const isThinkingImage =
    agentComposingStatus === AgentComposingStatus.Thinking ||
    agentComposingStatus === AgentComposingStatus.Composing;
  if (isLoadingImage) {
    if (isReadingImage) {
      return (
        <LoadingMessage
          text={`${t(
            'message.agent.reading',
            'Reading'
          )} "${agentMaterialFileName}"`}
        />
      );
    }
    if (isThinkingImage) {
      return <LoadingMessage text={t('message.agent.search.knowledge')} />;
    }
  }

  // File
  const isLoadingFile =
    agentMaterialProcessStatus === AgentMaterialProcessStatus.File &&
    agentComposingStatus !== AgentComposingStatus.Completed;
  const isReadingFile =
    agentComposingStatus === AgentComposingStatus.NotStarted;
  const isThinkingFile =
    agentComposingStatus === AgentComposingStatus.Thinking ||
    agentComposingStatus === AgentComposingStatus.Composing;
  if (isLoadingFile) {
    if (isReadingFile) {
      return (
        <LoadingMessage
          text={`${t(
            'message.agent.reading',
            'Reading'
          )} "${agentMaterialFileName}"`}
        />
      );
    }
    if (isThinkingFile) {
      return <LoadingMessage text={t('message.agent.search.knowledge')} />;
    }
  }

  if (
    typingEffectMessageIdsRef.current.has(messageId) &&
    typeof content === 'string'
  ) {
    return <TypingEffect text={content} onTypingComplete={onTypingComplete} />;
  }

  if (typeof content === 'string') {
    return <Markdown className="markdown-message">{content}</Markdown>;
  }

  return <RichText text={content} variant="chatBody" />;
}
