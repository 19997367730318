import { useMemo } from 'react';
import { ClubVisibility, useUserJoinedClubs } from '@lib/web/apis';

type MutualClubsReturn = {
  data: GetClubRes[];
  isLoading: boolean;
  unlistedCount: number;
};

export default function useMutualClubs({
  userId,
  targetUserId,
}: {
  userId?: string;
  targetUserId?: string;
}) {
  // Cause the API to get mutual clubs is not ready yet => do this way as a temporary workaround
  const { data: userJoinedClubData } = useUserJoinedClubs(userId);
  const { data: targetUserJoinedClubData } = useUserJoinedClubs(targetUserId);

  return useMemo<MutualClubsReturn>(() => {
    if (!userJoinedClubData || !targetUserJoinedClubData) {
      return {
        isLoading: true,
        data: [],
        unlistedCount: 0,
      };
    }
    const userJoinedClubs = userJoinedClubData.data || [];
    const targetUserJoinedClubs = targetUserJoinedClubData.data || [];
    const data = userJoinedClubs.filter((club) =>
      targetUserJoinedClubs.some((targetClub) => targetClub.id === club.id)
    );
    const unlisted = data.filter(
      (club) => club.visibility === ClubVisibility.Unlisted
    );
    return {
      isLoading: false,
      unlistedCount: unlisted.length,
      data,
    };
  }, [userJoinedClubData, targetUserJoinedClubData]);
}
