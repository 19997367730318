import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { OtherAddFriend as OtherAddFriendIcon } from '@front/icon';
import {
  EmphasizeButton,
  EmphasizeButtonProps,
  useBaseRightPanel,
} from '@front/ui';

type FollowMoreButtonProps = Omit<EmphasizeButtonProps, 'children'>;

export default function FollowMoreButton({
  onClick,
  ...rest
}: FollowMoreButtonProps) {
  const { t } = useTranslation();
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
    openRightPanel(GlobalPanelKeys.GlobalFollowMore);
  };

  return (
    <EmphasizeButton
      prefixIcon={<OtherAddFriendIcon />}
      onClick={handleClick}
      {...rest}
    >
      {t('button.Follow More')}
    </EmphasizeButton>
  );
}
