import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { appConfig } from '@front/config';
import { OtherFriendsChallenge as OtherFriendsChallengeIcon } from '@front/icon';
import { NumberField, ResponsiveTooltip, Slider } from '@front/ui';
import { useActiveUserCount } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';

import CreateQuizContext from '../../../context';
import { CreateQuizFormValue } from '../../../type';

const styles = {
  root: {
    px: { xs: 2.5, md: '12px' },
    display: 'grid',
    gap: 2,
    my: 2,
  },
  content: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: 1,
    '& .MuiTypography-root': {
      pb: '4px',
    },
  },
  sliderContainer: {
    '&:has(.MuiSlider-dragging) + .slider-labels': {
      opacity: 0,
    },
  },
  slider: {
    px: '13px',
    '& > div': {
      height: '26px',
    },

    '& .MuiSlider-root': {
      '&:before': {
        content: '""',
        width: 17,
        position: 'absolute',
        height: 8,
        bgcolor: 'text.primary',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        left: -13,
      },
    },
    '& .MuiSlider-rail': {
      bgcolor: 'text.primary',
      opacity: 0.1,
      '&:after': {
        content: '""',
        width: 17,
        position: 'absolute',
        height: 8,
        bgcolor: 'text.primary',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        right: -13,
      },
    },
  },
  disabled: {
    opacity: 0.5,
  },
  labels: {
    display: 'flex',
    justifyContent: 'space-between',
    opacity: 0.5,
  },
};

export default function AutoMatchPane({ disabled }: { disabled: boolean }) {
  const { t } = useTranslation('quiz');
  const [{ clubSlug: providedClubSlug }] = useContext(CreateQuizContext);
  const routerClubSlug = useClubSlug();
  const clubSlug = providedClubSlug || routerClubSlug;
  const { data } = useActiveUserCount(clubSlug);
  const maxChallengersCount = data
    ? Math.min(data.data.count, appConfig.maxChallengersCount)
    : 0;
  const { control, setValue, watch, register, resetField } =
    useFormContext<CreateQuizFormValue>();
  const onlyMe = maxChallengersCount === 1;
  const challengersCount = watch('challengers', []).length;
  const randomChallengerCount = watch('randomChallengerCount', 0);

  const remainingChallengersCount = Math.max(
    maxChallengersCount - challengersCount,
    0
  );
  const displayOptions = [
    {
      value: 0,
    },
    {
      value: remainingChallengersCount,
    },
  ];

  const handleNumberChange = (
    value: string,
    onChange: (...event: any[]) => void
  ) => {
    if (`${value}`.length > `${appConfig.maxChallengersCount}`.length + 1)
      return;

    if (!!value && !/^[0-9\b]+$/.test(value)) {
      return;
    }

    onChange(value);
  };

  const handleNumberBlur = (
    value: string,
    onChange: (...event: any[]) => void
  ) => {
    if (value === '') {
      register('randomChallengerCount');
      resetField('randomChallengerCount');
    } else if (+value < 1) onChange(0);
    else if (remainingChallengersCount && +value > remainingChallengersCount)
      onChange(remainingChallengersCount);
  };

  const tipContent = onlyMe
    ? t('createQuiz.challengers.chip.autoMatch.unavailable')
    : t('createQuiz.challengers.autoMatch.input.tip');
  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>
        <Controller
          name="randomChallengerCount"
          control={control}
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <ResponsiveTooltip
              titleIcon={<OtherFriendsChallengeIcon width={16} height={16} />}
              title={t('createQuiz.challengers.autoMatch.input.text')}
              content={tipContent}
              tooltipProps={{ followCursor: true }}
            >
              <span>
                <NumberField
                  inputRef={ref}
                  value={value}
                  onChange={(ev) =>
                    handleNumberChange(ev.target.value, onChange)
                  }
                  onBlur={(ev) => handleNumberBlur(ev.target.value, onChange)}
                  error={error?.message}
                  disabled={disabled || remainingChallengersCount === 0}
                />
              </span>
            </ResponsiveTooltip>
          )}
        />
        <Typography sx={[disabled && styles.disabled]} variant="body2">
          {t('createQuiz.challengers.autoMatch.input.title', {
            count: randomChallengerCount,
          })}
        </Typography>
      </Box>
      <Box sx={styles.sliderContainer}>
        <ResponsiveTooltip
          titleIcon={<OtherFriendsChallengeIcon width={16} height={16} />}
          title={t('createQuiz.challengers.autoMatch.input.text')}
          content={tipContent}
          tooltipProps={{ followCursor: true }}
        >
          <Box>
            <Controller
              name="randomChallengerCount"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={styles.slider}>
                  <Slider
                    marks={displayOptions}
                    min={0}
                    max={remainingChallengersCount}
                    value={value}
                    onChange={(ev, newValue) =>
                      handleNumberChange(`${newValue}`, onChange)
                    }
                    disabled={disabled && remainingChallengersCount > 0}
                  />
                </Box>
              )}
            />
          </Box>
        </ResponsiveTooltip>
      </Box>
      <Box sx={styles.labels} className="slider-labels">
        <ResponsiveTooltip
          titleIcon={<OtherFriendsChallengeIcon width={16} height={16} />}
          title={t('createQuiz.challengers.autoMatch.min.text')}
          content={t('createQuiz.challengers.autoMatch.min.tip')}
        >
          <Typography
            onClick={() =>
              setValue('randomChallengerCount', 0, { shouldDirty: true })
            }
          >
            0
          </Typography>
        </ResponsiveTooltip>
        {remainingChallengersCount > 0 && (
          <ResponsiveTooltip
            titleIcon={<OtherFriendsChallengeIcon width={16} height={16} />}
            title={t('createQuiz.challengers.autoMatch.max.text')}
            content={t('createQuiz.challengers.autoMatch.max.tip')}
          >
            <Typography
              onClick={() =>
                setValue('randomChallengerCount', remainingChallengersCount, {
                  shouldDirty: true,
                })
              }
            >
              {remainingChallengersCount}
            </Typography>
          </ResponsiveTooltip>
        )}
      </Box>
    </Box>
  );
}
