import { ReactNode, RefObject } from 'react';
import { createContext } from 'react';

export type RankingsContextValue = {
  size: 'sm' | 'md';
  scrollRef?: RefObject<HTMLDivElement>;
  clubSlug?: string;
};

export const RankingsContext = createContext<RankingsContextValue>({
  size: 'md',
});

type RankingsContextProviderProps = RankingsContextValue & {
  children: ReactNode;
};

export default function RankingsContextProvider({
  children,
  ...rest
}: RankingsContextProviderProps) {
  return (
    <RankingsContext.Provider value={rest}>{children}</RankingsContext.Provider>
  );
}
