// eslint-disable-next-line import/prefer-default-export
export function floatMul(arg1: number, arg2: number) {
  let m = 0;
  const s1 = arg1.toString();
  const s2 = arg2.toString();
  m += s1.split('.')[1]?.length || 0;
  m += s2.split('.')[1]?.length || 0;
  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / 10 ** m;
}

export function roundUpWithoutDecimal(numberWithDecimal: number): number {
  if (numberWithDecimal < 1 && numberWithDecimal > -1) {
    // e.g: 0.999 -> 1; 0.567 -> 0.6; 0.123 -> 0.1
    return Math.round(numberWithDecimal * 10) / 10;
  }

  // e.g: 1.234 -> 1; 5.678 -> 6;
  return Math.round(numberWithDecimal);
}
