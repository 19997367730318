import { useMemo } from 'react';
import { addYears } from 'date-fns';

import { SetGoalFormFields, setGoalFormSettings } from './config';

export default function useSetGoalDefaultValues(
  club: GetIaClubProfileCenterDefaultViewRes,
  goalData?: GetGoalRes,
  dailyGoalData?: GetGoalDailyRes
) {
  return useMemo(() => {
    const defaultValues = {
      [SetGoalFormFields.TargetDate]: goalData?.targetDate
        ? new Date(goalData?.targetDate)
        : addYears(new Date(), 1),

      [SetGoalFormFields.TargetScore]: goalData?.targetScore
        ? +goalData.targetScore
        : club.fullScore,

      [SetGoalFormFields.SetMaxScore]: false,
      [SetGoalFormFields.DailyQuestions]: dailyGoalData?.questionNumbers
        ? +dailyGoalData.questionNumbers
        : setGoalFormSettings.questions.default,
      [SetGoalFormFields.DailyTimes]: dailyGoalData?.practiceTimeMin
        ? +dailyGoalData.practiceTimeMin
        : setGoalFormSettings.times.default,
      [SetGoalFormFields.DailyMocks]: dailyGoalData?.mockExamRoundCount
        ? +dailyGoalData.mockExamRoundCount
        : setGoalFormSettings.mock.default,
    };

    if (defaultValues[SetGoalFormFields.TargetScore] === club.fullScore) {
      defaultValues[SetGoalFormFields.SetMaxScore] = true;
    }

    return defaultValues;
  }, [club.fullScore, dailyGoalData, goalData]);
}
