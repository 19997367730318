import React from 'react';
import { useMemberNftAvatar } from '@app/web/src/hooks/utils/useMemberNftAvatar';
import { SquareAvatar, SquareAvatarProps } from '@front/ui';

export type NFTAvatarProps = SquareAvatarProps & {
  nftSrc?: string;
  memberId?: string | null;
  userId?: string | null;
};

export default function NFTAvatar({
  sx,
  src,
  nftSrc,
  memberId = null,
  userId = null,
  children,
  ...rest
}: NFTAvatarProps) {
  const { nftAvatar, nftDisplay } = useMemberNftAvatar({
    userId,
    memberId,
  });

  return (
    <SquareAvatar src={nftAvatar || nftSrc || src} {...rest}>
      {nftDisplay || children}
    </SquareAvatar>
  );
}
