import { useTranslation } from 'react-i18next';
import { MainApps as MainAppsIcon } from '@front/icon';
import { BaseLayoutRightPanel, useBaseLayout } from '@front/ui';
import { useMemberInfo } from '@lib/web/apis';

import MutualClubs from './components/MutualClubs';

export default function MutualClubsPanel() {
  const { t } = useTranslation('profile');
  const { rightPanelParams } = useBaseLayout();
  const { userId, targetUserId } = rightPanelParams;
  const { data: userData } = useMemberInfo(targetUserId);

  const displayName =
    userData?.data.displayName || userData?.data.distinctName || '';

  const userFirstName = displayName.split(' ')[0];

  return (
    <BaseLayoutRightPanel
      titleIcon={<MainAppsIcon width="16" height="16" />}
      title={t('RHS.mutualClubs.title', { name: userFirstName })}
    >
      <MutualClubs userId={userId} targetUserId={targetUserId} />
    </BaseLayoutRightPanel>
  );
}
