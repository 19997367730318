import { MouseEvent } from 'react';
import useFloatingProfile from '@app/web/src/hooks/utils/useFloatingProfile';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { Mention, useBaseRightPanel } from '@front/ui';
import { useThread } from '@lib/web/thread/hooks/core/useThread';

export default function ThreadMentionUser({
  memberId,
  userId,
  agentId,
}: {
  memberId: string;
  userId?: string;
  agentId?: string;
}) {
  const { getThreadUser } = useThread();
  const mentionUser = getThreadUser(memberId);
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const { showUserIdProfile } = useFloatingProfile();

  const handleOpenProfile = (ev: MouseEvent) => {
    ev.stopPropagation();
    if (userId) {
      openRightPanel(GlobalPanelKeys.GlobalProfile, { userId });
    }
    if (agentId) {
      openRightPanel(GlobalPanelKeys.GlobalAgentProfile, { agentId });
    }
  };

  const handleMouseEnter = (ev: MouseEvent) => {
    if (!userId) return;
    showUserIdProfile({
      userId,
      anchorEl: ev.currentTarget,
      options: {
        spacing: 8,
      },
    });
  };

  return (
    <Mention
      src={mentionUser?.image}
      display={mentionUser?.distinctName}
      indicators={
        mentionUser && 'indicators' in mentionUser
          ? mentionUser.indicators
          : undefined
      }
      prefix="@"
      onClick={handleOpenProfile}
      onMouseEnter={handleMouseEnter}
      className="floating-avatar-anchor"
    />
  );
}
