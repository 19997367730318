import { Box, Typography } from '@mui/material';

import usePlaylistTitle from '../hooks/usePlaylistTitle';

import PlaylistInfo from './PlaylistInfo';

const styles = {
  root: {
    display: 'grid',
    gap: 1,
    mt: {
      xs: 2,
      md: 1,
    },
    px: '20px',
    pb: {
      xs: 1,
      md: '24px',
    },
  },
  title: {
    fontWeight: 700,
    display: {
      xs: 'none',
      md: 'block',
    },
  },
};

export default function PlaylistTitle() {
  const playlistTitle = usePlaylistTitle();

  return (
    <Box sx={styles.root}>
      <Typography variant="appH1" sx={styles.title}>
        {playlistTitle}
      </Typography>
      <PlaylistInfo />
    </Box>
  );
}
