import Router from 'next/router';
import { IaPlaylistQuestionDataItem } from '@app/web/src/ia/playlistQuestion/types';
import { IaPlaylistQuestionData } from '@app/web/src/ia/playlistQuestion/useIaPlaylistQuestionData';
import { useBaseRightPanel } from '@front/ui';
import { IaAvailableAction } from '@lib/ia/src/core/IaAction';
import { IaTableReactionActionValue } from '@lib/ia/src/layouts/TableLayout/components/IaTablePlugin/IaTableReactionPlugin/types';
import { TableLayoutRow } from '@lib/ia/src/layouts/TableLayout/types';
import { apis, useAuth } from '@lib/web/apis';
import { call } from '@lib/web/utils';

import useAuthLinks from '../../hooks/utils/useAuthLinks';
import { QuizPanelKeys, QuizPanelParams } from '../../types/panel';

export enum IaPlaylistQuestionAvailableAction {
  ChangeEmoji = 'ChangeEmoji',
  LoadMore = 'LoadMore',
  Share = 'Share',
  OpenQuestionPreview = 'OpenQuestionPreview',
}

const changePlaylistQuestionEmoji = async ({
  emoji,
  question,
}: {
  emoji: { id: string; code: string; name: string };
  question: IaPlaylistQuestionDataItem;
}) => {
  const metadata = question.metadata;

  if (metadata.questionId) {
    return emoji.id
      ? apis.quiz.addEmoji(metadata.questionId, emoji.id)
      : apis.quiz.removeEmoji(metadata.questionId);
  }

  if (
    metadata.examQuestionId !== undefined &&
    metadata.sectionId !== undefined &&
    metadata.materialSetId !== undefined
  ) {
    return emoji.id
      ? apis.quiz.addExamQuestionEmoji({
          sectionId: metadata.sectionId,
          examQuestionId: metadata.examQuestionId,
          materialSetId: metadata.materialSetId,
          markId: emoji.id,
        })
      : apis.quiz.removeExamQuestionEmoji({
          sectionId: metadata.sectionId,
          examQuestionId: metadata.examQuestionId,
          materialSetId: metadata.materialSetId,
        });
  }
};

export const useIaPlaylistQuestionAvailableActions = ({
  playlistQuestionData,
  onShare = () => {},
}: {
  playlistQuestionData: IaPlaylistQuestionData;
  onShare?: (question: IaPlaylistQuestionDataItem, el: HTMLElement) => void;
}): Record<string, IaAvailableAction> => {
  const { openRightPanel } = useBaseRightPanel<QuizPanelParams>();
  const { questions, data, reloadData, setQuestionsSize } =
    playlistQuestionData;

  const { isLogged } = useAuth();

  const { loginUrl } = useAuthLinks({
    loginReturnTo: window?.location?.href,
  });

  return {
    [IaPlaylistQuestionAvailableAction.ChangeEmoji]: {
      action: async ({ id, value }: IaTableReactionActionValue) => {
        if (!isLogged) {
          void Router.push(loginUrl);
          return;
        }

        const question = questions.find((q) => q.id === id);
        if (!question || !data) {
          return;
        }

        const updateValue = !value ? { id: '', code: '', name: '' } : value;

        reloadData({
          mutatorPromise: call(
            changePlaylistQuestionEmoji({
              emoji: updateValue,
              question,
            })
          ),
          optimisticQuestions: questions.map((q) =>
            q.id === id ? { ...q, emoji: updateValue } : q
          ),
        });
      },
      batchAction: async (values?: IaTableReactionActionValue[]) => {
        if (!isLogged) {
          void Router.push(loginUrl);
        }
        const ids = values?.map((i) => i.id) || [];
        const value = values?.[0]?.value;

        const question = questions.find((q) => q.id === ids[0]);
        if (!question || !data || !values?.length) {
          return;
        }
        const selectedQuestions = questions.filter((q) => ids.includes(q.id));
        const updateValue = !value ? { id: '', code: '', name: '' } : value;

        reloadData({
          mutatorPromise: call(
            selectedQuestions.map((q) =>
              changePlaylistQuestionEmoji({
                emoji: updateValue,
                question: q,
              })
            )
          ),
          optimisticQuestions: questions.map((q) =>
            ids.includes(q.id) ? { ...q, emoji: updateValue } : q
          ),
        });
      },
    },
    [IaPlaylistQuestionAvailableAction.Share]: {
      action: async ({
        id,
        anchorEl,
      }: {
        id: string;
        anchorEl?: HTMLElement;
      }) => {
        const question = questions.find((q) => q.id === id);
        if (!question || !anchorEl) {
          return;
        }
        onShare(question, anchorEl);
      },
    },
    [IaPlaylistQuestionAvailableAction.LoadMore]: {
      action: () => {
        void setQuestionsSize?.((prev) => prev + 1);
      },
    },
    [IaPlaylistQuestionAvailableAction.OpenQuestionPreview]: {
      action: (row: TableLayoutRow<{ questionNo: number }>) => {
        if (!data) return;

        openRightPanel(QuizPanelKeys.QuizQuestionPreview, {
          shortId: data.quiz.shortId,
          quizId: data.quiz.id,
          roundNo: data.quiz.latestRoundNo || 1,
          questionNo: row.metadata?.questionNo || 1,
          mode: data.quiz.mode,
        });
      },
    },
  };
};
