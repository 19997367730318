import { appConfig } from '@front/config';
import {
  AuthMemberShipTier,
  useMyMembership,
  useProfileSocial,
} from '@lib/web/apis';
import { differenceInDays, parseISO } from 'date-fns';

export default function useMemberSubscription() {
  const { data, error, mutate: reloadMembership } = useMyMembership();
  const { data: profileData } = useProfileSocial(null);
  const tier: undefined | AuthMemberShipTier = data?.data.membershipInfo.tier;
  const expiredAt = data?.data.membershipInfo.expiredAt || 0;
  const today = new Date();
  const inDays = expiredAt > 0 ? differenceInDays(expiredAt, today) + 1 : 0; // should include the day itself
  const isExpired = inDays < 0;

  const isPaid = tier && tier !== AuthMemberShipTier.PlanFree;

  const { isScheduledCancel } = data?.data.subscriptionInfo || {};

  const scheduledRenewalAt = data?.data.subscriptionInfo?.scheduledRenewalAt
    ? parseISO(data.data.subscriptionInfo.scheduledRenewalAt)
    : null;

  const scheduledCancelAt = data?.data.subscriptionInfo?.scheduledCancelAt
    ? parseISO(data.data.subscriptionInfo.scheduledCancelAt)
    : null;

  const cancelAt = data?.data.subscriptionInfo?.cancelAt
    ? parseISO(data.data.subscriptionInfo.cancelAt)
    : null;

  const joinedDays = profileData?.data.joinedAt
    ? differenceInDays(today, new Date(profileData.data.joinedAt))
    : 0;

  const trialDays =
    joinedDays >= appConfig.trialDays ? 0 : appConfig.trialDays - joinedDays;

  return {
    tier,
    expiredAt,
    isLoading: !data && !error,
    isExpired,
    isPaid,
    isFreeUser: !isPaid && tier === AuthMemberShipTier.PlanFree,
    trialDays,
    inDays,
    cancelAt,
    scheduledRenewalAt,
    scheduledCancelAt,
    isScheduledCancel,
    subscriptionInfo: data?.data.subscriptionInfo,
    subscriptionOrderInfo: data?.data.orderInfo,
    subscriptionCheckoutSession: data?.data.checkoutSession,
    reloadMembership,
  };
}
