import { convertToRFC3339 } from '@front/helper';
import { ThreadViewSortBy } from '@lib/web/apis';

import { FilterSearchQuery } from '../types/filter';
import {
  ThreadChannelFilter,
  ThreadFilterMap,
  ThreadMessageFilter,
  ThreadSort,
  ThreadSortMap,
} from '../types/threadFilterSort';
import { SortQuery } from '../widgets/CommonPanels/SortPanel/types';

const processSort = (
  orderByFields: string[],
  sortByFields: string[],
  sortMap: ThreadSortMap
): ThreadSort => {
  const sort: ThreadSort = {};

  orderByFields.forEach((field, index) => {
    const sortOrder = sortByFields[index];
    const direction = sortOrder === ThreadViewSortBy.Asc ? 1 : -1;
    if (sortMap[field]) {
      sort[sortMap[field].queryName] = direction;
    }
  });

  return sort;
};

export const transformThreadSort = (
  threadSortMap: ThreadSortMap,
  sortQuery?: SortQuery | null
): {
  streamSort: ThreadSort;
} => {
  if (!sortQuery) {
    return { streamSort: {} };
  }

  const { orderBy, sortBy } = sortQuery;
  const sortPairs = orderBy.split(';');
  const sortOrderPairs = sortBy.split(';');

  const streamSort = processSort(sortPairs, sortOrderPairs, threadSortMap);

  return { streamSort };
};

const processFilter = (
  searchPairs: string[],
  searchFieldMap: Record<string, string>,
  filterMap: ThreadFilterMap
): ThreadChannelFilter => {
  const filter: ThreadChannelFilter = {};

  searchPairs.forEach((pair) => {
    const [key, value] = pair.split(':');

    if (filterMap[key]) {
      const { queryName, operators, type, isArray } = filterMap[key];
      const operator = searchFieldMap[key];
      const targetOperator = operators[operator] ?? '';

      let formattedValue: unknown = value;
      if (type === 'number') {
        formattedValue = isArray ? value.split(',').map(Number) : Number(value);
      } else if (type === 'date') {
        formattedValue = isArray
          ? value.split(',').map(convertToRFC3339)
          : convertToRFC3339(value);
      } else if (isArray) {
        formattedValue = value.split(',');
      }

      filter[queryName] = { [targetOperator]: formattedValue };
    }
  });

  return filter;
};

export const transformThreadFilter = (
  threadFilterMap: ThreadFilterMap,
  searchQuery?: FilterSearchQuery | null
): {
  streamFilter: ThreadChannelFilter | ThreadMessageFilter;
} => {
  if (!searchQuery) {
    return { streamFilter: {} };
  }

  const { search, searchFields } = searchQuery;
  const searchPairs = search.split(';');
  const searchFieldPairs = searchFields.split(';');

  const searchFieldMap: Record<string, string> = {};
  searchFieldPairs.forEach((pair) => {
    const [key, operator] = pair.split(':');
    searchFieldMap[key] = operator;
  });

  const streamFilter = processFilter(
    searchPairs,
    searchFieldMap,
    threadFilterMap
  );

  return { streamFilter };
};
