import { useMemo } from 'react';
import { AgentVisibility, apis } from '@lib/web/apis';
import { callWithToast } from '@lib/web/utils';

export const useIaAgentUserAction = () => {
  return useMemo(
    () => ({
      showAgent: async ({
        id,
        toastAnchorEl,
      }: {
        id: string;
        toastAnchorEl?: HTMLElement | null;
      }) => {
        return callWithToast(
          () =>
            apis.agent.updateMyAgent({
              id,
              visibility: AgentVisibility.Shown,
            }),
          {
            errorMsg: 'Show Failed',
            anchorEl: toastAnchorEl || undefined,
          }
        );
      },
      hideAgent: async ({
        id,
        toastAnchorEl,
      }: {
        id: string;
        toastAnchorEl?: HTMLElement | null;
      }) => {
        return callWithToast(
          () =>
            apis.agent.updateMyAgent({
              id,
              visibility: AgentVisibility.Hidden,
            }),
          {
            errorMsg: 'Hide Failed',
            anchorEl: toastAnchorEl || undefined,
          }
        );
      },
      reorderAgent: async ({
        id,
        beforeItemId,
        afterItemId,
        toastAnchorEl,
      }: {
        id: string;
        beforeItemId?: string;
        afterItemId?: string;
        toastAnchorEl?: HTMLElement | null;
      }) => {
        return callWithToast(
          () =>
            apis.agent.reorderMyAgent({
              id,
              beforeItemId,
              afterItemId,
            }),
          {
            showLoading: false,
            errorMsg: 'Reorder Failed',
            anchorEl: toastAnchorEl || undefined,
          }
        );
      },
    }),
    []
  );
};
