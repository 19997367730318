import { useCallback, useContext, useMemo } from 'react';
import { TFunction, useTranslation } from 'next-i18next';
import { SearchState } from '@app/web/src/utils/search';
import { SquareAvatarStatus } from '@front/ui';
import { IconListLayoutItemActionMap } from '@lib/ia/src/layouts/IconListLayout/types';
import { getUserStatus, useOnlineStatus } from '@lib/web/hooks';
import { getIndicators } from '@lib/web/utils';

import CreateQuizContext from '../../../context';
import { ChallengerIconListLayoutItemObj } from '../../../type';

type UserType = {
  userId: string;
  displayName: string;
  userName: string;
  avatar?: string;
  nftAvatar?: string;
  indicator: BaseMemberInfo['indicator'];
  isFollowing: boolean;
};

const getActionMap = (
  item: UserType,
  selectedIds: string[]
): IconListLayoutItemActionMap => {
  if (selectedIds.includes(item.userId)) {
    return {
      hover: {
        value: 'userHovered',
      },
    };
  }

  if (item.isFollowing) {
    return {
      hover: {
        value: 'userHovered',
      },
      click: {
        actionType: 'tipButton' as const,
        type: 'event' as const,
        value: 'selectChanged',
        icon: 'MainChallenge',
        customSize: 32,
        title: 'Challenge',
      },
    };
  }

  if (!item.isFollowing) {
    return {
      hover: {
        value: 'userHovered',
      },
      click: {
        actionType: 'tipButton' as const,
        type: 'event' as const,
        value: 'follow',
        icon: 'OtherAddFriend',
        customSize: 32,
        title: 'Follow',
      },
    };
  }

  return {
    hover: {
      value: 'userHovered',
    },
  };
};

export const userToChallengerItem = ({
  t,
  user,
  disabledUpdate = false,
  selectedIds,
  getStatus,
}: {
  t: TFunction;
  user: UserType;
  disabledUpdate?: boolean;
  selectedIds: string[];
  getStatus: (id: string) => SquareAvatarStatus | undefined;
}): ChallengerIconListLayoutItemObj => {
  return {
    id: user.userId,
    title: user.displayName || user.userName || '',
    titleAction: { type: 'event', value: 'titleClick' },
    description: t('createQuiz.challengers.display', {
      value: `@${user.userName}`,
      context: user.isFollowing ? 'following' : 'noFollowing',
    }),
    avatarUrl: user.nftAvatar || user.avatar || '',
    indicators: getIndicators(user.indicator),
    actionMap: getActionMap(user, selectedIds),
    titleHoverAction: { value: 'titleHover' },
    titleClassName: 'floating-avatar-anchor',
    avatarHoverAction: { value: 'avatarHover' },
    avatarClassName: 'floating-avatar-anchor',
    status: getStatus(user.userId),
    statusInfo: {
      [SquareAvatarStatus.Busy]: t('common::user.status.busy'),
    },
    metadata: {
      userId: user.userId,
      userName: user.userName,
      isFollowing: true,
      disabledUpdate,
    },
  };
};

export default function useAddFriendsToDataset({
  listState,
  topScorerDataset,
  searchDataset,
  selectedIds,
}: {
  listState: SearchState | null;
  topScorerDataset: GetMembersByCategoryRes[];
  searchDataset: GetSearchClubMembersRes[];
  selectedIds: string[];
}): ChallengerIconListLayoutItemObj[] {
  const { t } = useTranslation('quiz');
  const [{ disabledParts }] = useContext(CreateQuizContext);
  const onlyAddChallengers = disabledParts.includes(
    'challenge-exclude-add-challengers'
  );
  const { getUserOnlineStatus } = useOnlineStatus();
  const getStatus = useCallback(
    (id: string) => {
      const statusCode = getUserOnlineStatus(id);
      return getUserStatus(statusCode);
    },
    [getUserOnlineStatus]
  );

  return useMemo(() => {
    if (listState === SearchState.Initial) {
      return topScorerDataset.map((data) =>
        userToChallengerItem({
          t,
          getStatus,
          user: {
            userId: data.userId,
            userName: data.distinctName,
            displayName: data.displayName,
            nftAvatar: data.nftAvatar,
            avatar: data.avatar,
            indicator: data.indicator || null,
            isFollowing: data.isFollowing,
          },
          disabledUpdate: onlyAddChallengers,
          selectedIds,
        })
      );
    }
    if (listState === SearchState.Searching) {
      return searchDataset.map((data) =>
        userToChallengerItem({
          t,
          getStatus,
          user: {
            userId: data.userId,
            userName: data.userName,
            displayName: data.displayName,
            nftAvatar: data.nftAvatar,
            avatar: data.avatar,
            indicator: data.indicator || null,
            isFollowing: data.isFollowing,
          },
          disabledUpdate: onlyAddChallengers,
          selectedIds,
        })
      );
    }

    return [];
  }, [
    listState,
    topScorerDataset,
    t,
    getStatus,
    onlyAddChallengers,
    selectedIds,
    searchDataset,
  ]);
}
