import { StreamChatGenerics } from '@lib/web/thread/types';
import { StreamMessage } from 'stream-chat-react';

import AgentStatusErrorMessage from './AgentStatusErrorMessage';
import MemberInvitedMessage from './MemberInvitedMessage';
import MemberRemovedMessage from './MemberRemovedMessage';

export type SystemMessageProps = {
  message: StreamMessage<StreamChatGenerics>;
};
export default function SystemMessage({ message }: SystemMessageProps) {
  if (message.customEventType === 'memberInvited') {
    return <MemberInvitedMessage message={message} />;
  }

  if (message.customEventType === 'memberRemoved') {
    return <MemberRemovedMessage message={message} />;
  }

  if (message.customEventType === 'agentStatusError') {
    return <AgentStatusErrorMessage message={message} />;
  }

  console.warn(`unsupported system message type : ${message.customEventType}`);
  return null;
}
