import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Theme } from '@mui/material';
import { useFilter } from '@app/web/src/hooks/utils/useFilter';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  ActionClear as ActionClearIcon,
  NFTFavorite as NFTFavoriteIcon,
  OtherDelete as OtherDeleteIcon,
  OtherFilter as OtherFilterIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';
import {
  BaseLayoutRightPanel,
  getPropertyIcon,
  TipButton,
  useBaseLayout,
} from '@front/ui';

import FilterPanelContent from './components/FilterPanelContent';

const styles = {
  toolbarButton: {
    marginLeft: 'auto',
  },
  filterIcon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  highlightIcon: {
    color: (theme: Theme) => theme.palette.primary.light,
  },
};

function isQuerySummaryPage(page?: string) {
  return !page || page === 'querySummary';
}

export default function FilterPanel() {
  const { t } = useTranslation();
  const { removeCondition, resetFilter, hasFilter, resetFilterDisabled } =
    useFilter();
  const { openRightPanel, getRightParams } = useBaseLayout<GlobalPanelParams>();

  const { page, selectedCondition, showEmojiPicker } = getRightParams(
    GlobalPanelKeys.GlobalFilter
  );

  const handleDeleteClick = () => {
    if (selectedCondition && selectedCondition.id) {
      removeCondition(selectedCondition.id);
    }
    openRightPanel(GlobalPanelKeys.GlobalFilter, { page: 'querySummary' });
  };

  const handleResetClick = () => {
    resetFilter();
  };

  let title;
  let titleIcon;
  if (isQuerySummaryPage(page)) {
    title = t('filter.panel.title');
    titleIcon = (
      <Box sx={[styles.filterIcon, hasFilter && styles.highlightIcon]}>
        <OtherFilterIcon width={16} height={16} />
      </Box>
    );
  } else if (page === 'fieldList') {
    title = t('filter.panel.title_addFilter');
    titleIcon = <TestAddIcon width={16} height={16} />;
  } else if (page === 'conditionDetail' && !showEmojiPicker) {
    title = selectedCondition?.field.label;
    titleIcon = getPropertyIcon(selectedCondition?.field.type, 18);
  } else if (page === 'conditionDetail' && showEmojiPicker) {
    title = t('filter.panel.title_filterByEmoji');
    titleIcon = <NFTFavoriteIcon width="16" height="16" />;
  }

  let toolComponent;
  if (page === 'conditionDetail') {
    toolComponent = (
      <TipButton
        title={t('toolbar.Delete filter')}
        sx={styles.toolbarButton}
        onClick={handleDeleteClick}
      >
        <OtherDeleteIcon />
      </TipButton>
    );
  } else if (isQuerySummaryPage(page) && hasFilter) {
    toolComponent = (
      <TipButton
        title={t('toolbar.Clear all')}
        sx={styles.toolbarButton}
        disabled={resetFilterDisabled}
        onClick={handleResetClick}
      >
        <ActionClearIcon />
      </TipButton>
    );
  }

  return (
    <BaseLayoutRightPanel
      titleIcon={titleIcon}
      title={title}
      toolComponent={toolComponent}
    >
      <FilterPanelContent />
    </BaseLayoutRightPanel>
  );
}
