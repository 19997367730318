import { StreamChatGenerics } from '@lib/web/thread/types';
import { Channel, DefaultGenerics } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

import UpgradeCtaMessage from './UpgradeCtaMessage';

type CtaMessageProps = {
  channel: Channel<DefaultGenerics>;
  message: StreamMessage<StreamChatGenerics>;
};

export default function CtaMessage({ channel, message }: CtaMessageProps) {
  if (message.customCtaType === 'upgrade') {
    return <UpgradeCtaMessage channel={channel} message={message} />;
  }
  console.warn(`unsupported message ctaType : ${message.customCtaType}`);
  return null;
}
