import { useCallback, useEffect, useMemo } from 'react';
import useIaFilterActions from '@app/web/src/hooks/utils/useIaFilterActions';
import { useIaSortActions } from '@app/web/src/hooks/utils/useIaSortActions';
import { PropertyType } from '@front/ui';
import { FilterConditionConfig } from '@lib/ia/src/filter/types';
import { SortCriteriaConfig } from '@lib/ia/src/sort/types';
import { ThreadViewType } from '@lib/web/apis';
import { useThread } from '@lib/web/thread/hooks/core/useThread';

type UseSetFilterSortConditionsProps = {
  type: 'table' | 'channel';
  view: GetThreadViewRes;
  viewMemberIds: string[];
  scope?: string;
  completeInitPartial: (name: string) => void;
  extraFilterConditions?: FilterConditionConfig[];
};

type UseSetFilterSortConditionsReturn = {
  setDefaultSortCriteria: () => void;
  setDefaultFilterCondition: () => void;
};

const useSetFilterSortConditions = ({
  type,
  view,
  viewMemberIds,
  scope,
  completeInitPartial,
  extraFilterConditions,
}: UseSetFilterSortConditionsProps): UseSetFilterSortConditionsReturn => {
  const { setConditions, forceResetFilter } = useIaFilterActions({ scope });
  const { setCriteria, resetSort } = useIaSortActions({ scope });
  const { getThreadUser } = useThread();

  const sortCriteria = useMemo<SortCriteriaConfig[]>(() => {
    if (type === 'table') {
      return [
        {
          id: 'lastActivityTime',
          field: {
            label: 'Last activity Time',
            name: 'lastActivityTime',
            type: PropertyType.DateOrTime,
          },
          direction: 'desc',
        },
      ];
    }
    if (type === 'channel') {
      return [
        {
          id: 'createdTime',
          field: {
            label: 'Created Time',
            name: 'createdTime',
            type: PropertyType.DateOrTime,
          },
          direction: 'asc',
        },
      ];
    }

    return [];
  }, [type]);

  const filterConditions = useMemo<FilterConditionConfig[]>(() => {
    const visibility =
      view.type === ThreadViewType.DmEveryone ? 'public' : 'private';
    const members = viewMemberIds
      .map((memberId) => {
        return getThreadUser(memberId);
      })
      .map((user) => {
        return { value: user?.id ?? '', label: user?.name ?? '' };
      });

    const visibilityCondition: FilterConditionConfig = {
      id: 'visibility',
      field: {
        label: 'Visibility',
        name: 'visibility',
        type: PropertyType.Select,
        customIcon: 'LoginSee',
        options: [
          { value: 'public', label: 'Public' },
          { value: 'private', label: 'Private' },
        ],
      },
      disabled: true,
      viewOnly: true,
      operator: 'IsSelectEqual',
      values: visibility,
    };
    const membersCondition: FilterConditionConfig = {
      id: 'members',
      field: {
        label: 'Members',
        name: 'members',
        type: PropertyType.Person,
        customIcon: 'PrivacyFriends',
      },
      disabled: true,
      viewOnly: true,
      operator: 'Contains',
      values: members,
    };
    const activeMembersCondition: FilterConditionConfig = {
      id: 'activeMembers',
      field: {
        label: 'Active Members',
        name: 'customActiveMembers',
        type: PropertyType.Person,
        customIcon: 'PrivacyFriends',
      },
      disabled: true,
      viewOnly: true,
      operator: 'Contains',
      values: members,
    };

    switch (view.type) {
      case ThreadViewType.DmOneOnOne:
        return [visibilityCondition, membersCondition];
      case ThreadViewType.DmGroup:
        return [visibilityCondition, membersCondition];
      case ThreadViewType.DmEveryone:
        return [visibilityCondition, activeMembersCondition];
      default:
        return [];
    }
  }, [getThreadUser, view.type, viewMemberIds]);

  const setDefaultSortCriteria = useCallback((): void => {
    setCriteria(sortCriteria);
  }, [setCriteria, sortCriteria]);

  const setDefaultFilterCondition = useCallback((): void => {
    setConditions([...filterConditions, ...(extraFilterConditions ?? [])]);
  }, [filterConditions, setConditions, extraFilterConditions]);

  useEffect(() => {
    setDefaultSortCriteria();
    completeInitPartial('threadChannel_sortInitializing');
  }, [setDefaultSortCriteria, completeInitPartial]);

  useEffect(() => {
    setDefaultFilterCondition();
    completeInitPartial('threadChannel_filterInitializing');
  }, [setDefaultFilterCondition, completeInitPartial]);

  useEffect(() => {
    return () => {
      resetSort();
    };
  }, [resetSort]);

  useEffect(() => {
    return () => {
      forceResetFilter();
    };
  }, [forceResetFilter]);

  return {
    setDefaultSortCriteria,
    setDefaultFilterCondition,
  };
};

export default useSetFilterSortConditions;
