import { useContext } from 'react';

import { FloatingProfileContext } from '../../contexts/floatingProfileContext';

export default function useFloatingProfile() {
  const { showAhaProfile, showUserProfile, showUserIdProfile, hideProfile } =
    useContext(FloatingProfileContext);

  return {
    showAhaProfile,
    showUserProfile,
    showUserIdProfile,
    hideProfile,
  };
}
