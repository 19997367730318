import { featureEnable } from '@lib/web/thread/config/constants';

import { useAppSelector } from '../redux';

export default function useLayoutSetting() {
  const layoutSetting = useAppSelector((st) => st.iaLayout.table);

  if (!featureEnable.tableLayoutSettings) {
    return {
      ...layoutSetting,

      // always show borders, even if the user's settings exist
      showRowBorder: true,
      showColumnBorder: true,
    };
  }
  return layoutSetting;
}
