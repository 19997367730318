import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Switch, Typography } from '@mui/material';
import { localStorageKeyConfig } from '@front/config';
import { TestClock as TestClockIcon } from '@front/icon';
import { ResponsiveTooltip } from '@front/ui';
import { ExamMode } from '@lib/web/apis';
import { setStorageItem } from '@lib/web/utils';

import { CreateQuizFormValue, Placement } from '../../type';

const styles = {
  root: (placement: Placement) => ({
    gap: 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      md: placement === 'center' ? 'row-reverse' : 'row',
    },
    justifyContent: {
      xs: 'space-between',
      md: placement === 'center' ? 'flex-end' : 'space-between',
    },
    width: {
      xs: '100%',
      md: placement === 'center' ? 'unset' : '100%',
    },
  }),
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '2.5px',
  },
};

type TimerSwitchProps = {
  placement: Placement;
  disabled: boolean;
};

export default function TimerSwitch({ placement, disabled }: TimerSwitchProps) {
  const { t } = useTranslation('quiz');

  const { watch, setValue } = useFormContext<CreateQuizFormValue>();
  const mode = watch('mode', ExamMode.Practice);
  const mockTimer = watch('mockTimer');
  const turboTimer = watch('turboTimer');
  const isOpenTimer = mode === ExamMode.MockExam ? mockTimer : turboTimer;
  const modeText =
    mode === ExamMode.MockExam
      ? t('createQuiz.mode.mock.title')
      : t('createQuiz.mode.turbo.title');
  const toggleTimer = () => {
    if (mode === ExamMode.MockExam) {
      setValue('mockTimer', !isOpenTimer, { shouldDirty: true });
    } else {
      setValue('turboTimer', !isOpenTimer, { shouldDirty: true });
    }
  };
  useEffect(() => {
    setStorageItem(localStorageKeyConfig.turboTimerDefaultValue, turboTimer);
  }, [turboTimer]);

  return (
    <ResponsiveTooltip
      titleIcon={<TestClockIcon width={16} height={16} />}
      title={t('createQuiz.settings.timer.hint.title', { mode: modeText })}
      content={t('createQuiz.settings.timer.hint.content', { mode: modeText })}
      tooltipProps={{ followCursor: true }}
    >
      <Box sx={styles.root(placement)}>
        <Box sx={styles.title}>
          <TestClockIcon width={16} height={16} />
          <Typography variant="body2">
            {t('createQuiz.settings.timer.label', { mode: modeText })}
          </Typography>
        </Box>
        <Switch
          checked={!!isOpenTimer}
          onClick={toggleTimer}
          disabled={disabled}
        />
      </Box>
    </ResponsiveTooltip>
  );
}
