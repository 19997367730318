import { useContext } from 'react';

import FilterContext from '../context/FilterContext';

import FilterConditionContent from './FilterConditionContent';
import FilterFieldsContent from './FilterFieldsContent';
import FilterQueryContent from './FilterQueryContent';

export default function FilterPanelContent() {
  const [settings] = useContext(FilterContext);

  const { page } = settings;

  if (!page || page === 'querySummary') return <FilterQueryContent />;
  if (page === 'conditionDetail') return <FilterConditionContent />;
  if (page === 'fieldList') return <FilterFieldsContent />;
}
