import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material';
import useDebounce from '@app/web/src/hooks/utils/useDebounce';
import { SearchBar, useBaseRightPanel } from '@front/ui';
import { useInfiniteScroll } from '@lib/web/hooks';
import { Emoji } from '@lib/web/ui';

import useFriendRankingList from '../hooks/useFriendRankingList';

import FriendRankingList from './FriendRankingList';
import RankingListHeader from './RankingListHeader';
import { RankingsContext } from './RankingsContextProvider';
import TabArea from './RankingTabArea';

const styles = {
  searchBar: {
    px: '20px',
    '& .search-input-wrap': {
      py: '12px',
    },
  },
  searchResult: {
    opacity: 0.75,
    px: '20px',
  },
  empty: {
    px: '12px',
    display: 'flex',
    flexDirection: 'column',
    '& svg': {
      width: { xs: 88, md: 110 },
      height: { xs: 160, md: 200 },
    },
  },
  emptyContent: {
    opacity: 0.5,
  },
};

type EmptyStateProps = {
  variant: 'following' | 'follower';
};

function EmptyState({ variant }: EmptyStateProps) {
  return (
    <Box sx={styles.empty}>
      <Emoji variant="alien" />
      <Typography sx={styles.emptyContent} variant="body1">
        You have no {variant === 'follower' ? 'followers' : 'following'} yet.
      </Typography>
    </Box>
  );
}

type FriendRankingTabContentProps = {
  variant: 'following' | 'follower';
};

export default function FriendRankingTabContent({
  variant,
}: FriendRankingTabContentProps) {
  const { t } = useTranslation('club');
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search.trim());
  const { rightPanelOpened } = useBaseRightPanel();
  const { scrollRef: outerScrollRef } = useContext(RankingsContext);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const data = useFriendRankingList({
    type: variant,
    keyword: debouncedSearch,
  });

  const {
    dataset,
    isEmpty,
    totalCount,
    isLoadingInitialData,
    error,
    isLoadingMore,
  } = data;

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: data,
    scrollEl: outerScrollRef?.current,
  });

  useEffect(() => {
    if (!rightPanelOpened) {
      setSearch('');
    }
  }, [rightPanelOpened]);

  const isSearching = !!search && isLoadingMore;

  const hideCta = !mdUp;
  const ctaCol = !hideCta ? '16px 105px' : '';
  const headerGridTemplateColumns = `1fr 8px 32px 16px 34px 16px 34px ${ctaCol}`;

  return (
    <TabArea scrollable={!outerScrollRef} scrollRef={scrollRef}>
      <TabArea.Header>
        <Box sx={styles.searchBar}>
          <SearchBar
            placeholder={
              variant === 'follower'
                ? t('search.placeholder_followers', 'Search followers...')
                : t('search.placeholder_followings', 'Search followings...')
            }
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
            loading={isSearching}
          />
        </Box>
        <Box sx={styles.searchResult}>
          {isLoadingInitialData && <Skeleton width={100} />}
          {!isLoadingInitialData && (
            <Typography variant="body2">
              {variant === 'follower'
                ? t('## Followers', { count: totalCount || 0 })
                : t('Following ##', { count: totalCount || 0 })}
            </Typography>
          )}
        </Box>
      </TabArea.Header>

      <TabArea.EmptyState
        visible={!isLoadingInitialData && (!!error || isEmpty)}
      >
        <EmptyState variant={variant} />
      </TabArea.EmptyState>

      <TabArea.List visible={!isLoadingInitialData && !error && !isEmpty}>
        <RankingListHeader gridTemplateColumns={headerGridTemplateColumns} />
        <FriendRankingList
          items={dataset || []}
          loading={isLoadingInitialData || isLoadingMore}
        />
      </TabArea.List>
    </TabArea>
  );
}
