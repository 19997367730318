import React, {
  ChangeEvent,
  MouseEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'next-i18next';
import { alpha, Box, InputProps, Theme } from '@mui/material';
import HorizontalTextAnimation from '@app/web/src/components/HorizontalTextAnimation';
import { Hoverable } from '@front/helper';
import {
  EmojiFace as EmojiFaceIcon,
  OtherEdit as OtherEditIcon,
} from '@front/icon';
import {
  IconButton,
  IndicatorGroup,
  Popper,
  ReactionPicker,
  ReactionPickerTrigger,
  ResponsiveTooltip,
} from '@front/ui';
import { ThreadViewType } from '@lib/web/apis';
import { useRecentMarks } from '@lib/web/hooks';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { ThreadUser } from '@lib/web/thread/types';
import ThreadEmojiPicker from '@lib/web/thread/ui/ThreadEmojiPicker';
import ThreadViewIcon from '@lib/web/thread/ui/ThreadViewIcon';
import { ResponsivePageTitle } from '@lib/web/ui';

import useThreadViewChangeEmoji from '../../hooks/useThreadViewChangeEmoji';
import useThreadViewRename from '../../hooks/useThreadViewRename';

const styles = {
  title: {
    minWidth: 0,
    position: 'relative',
  },
  iconButtonWrap: {
    pr: '50px',
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nameContainer: {
    px: '4px',
    py: '2px',
    height: 42,
    overflow: 'hidden',
  },
  name: {
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  h1: (theme: Theme) => ({
    p: 0,
    display: 'block',
    color: 'text.primary',
    width: '100%',
    fontWeight: 'bold',
    ...theme.typography.appH1,
    fontFamily: theme.typography.fontFamily,
  }),
  inputWrapper: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  input: {
    width: '100%',
    px: '4px',
    py: '2px',
    border: 'none',
    bgcolor: 'transparent',
    cursor: 'text',
    height: 43,
  },
  indicators: {
    verticalAlign: 'middle',
  },
  titleContent: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    borderBottom: '1px solid transparent',
  },
  titleContentHovered: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    borderBottom: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.5)}`,
  },
  popper: {
    zIndex: 10,
    '& .popper-content': {
      px: '2px',
      py: '1.5px',
      minWidth: 0,
    },
  },
};

const MAX_VISIBLE_MEMBER_COUNT = 3;

const EDITABLE_THREAD_VIEW_TYPES = [
  ThreadViewType.DmGroup,
  ThreadViewType.Club,
];

const SHOW_FIRST_MEMBER_INDICATORS_VIEW_TYPES = [
  ThreadViewType.DmOneOnOne,
  ThreadViewType.DmGroup,
];

type GetTooltipContentProps = {
  t: TFunction;
  viewType: ThreadViewType;
};

const getRenameTooltipProps = ({ t, viewType }: GetTooltipContentProps) => {
  switch (viewType) {
    case ThreadViewType.DmOneOnOne:
      return t(
        'dm.view.rename.description',
        'click to rename, this change will only visible to you.'
      );

    case ThreadViewType.DmGroup:
      return t(
        'group.view.rename.description',
        'click to rename, this change will visible to all group members.'
      );

    case ThreadViewType.Club:
      return t('club.view.rename.description', 'click to rename.');

    default:
      return null;
  }
};

const getChangeIconTooltipProps = ({ t, viewType }: GetTooltipContentProps) => {
  switch (viewType) {
    case ThreadViewType.DmOneOnOne:
      return t(
        'dm.view.changeIcon.description',
        'click to change icon, this change will only visible to you.'
      );

    case ThreadViewType.DmGroup:
      return t(
        'group.view.changeIcon.description',
        'click to change icon, this change will visible to all group members.'
      );

    case ThreadViewType.Club:
      return t('club.view.changeIcon.description', 'click to change icon.');

    default:
      return null;
  }
};

type TitleTextProps = {
  viewNameMembers: NonNullable<ThreadUser | null>[] | null;
  viewType: ThreadViewType;
  viewName: string;
  handleChangeViewName: (ev: ChangeEvent<HTMLInputElement>) => void;
  handleEmojiClick: () => void;
  prefix?: ReactNode;
  handleChangeEmoji: (emoji?: GetMarkEmojiRes | null) => void;
  emoji?: GetMarkEmojiRes | null;
};

function NameInput({
  value,
  onChange,
  onBlur,
  prefix,
}: {
  value: string;
  onChange: InputProps['onChange'];
  onBlur: () => void;
  prefix?: ReactNode;
}) {
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    });
  }, []);
  return (
    <Box sx={styles.inputWrapper}>
      {prefix}
      <Box
        ref={inputRef}
        sx={[styles.h1, styles.input]}
        component="input"
        value={value || ''}
        placeholder="Untitled"
        onChange={onChange}
        onBlur={onBlur}
      />
    </Box>
  );
}

function TitleEmojiPicker({
  open,
  anchorEl,
  onClose,
  onChange,
  onBlurClose,
  onMoreClick,
  emoji,
}: {
  open: boolean;
  anchorEl?: Element;
  onClose: () => void;
  onChange?: (emoji?: GetMarkEmojiRes | null) => void;
  onBlurClose?: () => void;
  onMoreClick?: (e: MouseEvent) => void;
  clubId?: string;
  emoji?: GetMarkEmojiRes | null;
}) {
  const { t } = useTranslation('thread');
  const { recentMarks, mutateRecentMarksWithNewMark } = useRecentMarks();

  const handleChange = async (ev: GetMarkEmojiRes | null, e: MouseEvent) => {
    e.stopPropagation();
    if (ev) {
      void mutateRecentMarksWithNewMark(ev);
    }
    onChange?.(ev);
    onClose();
  };

  return (
    <ReactionPicker
      anchorEl={anchorEl}
      open={open}
      selected={emoji ? [emoji] : undefined}
      onClose={onClose}
      onChange={handleChange}
      onBlurClose={onBlurClose}
      onMoreClick={onMoreClick}
      recentEmojis={recentMarks}
      customMoreLabel={t('thread.view.changeIcon.label', 'Change Icon')}
    />
  );
}

const TitleText = ({
  viewNameMembers,
  viewType,
  viewName,
  handleChangeViewName,
  handleEmojiClick,
  prefix,
  handleChangeEmoji,
  emoji,
}: TitleTextProps) => {
  const { t } = useTranslation('thread');
  const [editable, setEditable] = useState(false);
  const anchorRef = useRef<HTMLElement>(null);
  const [reactionMenuOpened, setReactionMenuOpened] = useState(false);
  const [reactionMenuAnchorEl, setReactionMenuAnchorEl] = useState<Element>();
  const [firstItem] = viewNameMembers || [];
  // Format the viewName to display member names or "+x others" when member count exceeds 3
  const formattedViewName = useMemo(() => {
    if (
      viewNameMembers?.length &&
      viewNameMembers.length > MAX_VISIBLE_MEMBER_COUNT
    ) {
      const displayedMembers = viewNameMembers
        .slice(0, MAX_VISIBLE_MEMBER_COUNT)
        .map((m) => m.name);
      const remainingCount = viewNameMembers.length - MAX_VISIBLE_MEMBER_COUNT;
      return `${displayedMembers.join(', ')} +${remainingCount} others`;
    }
    return viewName || 'Untitled';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(viewNameMembers), viewName]);

  const handleHoverReaction = (el?: Element) => {
    setReactionMenuOpened(true);
    setReactionMenuAnchorEl(el);
  };

  if (EDITABLE_THREAD_VIEW_TYPES.includes(viewType)) {
    if (editable) {
      return (
        <NameInput
          value={formattedViewName}
          onChange={handleChangeViewName}
          onBlur={() => setEditable(false)}
          prefix={prefix}
        />
      );
    }
    return (
      <Hoverable sx={styles.title}>
        {({ hovered, hoveredListeners, resetHovered }) => {
          const ViewTitleWrap = hovered ? HorizontalTextAnimation : Box;

          return (
            <Box
              {...hoveredListeners}
              onClick={() => {
                setEditable(true);
                resetHovered();
              }}
              ref={anchorRef}
            >
              <Popper
                open={hovered}
                anchorEl={anchorRef.current}
                placement="left"
                sx={styles.popper}
                popperOptions={{
                  modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
                }}
              >
                <Box sx={styles.iconButtonWrap}>
                  <ResponsiveTooltip
                    title={t(
                      'thread.view.changeIcon.tooltip.title',
                      'Change Icon'
                    )}
                    content={getChangeIconTooltipProps({ t, viewType })}
                    tooltipProps={{
                      followCursor: true,
                      disableInteractive: true,
                      slotProps: {
                        popper: {
                          sx: { zIndex: 9999 }, // above the reaction popover
                        },
                      },
                    }}
                  >
                    <Box
                      sx={styles.iconButton}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      <ReactionPickerTrigger
                        value={undefined}
                        onChangeReaction={() => {}}
                        onHover={handleHoverReaction}
                        onPress={handleHoverReaction}
                      >
                        <EmojiFaceIcon />
                      </ReactionPickerTrigger>
                    </Box>
                  </ResponsiveTooltip>
                  <ResponsiveTooltip
                    content={t(
                      'thread.view.rename.tooltip.title',
                      'Rename thread title'
                    )}
                    tooltipProps={{
                      followCursor: true,
                      disableInteractive: true,
                    }}
                  >
                    <Box sx={styles.iconButton}>
                      <IconButton customSize={24}>
                        <OtherEditIcon />
                      </IconButton>
                    </Box>
                  </ResponsiveTooltip>
                </Box>
              </Popper>
              <Box
                sx={[
                  styles.titleContent,
                  hovered && styles.titleContentHovered,
                ]}
              >
                {prefix}
                <ResponsiveTooltip
                  title={viewName}
                  content={getRenameTooltipProps({ t, viewType })}
                  tooltipProps={{ followCursor: true }}
                >
                  <Box sx={[styles.nameContainer]}>
                    <ViewTitleWrap sx={[styles.name]}>
                      {formattedViewName}
                    </ViewTitleWrap>
                  </Box>
                </ResponsiveTooltip>
              </Box>
              <TitleEmojiPicker
                open={reactionMenuOpened}
                anchorEl={reactionMenuAnchorEl}
                onClose={() => {
                  setReactionMenuOpened(false);
                }}
                onBlurClose={() => setReactionMenuOpened(false)}
                onMoreClick={(e) => {
                  e.stopPropagation();
                  handleEmojiClick();
                  setReactionMenuOpened(false);
                  resetHovered();
                }}
                onChange={(ev) => {
                  resetHovered();
                  handleChangeEmoji(ev);
                }}
                emoji={emoji}
              />
            </Box>
          );
        }}
      </Hoverable>
    );
  }

  return (
    <>
      {prefix}
      {viewName}{' '}
      {SHOW_FIRST_MEMBER_INDICATORS_VIEW_TYPES.includes(viewType) &&
        firstItem?.indicators && (
          <IndicatorGroup
            sx={styles.indicators}
            indicators={firstItem.indicators}
          />
        )}
    </>
  );
};

type ThreadTitleProps = {
  view: GetThreadViewRes;
  clubId?: string;
  subtitleText?: ReactNode;
};

const ThreadViewTitle = ({ view, clubId, subtitleText }: ThreadTitleProps) => {
  const { viewIcon, viewNameMembers, viewType } = useThreadViewDetail(view);

  const { emoji, handleOpenEmojiPanel, handleChangeEmoji } =
    useThreadViewChangeEmoji({
      view,
      clubId,
    });
  const { viewName, handleChangeViewName } = useThreadViewRename({
    view,
    clubId,
  });

  const onEmojiChanged = (e?: GetMarkEmojiRes | null) => {
    handleChangeEmoji({ changedEmoji: e });
  };

  const handleIconClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (EDITABLE_THREAD_VIEW_TYPES.includes(viewType)) {
      handleOpenEmojiPanel();
    }
  };

  const icon = (
    <>
      {viewIcon?.type === 'emoji' ? (
        <ThreadEmojiPicker emoji={emoji} onEmojiClick={handleIconClick} />
      ) : (
        <ThreadViewIcon view={view} size="md" onClick={handleIconClick} />
      )}
    </>
  );

  return (
    <ResponsivePageTitle
      titleText={
        <TitleText
          viewNameMembers={viewNameMembers}
          viewType={viewType as ThreadViewType}
          viewName={viewName}
          handleChangeViewName={handleChangeViewName}
          handleEmojiClick={handleOpenEmojiPanel}
          handleChangeEmoji={onEmojiChanged}
          prefix={icon}
          emoji={emoji}
        />
      }
      subtitleText={subtitleText}
    />
  );
};

export default ThreadViewTitle;
