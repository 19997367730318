import { Box } from '@mui/material';
import { GlobalPanelKeys } from '@app/web/src/types/panel';
import ChallengeButton from '@app/web/src/widgets/PlaylistDetailPage/ChallengeButton';
import RedoButton from '@app/web/src/widgets/PlaylistDetailPage/RedoButton';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
};

export default function RedoButtons() {
  return (
    <Box sx={styles.root}>
      <RedoButton
        providerExcluded
        panelKeyPrefix={GlobalPanelKeys.GlobalPlaylistDetail}
      />
      <ChallengeButton
        providerExcluded
        panelKeyPrefix={GlobalPanelKeys.GlobalPlaylistDetail}
      />
    </Box>
  );
}
