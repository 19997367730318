import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  OtherAddFriend as OtherAddFriendIcon,
  TestTrophy as TestTrophyIcon,
} from '@front/icon';
import { BaseLayoutRightPanel, TipButton, useBaseRightPanel } from '@front/ui';

import RankingsContent from './RankingsContent';

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    mt: 2,
  },
  scroll: {
    position: 'absolute',
    top: '300px',
    left: 0,
    right: 0,
    bottom: 0,
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  toolButton: {
    marginLeft: 'auto',
  },
  tabs: {
    '& .Mui-selected': {
      '& .MuiTypography-root': {
        fontWeight: 500,
      },
    },
    '& .MuiTabs-flexContainer': {
      px: {
        md: '12px',
        xs: '20px',
      },
    },
  },
};

export default function RankingsPanel() {
  const { t } = useTranslation('club');
  const { openRightPanel, getRightParams } =
    useBaseRightPanel<GlobalPanelParams>();
  const params = getRightParams(GlobalPanelKeys.GlobalRankings);
  const [tab, setTab] = useState(params?.tabIndex || 0);

  const changeTab = (tabIndex: number) => {
    openRightPanel(GlobalPanelKeys.GlobalRankings, { tabIndex });
  };

  useEffect(() => {
    /**
     * to support history.back, we cannot simply add setTab inside changeTab,
     * so we need to monitor params change, then set the tab
     */
    setTab(params?.tabIndex || 0);
  }, [params?.tabIndex]);

  return (
    <BaseLayoutRightPanel
      title="Rankings"
      titleIcon={<TestTrophyIcon width={16} height={16} />}
      toolComponent={
        <TipButton
          title={t('toolbar.Add Friends')}
          sx={styles.toolButton}
          onClick={() => openRightPanel(GlobalPanelKeys.GlobalFindUser)}
        >
          <OtherAddFriendIcon width={16} height={16} />
        </TipButton>
      }
      maximizeWidth
    >
      <RankingsContent
        tab={tab}
        changeTab={changeTab}
        size="sm"
        clubSlug={params.clubSlug}
      />
    </BaseLayoutRightPanel>
  );
}
