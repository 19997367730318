import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import useFloatingProfile from '@app/web/src/hooks/utils/useFloatingProfile';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { PrivacyFriends as PrivacyFriendsIcon } from '@front/icon';
import { BaseLayoutRightPanel, SearchBar, useBaseRightPanel } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import {
  IconListLayoutItemHoverEvent,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';
import {
  apis,
  buildInfiniteHookMutate,
  useSearchClubMembers,
} from '@lib/web/apis';
import {
  useClubSlug,
  useInfiniteScroll,
  useSearchStatus,
} from '@lib/web/hooks';
import { call } from '@lib/web/utils';

import useClubMembersLayoutConfig from './hooks/useClubMembersLayoutConfig';

const styles = {
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  search: {
    px: { xs: 2.5, md: '12px' },
  },
  content: {
    flex: 1,
    position: 'relative',
  },
};

export default function ClubMembersPanel() {
  const { t } = useTranslation('club');
  const { search, debouncedSearch, onChange, onBlur, onFocus } =
    useSearchStatus();
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const { showUserProfile } = useFloatingProfile();

  const clubSlug = useClubSlug();
  const data = useSearchClubMembers({
    clubSlug,
    keyword: debouncedSearch || '',
    isGetRole: true,
    isJoined: true,
  });
  const configs = useClubMembersLayoutConfig({ data });
  const { dataset, isLoading: searchLoading, totalCount, mutate } = data;

  const handleChallengeClick = (
    item: IconListLayoutItemObj<BaseMemberInfo>
  ) => {
    if (!item.metadata) return;
    const challenger = {
      id: item.id,
      display: item.title,
      avatar: item.avatarUrl,
      memberInfo: item.metadata,
    };
    openRightPanel(GlobalPanelKeys.GlobalStartChallenge, {
      challengerSource: [challenger],
    });
  };

  const reloadMembers = useMemo(
    () => buildInfiniteHookMutate(mutate),
    [mutate]
  );

  const availableActions = {
    titleClick: {
      action: ({ id }: { id: string }) => {
        openRightPanel(GlobalPanelKeys.GlobalProfile, {
          userId: id,
        });
      },
    },
    follow: {
      action: async ({ id }: { id: string }) => {
        await call(
          reloadMembers(apis.member.followUser(id), {
            optimisticData: dataset.map((d) =>
              d.userId === id ? { ...d, isFollowing: true } : d
            ),
            optimisticPageData: {
              totalCount,
            },
          })
        );
      },
    },
    challenge: {
      action: async (item: IconListLayoutItemObj<BaseMemberInfo>) => {
        handleChallengeClick(item);
      },
    },
    titleHover: {
      action: (event: IconListLayoutItemHoverEvent<UserProfileObj>) => {
        if (!event.target.metadata) return;
        showUserProfile({
          anchorEl: event.anchorEl,
          userProfile: event.target.metadata,
        });
      },
    },
    avatarHover: {
      action: (event: IconListLayoutItemHoverEvent<UserProfileObj>) => {
        if (!event.target.metadata) return;
        showUserProfile({
          anchorEl: event.anchorEl,
          userProfile: event.target.metadata,
          options: {
            spacing: 8,
          },
        });
      },
    },
  };

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: data,
  });

  return (
    <IaActionContextProvider availableActions={availableActions}>
      <BaseLayoutRightPanel
        titleIcon={<PrivacyFriendsIcon width="16" height="16" />}
        title={t('club.RHS.clubMembers.title')}
      >
        <Box sx={styles.main}>
          <Box sx={styles.search}>
            <SearchBar
              placeholder={t(
                'search.placeholder_activeMembers',
                'Search active members...'
              )}
              value={search}
              loading={searchLoading}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Box>
          <Box sx={styles.content}>
            <BaseLayoutRightPanel.ScrollArea
              scrollableNodeProps={{ ref: scrollRef }}
            >
              {configs && <IaLayouts layouts={configs} />}
            </BaseLayoutRightPanel.ScrollArea>
          </Box>
        </Box>
      </BaseLayoutRightPanel>
    </IaActionContextProvider>
  );
}
