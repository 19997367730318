import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useLayoutSetting from '@app/web/src/hooks/utils/useLayoutSetting';
import {
  IaPlaylistQuestion,
  iaPlaylistQuestionAttributes,
  IaPlaylistQuestionAvailableAction,
  IaPlaylistQuestionData,
  useIaPlaylistQuestion,
  useIaPlaylistQuestionEvents,
} from '@app/web/src/ia/playlistQuestion';
import { IaPlaylistQuestionDataItem } from '@app/web/src/ia/playlistQuestion/types';
import { QuizPanelKeys, QuizPanelParams } from '@app/web/src/types/panel';
import { nonNullable } from '@front/helper';
import { useBaseRightPanel } from '@front/ui';
import { IaAction } from '@lib/ia/src/core/types';
import {
  TableLayoutConfig,
  TableLayoutEmptyCell,
  TableLayoutIconCell,
  TableLayoutPlugin,
  TableLayoutRow,
} from '@lib/ia/src/layouts/TableLayout/types';
import { Difficulty, QuizQuestionStatus, QuizType } from '@lib/web/apis';

type LayoutSetting = TableLayoutConfig['settings']['layoutSetting'];

const emptyCell: TableLayoutEmptyCell = {
  type: 'empty',
};

const PAGE_SIZE = 30;
const INNER_WIDTH = 836;

export const usePlaylistDetailTableLayouts = ({
  playlistQuestionData,
  selected,
  isDynamic,
  settings = {
    minimizeNoteColumn: false,
    layoutSetting: {
      position: 'centre',
      minWidth: INNER_WIDTH,
      maxWidth: INNER_WIDTH,
    },
    enablePreview: true,
  },
}: {
  playlistQuestionData: IaPlaylistQuestionData;
  selected?: IaPlaylistQuestionDataItem;
  isDynamic?: boolean;
  settings?: {
    minimizeNoteColumn?: boolean;
    layoutSetting: Partial<LayoutSetting>;
    enablePreview?: boolean;
  };
}): [TableLayoutConfig] => {
  const { data, playlistPath, questions, totalCount, state, hideQuestion } =
    playlistQuestionData;
  const { t } = useTranslation('playlist');

  const layoutSetting = useLayoutSetting();

  const { selectIaPlaylistQuestion } = useIaPlaylistQuestion();

  const { getPlaylistQuestionEvents } = useIaPlaylistQuestionEvents();

  const { getRightParams, rightPanelOpened } =
    useBaseRightPanel<QuizPanelParams>();
  const { questionNo: previewQuestionNo } = getRightParams(
    QuizPanelKeys.QuizQuestionPreview
  );

  const getClickRowAction = useCallback(
    (iaQuestion: IaPlaylistQuestion): IaAction | undefined => {
      if (!data || !playlistPath) {
        return;
      }

      if (settings.enablePreview) {
        return {
          type: 'event',
          value: IaPlaylistQuestionAvailableAction.OpenQuestionPreview,
        };
      }

      return {
        type: 'link',
        value: `${playlistPath}#${iaQuestion.number.value}`,
      };
    },
    [data, playlistPath, settings.enablePreview]
  );

  const disableAddEmojiMsg =
    data?.quiz.type === QuizType.CreatorUserQuiz
      ? t('Reaction is only available for approved questions') // because creator question should not be added into emoji playlists
      : null;

  const questionsToRow = useCallback(
    (question: IaPlaylistQuestionDataItem): TableLayoutRow => {
      const { id, status } = question;
      const iaQuestion = selectIaPlaylistQuestion(question);
      const events = getPlaylistQuestionEvents();
      const isRemoved =
        !!status &&
        ![QuizQuestionStatus.Enabled, QuizQuestionStatus.Internal].includes(
          status
        );
      const isPreviewing =
        rightPanelOpened && previewQuestionNo === iaQuestion.number.value;

      const difficulty: TableLayoutIconCell = {
        type: 'icon',
      };
      if (
        iaQuestion.difficulty &&
        iaQuestion.difficulty.isDynamicDifficulty &&
        iaQuestion.difficulty.dynamicDifficulty
      ) {
        difficulty.icon = {
          [Difficulty.Easy]: 'EditorDifficultyEasy',
          [Difficulty.Hard]: 'EditorDifficultyHard',
          [Difficulty.Medium]: 'EditorDifficultyMedium',
          [Difficulty.Super]: 'EditorDifficultySuper',
        }[iaQuestion.difficulty.dynamicDifficulty];
        if (question.correctRate && question.overtimeRate) {
          difficulty.tooltip = {
            type: 'info',
            title: t(
              `table.questions.difficulty.${iaQuestion.difficulty.dynamicDifficulty}`
            ),
            titleTag: 'Dynamic',
            content: t('table.questions.difficulty.tooltip', {
              correct: (question.correctRate * 100).toFixed(0),
              overtime: (question.overtimeRate * 100).toFixed(0),
            }),
            placement: 'bottom-start',
          };
        }
      } else if (
        iaQuestion.difficulty &&
        iaQuestion.difficulty.staticDifficulty
      ) {
        difficulty.icon = {
          [Difficulty.Easy]: 'EditorDifficultyEasy',
          [Difficulty.Hard]: 'EditorDifficultyHard',
          [Difficulty.Medium]: 'EditorDifficultyMedium',
          [Difficulty.Super]: 'EditorDifficultySuper',
        }[iaQuestion.difficulty.staticDifficulty];
        difficulty.tooltip = {
          type: 'simple',
          content: t(
            `table.questions.difficulty.${iaQuestion.difficulty.staticDifficulty}`
          ),
        };
      } else {
        // default in case of legacy questions (sourceType = 1)
        difficulty.icon = 'EditorDifficultyMedium';
        difficulty.tooltip = {
          type: 'simple',
          content: t(`table.questions.difficulty.${Difficulty.Medium}`),
        };
      }

      return {
        id: id,
        draggable: false,
        selected: selected?.id === id || isPreviewing,
        clickAction:
          hideQuestion || isRemoved ? undefined : getClickRowAction(iaQuestion),
        moreActions: hideQuestion
          ? []
          : [
              disableAddEmojiMsg || !events.ChangeEmoji
                ? null
                : {
                    ...events.ChangeEmoji,
                    plugin: TableLayoutPlugin.Reaction,
                  },
            ].filter(nonNullable),
        metadata: {
          questionNo: iaQuestion.number.value,
        },
        cells: {
          indicator: {
            type: 'indicator',
            value: hideQuestion ? 'gray' : iaQuestion.lineColor.value,
          },
          overtime: {
            type: 'indicator',
            value: iaQuestion.overtime.value === 'overtime' ? 'orange' : '',
            tooltip:
              iaQuestion.overtime.value === 'overtime'
                ? {
                    type: 'simple',
                    content: 'Overtime',
                  }
                : undefined,
          },
          difficulty,
          number: {
            type: 'number',
            value: iaQuestion.number.value,
            asSequence: true,
            sx: [isRemoved && { opacity: 0.64 }],
          },
          content: hideQuestion
            ? { type: 'blur' }
            : {
                type: 'text',
                html: iaQuestion.content.value,
                className: 'render-one-line',
                sx: [
                  isRemoved && { opacity: 0.64 },
                  iaQuestion.isAnswered.value && { opacity: 0.5 },
                ],
              },
          note: hideQuestion
            ? { type: 'blur' }
            : settings.minimizeNoteColumn
            ? {
                type: 'icon',
                icon: iaQuestion.note.value ? 'OtherNotes' : undefined,
              }
            : {
                type: 'text',
                html: iaQuestion.note.value,
              },
          emoji:
            hideQuestion || isRemoved
              ? emptyCell
              : {
                  type: TableLayoutPlugin.Reaction,
                  value: iaQuestion.emoji,
                  changeAction: IaPlaylistQuestionAvailableAction.ChangeEmoji,
                  disabled: !!disableAddEmojiMsg,
                  tooltip: disableAddEmojiMsg
                    ? {
                        type: 'simple',
                        content: disableAddEmojiMsg,
                      }
                    : undefined,
                },
          share:
            hideQuestion || !playlistPath || isRemoved
              ? emptyCell
              : {
                  type: 'icon',
                  icon: 'OtherShareToDirect',
                  clickAction: IaPlaylistQuestionAvailableAction.Share,
                },
        },
      };
    },
    [
      selectIaPlaylistQuestion,
      getPlaylistQuestionEvents,
      rightPanelOpened,
      previewQuestionNo,
      selected?.id,
      hideQuestion,
      getClickRowAction,
      disableAddEmojiMsg,
      settings.minimizeNoteColumn,
      playlistPath,
      t,
    ]
  );

  return useMemo(
    () => [
      {
        layout: 'table-layout',
        plugins: [TableLayoutPlugin.Reaction],
        table: {
          columnOrder: [
            'indicator',
            'overtime',
            'difficulty',
            'number',
            'content',
            ...(isDynamic ? [] : ['note']),
            'emoji',
            'share',
          ],
          gridTemplateColumns: `4px 12px 32px 47px 1fr ${
            isDynamic ? '' : settings.minimizeNoteColumn ? '32px' : '170px'
          } 32px 32px`,
          head: {
            cells: {
              indicator: {
                type: 'default',
              },
              overtime: {
                type: 'default',
              },
              difficulty: {
                type: 'default',
                icon: iaPlaylistQuestionAttributes.difficulty.icon,
              },
              number: {
                type: 'default',
                icon: iaPlaylistQuestionAttributes.number.icon,
              },
              content: {
                type: 'default',
                icon: iaPlaylistQuestionAttributes.content.icon,
                label: t(iaPlaylistQuestionAttributes.content.name),
              },
              note: {
                type: 'default',
                icon: iaPlaylistQuestionAttributes.note.icon,
                label: !settings.minimizeNoteColumn
                  ? t(iaPlaylistQuestionAttributes.note.name)
                  : undefined,
              },
              emoji: {
                type: 'default',
                icon: hideQuestion
                  ? undefined
                  : iaPlaylistQuestionAttributes.emoji.icon,
              },
              share: {
                type: 'default',
                icon: hideQuestion ? undefined : 'OtherShareToDirect',
              },
            },
            hideCheckbox: hideQuestion,
          },
          rows: questions.map(questionsToRow),
        },
        data: {
          pageSize: PAGE_SIZE,
          state,
          totalCount,
          emptyRow: {
            text: t('No questions'),
          },
          loadMorePagesAction: IaPlaylistQuestionAvailableAction.LoadMore,
        },
        settings: {
          layoutSetting: {
            ...layoutSetting,
            ...(settings.layoutSetting as LayoutSetting),
          },
          disableCellFocus: true,
        },
      },
    ],
    [
      hideQuestion,
      layoutSetting,
      questions,
      questionsToRow,
      state,
      t,
      totalCount,
      isDynamic,
      settings,
    ]
  );
};
