import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';

export default function useOpenGlobalProfilePanel() {
  const { openRightPanel, setRightParams, getRightParams } =
    useBaseRightPanel<GlobalPanelParams>();

  const openProfile = (targetId: string, search = '') => {
    if (typeof search === 'string') {
      setRightParams((prev) => ({ ...prev, defaultSearch: search }));
    }

    openRightPanel(GlobalPanelKeys.GlobalProfile, {
      userId: targetId,
    });
  };

  return {
    openProfile,
    defaultSearch: getRightParams(GlobalPanelKeys.GlobalClubInvitation)
      .defaultSearch,
  };
}
