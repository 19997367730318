import { useFilter } from '@app/web/src/hooks/utils/useFilter';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseLayout } from '@front/ui';
import FilterFields from '@lib/ia/src/filter/FilterFields';
import FilterQuery from '@lib/ia/src/filter/FilterQuery';
import {
  FilterConditionConfig,
  FilterConditionSelectedEvent,
  FilterField,
} from '@lib/ia/src/filter/types';

export default function FilterQueryContent() {
  const { openRightPanel, rightPanelOpened } =
    useBaseLayout<GlobalPanelParams>();
  const { filter, hasFilter } = useFilter();
  const isEmpty = !hasFilter;
  const selectedFields = filter.conditions.map((item) => item.field.name);

  const handleAddNewFilter = () => {
    openRightPanel(GlobalPanelKeys.GlobalFilter, { page: 'fieldList' });
  };

  const handleFieldSelected = (field: FilterField) => {
    const newCondition: FilterConditionConfig = {
      field,
    };
    openRightPanel(GlobalPanelKeys.GlobalFilter, {
      page: 'conditionDetail',
      selectedCondition: newCondition,
    });
  };

  const handleConditionSelected = (event: FilterConditionSelectedEvent) => {
    if (event.viewOnly) {
      // for view only purpose -> do nothing
      return;
    }
    const selectedCondition: FilterConditionConfig = {
      id: event.id,
      field: event.field,
      operator: event.operator,
      values: event.values,
    };
    openRightPanel(GlobalPanelKeys.GlobalFilter, {
      page: 'conditionDetail',
      selectedCondition,
    });
  };

  if (isEmpty) {
    return (
      <FilterFields
        fields={filter.fields}
        excludeNames={selectedFields}
        onSelect={handleFieldSelected}
        autoFocus={rightPanelOpened}
        focusDelay={
          300 /** right panel animation duration: 300s => avoid layout jumping */
        }
      />
    );
  }

  return (
    <FilterQuery
      conditions={filter.conditions}
      onAddFilter={handleAddNewFilter}
      onSelect={handleConditionSelected}
    />
  );
}
