import React, { PropsWithChildren, useState } from 'react';

import SortContext, { initialValue } from './SortContext';

export type SortProviderProps = PropsWithChildren<{
  scope: string;
}>;

export default function SortProvider({ children, scope }: SortProviderProps) {
  const providerValue = useState({ ...initialValue, scope });
  return (
    <SortContext.Provider value={providerValue}>
      {children}
    </SortContext.Provider>
  );
}
