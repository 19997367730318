import { useMemo } from 'react';
import { buildSortQuery } from '@lib/ia/src/sort/utils';

import { SortQuery } from '../../widgets/CommonPanels/SortPanel/types';
import { useAppSelector } from '../redux';

type Options = {
  scope?: string;
};

export default function useIaSortValues({ scope = 'global' }: Options = {}) {
  const sort = useAppSelector((st) => st.sort);

  return useMemo(() => {
    const result = buildSortQuery(sort.criteria[scope] || []) || {
      rawQueryParams: null,
      orderBy: '',
      sortBy: '',
    };
    const { rawQueryParams, ...rest } = result;
    return {
      sort: {
        criteria: sort.criteria[scope] || [],
        fields: sort.fields[scope] || [],
      },

      hasSort: !!sort.criteria[scope] && sort.criteria[scope].length > 0,

      sortQuery: rest as SortQuery,
      rawQueryParams: rawQueryParams,
    };
  }, [scope, sort.criteria, sort.fields]);
}
