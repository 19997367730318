import { useState } from 'react';
import AuthGuard from '@app/web/src/components/AuthGuard';
import { FollowButton } from '@app/web/src/components/FriendButtons';
import {
  OtherAddFriend as OtherAddFriendIcon,
  OtherFollowing as OtherFollowingIcon,
} from '@front/icon';
import { Button } from '@front/ui';
import { useUserProfileSocial } from '@lib/web/apis';

const styles = {
  button: {
    px: 2,
    flex: 'unset',
    width: 135,
    ml: 'auto',
  },
};

type FriendFollowButtonProps = {
  userId: string;
};
export default function FriendFollowButton({
  userId,
}: FriendFollowButtonProps) {
  const [loading] = useState(false);

  const { data } = useUserProfileSocial(userId);

  const prefixIcon = data?.data.isFollowing ? (
    <OtherFollowingIcon />
  ) : (
    <OtherAddFriendIcon />
  );

  if (!data) return <Button prefixIcon={prefixIcon} loading />;

  return (
    <AuthGuard>
      {({ disabled }) => (
        <FollowButton
          userId={userId}
          autoFetchSocialData
          isFollower={data.data.isFollower}
          isFollowing={data.data.isFollowing}
          sx={styles.button}
          loading={loading}
          disabled={disabled}
        />
      )}
    </AuthGuard>
  );
}
