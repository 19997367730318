import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentUserId from '@app/web/src/hooks/utils/useCurrentUserId';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  MainClubGroup as MainClubGroupIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';
import {
  BaseLayoutRightPanel,
  Button,
  SearchBar,
  SquareAvatar,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import { apis, ClubGroupViewSlug, useIaClubGroups } from '@lib/web/apis';
import { useSearchStatus } from '@lib/web/hooks';
import { call } from '@lib/web/utils';

import useClubGroupConfig from './useClubGroupConfig';

export default function AddClubGroupPanel() {
  const { t } = useTranslation('club');
  const searchInputRef = useRef<HTMLInputElement>();
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { getRightParams, closeRightPanel } =
    useBaseRightPanel<GlobalPanelParams>();
  const { focused, search, debouncedSearch, onChange, onBlur, onFocus } =
    useSearchStatus();
  const userId = useCurrentUserId();
  const rightParams = getRightParams(GlobalPanelKeys.GlobalAddClubGroup);
  const clubGroupData = useIaClubGroups({
    viewSlug: ClubGroupViewSlug.CardCenterClubGroupDefault,
    expandSearch: `creator:clubCreatorSourceId:${userId}`,
    search: 'expandClubLimit:100',
    keywordFuzzy: debouncedSearch,
  });

  const {
    dataset: searchDataset,
    isLoading: searchLoading,
    totalCount: searchTotalCount,
    mutate: searchMutate,
  } = clubGroupData;

  const displayDataset = useMemo(() => {
    return searchDataset.filter(
      (clubGroup) =>
        !clubGroup.clubs?.some((c) => c.clubSlug === rightParams.clubSlug)
    );
  }, [searchDataset, rightParams.clubSlug]);

  const config = useClubGroupConfig({
    displayDataset,
    totalCount: searchTotalCount,
  });

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );

  const getItemStatus = ({ id }: { id: string }) => {
    return {
      selected: selected.includes(id),
    };
  };

  const availableActions = {
    selectChanged: {
      action: (value: IconListLayoutItemObj) => {
        if (value.metadata) {
          setSelected((ids) =>
            ids.includes(value.id)
              ? ids.filter((id) => id !== value.id)
              : [...ids, value.id]
          );
        }
      },
    },
    groupHovered: {
      action: updateSuggestItem,
    },
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && suggestItem) {
      event.preventDefault();
      setSelected((ids) =>
        ids.includes(suggestItem.id)
          ? ids.filter((id) => id !== suggestItem.id)
          : [...ids, suggestItem.id]
      );
      searchInputRef.current?.focus();
    }
  };

  const handleAddToClubGroup = async () => {
    setLoading(true);
    const allPromise = selected.map((id) =>
      apis.clubGroup.addClubsIntoGroup({
        id: id,
        clubSlugs: [rightParams.clubSlug],
      })
    );
    await call(allPromise);
    setSelected([]);
    searchMutate();
    closeRightPanel();
    setLoading(false);
  };

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <BaseLayoutRightPanel
          titleIcon={<MainClubGroupIcon width={16} height={16} />}
          title={t('addClubGroup.RHS.title', 'Add to My Club Group')}
          actionComponent={
            <Button
              prefixIcon={<TestAddIcon width={16} height={16} />}
              onClick={handleAddToClubGroup}
              data-testid="right-panel-action-button"
              disabled={selected.length === 0}
              loading={loading}
            >
              {t('addClubGroup.RHS.cta', { count: selected.length })}
            </Button>
          }
        >
          <BaseLayoutRightPanel.SearchWrapper>
            <BaseLayoutRightPanel.SearchInput>
              <SearchBar
                inputRef={searchInputRef}
                placeholder={t(
                  'search.placeholder_clubGroup',
                  'Search club group...'
                )}
                value={search}
                loading={searchLoading}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={handleKeyDown}
                suggestText={suggestItem?.name as string}
                prefixIcon={
                  suggestItem && (
                    <SquareAvatar src={suggestItem.logo as string} size={16}>
                      {suggestItem?.name}
                    </SquareAvatar>
                  )
                }
              />
            </BaseLayoutRightPanel.SearchInput>
            <BaseLayoutRightPanel.SearchContent>
              <BaseLayoutRightPanel.ScrollArea>
                <IaLayouts layouts={config} />
              </BaseLayoutRightPanel.ScrollArea>
            </BaseLayoutRightPanel.SearchContent>
          </BaseLayoutRightPanel.SearchWrapper>
        </BaseLayoutRightPanel>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
