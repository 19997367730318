import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import {
  ActionClear as ActionClearIcon,
  ActionClose as ActionCloseIcon,
  ActionCloseThick as ActionCloseThickIcon,
  ChatHashtag as ChatHashtagIcon,
  OtherOutOfPractice as OtherOutOfPracticeIcon,
  TestClockSolid as TestClockSolidIcon,
} from '@front/icon';
import {
  BaseLayoutRightPanel,
  Scrollbar,
  SearchBar,
  TipButton,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import TableLayoutActionsContext from '@lib/ia/src/layouts/TableLayout/TableLayoutActions/TableLayoutActionsContext';
import { useAnalyticsWeaknessTopic } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';
import { trackEvent } from '@lib/web/utils';
import { isEqual, uniq } from 'lodash';

import CreateQuizContext from '../../../context';
import useClosePanel from '../../../hooks/useClosePanel';
import { CreateQuizFormValue } from '../../../type';
import PanelStartButton from '../../parts/PanelStartButton';

import useQuizOrganizedTags from './useQuizOrganizedTags';
import useQuizTopicConfig from './useQuizTopicConfig';
import useQuizTopicRowItems from './useQuizTopicRowItems';
const styles = {
  resetButton: {
    marginLeft: 'auto',
  },
  tabs: {
    px: { xs: 2.5, md: '12px' },
    display: 'flex',
    gap: 2,
    width: 'fit-content',
  },
};

export default function CreateQuizHashtagsPanel() {
  const { t } = useTranslation(['quiz', 'common']);
  const { sectionId } = useCurrentIaClub();

  const { data: weaknessTopicData } = useAnalyticsWeaknessTopic(sectionId);
  const organizedTags = useQuizOrganizedTags(weaknessTopicData?.data);

  const handleClosePanel = useClosePanel();
  const { rightPanelOpened } = useBaseRightPanel();
  const { watch, setValue, register, resetField, getFieldState, formState } =
    useFormContext<CreateQuizFormValue>();
  const searchInputRef = useRef<HTMLInputElement>();
  const [search, setSearch] = useState('');
  const [selectedTab, setSelectedTab] =
    useState<keyof GetAnalyticsWeaknessTopicRes>();

  const [{ disabledParts }] = useContext(CreateQuizContext);
  const hashtagsDisabled =
    disabledParts.includes('all') || disabledParts.includes('topics');
  const isTagsDirty = getFieldState('tags').isDirty;
  const defaultValues = useMemo(
    () => (formState.defaultValues?.tags || []) as string[],
    [formState.defaultValues?.tags]
  );
  const tagValue = watch('tags', defaultValues);

  const codeByType = useMemo(() => {
    return {
      all: weaknessTopicData
        ? uniq(
            [
              ...weaknessTopicData.data.mistake,
              ...weaknessTopicData.data.overtime,
              ...weaknessTopicData.data.notPracticedYet,
            ].map((tag) => tag.tagCode)
          )
        : [],
      mistake:
        weaknessTopicData?.data.mistake.map((d) => d.tagCode) ||
        ([] as string[]),
      overtime:
        weaknessTopicData?.data.overtime.map((d) => d.tagCode) ||
        ([] as string[]),
      notPracticedYet:
        weaknessTopicData?.data.notPracticedYet.map((d) => d.tagCode) ||
        ([] as string[]),
    };
  }, [weaknessTopicData]);
  const countByType = useMemo(() => {
    return {
      mistake:
        codeByType.mistake.filter((tagCode) => tagValue.includes(tagCode))
          .length || 0,
      overtime:
        codeByType.overtime.filter((tagCode) => tagValue.includes(tagCode))
          .length || 0,
      notPracticedYet:
        codeByType.notPracticedYet.filter((tagCode) =>
          tagValue.includes(tagCode)
        ).length || 0,
    };
  }, [
    codeByType.mistake,
    codeByType.notPracticedYet,
    codeByType.overtime,
    tagValue,
  ]);

  const rowItems = useQuizTopicRowItems({
    data: organizedTags,
    search,
    tab: selectedTab,
  });

  const config = useQuizTopicConfig({
    items: rowItems,
    loading: !weaknessTopicData,
    tab: selectedTab,
    selectedCodes: tagValue,
    codeByType,
  });

  const clearHashtagsAndEmojis = () => {
    register('tags');
    resetField('tags');
  };

  const updateValue = (newValues: string[]) => {
    if (isEqual(newValues, tagValue)) return;
    setValue('tags', newValues, {
      shouldDirty: true,
    });
    trackEvent('form', {
      elementName: 'hashtag',
      action: 'change',
    });
  };

  const availableActions = {
    addTopic: {
      action: ({ id }: { id: string }) => {
        updateValue([...tagValue, id]);
      },
    },
    removeTopic: {
      action: ({ id }: { id: string }) => {
        updateValue(tagValue.filter((tagCode) => tagCode !== id));
      },
    },
    toggleAll: {
      action: () => {
        if (!rightPanelOpened) return;

        const basedTags = selectedTab
          ? codeByType[selectedTab]
          : codeByType.all;

        const isSelectedAll = basedTags.every((tagCode) =>
          tagValue.includes(tagCode)
        );

        if (isSelectedAll) {
          updateValue(
            tagValue.filter((tagCode) => !basedTags.includes(tagCode))
          );
        } else {
          updateValue(uniq([...tagValue, ...basedTags]));
        }
      },
    },
  };

  const handleTabChanges = (tab?: keyof GetAnalyticsWeaknessTopicRes) => {
    setSelectedTab(tab);
  };

  const { actionsRef: tableActionsRef } = useContext(TableLayoutActionsContext);

  useEffect(() => {
    if (!rightPanelOpened) {
      tableActionsRef.current?.clearAllCheckbox();
    }
  }, [rightPanelOpened, tableActionsRef]);

  return (
    <IaActionContextProvider availableActions={availableActions}>
      <BaseLayoutRightPanel
        titleIcon={<ChatHashtagIcon width="16" height="16" />}
        title={t('createQuiz.topic.title')}
        toolComponent={
          isTagsDirty && !hashtagsDisabled ? (
            <TipButton
              title={t('toolbar.Reset')}
              onClick={clearHashtagsAndEmojis}
              sx={styles.resetButton}
            >
              <ActionClearIcon />
            </TipButton>
          ) : undefined
        }
        onIconClick={handleClosePanel}
        actionComponent={<PanelStartButton />}
        maximizeWidth
      >
        <BaseLayoutRightPanel.SearchWrapper>
          <BaseLayoutRightPanel.SearchInput>
            <SearchBar
              inputRef={searchInputRef}
              placeholder={t('search.placeholder_topics', 'Search topics...')}
              value={search}
              onChange={(ev) => setSearch(ev.target.value)}
            />
          </BaseLayoutRightPanel.SearchInput>
          <Scrollbar>
            <Box sx={styles.tabs}>
              {!!selectedTab && (
                <Chip
                  label={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <ActionCloseIcon width={16} height={16} />
                    </Box>
                  }
                  variant="outlined"
                  onClick={() => handleTabChanges(undefined)}
                />
              )}
              <Chip
                icon={<ActionCloseThickIcon width={16} height={16} />}
                label={t('createQuiz.topic.tab.mistake', {
                  count: countByType.mistake,
                })}
                variant={selectedTab === 'mistake' ? 'filled' : 'outlined'}
                onClick={() => handleTabChanges('mistake')}
              />
              <Chip
                icon={<TestClockSolidIcon width={16} height={16} />}
                label={t('createQuiz.topic.tab.overtime', {
                  count: countByType.overtime,
                })}
                variant={selectedTab === 'overtime' ? 'filled' : 'outlined'}
                onClick={() => handleTabChanges('overtime')}
              />
              <Chip
                icon={<OtherOutOfPracticeIcon width={16} height={16} />}
                label={t('createQuiz.topic.tab.notPracticed', {
                  count: countByType.notPracticedYet,
                })}
                variant={
                  selectedTab === 'notPracticedYet' ? 'filled' : 'outlined'
                }
                onClick={() => handleTabChanges('notPracticedYet')}
              />
            </Box>
          </Scrollbar>
          <BaseLayoutRightPanel.SearchContent>
            <BaseLayoutRightPanel.ScrollArea>
              <IaLayouts layouts={config} />
            </BaseLayoutRightPanel.ScrollArea>
          </BaseLayoutRightPanel.SearchContent>
        </BaseLayoutRightPanel.SearchWrapper>
      </BaseLayoutRightPanel>
    </IaActionContextProvider>
  );
}
