import {
  AwardBadgeLevelUpType,
  AwardBadgeLevelUserStatus,
  AwardBadgeProgressType,
} from '@lib/web/apis';

import useCurrentUserId from '../../hooks/utils/useCurrentUserId';

import { AwardBadgeDataItem } from './types';

type Options = {
  items?:
    | GetIaAwardBadgeCategoryRes[]
    | GetIaAwardBadgeAlmostThereRes[]
    | GetIaAwardBadgePracticeResultRes[];
  userId?: string;
};

export default function useIaAwardBadgeData({
  items,
  userId,
}: Options): AwardBadgeDataItem[] {
  const currentUserId = useCurrentUserId();
  const targetUserId = userId || currentUserId;

  if (!items) return [];
  return items.map((item) => {
    let levelUser: AwardBadgeDataItem['levelUser'];
    let levelInfo: AwardBadgeDataItem['levelInfo'];
    const awardBadgeLevelUser = item.awardBadgeLevelUser?.find(
      (u) => u.userId === targetUserId
    );
    if (awardBadgeLevelUser) {
      levelUser = {
        level: item.awardBadgeLevelUser[0].level,
        repeatedCount: item.awardBadgeLevelUser[0].repeatedCount,
        currentCount: item.awardBadgeLevelUser[0].currentCount,
        currentValue: item.awardBadgeLevelUser[0].currentValue,
        currentDate: item.awardBadgeLevelUser[0].currentDate,
        status: item.awardBadgeLevelUser[0].status,
        earnedAt: item.awardBadgeLevelUser[0].earnedAt,
        progressText: item.awardBadgeLevelUser[0].progress,
      };
      levelInfo = {
        maxLevel: item.maxLevel,
        requirement: item.awardBadgeLevelUser[0].requirement,
        goalCount: item.awardBadgeLevelUser[0].goalCount,
        goalValue: item.awardBadgeLevelUser[0].goalValue,
        goalDate: item.awardBadgeLevelUser[0].goalDate,
        status: item.awardBadgeLevelUser[0].status,
      };
    } else {
      levelUser = {
        level: 0,
        repeatedCount: 0,
        currentCount: 0,
        currentValue: 0,
        currentDate: null,
        status: null,
        earnedAt: null,
        progressText: null,
      };
      if (item.levelUpType === AwardBadgeLevelUpType.MultiLevel) {
        levelUser.level = 1;
      }
      if (item.progressType === AwardBadgeProgressType.Progress) {
        levelUser.status = AwardBadgeLevelUserStatus.Progress;
      }

      levelInfo = {
        maxLevel: item.maxLevel,
        requirement: null,
        goalCount: null,
        goalValue: null,
        goalDate: null,
        status: null,
      };
      if (item.awardBadgeLevel?.length > 0) {
        levelUser.level = item.awardBadgeLevel[0].level;
        levelUser.progressText = item.awardBadgeLevel[0].progress;

        levelInfo.requirement = item.awardBadgeLevel[0].requirement;
        levelInfo.goalCount = item.awardBadgeLevel[0].goalCount;
        levelInfo.goalValue = item.awardBadgeLevel[0].goalValue;
        levelInfo.goalDate = item.awardBadgeLevel[0].goalDate;
        levelInfo.status = item.awardBadgeLevel[0].status;
      }
    }

    return {
      name: item.name,
      subType: item.subType,
      goalType: item.goalType,
      progressType: item.progressType,
      levelUpType: item.levelUpType,
      levelInfo,
      levelUser,
    };
  });
}
