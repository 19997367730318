import { useEffect } from 'react';
import { PopperProps, Theme, useMediaQuery } from '@mui/material';

import SortPanel from './components/SortPanel';
import SortPopup from './components/SortPopup';
import SortProvider, { SortProviderProps } from './context/SortProvider';
import useSortActions from './hooks/useSortActions';

type SortResponsivePanelProps = {
  anchorEl: PopperProps['anchorEl'];
  open: boolean;
  onClose: () => void;
};
function SortResponsivePanel({
  anchorEl,
  open,
  onClose,
}: SortResponsivePanelProps) {
  const { goPage, clear } = useSortActions();
  useEffect(() => {
    if (open) {
      goPage({ page: 'sortSummary' });
    } else {
      clear();
    }
  }, [open, goPage, clear]);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  if (!open) return null;
  if (mdUp)
    return (
      <SortPopup
        open={open && !!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
      />
    );

  return <SortPanel onClose={onClose} />;
}

export default function SortResponsivePanelRoot({
  scope,
  ...rest
}: SortResponsivePanelProps & Omit<SortProviderProps, 'children'>) {
  return (
    <SortProvider scope={scope}>
      <SortResponsivePanel {...rest} />
    </SortProvider>
  );
}
