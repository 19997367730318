import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { EmphasizeButton, EmphasizeButtonProps } from '@front/ui';
import { useClubSlug } from '@lib/web/hooks';

import useIaClubCtaAction from '../../ia/club/useIaClubCTAAction';

const styles = {
  alignRight: {
    marginLeft: 'auto',
    width: { xs: '100%', md: 'auto' },
  },
};

type AutoJoinClubButtonProps = EmphasizeButtonProps & {
  joinOnLoad?: boolean;
  onSuccess?: () => void;
};
const AutoJoinClubButton = ({
  joinOnLoad = false,
  onClick,
  onSuccess,
  prefixIcon,
  children,
  ...rest
}: AutoJoinClubButtonProps) => {
  const [loading, setLoading] = useState(false);
  const clubSlug = useClubSlug();

  const { club, clubCtaAction } = useIaClubCtaAction(clubSlug, {
    onJoined: onSuccess,
  });

  const processed = useRef(false);
  const isLoading = club === undefined;

  const handleAutoJoinClub = useCallback(async () => {
    if (!clubCtaAction) {
      return;
    }

    setLoading(true);
    await clubCtaAction.action({
      id: club?.clubSlug,
      title: club?.clubName,
    });
    setLoading(false);
  }, [club?.clubName, club?.clubSlug, clubCtaAction]);

  useEffect(() => {
    if (joinOnLoad && !processed.current && !isLoading) {
      handleAutoJoinClub();
      processed.current = true;
    }
  }, [joinOnLoad, handleAutoJoinClub, isLoading]);

  const handleClick = (ev: MouseEvent<HTMLButtonElement>) => {
    handleAutoJoinClub();
    onClick?.(ev);
  };

  return (
    <EmphasizeButton
      sx={styles.alignRight}
      prefixIcon={prefixIcon}
      onClick={handleClick}
      loading={!club || loading}
      {...rest}
    >
      {children}
    </EmphasizeButton>
  );
};

export default AutoJoinClubButton;
