import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { TableLayoutConfig } from '@lib/ia/src/layouts/TableLayout/types';
import { shortName } from '@lib/web/utils';

import {
  getTopicTooltipContent,
  getTopicTooltipProperties,
} from '../../../utils/topicTooltip';

import { OrganizedTagType } from './types';

const getTabColor = (tab: keyof GetAnalyticsWeaknessTopicRes) => {
  if (tab === 'mistake') return 'error';
  if (tab === 'overtime') return 'warning';
  return 'primary';
};

const getTabContext = (tab?: keyof GetAnalyticsWeaknessTopicRes) => {
  if (!tab) return undefined;
  return tab === 'notPracticedYet' ? 'notPracticed' : tab;
};

export default function useQuizTopicConfig({
  loading,
  items,
  tab,
  selectedCodes,
  codeByType,
}: {
  items: OrganizedTagType[];
  loading: boolean;
  tab?: keyof GetAnalyticsWeaknessTopicRes;
  selectedCodes: string[];
  codeByType: {
    all: string[];
    mistake: string[];
    overtime: string[];
    notPracticedYet: string[];
  };
}): TableLayoutConfig[] {
  const { t } = useTranslation('quiz');

  return useMemo(() => {
    const tabColor = tab ? getTabColor(tab) : 'default';
    const basedTags = tab ? codeByType[tab] : codeByType.all;

    const isSelectedAll =
      basedTags.every((tagCode) => selectedCodes.includes(tagCode)) &&
      basedTags.length > 0;

    const tabContext = getTabContext(tab);
    return [
      {
        layout: 'table-layout',
        table: {
          columnOrder: ['name', 'questions', 'action'],
          gridTemplateColumns: '1fr 108px 114px',
          head: {
            action: {
              type: 'button',
              icon: isSelectedAll ? 'ActionCloseSmall' : 'TestAdd',
              variant: isSelectedAll ? 'outlined' : 'filled',
              value: 'toggleAll',
              text: isSelectedAll
                ? t('createQuiz.topic.removeAll', { context: tabContext })
                : t('createQuiz.topic.addAll', { context: tabContext }),
              disabled: basedTags.length === 0,
            },
            cells: {
              name: {
                type: 'default',
                icon: 'ChatHashtag',
                label: 'Topic',
              },
              questions: {
                type: 'default',
                icon: 'ChatQuestionList',
                label: 'Question',
              },
              action: {
                type: 'default',
              },
            },
          },
          rows: items.map((item) => {
            const isSelected = selectedCodes.includes(item.code);
            const color = isSelected ? tabColor : 'default';

            return {
              id: item.code,
              cells: {
                name: {
                  type: 'chip',
                  text: `#${item.name}`,
                  color,
                  variant: isSelected ? 'filled' : 'outlined',
                  tooltip: {
                    type: 'list',
                    title: `#${shortName(item.name, 25)}`,
                    content: getTopicTooltipContent(
                      t,
                      `#${shortName(item.name, 25)}`,
                      tab
                    ),
                    properties: getTopicTooltipProperties(t, item, tab),
                  },
                },
                questions: {
                  type: 'progress',
                  value: tab ? item[tab].count : item.total,
                  total: tab ? item[tab].total : item.total,
                  color,
                },
                action: {
                  type: 'button',
                  buttonVariant: isSelected ? 'outlined' : 'filled',
                  buttonColor: 'default',
                  icon: isSelected ? 'ActionCloseSmall' : 'TestAdd',
                  text: isSelected ? 'Remove' : 'Add',
                  value: isSelected ? 'removeTopic' : 'addTopic',
                },
              },
            };
          }),
        },
        data: {
          state: loading
            ? 'loading'
            : items.length === 0
            ? 'empty'
            : 'reachedEnd',
          pageSize: 10,
          totalCount: 100,
          emptyRow: {
            text: t('createQuiz.topic.empty', {
              context: tabContext,
            }),
          },
        },
        settings: {
          layoutSetting: {
            position: 'rhs',
            showRowBorder: false,
            showColumnBorder: false,
            minWidth: '100%',
          },
          disableCellFocus: true,
        },
      } as TableLayoutConfig,
    ];
  }, [codeByType, items, loading, selectedCodes, t, tab]);
}
