import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { ChatExplore as ChatExploreIcon } from '@front/icon';
import { Button } from '@front/ui';

export default function DiscoverClubsButton() {
  const { t } = useTranslation('notification');

  return (
    <Button
      prefixIcon={<ChatExploreIcon />}
      component={Link}
      href="/"
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      {t('email.verify.cta.discoverClubs')}
    </Button>
  );
}
