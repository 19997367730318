import combineSearch from '@lib/ia/src/utils/combineSearch';
import { AwardBadgeViewSlug, useIaAwardBadgeList } from '@lib/web/apis';
import { format } from 'date-fns';

import { enabledAwardSubTypes } from '../../config/awardConfig';
import useCurrentUserId from '../../hooks/utils/useCurrentUserId';

import useIaAwardBadgeData from './useIaAwardBadgeData';

export default function useIaAwardsAlmostThere({
  userId,
  limit = 10,
  keyword,
  customDate,
}: {
  userId?: string;
  limit?: number;
  keyword?: string;
  customDate?: Date;
} = {}) {
  const currentUserId = useCurrentUserId();

  const data = useIaAwardBadgeList(
    {
      viewSlug: AwardBadgeViewSlug.ProfileCenterIaBadgeAlmostThere,
      limit,
      filter: 'subType;goalType;awardBadgeLevel',
      ...combineSearch(
        !!customDate && [
          `awardBadgeLevelUser:earnedAt:${format(customDate, 'yyyy-MM-dd')}`,
          'awardBadgeLevelUser:earnedAt:eq',
        ],
        {
          search: `subType:${enabledAwardSubTypes.join(',')}`,
          searchFields: `subType:in`,
        }
      ),

      keyword,
      userId,
    },
    {
      enable: !!currentUserId,
    }
  );
  const { dataset } = data;
  const items = useIaAwardBadgeData({
    items: dataset,
    userId,
  });

  return {
    ...data,
    items,
  };
}
