import { awardSubTypeIdsMap } from '@app/web/src/config/awardConfig';
import { Icon } from '@front/ui';
import { AwardBadgeSubType } from '@lib/web/apis';

type AwardBadgeIconProps = {
  subType: AwardBadgeSubType;
  level?: number;
  locked?: boolean;
  size?: number;
  [x: string]: any;
};
export default function AwardBadgeIcon({
  subType,
  level = 0,
  locked = false,
  size,
  ...rest
}: AwardBadgeIconProps) {
  const config = awardSubTypeIdsMap[subType];
  if (!config) {
    console.warn(`The subType=${subType} has not supported yet!`);
    return null;
  }
  const nameId = config.nameId;
  const awardIconName = `Award${nameId}${level}${locked ? 'Locked' : ''}`;
  const sizeProps = size ? { width: size, height: size } : undefined;
  return <Icon name={awardIconName} {...rest} {...sizeProps} />;
}
