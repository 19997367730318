import { useMemo } from 'react';

import usePlaylistSummary from '../../../PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistSummary';

import usePlaylistDetailRightPanelParams from './usePlaylistDetailRightPanelParams';

export default function usePlaylistTitle() {
  const { tag } = usePlaylistDetailRightPanelParams();
  const { data } = usePlaylistSummary();

  return useMemo(() => {
    if (tag) return `#${tag.tagCode}`;
    return data?.data.quiz.quizCustomName;
  }, [data, tag]);
}
