import { InvitationNewUserItem } from '@app/web/src/hooks/utils/useAhaInvitation';
import { SearchState } from '@app/web/src/utils/search';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import { getIndicators } from '@lib/web/utils';
import { TFunction } from 'i18next';

export default function getEditClubInvitationMembersDisplayDataset({
  t,
  state,
  initialResult,
  searchResult,
  newUser,
  showSkeleton,
}: {
  t: TFunction;
  state: SearchState | null;
  initialResult: GetNonJoinedFriendsRes[];
  searchResult: GetSearchClubMembersRes[];
  newUser: InvitationNewUserItem | null;
  showSkeleton: boolean;
}): IconListLayoutItemObj[] {
  if (state === SearchState.Searching) {
    if (newUser) {
      return [
        {
          id: newUser.email,
          title: newUser.email,
          actionMap: {
            click: {
              type: 'event',
              value: 'inviteToAha',
              text: t(
                `create.RHS.members.invite.aha.label.${newUser.actionText}`
              ),
              actionType: 'textButton',
              disabled:
                newUser.actionText === 'accepted' ||
                newUser.actionText === 'pending',
            },
          },
        },
      ];
    }

    if (showSkeleton) {
      return [];
    }
    return searchResult.map((result) => {
      return {
        id: result.userId,
        title: result.displayName,
        titleAction: { type: 'event' as const, value: 'titleClick' },
        description: result.isJoined ? 'Club Member' : `@${result.userName}`,
        avatarUrl: result.nftAvatar || result.avatar,
        indicators: getIndicators(result.indicator),
        actionMap: result.isJoined
          ? undefined
          : {
              select: {
                value: 'userSelected',
              },
              hover: {
                value: 'userHovered',
              },
            },
      };
    });
  }
  if (state === SearchState.Initial) {
    return initialResult.map((result) => {
      return {
        id: result.userId,
        title: result.displayName,
        titleAction: { type: 'event' as const, value: 'titleClick' },
        description: `@${result.userName}`,
        avatarUrl: result.nftAvatar || result.avatar,
        indicators: getIndicators(result.indicator),
        actionMap: {
          select: {
            value: 'userSelected',
          },
          hover: {
            value: 'userHovered',
          },
        },
      };
    });
  }

  return [];
}
