import React, { ReactNode } from 'react';
import { CardActionArea, CardContent, useTheme } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import Card from '@mui/material/Card';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SquareAvatar } from '@front/ui';

const styles = {
  card: {
    width: 142,
    height: 96,
  },
  cardActionArea: {
    width: 142,
    height: 96,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    p: 1,
  },
};

export type LayoutCardProps = {
  selected: boolean;
  icon: ReactNode;
  title: string;
  onClick?: BoxProps['onClick'];
};

export default function LayoutCard({
  selected,
  icon,
  title,
  onClick,
}: LayoutCardProps) {
  const theme = useTheme();

  return (
    <Card
      sx={[
        styles.card,
        {
          bgcolor: selected
            ? theme.palette.text.primary
            : alpha(theme.palette.text.primary, 0.05),
        },
      ]}
      onClick={onClick}
    >
      <CardActionArea sx={styles.cardActionArea}>
        <CardContent sx={[styles.cardContent]}>
          <SquareAvatar
            textColor={
              selected
                ? theme.palette.background.darker
                : theme.palette.text.primary
            }
            bgcolor={
              selected
                ? alpha(theme.palette.background.darker, 0.1)
                : alpha(theme.palette.text.primary, 0.05)
            }
          >
            {icon}
          </SquareAvatar>
          <Typography
            variant="subtitle2"
            color={
              selected
                ? theme.palette.background.darker
                : theme.palette.text.primary
            }
          >
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
