import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  getAwardCurrentValue,
  getAwardGoalValue,
  getAwardProgressRate,
  getAwardState,
} from '@app/web/src/utils/award';
import { useDateFormat } from '@front/helper';
import { ProfileAwards as ProfileAwardsIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  BaseLayoutScrollContainer,
  ProgressBar,
  useBaseRightPanel,
} from '@front/ui';
import { AwardBadgeLevelUpType, AwardBadgeProgressType } from '@lib/web/apis';

import AwardBadgeIcon from '../../../components/AwardBadgeIcon';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  main: {
    px: { xs: 2.5, md: '12px' },
    pt: 1,
    position: 'relative',
  },
  card: {
    display: 'grid',
    gap: 1,
  },
  iconName: {
    display: 'grid',
    gridTemplateColumns: '80px 1fr',
    gap: 2,
    alignItems: 'end',
  },
  title: {
    display: 'grid',
  },
  name: {
    fontSize: 20,
    fontWeight: 700,
  },
  subTitle: {
    display: 'flex',
    gap: 1,
    opacity: 0.75,
  },
  level: {
    fontSize: 16,
  },
  archivedDate: {
    fontSize: 16,
  },
  progressContainer: {
    display: 'grid',
    gap: 0.5,
  },
  currentValue: {
    display: 'block',
    textAlign: 'right',
    opacity: 0.5,
    fontSize: 12,
  },
  progressBar: {
    width: '100%',
  },
  requirement: {
    fontSize: 16,
  },
};

export default function AwardDetailsPanel() {
  const { t } = useTranslation('profile');
  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();
  const { awardItem } = getRightParams(GlobalPanelKeys.GlobalAwardDetail);
  const { dateFormat } = useDateFormat();

  const state = getAwardState(awardItem.levelUser.status);
  const currentLevel =
    awardItem.levelUpType === AwardBadgeLevelUpType.MultiLevel
      ? awardItem.levelUser?.level || 1 // MultiLevel type level starts with 1
      : 0;
  const rate = getAwardProgressRate(
    awardItem.goalType,
    awardItem.levelUser,
    awardItem.levelInfo
  );
  const currentValue = getAwardCurrentValue(
    awardItem.goalType,
    awardItem.levelUser
  );
  const goalValue = getAwardGoalValue(awardItem.goalType, awardItem.levelInfo);

  return (
    <BaseLayoutRightPanel
      titleIcon={<ProfileAwardsIcon width="16" height="16" />}
      title={t('Award')}
    >
      <Box sx={styles.wrapper}>
        <BaseLayoutScrollContainer sx={styles.main}>
          <Box sx={styles.card}>
            <Box sx={styles.iconName}>
              <AwardBadgeIcon
                level={currentLevel}
                locked={state !== 'Archived'}
                subType={awardItem.subType}
                size={80}
              />
              <Box sx={styles.title}>
                <Typography variant="appH3" sx={styles.name}>
                  {awardItem.name}
                </Typography>
                <Box sx={styles.subTitle}>
                  {awardItem.levelUpType ===
                    AwardBadgeLevelUpType.MultiLevel && (
                    <Typography variant="body1" sx={styles.level}>
                      Level {awardItem.levelUser.level}
                    </Typography>
                  )}
                  {state === 'Archived' && awardItem.levelUser.earnedAt && (
                    <Typography variant="body1" sx={styles.archivedDate}>
                      {dateFormat(awardItem.levelUser.earnedAt)}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            {awardItem.progressType === AwardBadgeProgressType.Progress && (
              <Box sx={styles.progressContainer}>
                <Typography variant="body1" sx={styles.currentValue}>
                  {currentValue}/{goalValue}
                </Typography>
                <ProgressBar ratio={rate} sx={styles.progressBar} />
              </Box>
            )}
            <Typography variant="body1" sx={styles.requirement}>
              {awardItem.levelInfo.requirement}
            </Typography>
          </Box>
        </BaseLayoutScrollContainer>
      </Box>
    </BaseLayoutRightPanel>
  );
}
