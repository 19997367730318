import { ReactNode } from 'react';
import { Box } from '@mui/material';
import { BaseLayoutRightPanel, BaseLayoutRightPanelProps } from '@front/ui';

import Chips from './Chips';
import ErrorResult from './ErrorResult';
import { ChipOption } from './types';

type ChipsPanelWrapProps<V> = {
  children: ReactNode;
  panelProps: BaseLayoutRightPanelProps;
  renderInput: ReactNode;
  options: ChipOption<V>[];
  value: V;
  unselectedValue: V;
  isLoading?: boolean;
  error?: string;
  clearTitle: string;
  clearTip: string;
  onRetry?: () => void;
  onChange?: (value: V) => void;
};
export default function ChipsPanelWrap<V>({
  children,
  panelProps,
  renderInput,
  options,
  value,
  unselectedValue,
  isLoading,
  error,
  clearTip,
  clearTitle,
  onChange,
  onRetry,
}: ChipsPanelWrapProps<V>) {
  return (
    <BaseLayoutRightPanel {...panelProps}>
      <BaseLayoutRightPanel.SearchWrapper>
        <Box minHeight={8} />
        <Chips
          unselectedValue={unselectedValue}
          clearTip={clearTip}
          clearTitle={clearTitle}
          options={options}
          value={value}
          onChange={onChange}
          isLoading={isLoading}
          error={error}
        />
        {!!error && (
          <ErrorResult
            isLoading={isLoading}
            error={error}
            onRetry={() => onRetry?.()}
          />
        )}
        {!!renderInput && !error && (
          <BaseLayoutRightPanel.SearchInput>
            {renderInput}
          </BaseLayoutRightPanel.SearchInput>
        )}

        <BaseLayoutRightPanel.SearchContent>
          {children}
        </BaseLayoutRightPanel.SearchContent>
      </BaseLayoutRightPanel.SearchWrapper>
    </BaseLayoutRightPanel>
  );
}
