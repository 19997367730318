import { useMemo } from 'react';
import { basicBlockGenerator, ComposerBlock } from '@lib/web/composer';
import { AgentMaterial } from '@lib/web/thread/types';
export const useAskForHelpAvailableActions = ({
  submitMessage,
  setSubmittingChipId,
}: {
  submitMessage: (props: {
    blocks: ComposerBlock[];
    agentMaterials?: AgentMaterial[];
  }) => Promise<void>;
  setSubmittingChipId: (id: string) => void;
}) => {
  return useMemo(() => {
    return {
      sendHelpRequest: {
        action: ({
          id,
          helpRequestText,
          additionalPrompt,
        }: {
          id: string;
          helpRequestText: string;
          additionalPrompt: string;
        }) => {
          submitMessage({
            blocks: [basicBlockGenerator.textBlock(helpRequestText)],
            agentMaterials: additionalPrompt
              ? [{ type: 'text' as const, value: additionalPrompt }]
              : [],
          });
          setSubmittingChipId(id);
        },
      },
    };
  }, [submitMessage, setSubmittingChipId]);
};
