import { useContext } from 'react';

import RouteHistoryContext from './RouteHistoryContext';

export default function useRouterHistory() {
  const [value, setValue] = useContext(RouteHistoryContext);

  const blockRouteChange = () =>
    setValue((st) => ({
      ...st,
      shouldInterrupt: true,
      showBackConfirm: false,
    }));

  const releaseRouteChange = () =>
    setValue((st) => ({
      ...st,
      shouldInterrupt: false,
      showBackConfirm: false,
    }));

  const confirmRouteChange = () =>
    setValue((st) => ({ ...st, shouldInterrupt: true, showBackConfirm: true }));

  const checkRouteChange = () =>
    setValue((st) => ({ ...st, showBackConfirm: true }));

  const cancelRouteChange = () =>
    setValue((st) => ({ ...st, showBackConfirm: false }));

  return {
    ...value,
    setRoutes: setValue,
    confirmRouteChange,
    blockRouteChange,
    releaseRouteChange,
    checkRouteChange,
    cancelRouteChange,
  };
}
