import {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Box, Chip, Skeleton, Theme, useMediaQuery } from '@mui/material';
import { OtherError as OtherErrorIcon } from '@front/icon';
import {
  Icon,
  InfoTooltip,
  LoadingIcon,
  Scrollbar,
  ScrollHorizontalContainer,
  SimpleTooltip,
} from '@front/ui';

import useIaFilterActions from '../../hooks/utils/useIaFilterActions';
import { useIaSortActions } from '../../hooks/utils/useIaSortActions';
import { FilterType } from '../../types/filter';
import { SortType } from '../../widgets/CommonPanels/SortPanel/types';

import useFilterChipsRouterInteractive from './hooks/useFilterChipsRouterInteractive';
import { FilterChipItem } from './types';

const styles = {
  root: {
    display: 'flex',
    gap: 1,
    '& .MuiChip-root .MuiChip-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  chipError: {
    bgcolor: 'error.dark',
    color: 'text.primary',
  },
  chipIconWrapper: {
    width: 16,
    height: 16,
  },
  skeleton: {
    borderRadius: '40px',
  },
};

function getIcon(
  icon?: FilterChipItem['icon'],
  loading?: boolean,
  error?: boolean
) {
  if (error) {
    return (
      <Box sx={styles.chipIconWrapper}>
        <OtherErrorIcon width={16} height={16} />
      </Box>
    );
  }
  if (loading) {
    return (
      <Box sx={styles.chipIconWrapper}>
        <LoadingIcon />
      </Box>
    );
  }
  if (typeof icon === 'string') {
    return <Icon name={icon} width={16} height={16} />;
  }
  return icon;
}

type ChipItemProps = {
  label?: ReactNode;
  icon?: FilterChipItem['icon'];
  tooltip?:
    | string
    | {
        icon: string;
        title: string;
        content: string;
      };
  selected?: boolean;
  disabled?: boolean;
  loading?: boolean;
  error?: boolean;
  className?: string;
  onClick?: () => void;
  order?: number;
};

function ChipItem({
  label,
  icon,
  tooltip,
  loading = false,
  selected = false,
  disabled = false,
  error = false,
  className,
  onClick,
  order = 1,
}: ChipItemProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const isLoading = selected && loading;
  const isError = selected && error;
  const chip = (
    <Chip
      label={label}
      variant={selected ? 'filled' : 'outlined'}
      clickable
      disabled={disabled}
      onClick={onClick}
      icon={getIcon(icon, isLoading, isError)}
      sx={[isError && styles.chipError, { order }]}
      className={`${className} ${isError ? 'filter-chip-item_error' : ''}`}
    />
  );

  if (!mdUp || !tooltip) return chip;

  if (typeof tooltip === 'string') {
    return <SimpleTooltip title={tooltip}>{chip}</SimpleTooltip>;
  }

  return (
    <InfoTooltip
      titleIcon={<Icon name={tooltip.icon} width={16} height={16} />}
      title={tooltip.title}
      content={tooltip.content}
    >
      {chip}
    </InfoTooltip>
  );
}

type QuickFilterChipsSkeletonsProps = {
  widths?: number[];
};

export function QuickFilterChipsSkeletons({
  widths = [70, 100, 100],
}: QuickFilterChipsSkeletonsProps) {
  return (
    <Scrollbar>
      <Box sx={styles.root}>
        {widths.map((width, index) => (
          <Skeleton
            key={index}
            variant="rounded"
            width={width}
            height={40}
            sx={styles.skeleton}
          />
        ))}
      </Box>
    </Scrollbar>
  );
}

type QuickFilterChipsProps = {
  scope?: string;
  filterType: FilterType;
  sortType?: SortType;
  items: (FilterChipItem | false)[] | null;
  allItem?: {
    title?: ReactNode;
    icon?: FilterChipItem['icon'];
    tooltip?:
      | string
      | {
          icon: string;
          title: string;
          content: string;
        };
    autoHide?: boolean;
  };
  state?: 'default' | 'loading' | 'error';
  routerInteractive?: boolean;
  onInitComplete?: () => void;
  isInitLoading?: boolean;
  skeletonWidths?: number[];
  hidden?: boolean;
  scrollRef?: RefObject<HTMLDivElement>;
} & (
  | {
      multiple: true;
      onChange?: (value?: FilterChipItem[]) => void;
    }
  | {
      multiple?: false | undefined;
      onChange?: (value?: FilterChipItem) => void;
    }
);

export default function QuickFilterChips({
  scope,
  filterType,
  sortType,
  items,
  allItem,
  state = 'default',
  routerInteractive,
  onInitComplete,
  isInitLoading = false,
  skeletonWidths,
  hidden = false,
  scrollRef,
  ...rest
}: QuickFilterChipsProps) {
  const { multiple = false } = rest;
  const loaded = useRef(false);
  const chipItems = (items?.filter((item) => !!item) as FilterChipItem[]) || [];

  const { removeConditionByField, replaceCondition, isConditionExist } =
    useIaFilterActions({ scope });

  const { removeCriteriaByField, replaceCriteria, isCriteriaExist } =
    useIaSortActions({ scope });

  const activeFilters = chipItems.filter((item) => {
    return item.conditions.every((condition) =>
      isConditionExist(
        condition.fieldName,
        condition.operator,
        condition.values
      )
    );
  });

  const activeSortItems = chipItems.filter((item) => {
    return item.sort?.every((criteria) =>
      isCriteriaExist(criteria.fieldName, criteria.direction)
    );
  });

  const handleChange = useCallback(() => {
    if (activeFilters.length) {
      if (rest.multiple) {
        rest.onChange?.(activeFilters);
        return;
      }
      rest.onChange?.(activeFilters[0]);
      return;
    }
    rest.onChange?.();
  }, [rest, activeFilters]);

  const activeFilterIds = activeFilters.map((item) => item.id).join(',');
  useEffect(() => {
    handleChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilterIds]);

  const clearAll = useCallback(() => {
    // remove all activated filter conditions
    activeFilters.forEach((item) => {
      item.conditions.forEach((condition) => {
        removeConditionByField(condition.fieldName);
      });
    });
    // remove all activated sort criteria
    activeSortItems.forEach((item) => {
      item.sort?.forEach((criteria) => {
        removeCriteriaByField(criteria.fieldName);
      });
    });
  }, [
    activeFilters,
    activeSortItems,
    removeConditionByField,
    removeCriteriaByField,
  ]);

  const setActiveFilter = useCallback(
    (item: FilterChipItem) => {
      if (multiple) {
        const activeFilter = activeFilters.find(
          (filter) => filter.id === item.id
        );
        if (activeFilter) {
          activeFilter.conditions.forEach((condition) => {
            removeConditionByField(condition.fieldName);
          });
          return;
        }
      } else {
        clearAll();
      }
      item.conditions.forEach((condition) => {
        replaceCondition(
          filterType,
          condition.fieldName,
          condition.operator,
          condition.values
        );
      });
      if (sortType) {
        item.sort?.forEach((criteria) => {
          replaceCriteria(sortType, criteria.fieldName, criteria.direction);
        });
      }
    },
    [
      activeFilters,
      clearAll,
      filterType,
      multiple,
      removeConditionByField,
      replaceCondition,
      replaceCriteria,
      sortType,
    ]
  );

  const actions = useMemo(() => {
    return {
      resetFilter: () => {
        clearAll();
      },
      toggleFilter: (item: FilterChipItem) => {
        setActiveFilter(item);
      },
      completeInit: () => {
        onInitComplete?.();
      },
    };
  }, [clearAll, setActiveFilter, onInitComplete]);

  const handleAllClick = () => {
    actions.resetFilter();
  };

  const handleChipClick = (item: FilterChipItem) => {
    actions.toggleFilter(item);
  };

  const { isRouterSynced } = useFilterChipsRouterInteractive({
    enabled: routerInteractive,
    actions,
    activeFilters,
    chipItems,
  });

  const isRouterSyncing = routerInteractive && !isRouterSynced;
  const isActive = useCallback(
    (chipItem?: FilterChipItem) => {
      if (isRouterSyncing) {
        return false;
      }
      if (!chipItem) return activeFilters.length === 0;
      return activeFilters.some((item) => item.id === chipItem.id);
    },
    [activeFilters, isRouterSyncing]
  );

  useEffect(() => {
    if (!isInitLoading) {
      loaded.current = true;
    }
  }, [isInitLoading]);

  if (hidden) return null;
  if (isInitLoading && !loaded.current) {
    return <QuickFilterChipsSkeletons widths={skeletonWidths} />;
  }

  const allItemHidden = allItem?.autoHide && isActive();
  return (
    <ScrollHorizontalContainer scrollableNodeProps={{ ref: scrollRef }}>
      <Box sx={styles.root} className="filter-chip">
        {!allItemHidden && (
          <ChipItem
            label={allItem?.title || 'All'}
            icon={allItem?.icon}
            tooltip={allItem?.tooltip}
            disabled={isRouterSyncing}
            selected={isActive()}
            loading={state === 'loading'}
            error={state === 'error'}
            onClick={handleAllClick}
            className="filter-chip-item_all"
            order={0}
          />
        )}

        {chipItems.map((item, index) => (
          <ChipItem
            key={index}
            label={item.label}
            icon={item.icon}
            tooltip={item.tooltip}
            disabled={item.disabled || isRouterSyncing}
            selected={isActive(item)}
            loading={state === 'loading'}
            error={state === 'error'}
            onClick={() => handleChipClick(item)}
            className={item.className}
            order={item.orderFirst ? -1 : 1}
          />
        ))}
      </Box>
    </ScrollHorizontalContainer>
  );
}

QuickFilterChips.Skeletons = QuickFilterChipsSkeletons;
