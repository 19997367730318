import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { GlobalPanelKeys } from '@app/web/src/types/panel';
import {
  MainChallenge as MainChallengeIcon,
  PrivacyFriends as PrivacyFriendsIcon,
} from '@front/icon';
import {
  DropdownButton,
  DropdownButtonOption,
  InfoTooltip,
  TextButton,
  useBaseLayout,
} from '@front/ui';
import { ChallengeStatus, QuizType } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';

import usePlaylistInfo from '../../hooks/usePlaylistInfo';
import usePlaylistSummary from '../../hooks/usePlaylistSummary';

import RedoCreateQuizPanels from './RedoCreateQuizPanels';

type Props = {
  panelKeyPrefix?: string;
};

function ChallengeDropdownButton() {
  const { t } = useTranslation('playlist');
  const { data } = usePlaylistSummary();
  const clubSlug = useClubSlug();

  const { toggleRightPanel } = useBaseLayout();

  const quiz = data?.data.quiz;
  const challenge = data?.data.challenge;

  const dropdownOptions = useMemo(() => {
    if (!data || !data.data.challenge) return [];
    const { status, challengeId } = data.data.challenge;
    const { shortId, id } = data.data.quiz;
    const quizId = shortId || id;

    const options: DropdownButtonOption[] = [];
    if (status === ChallengeStatus.Invited) {
      options.push({
        icon: 'MainChallenge',
        display: t(
          'detail.challenge.addFriends.notAccepted.label',
          'Challenge Friends'
        ),
        disabled: true,
        tooltip: {
          icon: 'MainChallenge',
          title: t(
            'detail.challenge.addFriends.notAccepted.tooltip.title',
            'Challenge Friends'
          ),
          content: t(
            'detail.challenge.addFriends.notAccepted.tooltip.content',
            'You must accept the challenge to add friends to this challenge.'
          ),
        },
      });
    }
    if (status === ChallengeStatus.Ongoing) {
      options.push({
        icon: 'MainChallenge',
        display: t('detail.challenge.addFriends.label', 'Challenge Friends'),
        tooltip: {
          icon: 'MainChallenge',
          title: t(
            'detail.challenge.addFriends.tooltip.title',
            'Challenge Friends'
          ),
          content: t(
            'detail.challenge.addFriends.tooltip.content',
            'Add friends to this challenge.'
          ),
        },
        onClick: () => {
          toggleRightPanel(GlobalPanelKeys.GlobalChallengeRank, {
            challengeId,
            quizId: id,
          });
        },
      });
    }
    options.push({
      icon: 'OtherFriendsChallenge',
      display: t('detail.challenge.seeChallengers.label', 'See Challengers'),
      tooltip: {
        icon: 'OtherFriendsChallenge',
        title: t(
          'detail.challenge.seeChallengers.tooltip.title',
          'See Challengers'
        ),
        content: t(
          'detail.challenge.seeChallengers.tooltip.content',
          'See statistic and list of the challengers.'
        ),
      },
      onClick: () => {
        Router.push(
          `/club/${clubSlug}/challenge/${quizId}/challengers?status=${status}`
        );
      },
    });
    return options;
  }, [t, clubSlug, data, toggleRightPanel]);

  if (!quiz || !challenge) return null;

  return (
    <DropdownButton
      options={dropdownOptions}
      tooltip={{
        icon: 'PrivacyFriends',
        title: t('detail.challenge.tooltip.title', 'Challengers'),
        content: t(
          'detail.challenge.tooltip.content',
          'View the challengers who participated in this challenge.'
        ),
      }}
      dropdownProps={{ sx: { width: 170 } }}
    >
      <TextButton prefixIcon={<MainChallengeIcon width={16} height={16} />}>
        {t('detail.challenge.label', 'Challenge')}
      </TextButton>
    </DropdownButton>
  );
}

function CreateChallengeButton({ panelKeyPrefix }: Props) {
  const { t } = useTranslation('playlist');
  const { data } = usePlaylistSummary();
  const { openRightPanel } = useBaseLayout();

  if (!data?.data) return null;

  const handleClick = () => {
    openRightPanel(
      `${panelKeyPrefix}${RedoCreateQuizPanels.PanelKeys.CreateChallenge}`,
      { withSubmitButton: true }
    );
  };

  return (
    <InfoTooltip
      titleIcon={<PrivacyFriendsIcon width={16} height={16} />}
      title={t('detail.createChallenge.tooltip.title', 'Challenger')}
      content={t(
        'detail.createChallenge.tooltip.content',
        'Add other users to create a challenge that includes all questions in this playlist.'
      )}
    >
      <TextButton
        prefixIcon={<MainChallengeIcon width={16} height={16} />}
        onClick={handleClick}
      >
        {t('detail.challenge.label', 'Challenge')}
      </TextButton>
    </InfoTooltip>
  );
}

export default function ChallengeButton({
  panelKeyPrefix = RedoCreateQuizPanels.DEFAULT_PANEL_KEY,
}: Props) {
  const { data } = usePlaylistSummary();
  const { dynamicType } = usePlaylistInfo();
  const quiz = data?.data.quiz;
  const challenge = data?.data.challenge;

  // CreatorUserQuiz and dynamic playlist are not supported for challenges
  if (!quiz || quiz.type === QuizType.CreatorUserQuiz || dynamicType)
    return null;
  if (challenge) return <ChallengeDropdownButton />;
  const isTaken = Boolean(quiz.latestRoundNo && quiz.lastTakenAt);
  if (isTaken && quiz.isCompleted)
    return <CreateChallengeButton panelKeyPrefix={panelKeyPrefix} />;
  return null;
}
