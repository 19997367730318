import React from 'react';
import useMeasure from 'react-use-measure';
import Box, { BoxProps } from '@mui/material/Box';
import { ResizeObserver } from '@juggle/resize-observer';

import NFTAvatar from '../NFTAvatar';

type AvatarsProps = BoxProps & {
  displayRow?: number;
  avatarSize?: number;
  avatarOffset?: number;
  avatarBorderWidth?: number;
  avatarBorderColor?: string;
  gap?: number;
  userIds: string[];
};

type AvatarItemProps = {
  userId: string;
  size?: number;
  sx?: BoxProps['sx'];
  borderColor: string;
};

const styles = {
  root: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  inner: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  remain: {
    position: 'absolute',
    bottom: 0,
  },
  remainInner: {
    fontWeight: 500,
    backgroundColor: 'text.primary',
    color: 'background.default',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    typography: 'caption',
    mask: 'url(/mask-avatar.svg) center center/contain no-repeat',
  },
  hide: { opacity: 0, visibility: 'hidden' },
  border: {
    mask: `url(/mask-avatar.svg) no-repeat center center`,
    maskSize: 'contain',
    backgroundColor: 'white',
    mr: '-6px',
    p: '2px',
  },
};

function AvatarItem({ userId, size, borderColor, sx }: AvatarItemProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <Box sx={[styles.border, { backgroundColor: borderColor }, ...sxProps]}>
      <NFTAvatar size={size} sx={sx} userId={userId} />
    </Box>
  );
}
export default function Avatars({
  sx,
  displayRow = 1,
  avatarSize = 32,
  avatarOffset = 6,
  avatarBorderWidth = 2,
  avatarBorderColor = 'background.default',
  gap = 20,
  userIds,
  ...rest
}: AvatarsProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const avatarBorderSize = avatarBorderWidth * 2;
  const containerHeight = avatarSize * displayRow + gap * (displayRow - 1) + 4;
  const [ref, { width, height }] = useMeasure({ polyfill: ResizeObserver });

  const rowCount = Math.floor(
    (width - avatarOffset) / (avatarSize + avatarBorderSize - avatarOffset)
  );

  const currentCount =
    height > containerHeight ? rowCount * displayRow : userIds.length;

  const remainNumber = userIds.length - currentCount;

  return (
    <Box sx={[styles.root, { height: containerHeight }, ...sxProps]} {...rest}>
      <div ref={ref}>
        <Box sx={styles.inner}>
          {userIds.map((id, i) => (
            <AvatarItem
              key={id}
              userId={id}
              sx={[i === rowCount && remainNumber > 0 && styles.hide]}
              size={avatarSize}
              borderColor={avatarBorderColor}
            />
          ))}
          {remainNumber > 0 && (
            <Box
              sx={[
                styles.border,
                styles.remain,
                {
                  backgroundColor: avatarBorderColor,
                  left:
                    (rowCount - 1) *
                    (avatarSize + avatarBorderSize - avatarOffset),
                },
              ]}
            >
              <Box
                sx={[
                  styles.remainInner,
                  {
                    width: avatarSize,
                    height: avatarSize,
                  },
                ]}
              >{`+${remainNumber + 1}`}</Box>
            </Box>
          )}
        </Box>
      </div>
    </Box>
  );
}
