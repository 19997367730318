import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';

import {
  SetGoalFormFields,
  setGoalFormSettings,
  SetGoalFormValuesTypes,
} from './config';

export default function useSetGoalLayoutConfig(
  club: GetIaClubProfileCenterDefaultViewRes,
  defaultValues: SetGoalFormValuesTypes
): IaLayoutConfig[] {
  const { t } = useTranslation('analytics');

  return useMemo(() => {
    return [
      {
        layout: 'form-layout',
        settings: {
          mode: 'onChange',
          triggerSubmitMode: 'onChange' as const,
          touchedFields: 'all',
        },
        defaultValues,
        formAction: {
          type: 'event' as const,
          value: 'submitSettings',
        },
        mainItems: [
          {
            title: t('goal.RHS.form.target.title', 'Target Goal'),
            type: 'group',
            items: [
              {
                type: 'date' as const,
                label: t('goal.RHS.form.target.date.label'),
                icon: 'OtherCalendarDay',
                errorIcon: 'OtherInfoSolid',
                name: SetGoalFormFields.TargetDate,
                placeholder: 'MM/DD/YYYY',
                textFieldSize: 'rwd',
                minDate: new Date(),
                dateFormat: 'MM/dd/yyyy',
                customRules: {
                  minDate: {
                    value: new Date(),
                    message: t('goal.RHS.form.target.date.min'),
                  },
                },
              },
              {
                type: 'text' as const,
                inputType: 'number',
                label: t(
                  'goal.RHS.form.target.score.label',
                  'Target {{club}} Score',
                  { club: club.clubName }
                ),
                icon: 'TestScoreTally',
                name: SetGoalFormFields.TargetScore,
                placeholder: `${club.fullScore}`,
                textFieldSize: 'rwd',
                helperText: t('goal.RHS.form.target.score.tip', {
                  min: club.bottomScore,
                  max: club.fullScore,
                }),
                rules: {
                  min: {
                    value: club.bottomScore,
                    message: t('goal.RHS.form.target.score.range', {
                      min: club.bottomScore,
                      max: club.fullScore,
                    }),
                  },
                  max: {
                    value: club.fullScore,
                    message: t('goal.RHS.form.target.score.range', {
                      min: club.bottomScore,
                      max: club.fullScore,
                    }),
                  },
                },
                actionMap: {
                  change: {
                    value: `${SetGoalFormFields.TargetScore}Changed`,
                  },
                },
              },
              {
                type: 'switch' as const,
                icon: 'TestScoreTally',
                label: t(
                  'goal.RHS.form.target.switch.label',
                  'Set the target score to maximum'
                ),
                labelTooltip: t(
                  'goal.RHS.form.target.switch.tip',
                  'Set the target score to maximum'
                ),
                name: SetGoalFormFields.SetMaxScore,
                labelPosition: 'start',
                actionMap: {
                  change: {
                    value: `${SetGoalFormFields.SetMaxScore}Changed`,
                  },
                },
              },
            ],
          },
          {
            title: t('goal.RHS.form.daily.title', 'Daily Goals'),
            type: 'group',
            content: t(
              'goal.RHS.form.daily.content',
              'Set your daily practice time and question number that you want to achieve everyday (Everyday start at 00:00 +UTC)'
            ),
            items: [
              {
                type: 'text' as const,
                inputType: 'number',
                label: t(
                  'goal.RHS.fom.daily.questions.label',
                  'Questions Answered'
                ),
                icon: { type: 'color', value: 'primary.dark' },
                name: SetGoalFormFields.DailyQuestions,
                placeholder: `${setGoalFormSettings.questions.default}`,
                textFieldSize: 'rwd',
                suffix: {
                  type: 'text' as const,
                  value: t('goal.RHS.fom.daily.questions.suffix', 'questions'),
                },
                rules: {
                  min: {
                    value: setGoalFormSettings.questions.min,
                    message: t('goal.RHS.fom.daily.questions.min', {
                      value: setGoalFormSettings.questions.min,
                    }),
                  },
                  max: {
                    value: setGoalFormSettings.questions.max,
                    message: t('goal.RHS.fom.daily.questions.max', {
                      value: setGoalFormSettings.questions.max,
                    }),
                  },
                },
              },
              {
                type: 'text' as const,
                inputType: 'number',
                label: t('goal.RHS.fom.daily.times.label', 'Time Practiced'),
                icon: { type: 'color', value: 'primary.light' },
                name: SetGoalFormFields.DailyTimes,
                placeholder: `${setGoalFormSettings.times.default}`,
                textFieldSize: 'rwd',
                suffix: {
                  type: 'text' as const,
                  value: t('goal.RHS.fom.daily.times.suffix', 'minutes'),
                },
                rules: {
                  min: {
                    value: setGoalFormSettings.times.min,
                    message: t('goal.RHS.fom.daily.times.min', {
                      value: setGoalFormSettings.times.min,
                    }),
                  },
                  max: {
                    value: setGoalFormSettings.times.max,
                    message: t('goal.RHS.fom.daily.times.max', {
                      value: setGoalFormSettings.times.max,
                    }),
                  },
                },
              },
              {
                type: 'text' as const,
                inputType: 'number',
                label: t('goal.RHS.fom.daily.mock.label', 'Mock Completed'),
                icon: { type: 'color', value: 'text.primary' },
                name: SetGoalFormFields.DailyMocks,
                placeholder: `${setGoalFormSettings.mock.default}`,
                textFieldSize: 'rwd',
                suffix: {
                  type: 'text' as const,
                  value: t('goal.RHS.fom.daily.mock.suffix', 'mock'),
                },
                rules: {
                  min: {
                    value: setGoalFormSettings.mock.min,
                    message: t('goal.RHS.fom.daily.mock.min', {
                      value: setGoalFormSettings.mock.min,
                    }),
                  },
                  max: {
                    value: setGoalFormSettings.mock.max,
                    message: t('goal.RHS.fom.daily.mock.max', {
                      value: setGoalFormSettings.mock.max,
                    }),
                  },
                },
              },
            ],
          },
        ],
      },
    ];
  }, [club.bottomScore, club.clubName, club.fullScore, defaultValues, t]);
}
