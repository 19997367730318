import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { OtherAdvanceFilter as OtherAdvanceFilterIcon } from '@front/icon';
import { BaseLayoutRightPanel, useBaseRightPanel } from '@front/ui';
import { useChannel } from '@lib/web/thread/hooks/channel/useChannel';
import { ThreadChannelProperties } from '@lib/web/thread/ThreadProperties';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
};

export default function ThreadPropertiesPanel() {
  const { t } = useTranslation('thread');
  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();
  const { channelCid } = getRightParams(GlobalPanelKeys.GlobalThreadProperties);
  const { channel } = useChannel({ channelCid });

  return (
    <BaseLayoutRightPanel
      titleIcon={<OtherAdvanceFilterIcon width="16" height="16" />}
      title={t('rhs.properties.title')}
      resizable
    >
      <Box sx={styles.main}>
        {channel && <ThreadChannelProperties channel={channel} mode="full" />}
      </Box>
    </BaseLayoutRightPanel>
  );
}
