import { useCallback } from 'react';
import { useAppSelector } from '@app/web/src/hooks/redux';
import {
  selectCurrentQuestionLeftQuestionContent,
  selectCurrentQuestionRightQuestionContent,
} from '@app/web/src/reducers/quizReducer/selector';
import { selectCurrentQuestionAnswerOptionContentList } from '@app/web/src/reducers/quizReducer/selector';
import { AgentMaterial } from '@lib/web/thread/types';
import { extractTextFromHtml } from '@lib/web/utils';

export const useAskForHelpAgentMaterials = () => {
  const leftQuestionContent = useAppSelector((st) =>
    selectCurrentQuestionLeftQuestionContent(st.quiz)
  );
  const rightQuestionContent = useAppSelector((st) =>
    selectCurrentQuestionRightQuestionContent(st.quiz)
  );
  const answerOptionContentList = useAppSelector((st) =>
    selectCurrentQuestionAnswerOptionContentList(st.quiz)
  );

  const getAskForHelpAgentMaterials = useCallback(() => {
    const passage = extractTextFromHtml(leftQuestionContent);
    const question = extractTextFromHtml(rightQuestionContent);
    const answerOptions = answerOptionContentList.map(extractTextFromHtml);

    const materials: AgentMaterial[] = [
      {
        type: 'text',
        value: JSON.stringify({
          passage,
          question,
          answerOptions,
        }),
      },
    ];

    return materials;
  }, [leftQuestionContent, rightQuestionContent, answerOptionContentList]);

  return {
    getAskForHelpAgentMaterials,
  };
};
