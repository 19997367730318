import React, { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Scrollbar, ScrollbarProps } from '@front/ui';

type PanelContentProps = {
  sx?: BoxProps['sx'];
  children?: BoxProps['children'];
  topComponent?: ReactNode;
  actionComponent?: ReactNode;
  scrollableNodeProps?: ScrollbarProps['scrollableNodeProps'];
};

const styles = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  main: { position: 'relative', flex: 1 },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  content: {
    px: { xs: 2.5, md: '12px' },
    pb: 2.5,
  },
  top: {
    px: { xs: 2.5, md: '12px' },
  },
  action: {
    px: { xs: 2.5, md: '12px' },
    py: '12px',
  },
};
export default function PanelContent({
  sx,
  children,
  topComponent,
  actionComponent,
  scrollableNodeProps,
}: PanelContentProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const content = (
    <Scrollbar sx={styles.scroll} scrollableNodeProps={scrollableNodeProps}>
      <Box sx={[styles.content, ...sxProps]}>{children}</Box>
    </Scrollbar>
  );
  if (!actionComponent && !topComponent) {
    return content;
  }

  return (
    <Box sx={styles.root}>
      {!!topComponent && <Box sx={styles.top}>{topComponent}</Box>}
      <Box sx={styles.main}>{content}</Box>
      {!!actionComponent && <Box sx={styles.action}>{actionComponent}</Box>}
    </Box>
  );
}
