import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { getClubIconPath } from '@lib/web/utils';

export default function useClubGroupConfig({
  displayDataset,
  totalCount,
}: {
  displayDataset: GetIaClubGroupCardCenterClubGroupDefaultRes[];
  totalCount?: number;
}): IaLayoutConfig[] {
  const { t } = useTranslation('club');

  return useMemo(() => {
    if (totalCount === undefined) {
      return [
        {
          layout: 'icon-list-layout-skeleton',
        },
      ];
    }
    if (displayDataset.length === 0) {
      return [
        {
          layout: 'icon-list-layout',
          items: [t('No results')],
        },
      ];
    }
    return [
      {
        layout: 'icon-list-layout',
        items: [
          t('addClubGroup.RHS.count', { count: displayDataset.length }),
          ...displayDataset.map((clubGroup) => ({
            id: clubGroup.id,
            title: clubGroup.name,
            description: t('addClubGroup.RHS.club', {
              count: clubGroup.clubCount,
            }),
            avatarUrl: clubGroup.logo,
            subAvatars: clubGroup.clubs?.map((club) => ({
              avatarUrl: club.clubLogo || getClubIconPath(club.clubName),
              name: club.clubName,
            })),
            actionMap: {
              select: {
                value: 'selectChanged',
              },
              hover: {
                value: 'groupHovered',
              },
            },
            metadata: clubGroup,
          })),
        ],
      },
    ];
  }, [displayDataset, t, totalCount]);
}
