import { useMemo } from 'react';
import Router from 'next/router';
import { useTranslation } from 'next-i18next';
import useMutualClubs from '@app/web/src/hooks/utils/useMutualClubs';
import { getClubIconPath } from '@app/web/src/utils/clubs';
import { BaseLayoutRightPanel, SearchBar, SquareAvatar } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import {
  IconListLayoutItem,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';
import { ClubVisibility } from '@lib/web/apis';
import { useSearchStatus } from '@lib/web/hooks';

type ClubsProps = {
  userId: string;
  targetUserId: string;
};

export default function MutualClubs({ userId, targetUserId }: ClubsProps) {
  const { t } = useTranslation('profile');
  const {
    data: mutualClubs,
    unlistedCount,
    isLoading,
  } = useMutualClubs({
    userId,
    targetUserId,
  });
  const { focused, search, setSearch, onChange, onBlur, onFocus } =
    useSearchStatus();

  const displayDataset: IconListLayoutItemObj[] = useMemo(() => {
    return (
      search
        ? mutualClubs.filter((d) =>
            d.name.toLowerCase().includes(search.toLowerCase().trim())
          )
        : mutualClubs
    ).map((item) => ({
      id: item.slug,
      title: item.name,
      description: item.apps[0]?.extra.latestRoundScore || '',
      avatarUrl: item.iconPath || getClubIconPath(item.name),
      statusTags:
        item.visibility === ClubVisibility.Unlisted
          ? [
              {
                name: 'Hidden',
                color: 'default',
                infoTooltip: {
                  title: t('club::club.hidden.tooltip.title', 'Hidden Club'),
                  icon: 'LoginHide',
                  content: t(
                    'club::club.hidden.tooltip.content',
                    'This club is only visible to you and your invited friends.'
                  ),
                },
              },
            ]
          : undefined,
      action: {
        type: 'link',
        value: `/club/${item.slug}`,
      },
      actionMap: {
        hover: {
          value: 'clubHovered',
        },
      },
    }));
  }, [mutualClubs, search, t]);

  const config: IaLayoutConfig[] = useMemo(() => {
    if (isLoading)
      return [
        {
          layout: 'icon-list-layout-skeleton',
        },
      ];

    return [
      {
        layout: 'icon-list-layout',
        items: (
          [
            unlistedCount
              ? t('RHS.mutualClubs.resultUnlisted', {
                  count: mutualClubs.length,
                  unlistedCount,
                })
              : t('RHS.mutualClubs.result', {
                  count: mutualClubs.length,
                }),
          ] as IconListLayoutItem[]
        ).concat(displayDataset),
      },
    ];
  }, [isLoading, unlistedCount, t, mutualClubs.length, displayDataset]);

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && suggestItem) {
      event.preventDefault();

      Router.push(`/club/${suggestItem.id}`);
      setSearch('');
    }
  };
  const availableActions = {
    clubHovered: {
      action: updateSuggestItem,
    },
  };

  const getItemStatus = (ev: IconListLayoutItemObj) => {
    return {
      focused: ev.id === suggestItem?.id,
    };
  };

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <BaseLayoutRightPanel.SearchWrapper>
          <BaseLayoutRightPanel.SearchInput>
            <SearchBar
              placeholder={t(
                'RHS.mutualClubs.search.placeholder',
                'Search mutual clubs...'
              )}
              value={search}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyDown={handleKeyDown}
              suggestText={suggestItem?.title}
              prefixIcon={
                suggestItem && (
                  <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                    {suggestItem.title}
                  </SquareAvatar>
                )
              }
            />
          </BaseLayoutRightPanel.SearchInput>
          <BaseLayoutRightPanel.SearchContent>
            <BaseLayoutRightPanel.ScrollArea>
              <IaLayouts layouts={config} />
            </BaseLayoutRightPanel.ScrollArea>
          </BaseLayoutRightPanel.SearchContent>
        </BaseLayoutRightPanel.SearchWrapper>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
