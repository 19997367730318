import { useContext } from 'react';

import { PlaylistDetailContext } from '../context';

export default function usePlaylistInfo() {
  const {
    quizId,
    markId,
    tagId,
    roundNo,
    type,
    customDate,
    dynamicType,
    sectionId,
    isCommon,
  } = useContext(PlaylistDetailContext);
  return {
    quizId,
    markId,
    tagId,
    type,
    customDate,
    roundNo: roundNo ?? null,
    loading: roundNo === null,
    dynamicType,
    sectionId,
    isCommon,
  };
}
