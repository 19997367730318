import { useEffect } from 'react';
import { PopperProps, Theme, useMediaQuery } from '@mui/material';

import FilterPanel from './components/FilterPanel';
import FilterPopup from './components/FilterPopup';
import FilterProvider from './context/FilterProvider';
import useFilterActions from './hooks/useFilterActions';

type FilterResponsivePanelProps = {
  anchorEl: PopperProps['anchorEl'];
  open: boolean;
  onClose: () => void;
};
function FilterResponsivePanel({
  anchorEl,
  open,
  onClose,
}: FilterResponsivePanelProps) {
  const { goPage, clear } = useFilterActions();
  useEffect(() => {
    if (open) {
      goPage({ page: 'querySummary' });
    } else {
      clear();
    }
  }, [open, goPage, clear]);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  if (!open) return null;
  if (mdUp)
    return (
      <FilterPopup
        open={open && !!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
      />
    );

  return <FilterPanel onClose={onClose} />;
}

export default function FilterResponsivePanelRoot({
  scope,
  ...rest
}: FilterResponsivePanelProps & {
  scope: string;
}) {
  return (
    <FilterProvider scope={scope}>
      <FilterResponsivePanel {...rest} />
    </FilterProvider>
  );
}
