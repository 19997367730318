import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { ChannelLayoutConfig } from '@lib/ia/src/layouts/ChannelLayout/types';
import { useAuth } from '@lib/web/apis';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { v4 } from 'uuid';

const HELP_REQUEST_CHIPS = [
  {
    id: 'hint',
    messageKey: 'message.Can I have a hint?',
    additionalPrompt:
      'Do not give me the answer, but a hint that will help guide me toward the answer without giving it away completely.',
  },
  {
    id: 'answer',
    messageKey: 'message.What is the answer to this question?',
    additionalPrompt: '',
  },
  {
    id: 'not_make_sense',
    messageKey: "message.This problem doesn't make sense.",
    additionalPrompt:
      'Please help me understand this problem better. What specific parts are unclear or confusing? Could you break it down and explain it in simpler terms?',
  },
  {
    id: 'help',
    messageKey: 'message.Can you help me with this question?',
    additionalPrompt:
      'I need assistance understanding and solving this question. Please help me work through it step by step, explaining the concepts involved and guiding me toward the solution.',
  },
  {
    id: 'explain',
    messageKey: 'message.Please explain this question to me.',
    additionalPrompt:
      "Could you provide a detailed explanation of this question, breaking down its key components, requirements, and what it's asking me to do? Please help me understand the core concepts involved and what approach I should take to solve it.",
  },
];

export const useAskForHelpMessagesConfig = ({
  memberIds,
  isSubmitting,
  submittingChipId,
}: {
  memberIds: string[];
  isSubmitting: boolean;
  submittingChipId: string | null;
}) => {
  const { t } = useTranslation('agent');
  const { getThreadUser } = useThread();
  const { isBlocking } = useAuth();

  return useMemo(() => {
    const firstAiMemberId = memberIds.find((id) => id.startsWith('agent_'));
    if (!firstAiMemberId) return [];

    const ai = getThreadUser(firstAiMemberId);
    if (!ai) return [];

    const id = v4();

    return [
      {
        layout: 'channel-layout',
        items: [
          {
            type: 'message',
            id,
            key: id,
            sender: {
              type: 'avatar',
              name: ai.name || '',
              avatarUrl: ai.image || '',
              indicators: ai.indicators,
            },
            content: t('message.How may I help you'),
            sentTime: new Date().toISOString(),
            chips: HELP_REQUEST_CHIPS.map((chip) => {
              return {
                label: {
                  type: 'text',
                  value: t(chip.messageKey),
                },
                eventMap: {
                  click: {
                    type: 'event',
                    value: 'sendHelpRequest',
                  },
                },
                metadata: {
                  id: chip.id,
                  helpRequestText: t(chip.messageKey),
                  additionalPrompt: chip.additionalPrompt,
                },
                isLoading: submittingChipId === chip.id,
                disabled: isSubmitting || isBlocking,
              };
            }),
          },
        ],
      } as ChannelLayoutConfig,
    ];
  }, [memberIds, getThreadUser, t, submittingChipId, isSubmitting, isBlocking]);
};
