import { useMemo } from 'react';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import EmojiPickerPanel from '@app/web/src/widgets/CommonPanels/EmojiPickerPanel';
import { useBaseLayout } from '@front/ui';

export default function ThreadEmojiRightPanel() {
  const { getRightParams, setRightParams } = useBaseLayout<GlobalPanelParams>();

  const emojiRightPanelParams = getRightParams(
    GlobalPanelKeys.GlobalThreadEmoji
  );
  const { emojiId, emojiCode, emojiName, onChange } = emojiRightPanelParams;

  const emojiValue = useMemo<GetQuizMarkEmojiRes | null>(() => {
    if (emojiId) {
      return {
        id: emojiId,
        code: emojiCode,
        name: emojiName,
      };
    }

    return null;
  }, [emojiCode, emojiId, emojiName]);

  const handleChange = async (ev: GetMarkEmojiRes | null): Promise<void> => {
    const emoji = ev ? ev : { id: '', code: '', name: '' };
    setRightParams<GlobalPanelKeys.GlobalThreadEmoji>((st) => ({
      ...st,
      emojiId: emoji.id,
      emojiCode: emoji.code,
      emojiName: emoji.name,
    }));
    onChange({ changedEmoji: emoji });
  };

  return (
    <EmojiPickerPanel
      title="Change Emoji"
      value={emojiValue}
      onChange={handleChange}
      enableRemove={false}
    />
  );
}
