import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchState } from '@app/web/src/utils/search';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';

export default function useClubMembersTabLayoutConfig({
  listState,
  displayDataset,
  searchLoading,
  totalCount,
}: {
  listState: SearchState | null;
  displayDataset: IconListLayoutItemObj<UserProfileObj>[];
  searchLoading?: boolean;
  totalCount?: number;
}) {
  const { t } = useTranslation('club');
  return useMemo<IaLayoutConfig[]>(() => {
    if (searchLoading) return [];
    if (totalCount === 0) return [];
    if (listState !== SearchState.Searching) {
      return [
        {
          layout: 'icon-list-layout',
          items: [
            t('## Member', { count: totalCount ?? 0 }),
            ...displayDataset,
          ],
        },
      ];
    }
    return [
      {
        layout: 'icon-list-layout',
        items: [t('## Results', { count: totalCount ?? 0 }), ...displayDataset],
      },
    ];
  }, [searchLoading, totalCount, listState, t, displayDataset]);
}
