import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  alpha,
  Box,
  BoxProps,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { awardSubTypeIdsMap } from '@app/web/src/config/awardConfig';
import { useDateFormat } from '@front/helper';
import { ProfileAwards as ProfileAwardsIcon } from '@front/icon';
import { InfoTooltip, ProgressBar, ReverseTheme } from '@front/ui';
import {
  AwardBadgeGoalType,
  AwardBadgeLevelUpType,
  AwardBadgeProgressType,
} from '@lib/web/apis';

import { AwardBadgeDataItem } from '../../ia/award/types';
import {
  getAwardCurrentValue,
  getAwardGoalValue,
  getAwardProgressRate,
  getAwardState,
} from '../../utils/award';
import AwardBadgeIcon from '../AwardBadgeIcon';

const styles = {
  root: {
    p: 1,
    borderRadius: 2,
    cursor: 'pointer',
    position: 'relative',
    minHeight: 164,
    svg: {
      width: '100%',
      height: '100%',
    },
  },
  simpleCard: {
    minHeight: 'unset',
  },
  skeleton: {
    opacity: 0.5,
    cursor: 'default',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  hoverBackground: {
    transitionDuration: '0.3s',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  selected: {
    background: (theme: Theme) => theme.palette.text.primary,
    color: 'background.default',
    '& .progress-bar': {
      bgcolor: (theme: Theme) => alpha(theme.palette.background.darker, 0.05),
    },
  },
  icon: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  nameWrap: {
    width: '100%',
    display: 'grid',
    overflow: 'hidden',
  },
  name: {
    width: '100%',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  levelContent: {
    width: '100%',
    opacity: 0.5,
    fontSize: 12,
    lineHeight: '16px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  progressTextWrap: {
    display: 'grid',
    width: '100%',
  },
  progressText: {
    width: '100%',
    opacity: 0.5,
    fontSize: 12,
    lineHeight: '16px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  progressBarWrap: {
    mt: 2,
  },
  progressBarPlaceholder: {
    height: 8,
  },
  progressBar: {
    position: 'absolute',
    bottom: 8,
    width: 'calc(100% - 16px)',
  },
  archivedContent: {
    width: '100%',
    opacity: 0.5,
    fontSize: 12,
    lineHeight: '16px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  tooltip: {
    width: '200px',
  },
  tooltipContent: {
    display: 'grid',
    gap: 0.5,
  },
  tooltipProgress: {
    mb: 0.5,
  },
  tooltipLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    opacity: 0.5,
  },
};

function Progress({
  progressType,
  goalType,
  levelUser,
  levelInfo,
}: {
  progressType: AwardBadgeProgressType;
  goalType: AwardBadgeGoalType;
  levelUser: AwardBadgeDataItem['levelUser'];
  levelInfo: AwardBadgeDataItem['levelInfo'];
}) {
  if (progressType === AwardBadgeProgressType.NoProgress) return null;
  if (!levelUser) return null;
  const rate = getAwardProgressRate(goalType, levelUser, levelInfo);

  const progressText = levelUser.progressText;

  return (
    <>
      {progressText && (
        <Box sx={styles.progressTextWrap}>
          <Typography variant="caption" sx={styles.progressText}>
            {progressText}
          </Typography>
        </Box>
      )}
      <Box sx={styles.progressBarWrap}>
        <Box sx={styles.progressBarPlaceholder} />
        <ProgressBar ratio={rate} sx={styles.progressBar} />
      </Box>
    </>
  );
}

function ArchivedContent({ earnedAt }: { earnedAt?: string | null }) {
  const { dateFormat } = useDateFormat();

  if (!earnedAt) return null;
  return (
    <Box>
      <Typography variant="caption" sx={styles.archivedContent}>
        {dateFormat(earnedAt)}
      </Typography>
    </Box>
  );
}

function LevelContent({
  maxLevel,
  level,
}: {
  maxLevel?: number | null;
  level?: number | null;
}) {
  const { t } = useTranslation('profile');

  if (!maxLevel || !level) return null;
  return (
    <Typography variant="caption" sx={styles.levelContent}>
      {t('award.levelContent', {
        value: level,
        maxLevel: maxLevel,
      })}
    </Typography>
  );
}

function Tooltip({
  title,
  titleIcon,
  content,
  goalType,
  progressType,
  levelUser,
  levelInfo,
}: {
  title?: string;
  titleIcon?: ReactNode;
  content?: string;
  progressType: AwardBadgeProgressType;
  goalType: AwardBadgeGoalType;
  levelUser: AwardBadgeDataItem['levelUser'];
  levelInfo: AwardBadgeDataItem['levelInfo'];
}) {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if (mdDown) return null;

  if (!title && !content) return null;

  const rate = getAwardProgressRate(goalType, levelUser, levelInfo);
  const currentValue = getAwardCurrentValue(goalType, levelUser);
  const goalValue = getAwardGoalValue(goalType, levelInfo);
  return (
    <InfoTooltip
      sx={styles.tooltip}
      title={title}
      titleIcon={titleIcon || <ProfileAwardsIcon width={16} height={16} />}
      followCursor
      customContent={
        <Box sx={styles.tooltipContent}>
          {progressType === AwardBadgeProgressType.Progress && (
            <>
              <Box sx={styles.tooltipLabel}>
                {levelUser.level ? (
                  <Typography variant="caption">
                    Level {levelUser?.level}
                  </Typography>
                ) : (
                  <Box />
                )}
                <Typography variant="caption">
                  {currentValue}/{goalValue}
                </Typography>
              </Box>
              <ReverseTheme>
                <ProgressBar ratio={rate} sx={styles.tooltipProgress} />
              </ReverseTheme>
            </>
          )}
          <Typography variant="caption" display="block">
            {content}
          </Typography>
        </Box>
      }
    >
      <Box
        sx={{ userSelect: 'none' }}
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
      />
    </InfoTooltip>
  );
}

function CardSkeleton() {
  return (
    <Box sx={[styles.root, styles.skeleton]} className="hello">
      <Skeleton width={84} height={84} variant="circular" />
      <Skeleton width={100} height={24} />
      <Skeleton width={80} height={16} />
      <Skeleton width={150} height={16} />
    </Box>
  );
}

type AwardBadgeCardProps = AwardBadgeDataItem & {
  sx?: BoxProps['sx'];
  children: ReactNode;
  selected?: boolean;
  onClick?: () => void;
};

export function SimpleCard({
  sx,
  subType,
  levelUpType,
  levelUser,
  selected,
  onClick,
  children,
}: AwardBadgeCardProps) {
  const state = getAwardState(levelUser.status);
  const config = awardSubTypeIdsMap[subType];
  const sxProps = Array.isArray(sx) ? sx : [sx];
  if (!config) return null;
  const currentLevel =
    levelUpType === AwardBadgeLevelUpType.MultiLevel
      ? levelUser?.level || 1 // MultiLevel type level starts with 1
      : 0;
  return (
    <Box
      sx={[
        styles.root,
        styles.simpleCard,
        !selected && styles.hoverBackground,
        !!selected && styles.selected,
        ...sxProps,
      ]}
      className="award-badge-card"
      onClick={onClick}
    >
      <Box sx={styles.icon}>
        <AwardBadgeIcon
          level={currentLevel}
          locked={state !== 'Archived'}
          subType={subType}
        />
      </Box>
      {selected ? <ReverseTheme>{children}</ReverseTheme> : children}
    </Box>
  );
}

export default function AwardBadgeCard({
  name,
  subType,
  levelUpType,
  goalType,
  progressType,
  levelUser,
  levelInfo,
  selected,
  onClick,
  children,
}: AwardBadgeCardProps) {
  const state = getAwardState(levelUser.status);
  const config = awardSubTypeIdsMap[subType];

  if (!config) return null;
  const currentLevel =
    levelUpType === AwardBadgeLevelUpType.MultiLevel
      ? levelUser?.level || 1 // MultiLevel type level starts with 1
      : 0;
  return (
    <Box
      sx={[
        styles.root,
        !selected && styles.hoverBackground,
        !!selected && styles.selected,
      ]}
      className="award-badge-card"
      onClick={onClick}
    >
      <Box sx={styles.icon}>
        <AwardBadgeIcon
          level={currentLevel}
          locked={state !== 'Archived'}
          subType={subType}
        />
      </Box>
      <Box sx={styles.nameWrap}>
        <Typography variant="body1" sx={styles.name}>
          {name}
        </Typography>
      </Box>
      {levelUpType === AwardBadgeLevelUpType.MultiLevel && (
        <LevelContent maxLevel={levelInfo.maxLevel} level={levelUser.level} />
      )}
      {state === 'Archived' && (
        <ArchivedContent earnedAt={levelUser.earnedAt} />
      )}
      {state === 'InProgress' && (
        <Progress
          goalType={goalType}
          progressType={progressType}
          levelUser={levelUser}
          levelInfo={levelInfo}
        />
      )}
      {selected ? <ReverseTheme>{children}</ReverseTheme> : children}
    </Box>
  );
}

AwardBadgeCard.SimpleCard = SimpleCard;
AwardBadgeCard.Tooltip = Tooltip;
AwardBadgeCard.Skeleton = CardSkeleton;
