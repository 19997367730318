import React from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { NumberCard } from '@front/ui';
import { getLocaleCode, trackEvent } from '@lib/web/utils';

const styles = {
  card: { flex: 1, maxWidth: 255 },
};

type PlanCardProps = {
  currency: string;
  subscriptionPrice: number;
  isAnnual: boolean;
};
export default function PlanCard({
  currency,
  subscriptionPrice,
  isAnnual,
}: PlanCardProps) {
  const { t } = useTranslation('notification');
  const displayPrice = isAnnual ? subscriptionPrice / 12 : subscriptionPrice;
  const localeCode = getLocaleCode(currency);
  const formatter = new Intl.NumberFormat(localeCode, {
    style: 'currency',
    currency,
  });
  return (
    <NumberCard
      sx={styles.card}
      title={isAnnual ? t('Pro Plan Annual') : t('Pro Plan Monthly')}
      titleIcon="ProfileMyPlan"
      actionIcon="ActionArrowRightUp"
      onClick={() => {
        void Router.push('/profile/my-plan');
        trackEvent('click', {
          elementName: 'plan-card',
          fromLocation: 'notification',
        });
      }}
    >
      <NumberCard.Value variant="currency">
        {formatter.format(displayPrice)}
      </NumberCard.Value>
      <NumberCard.Description>
        {isAnnual ? t('Per Year') : t('Per Month')}
      </NumberCard.Description>
    </NumberCard>
  );
}
