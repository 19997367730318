import { createContext, Dispatch, SetStateAction } from 'react';
import { FilterConditionConfig } from '@lib/ia/src/filter/types';

export interface FilterContextValue {
  selectedCondition?: FilterConditionConfig;
  page?: 'querySummary' | 'fieldList' | 'conditionDetail';
  showEmojiPicker?: boolean;
  history: { currentIndex: number; items: FilterContextValue[] };
  scope: string;
}

export const initialValue = {
  history: { currentIndex: -1, items: [] as FilterContextValue[] },
  scope: 'global',
};

export type FilterContextType = [
  FilterContextValue,
  Dispatch<SetStateAction<FilterContextValue>>
];

const FilterContext = createContext<FilterContextType>([
  initialValue,
  () => {},
]);

export default FilterContext;
