import { useMemo } from 'react';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import { TableLayoutRow } from '@lib/ia/src/layouts/TableLayout/types';
import { v4 } from 'uuid';

export const useThreadViewTableAvailableAction = ({
  view,
  setSelectedRowId,
  loadMore,
  expandRow,
}: {
  view: GetThreadViewRes;
  setSelectedRowId: (selectedRowId: string) => void;
  loadMore: () => void;
  expandRow: (data: { rowId: string; value: boolean }) => void;
}) => {
  const { openRightPanel, getRightParams, isTargetPanelOpened } =
    useBaseRightPanel<GlobalPanelParams>();

  return useMemo(
    () => ({
      viewThread: {
        action: ({ id }: { id: string }) => {
          const { channelCid } = getRightParams(
            GlobalPanelKeys.GlobalThreadChat
          );
          if (
            isTargetPanelOpened(GlobalPanelKeys.GlobalThreadChat) &&
            channelCid === id
          ) {
            // already opened
            return;
          }
          setSelectedRowId(id);
          openRightPanel(GlobalPanelKeys.GlobalThreadChat, {
            channelCid: id,
          });
        },
      },
      openThread: {
        action: ({ id }: { id: string }) => {
          const { channelCid } = getRightParams(
            GlobalPanelKeys.GlobalThreadChat
          );
          if (
            isTargetPanelOpened(GlobalPanelKeys.GlobalThreadChat) &&
            channelCid === id
          ) {
            // already opened
            return;
          }
          setSelectedRowId(id);
          openRightPanel(GlobalPanelKeys.GlobalThreadChat, {
            channelCid: id,
          });
        },
      },
      newThread: {
        action: () => {
          const tmpId = v4();
          setSelectedRowId(tmpId);
          openRightPanel(GlobalPanelKeys.GlobalThreadCreatorFromViewPanel, {
            tmpId,
            view,
          });
        },
      },
      loadMore: {
        action: () => {
          loadMore();
        },
      },
      expandRow: {
        action: ({ value, row }: { value: boolean; row: TableLayoutRow }) => {
          expandRow({ rowId: row.id, value });
        },
      },
    }),
    [
      getRightParams,
      isTargetPanelOpened,
      loadMore,
      expandRow,
      openRightPanel,
      setSelectedRowId,
      view,
    ]
  );
};
