import { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import {
  DateRangeFilterValue,
  FilterDateOrTimeField,
  FilterOperator,
  RelativeToTodayDateFilterValue,
  SingleDateFilterValue,
} from '../../../types';

import DateRangeFilter from './DateRangeFilter';
import RelativeToTodayFilter from './RelativeToTodayFilter';
import SingleDateFilter from './SingleDateFilter';

type DateFilterProps = {
  operator: FilterOperator;
  field: FilterDateOrTimeField;
};

export default function DateFilter({ operator, field }: DateFilterProps) {
  const { control, setValue } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    control,
    name: field.name,
    rules: { required: true },
  });

  const [dateRangeValue, setDateRangeValue] = useState(
    value?.dateType === 'dateRange' ? value : null
  );
  const [singleDateValue, setSingleDateValue] = useState(
    value?.dateType === 'singleDate' ? value : null
  );
  const [relativeToTodayValue, setRelativeToTodayValue] = useState(
    value?.dateType === 'relativeToToday' ? value : null
  );

  const dateOperatorType =
    operator === 'IsBetweenTime'
      ? 'dateRange'
      : operator === 'IsRelativeToToday'
      ? 'relativeToToday'
      : 'singleDate';

  useEffect(() => {
    return () => {
      setValue(field.name, null);
      setDateRangeValue(null);
      setSingleDateValue(null);
      setRelativeToTodayValue(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.name, dateOperatorType]);

  const handleDateRangeChange = (newValue: DateRangeFilterValue | null) => {
    onChange?.(newValue);
    setDateRangeValue(newValue);
  };
  const handleRelativeToTodayChange = (
    newValue: RelativeToTodayDateFilterValue | null
  ) => {
    onChange?.(newValue);
    setRelativeToTodayValue(newValue);
  };
  const handleSingleDateChange = (newValue: SingleDateFilterValue | null) => {
    onChange?.(newValue);
    setSingleDateValue(newValue);
  };

  if (operator === 'IsBetweenTime') {
    return (
      <DateRangeFilter
        value={dateRangeValue}
        onChange={handleDateRangeChange}
        dateFormat={field.dateFormat}
      />
    );
  }

  if (operator === 'IsRelativeToToday') {
    return (
      <RelativeToTodayFilter
        value={relativeToTodayValue}
        onChange={handleRelativeToTodayChange}
      />
    );
  }

  return (
    <SingleDateFilter
      value={singleDateValue}
      onChange={handleSingleDateChange}
      dateFormat={field.dateFormat}
      placeholder={field.placeholder}
    />
  );
}
