import { PropsWithChildren } from 'react';
import { Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useSolutionViewableStatus } from '@lib/web/practice';
import {
  PaidFeatureBlocker,
  PaidFeatureBlockerPlacement,
  PaidFeatureBlockerType,
} from '@lib/web/ui';

import { useAppSelector } from '../../hooks/redux';

const styles = {
  wrapper: {
    px: 2.5,
    pb: 10,
  },
};

export type SolutionBlockerProps = PropsWithChildren & {
  quizQuestionId: string;
  placement?: PaidFeatureBlockerPlacement;
};

export default function QuizSolutionBlocker({
  quizQuestionId,
  children,
  placement = 'rhs',
}: SolutionBlockerProps) {
  const { solutionViewableStatus } = useSolutionViewableStatus({
    quizQuestionId,
  });
  const blockerLeftOffset = useAppSelector((st) => st.layout.blockerLeftOffset);

  if (solutionViewableStatus === 'init') {
    return (
      <Box sx={styles.wrapper}>
        <Skeleton width="100%" />
        <Skeleton width="100%" />
        <Skeleton width="100%" />
      </Box>
    );
  }
  if (solutionViewableStatus === 'error') {
    return (
      <Box sx={styles.wrapper}>
        <Typography variant="body2">Unable to load solution.</Typography>
        <Typography variant="caption">
          Please refresh the page or try again later.
        </Typography>
      </Box>
    );
  }

  if (
    solutionViewableStatus === 'exceed-quota' ||
    solutionViewableStatus === 'unauthorized'
  ) {
    return (
      <Box sx={styles.wrapper}>
        <PaidFeatureBlocker
          type={PaidFeatureBlockerType.ViewSolution}
          placement={placement}
          showCloseButton={false}
          modalOffsetLeft={placement === 'center' ? blockerLeftOffset : 0}
        />
      </Box>
    );
  }

  return children;
}
