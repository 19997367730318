import { CreateQuizPanelKeys } from '@app/web/src/components/CreateQuiz/enums';
import { PrivatePanelKeys } from '@app/web/src/types/panel';

import usePlaylistSummary from '../../hooks/usePlaylistSummary';

import CreateQuizPanel from './CreateQuizPanel';

export const DEFAULT_PANEL_KEY_PREFIX = 'PlaylistDetail';

type RedoCreateQuizPanelsProps = {
  panelKeyPrefix?: string;
  selectedTag?: string;
  clubSlug?: string;
};

enum PanelType {
  Redo = 'Redo',
  MistakesOnly = 'MistakesOnly',
  Practice = 'Practice',
  Challenge = 'Challenge',
  PracticeAgain = 'PracticeAgain',
}

const PanelKeys = {
  Redo: `${PanelType.Redo}${PrivatePanelKeys.CreateQuiz}`,
  MistakesOnly: `${PanelType.MistakesOnly}${PrivatePanelKeys.CreateQuiz}`,
  Practice: `${PanelType.Practice}${PrivatePanelKeys.CreateQuiz}`,
  CreateChallenge: `${PanelType.Challenge}${CreateQuizPanelKeys.Challenger}`,
  PracticeAgain: `${PanelType.PracticeAgain}${PrivatePanelKeys.CreateQuiz}`,
};

export default function RedoCreateQuizPanels({
  panelKeyPrefix = DEFAULT_PANEL_KEY_PREFIX,
  selectedTag,
  clubSlug,
}: RedoCreateQuizPanelsProps) {
  const { data } = usePlaylistSummary();
  if (!data) return null;

  return (
    <>
      {/* Redo current playlist */}
      <CreateQuizPanel
        data={data.data}
        panelKeyPrefix={`${panelKeyPrefix}${PanelType.Redo}`}
        selectedTag={selectedTag}
        redo
        clubSlug={clubSlug}
        defaultDisabledParts={['all']}
      />
      {/* Redo mistakes only */}
      <CreateQuizPanel
        data={data.data}
        mistakeOnly
        panelKeyPrefix={`${panelKeyPrefix}${PanelType.MistakesOnly}`}
        selectedTag={selectedTag}
        clubSlug={clubSlug}
        defaultDisabledParts={['all']}
      />
      {/* Start new practice */}
      <CreateQuizPanel
        data={data.data}
        panelKeyPrefix={`${panelKeyPrefix}${PanelType.Practice}`}
        selectedTag={selectedTag}
        clubSlug={clubSlug}
        defaultDisabledParts={['duration', 'difficulty', 'topics']}
      />
      {/* Create challenge */}
      <CreateQuizPanel
        data={data.data}
        panelKeyPrefix={`${panelKeyPrefix}${PanelType.Challenge}`}
        formId="playlist-detail-create-challenge"
        selectedTag={selectedTag}
        clubSlug={clubSlug}
        defaultDisabledParts={['duration', 'difficulty', 'topics']}
      />
      {/* Start new round */}
      <CreateQuizPanel
        data={data.data}
        panelKeyPrefix={`${panelKeyPrefix}${PanelType.PracticeAgain}`}
        selectedTag={selectedTag}
        clubSlug={clubSlug}
        defaultDisabledParts={['mode', 'duration', 'difficulty', 'topics']}
      />
    </>
  );
}

RedoCreateQuizPanels.DEFAULT_PANEL_KEY = DEFAULT_PANEL_KEY_PREFIX;
RedoCreateQuizPanels.Type = PanelType;
RedoCreateQuizPanels.PanelKeys = PanelKeys;
