import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { useFilter } from '@app/web/src/hooks/utils/useFilter';
import { Button, Scrollbar } from '@front/ui';
import FilterCondition from '@lib/ia/src/filter/FilterCondition';
import { FilterConditionHandler } from '@lib/ia/src/filter/FilterCondition/FilterCondition';
import {
  FilterConditionChangedEvent,
  FilterConditionConfig,
} from '@lib/ia/src/filter/types';
import { apis } from '@lib/web/apis';
import { call } from '@lib/web/utils';
import { v4 } from 'uuid';

import FilterContext from '../context/FilterContext';
import useFilterActions from '../hooks/useFilterActions';

import FilterEmojiPicker from './FilterEmojiPicker';

const mobileStyles = {
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: '60px',
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    p: 1.5,
  },
  hidden: {
    display: 'none',
  },
};

const desktopStyles = {
  action: {
    display: 'flex',
    gap: 1,
    width: '100%',
    p: 1.5,
    '& .MuiButtonBase-root': {
      minWidth: 'unset',
      flex: 1,
      px: 0,
      letterSpacing: 0,
    },
  },
  hidden: {
    display: 'none',
  },
};

export default function FilterConditionContent() {
  const { t } = useTranslation();
  const { goPage, back } = useFilterActions();
  const [settings] = useContext(FilterContext);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { selectedCondition, showEmojiPicker, scope } = settings;

  const { upsertCondition, removeCondition } = useFilter(scope);
  const filterConditionRef = useRef<FilterConditionHandler>(null);

  const [data, setData] = useState<FilterConditionChangedEvent>();

  const handleAddFilter = () => {
    if (!data || !data.isValid || !data.operator || !data.field) {
      return;
    }
    const currentCondition: FilterConditionConfig = {
      id: data.id || v4(),
      field: data.field,
      operator: data.operator,
      values: data.values,
      disabled: false,
    };
    upsertCondition(currentCondition);
    goPage({ page: 'querySummary' });
  };

  const handleChanged = (event: FilterConditionChangedEvent) => {
    setData(event);
  };

  const handleDeleteFilter = () => {
    if (selectedCondition && selectedCondition.id) {
      removeCondition(selectedCondition.id);
    }
    back();
  };

  const handleOpenEmojiPicker = () => {
    goPage({
      page: 'conditionDetail',
      selectedCondition,
      showEmojiPicker: true,
    });
  };

  const handleEmojiSelected = (emoji: GetMarkEmojiRes | null) => {
    if (emoji) {
      filterConditionRef?.current?.addEmoji({
        name: emoji.name,
        label: emoji.code,
        value: emoji.code,
      });
    }
  };

  const searchUsers = async ({ keyword }: { keyword: string }) => {
    const [res] = await call(apis.member.searchMember({ keyword }));
    return (
      res?.data?.items.map((user) => ({
        label: user.displayName,
        value: user.userId,
        memberId: user.memberId,
        avatarUrl: user.nftAvatar || user.avatar,
      })) || []
    );
  };

  if (!selectedCondition) return null;

  if (mdUp)
    return (
      <>
        <FilterEmojiPicker
          onEmojiSelected={handleEmojiSelected}
          visible={!!showEmojiPicker}
        />
        <FilterCondition
          {...selectedCondition}
          onChange={handleChanged}
          onOpenEmojiPicker={handleOpenEmojiPicker}
          onEnter={handleAddFilter}
          searchUsers={searchUsers}
          ref={filterConditionRef}
        />
        <Box
          sx={[desktopStyles.action, !!showEmojiPicker && desktopStyles.hidden]}
        >
          <Button onClick={handleDeleteFilter} variant="outlined" color="error">
            {t('filter.buttons.deleteFilter')}
          </Button>
          <Button onClick={handleAddFilter} disabled={!data?.isValid}>
            {!data?.id
              ? t('filter.buttons.addFilter')
              : t('filter.buttons.updateFilter')}
          </Button>
        </Box>
      </>
    );
  return (
    <>
      <FilterEmojiPicker
        onEmojiSelected={handleEmojiSelected}
        visible={!!showEmojiPicker}
      />
      <Scrollbar
        sx={[mobileStyles.scroll, !!showEmojiPicker && mobileStyles.hidden]}
      >
        <FilterCondition
          {...selectedCondition}
          onChange={handleChanged}
          onOpenEmojiPicker={handleOpenEmojiPicker}
          onEnter={handleAddFilter}
          searchUsers={searchUsers}
          ref={filterConditionRef}
        />
      </Scrollbar>
      <Box sx={[mobileStyles.action, !!showEmojiPicker && mobileStyles.hidden]}>
        <Button onClick={handleAddFilter} disabled={!data?.isValid}>
          {!data?.id
            ? t('filter.buttons.addFilter')
            : t('filter.buttons.updateFilter')}
        </Button>
      </Box>
    </>
  );
}
