import { alpha, Box, ButtonBase, Chip, Theme, Typography } from '@mui/material';
import { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import {
  ActionCloseSmall as ActionCloseSmallIcon,
  ChatNotificationDot as ChatNotificationDotIcon,
  OtherCheckedSquareSolid as OtherCheckedSquareSolidIcon,
  OtherCheckSquareOutline as OtherCheckSquareOutlineIcon,
} from '@front/icon';
import {
  BottomSheet,
  NewAutocomplete,
  Scrollbar,
  StatusTag,
  TextField,
} from '@front/ui';
import { useSelectFilter } from '@lib/ia/src/filter/FilterCondition/hooks/useSelectFilter';

import { FilterStatusField, StatusOption } from '../../types';

const styles = {
  root: {
    '& .MuiInputBase-root': {
      pr: '50px',
      flexWrap: 'wrap',
    },
    '& .MuiAutocomplete-input': {
      width: 0,
      minWidth: '50px',
      pr: 0,
    },
    '& .MuiAutocomplete-tag': {
      margin: '1px 2px',
    },
  },
  paper: {
    '& .MuiAutocomplete-option': {
      pt: '1px !important',
      pb: '1px !important',
      height: '28px',
    },
  },
  option: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    fontSize: { xs: 16, md: 14 },
  },
  chip: {
    height: 24,
    borderRadius: 1,
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    color: (theme: Theme) => theme.palette.text.primary,
    fontSize: 16,
  },
  statusChip: {
    borderRadius: 5,
    '& .MuiChip-label': {
      px: '10px',
    },
  },
  bottomSheetInputWrap: {
    px: '20px',
  },
  button: {
    height: 45,
    px: 2.5,
    width: '100%',
    justifyContent: 'flex-start',
    typography: 'body1',
  },
  noOption: {
    fontSize: 14,
    px: '20px',
  },
};

const getColorValues = (
  color: 'yellow' | 'orange' | 'red' | 'green' | 'blue'
) => {
  return {
    backgroundColor: (theme: Theme) =>
      alpha(theme.palette.highlight[color], 0.05),
    color: `highlight.${color}`,
  };
};

const statusColors = {
  default: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    color: 'grey.500',
  },
  success: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.success.light, 0.05),
    color: 'success.light',
  },
  error: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.error.dark, 0.05),
    color: 'error.dark',
  },
  info: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.primary.light, 0.05),
    color: 'primary.light',
  },
  warning: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.warning.light, 0.05),
    color: 'warning.light',
  },
  text: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    color: 'text.primary',
  },
  yellow: getColorValues('yellow'),
  orange: getColorValues('orange'),
  green: getColorValues('green'),
  red: getColorValues('red'),
  blue: getColorValues('blue'),
};

type CheckboxProps = { checked: boolean };
function Checkbox({ checked }: CheckboxProps) {
  return checked ? (
    <OtherCheckedSquareSolidIcon width={16} height={16} />
  ) : (
    <OtherCheckSquareOutlineIcon width={16} height={16} />
  );
}

type AutoCompeteProps = MuiAutocompleteProps<any, any, any, any>;

type StatusSelectFilterProps = {
  item: FilterStatusField;
  multiple?: boolean;
  showCheckbox?: boolean;
};

export default function StatusSelectFilter({
  item,
  multiple,
  showCheckbox,
}: StatusSelectFilterProps) {
  const {
    desktopAutocompleteProps,
    mobileAutocompleteProps,
    mobileBottomSheetProps,
    scrollHeight,
    filteredOptions,
    selectedOptions,
    handleOptionClick,
  } = useSelectFilter({ item, multiple });

  const renderOption: AutoCompeteProps['renderOption'] = (
    props,
    option,
    { selected }
  ) => (
    <li {...props}>
      <Box sx={styles.option}>
        {showCheckbox && <Checkbox checked={selected} />}
        <StatusTag color={option.color} name={option.label} size="small" />
      </Box>
    </li>
  );

  const renderTags: AutoCompeteProps['renderTags'] = (val, getTagProps) =>
    val.map((opt, index) => (
      <Chip
        {...getTagProps({ index })}
        label={opt.label}
        key={index}
        sx={[
          styles.chip,
          styles.statusChip,
          statusColors[opt.color as keyof typeof statusColors],
        ]}
        icon={<ChatNotificationDotIcon width={16} height={16} />}
        deleteIcon={<ActionCloseSmallIcon width={16} height={16} />}
      />
    ));
  const renderInput: AutoCompeteProps['renderInput'] = ({
    InputProps,
    inputProps,
    InputLabelProps,
    size,
    ...params
  }) => (
    <TextField
      ref={InputProps.ref}
      endAdornment={InputProps.endAdornment}
      startAdornment={InputProps.startAdornment}
      {...params}
      inputProps={inputProps}
      labelIcon={null}
      placeholder={item.placeholder || 'Select one or more options...'}
      size="rwd"
      autoFocus
    />
  );

  return (
    <>
      <NewAutocomplete
        {...desktopAutocompleteProps}
        sx={styles.root}
        slotProps={{ paper: { sx: styles.paper } }}
        componentsProps={{ popper: { sx: { marginTop: '-20px!important' } } }}
        renderOption={renderOption}
        renderTags={renderTags}
        renderInput={renderInput}
      />
      <BottomSheet {...mobileBottomSheetProps}>
        <Box sx={styles.bottomSheetInputWrap}>
          <NewAutocomplete
            {...mobileAutocompleteProps}
            sx={styles.root}
            renderTags={renderTags}
            renderInput={renderInput}
          />
        </Box>
        <Scrollbar sx={{ height: scrollHeight }}>
          {filteredOptions.map((option, index) => {
            const isSelected = selectedOptions.some(
              (val) => val?.value === option.value
            );
            return (
              <ButtonBase
                key={index}
                sx={styles.button}
                onClick={() => handleOptionClick(option)}
              >
                <Box sx={styles.option}>
                  {showCheckbox && <Checkbox checked={isSelected} />}
                  <StatusTag
                    color={(option as StatusOption).color}
                    name={option.label}
                    size="small"
                  />
                </Box>
              </ButtonBase>
            );
          })}
        </Scrollbar>
        {filteredOptions.length === 0 && (
          <Typography sx={styles.noOption}>No results found</Typography>
        )}
      </BottomSheet>
    </>
  );
}
