import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { Theme, useMediaQuery } from '@mui/material';
import NFTAvatar from '@app/web/src/components/NFTAvatar';
import { NumberCard } from '@front/ui';
import { useSharedMemberInfo } from '@lib/web/apis';

const styles = {
  card: { flex: 1, maxWidth: 255 },
  avatarWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: { xs: 1.5, md: 0 },
  },
};

type SharedSummaryCardProps = {
  sid: string;
  summaryLink: string;
};
export default function SharedSummaryCard({
  sid,
  summaryLink,
}: SharedSummaryCardProps) {
  const { t } = useTranslation('notification');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { data: userData } = useSharedMemberInfo(sid);
  return (
    <NumberCard
      sx={styles.card}
      title={t('Summary')}
      titleIcon="AnalyticsProgress"
      onClick={() => Router.push(summaryLink)}
      actionIcon="ActionArrowRightUp"
    >
      <NumberCard.Value>
        <NFTAvatar size={mdUp ? 64 : 32} userId={userData?.data.userId} />
      </NumberCard.Value>
      <NumberCard.Description>
        {t('## Summary', { name: userData?.data.displayName })}
      </NumberCard.Description>
    </NumberCard>
  );
}
