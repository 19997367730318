import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import ThreadMembersList from '@app/web/src/widgets/CommonPanels/ThreadPanels/components/ThreadMembersList';
import {
  OtherAddFriend as OtherAddFriendIcon,
  PrivacyFriends as PrivacyFriendsIcon,
} from '@front/icon';
import {
  BaseLayoutContainer,
  BaseLayoutRightPanel,
  BaseLayoutScrollArea,
  EmphasizeButton,
  useBaseRightPanel,
} from '@front/ui';
import { apis, useAuth } from '@lib/web/apis';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';
import { useMaybeRedirectUserToNewDmView } from '@lib/web/thread/hooks/view/useMaybeRedirectUserToNewDmView';
import { callWithToast } from '@lib/web/utils';

const styles = {
  scroll: {
    height: '100%',
  },
  container: {
    px: 0,
  },
};

export default function ThreadMembersPanel() {
  const { t } = useTranslation('thread');
  const { getRightParams, openRightPanel } =
    useBaseRightPanel<GlobalPanelParams>();
  const { channel } = getRightParams(GlobalPanelKeys.GlobalThreadMembers);
  const { getChannelInformation } = useChannelInformation();
  const { channelMemberIds, channelCreator } = getChannelInformation(channel);
  const { maybeRedirectUserToNewDmView } = useMaybeRedirectUserToNewDmView();
  const { member } = useAuth();
  const myMemberId = member?.memberId;

  const handleRemoveMember = async (removeMemberId: string) => {
    const [res] = await callWithToast(
      apis.thread.removeMember({
        channelType: channel.type as 'team' | 'public',
        channelId: channel.id as string,
        memberIds: [removeMemberId],
      })
    );

    if (res?.data.afterRemovedDmViewId) {
      await maybeRedirectUserToNewDmView({
        channel,
        newViewId: res.data.afterRemovedDmViewId,
      });
    }
  };

  const handleAddMemberClick = useCallback(() => {
    openRightPanel(GlobalPanelKeys.GlobalThreadAddMember, { channel });
  }, [channel, openRightPanel]);

  return (
    <BaseLayoutRightPanel
      resizable
      title={t('rhs.member.title')}
      titleIcon={<PrivacyFriendsIcon width={16} height={16} />}
      actionComponent={
        <EmphasizeButton
          prefixIcon={<OtherAddFriendIcon />}
          onClick={handleAddMemberClick}
        >
          {t('rhs.member.addMemberButton')}
        </EmphasizeButton>
      }
    >
      <BaseLayoutScrollArea sx={styles.scroll}>
        <BaseLayoutContainer sx={styles.container} fullWidth>
          <ThreadMembersList
            memberIds={channelMemberIds}
            onRemoveMember={handleRemoveMember}
            enableRemoveMember={channelCreator === myMemberId}
          />
        </BaseLayoutContainer>
      </BaseLayoutScrollArea>
    </BaseLayoutRightPanel>
  );
}
