import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Router from 'next/router';
import { alpha, Theme } from '@mui/material';
import {
  apis,
  ChallengeStatus,
  ExamMode,
  useChallengeRank,
} from '@lib/web/apis';
import { useClubSlug, useCurrentIaClub } from '@lib/web/hooks';
import { call } from '@lib/web/utils';
import { isNil } from 'lodash';

import usePlaylistSummary from '../../../hooks/usePlaylistSummary';

import BaseRedoButton from './BaseRedoButton';
import PracticeRedoButton from './PracticeRedoButton';
import StartRedoButton from './StartRedoButton';

const styles = {
  lightBlueButton: {
    bgcolor: 'primary.light',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.primary.light, 0.5),
        color: 'text.primary',
      },
    },
  },
};

function hasAttempt(attemptNum = 0, maxAttemptNum?: number) {
  // maxAttemptNum not set -> old challenges
  if (isNil(maxAttemptNum)) return false;
  // unlimited attempt
  if (maxAttemptNum === -1) return true;
  return maxAttemptNum - attemptNum > 0;
}

type ChallengeRedoButtonProps = {
  data: GetQuizResultSummaryRes;
  panelKeyPrefix?: string;
  clubSlug?: string;
};

export default function ChallengeRedoButton({
  data,
  panelKeyPrefix,
  clubSlug: clubSlugProp,
}: ChallengeRedoButtonProps) {
  const { t } = useTranslation('playlist');
  const [loading, setLoading] = useState(false);
  const { mutate } = usePlaylistSummary();
  const { mutate: clubMutate } = useCurrentIaClub();
  const { mutate: challengeRankCountMutate } = useChallengeRank(
    data.challenge?.challengeId
  );
  const routerClubSlug = useClubSlug();
  const clubSlug = clubSlugProp || routerClubSlug;

  if (!data.challenge) return null;

  const { isCompleted } = data.quiz;
  const { challengeId, isTaken, status, maxAttemptNum, attemptNum, daysLeft } =
    data.challenge;

  const startQuiz = (
    roundNo = Math.max(data.quiz.latestRoundNo, 1),
    isContinue?: boolean
  ) => {
    const targetUrl =
      data.quiz.mode === ExamMode.MockExam ? 'exam' : 'practice';
    const id = data.quiz.shortId || data.quiz.id;
    const questionNoHash = isContinue
      ? `#${(data.quiz.continueQuestionIdx || 0) + 1}`
      : `#1`;
    const path = `/club/${clubSlug}/${targetUrl}/${id}/${roundNo}${questionNoHash}`;
    return Router.push(path);
  };

  const handleAcceptChallenge = async () => {
    setLoading(true);
    const [res] = await call(() =>
      apis.challenge.acceptChallenge({
        challengeId,
      })
    );
    if (res) {
      await mutate();
      await clubMutate();
      await startQuiz();
    }
    setLoading(false);
  };

  const handleJoinChallenge = async () => {
    setLoading(true);
    const [res] = await call(() =>
      apis.challenge.joinChallenge({
        challengeId,
      })
    );
    if (res) {
      await mutate();
      challengeRankCountMutate();
      await startQuiz();
    }
    setLoading(false);
  };

  const handleDoingChallenge = async () => {
    setLoading(true);
    // start next round
    await startQuiz(data.quiz.latestRoundNo + 1);
    setLoading(false);
  };

  const handleContinuePractice = async () => {
    setLoading(true);
    await startQuiz(undefined, true);
    setLoading(true);
  };

  // open challenge
  if (!isTaken && status === ChallengeStatus.NotInvited) {
    return (
      <BaseRedoButton
        label={t('button.Join')}
        onClick={handleJoinChallenge}
        loading={loading}
        tooltip={{
          icon: 'OtherPlay',
          title: t('detail.challenge.join.tooltip.title'),
          content: t('detail.challenge.join.tooltip.content', {
            count: daysLeft,
          }),
        }}
      />
    );
  }

  // invited challenge
  if (!isTaken && status === ChallengeStatus.Invited) {
    return (
      <BaseRedoButton
        label={t('button.Accept')}
        onClick={handleAcceptChallenge}
        loading={loading}
        sx={styles.lightBlueButton}
        tooltip={{
          icon: 'OtherPlay',
          title: t('detail.challenge.accept.tooltip.title'),
          content: t('detail.challenge.accept.tooltip.content', {
            count: daysLeft,
          }),
        }}
      />
    );
  }

  // accepted, incomplete
  if (status === ChallengeStatus.Ongoing && !isCompleted) {
    return (
      <BaseRedoButton
        label={t('button.Continue')}
        color="success"
        loading={loading}
        onClick={handleContinuePractice}
      />
    );
  }

  // accepted, still have attempt
  if (
    status === ChallengeStatus.Ongoing &&
    hasAttempt(attemptNum, maxAttemptNum)
  ) {
    const attemptLeft =
      maxAttemptNum === -1 ? 'infinite' : maxAttemptNum - attemptNum;
    return (
      <BaseRedoButton
        label={t('button.Redo')}
        color="success"
        onClick={handleDoingChallenge}
        loading={loading}
        tooltip={{
          icon: 'OtherPlay',
          title: t('detail.challenge.ongoingHasAttempt.tooltip.title'),
          content: t('detail.challenge.ongoingHasAttempt.tooltip.content', {
            value: attemptLeft,
            count: maxAttemptNum - attemptNum,
          }),
        }}
      />
    );
  }

  // accepted, no attempt left
  if (
    status === ChallengeStatus.Ongoing &&
    !hasAttempt(attemptNum, maxAttemptNum)
  ) {
    return (
      <PracticeRedoButton
        data={data}
        tooltip={{
          icon: 'OtherPlay',
          title: t(
            'detail.challenge.ongoingAttemptExceeded.tooltip.title',
            'Redo'
          ),
          content: (
            <Trans
              i18nKey="playlist::detail.challenge.ongoingAttemptExceeded.tooltip.content"
              components={{ p: <p /> }}
            />
          ),
        }}
        panelKeyPrefix={panelKeyPrefix}
      />
    );
  }

  // expired, not taken
  if (status === ChallengeStatus.Expired && !isTaken) {
    /**
     * TODO: for now, the API is not ready for `practice with the same question set`
     * as requirement https://www.figma.com/design/suKzS2IdDeYCXCoNYorHxf/Sprint-10.2-2024-%5BEng-Handoff%5D?node-id=6097-390793&m=dev
     * => temporary, will show default Start RHS, then update later when API ready
     */
    return (
      <StartRedoButton
        tooltip={{
          icon: 'OtherPlay',
          title: t('detail.challenge.startExpired.tooltip.title', 'Start'),
          content: t(
            'detail.challenge.startExpired.tooltip.content',
            'Star practice with this playlist.'
          ),
        }}
        panelKeyPrefix={panelKeyPrefix}
      />
    );
  }

  // expired, taken
  if (status === ChallengeStatus.Expired && isTaken) {
    return (
      <PracticeRedoButton
        data={data}
        tooltip={{
          icon: 'OtherPlay',
          title: t('detail.challenge.redoExpired.tooltip.title', 'Redo'),
          content: t(
            'detail.challenge.redoExpired.tooltip.content',
            'Redo this playlist.'
          ),
        }}
        panelKeyPrefix={panelKeyPrefix}
      />
    );
  }

  return null;
}
