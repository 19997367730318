import { useContext } from 'react';

import { PlaylistDetailContext } from '../context';

export default function useFilterPlaylist() {
  const { isFilterMistake, isFilterOvertime, isFilterEmoji, type, setFilter } =
    useContext(PlaylistDetailContext);

  return {
    setFilter,
    isFilterMistake: type === 'mistakes' || isFilterMistake,
    isFilterOvertime: type === 'overtime' || isFilterOvertime,
    isFilterEmoji: type === 'emoji' || isFilterEmoji,
  };
}
