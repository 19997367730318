import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseLayout } from '@front/ui';

import FilterConditionContent from './FilterConditionContent';
import FilterFieldsContent from './FilterFieldsContent';
import FilterQueryContent from './FilterQueryContent';

export default function FilterPanelContent() {
  const { getRightParams } = useBaseLayout<GlobalPanelParams>();
  const { page } = getRightParams(GlobalPanelKeys.GlobalFilter);

  if (!page || page === 'querySummary') return <FilterQueryContent />;
  if (page === 'conditionDetail') return <FilterConditionContent />;
  if (page === 'fieldList') return <FilterFieldsContent />;
}
