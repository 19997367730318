import React from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { NumberCard } from '@front/ui';

const styles = {
  card: { flex: 1, maxWidth: 255 },
};

type SharedPlaylistCardProps = {
  playlistLink: string;
  playlistName: string;
  questionCount: number;
};
export default function SharedPlaylistCard({
  playlistLink,
  playlistName,
  questionCount,
}: SharedPlaylistCardProps) {
  const { t } = useTranslation('notification');
  return (
    <NumberCard
      sx={styles.card}
      title={playlistName}
      titleIcon="ProfilePlaylist"
      onClick={() => Router.push(playlistLink)}
      actionIcon="ActionArrowRightUp"
    >
      <NumberCard.Value>{questionCount}</NumberCard.Value>
      <NumberCard.Description>
        {t('Question', { count: questionCount })}
      </NumberCard.Description>
    </NumberCard>
  );
}
