import { useTranslation } from 'react-i18next';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { OtherShareToDirect as OtherShareToDirectIcon } from '@front/icon';
import { Button, useBaseRightPanel } from '@front/ui';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import { useShareToDirect } from '@lib/web/hooks';
import { trackEvent } from '@lib/web/utils';

import SearchUserPanel from '../../CommonPanels/SearchUserPanel';

export default function SharePanel() {
  const { t } = useTranslation();
  const { getRightParams, isTargetPanelOpened } =
    useBaseRightPanel<GlobalPanelParams>();
  const { autoFocus, virtualRouter, customTitle } = getRightParams(
    GlobalPanelKeys.GlobalDirectToShare
  );
  const { pageSettings, shareToDirect } = useShareToDirect(virtualRouter);
  const shareTitle = pageSettings
    ? `Share ${customTitle || pageSettings.page}`
    : 'Share Page';

  const handleSend = async (selected: IconListLayoutItemObj[]) => {
    if (!pageSettings) return;
    const userIds: string[] = [];
    const emails: string[] = [];

    selected.forEach((user) => {
      if (user.metadata?.userId) userIds.push(user.metadata?.userId);
      else if (user.metadata?.email) emails.push(user.metadata.email);
    });
    const [res] = await shareToDirect({ userIds, emails });
    if (res) {
      trackEvent('click', {
        elementName: 'share-to-direct-button',
        target: pageSettings.page,
      });
    }
  };

  const isAutoFocus =
    autoFocus && isTargetPanelOpened(GlobalPanelKeys.GlobalDirectToShare);

  return (
    <SearchUserPanel
      title={shareTitle}
      titleIcon={<OtherShareToDirectIcon width={16} height={16} />}
      buttonTestId="share-page"
      placeholder="Share to..."
      tip="You can share this page to Aha users or others by email"
      renderAction={({ selectedCount, sending, onActionClick }) => (
        <Button
          onClick={onActionClick}
          loading={sending}
          disabled={!selectedCount || !pageSettings}
        >
          {t('rhs.share.cta.label', { count: selectedCount })}
        </Button>
      )}
      onActionClick={handleSend}
      autoFocus={isAutoFocus}
    />
  );
}
