import { useEffect, useMemo, useRef } from 'react';

import { useAppSelector } from '../redux';

type Options = {
  scope?: string;
  waitUntilInitComplete?: boolean | Record<string, boolean>;
};

export default function useIaQueryHelperValues({
  scope = 'global',
}: Options = {}) {
  const loaded = useRef(false);
  const helper = useAppSelector((st) => st.iaQueryHelper);

  const currentInitializing = useMemo(() => {
    const value = helper.isInitializing[scope];
    if (!value) return false;
    if (typeof value === 'boolean') return value;

    return Object.values(helper.isInitializing[scope]).some(
      (val) => val === true
    );
  }, [helper.isInitializing, scope]);

  useEffect(() => {
    loaded.current = true;
  }, []);

  /**
   * We need `loaded` variable here to ensure the page (which uses this hook) is mounted first
   * then the currentInitializing reflected the correct value.
   * If no, the currentInitializing is the stale value of the previous page
   */
  const isInitializing = !loaded.current || currentInitializing;

  return {
    isInitializing,
  };
}
