import { ButtonProps } from '@mui/material';
import { useFollowingStatusMutate } from '@lib/web/hooks';
import { ProfileButton } from '@lib/web/ui';

type FollowButtonProps = {
  userId: string;
  isFollowing: boolean;
  isFollower: boolean;
  loading?: boolean;
  disabled?: boolean;
  size?: 'sm' | 'md';
  buttonType?: 'iconButton' | 'emphasizeButton' | 'button';
  autoFetchSocialData?: boolean;
  onToggleFollow?: (userId: string, data: Promise<any>) => void;
  sx?: ButtonProps['sx'];
};

export default function FollowButton({
  userId,
  isFollowing,
  isFollower,
  loading,
  disabled,
  size,
  buttonType,
  autoFetchSocialData,
  onToggleFollow,
  sx,
}: FollowButtonProps) {
  const { mutate: followingStatusMutate } = useFollowingStatusMutate();

  const handleToggleFollow = (targetUserId: string, data: Promise<any>) => {
    followingStatusMutate(targetUserId, data);
    onToggleFollow?.(targetUserId, data);
  };

  return (
    <ProfileButton.Follow
      buttonType={buttonType}
      loading={loading}
      disabled={disabled}
      size={size}
      isFollower={isFollower}
      isFollowing={isFollowing}
      userId={userId}
      onToggleFollow={handleToggleFollow}
      autoFetchSocialData={autoFetchSocialData}
      sx={sx}
    />
  );
}
