import { useContext, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'next-i18next';
import { Box, Switch, Typography } from '@mui/material';
import { ChatQuestionList as ChatQuestionListIcon } from '@front/icon';
import { ResponsiveTooltip } from '@front/ui';

import CreateQuizContext from '../../context';
import { CreateQuizFormValue } from '../../type';

const styles = {
  officialQuestionTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  switch: {
    position: 'relative',
    display: 'inline-flex',
    flexDirection: { xs: 'row-reverse', md: 'row' },
    justifyContent: { xs: 'space-between', md: 'flex-start' },
    gap: 2,
  },
  tooltipDetect: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    userSelect: 'none',
  },
};
export default function OfficialSwitch() {
  const { t } = useTranslation('quiz');
  const { watch, setValue } = useFormContext<CreateQuizFormValue>();
  const questionCount = +watch('questionCount', 2);
  const [createQuizSettings] = useContext(CreateQuizContext);
  const previousValue = useRef<number>();
  const {
    disabledParts,
    availableQuestionCount,
    maxQuestionCount,
    officialQuestionCount,
    minQuestionCount,
  } = createQuizSettings;
  const maxAvailableQuestionCount =
    availableQuestionCount === undefined
      ? Math.min(maxQuestionCount, officialQuestionCount)
      : Math.min(
          maxQuestionCount,
          officialQuestionCount,
          availableQuestionCount
        );

  const minAvailableQuestionCount = Math.min(
    minQuestionCount,
    maxAvailableQuestionCount
  );

  const sectionDisabled =
    disabledParts.includes('all') || disabledParts.includes('duration');
  const notEnoughOfficialQuestion =
    officialQuestionCount > maxAvailableQuestionCount;

  const disabled =
    sectionDisabled ||
    notEnoughOfficialQuestion ||
    minAvailableQuestionCount === maxAvailableQuestionCount;

  const isOfficial = officialQuestionCount === questionCount;

  const toggleOfficialQuestion = () => {
    if (isOfficial) {
      if (
        previousValue.current &&
        previousValue.current !== officialQuestionCount &&
        previousValue.current <= maxAvailableQuestionCount &&
        previousValue.current >= minAvailableQuestionCount
      ) {
        setValue('questionCount', previousValue.current, { shouldDirty: true });
      } else {
        const gap = Math.floor(
          (maxAvailableQuestionCount - minAvailableQuestionCount) / 2
        );
        const updateValue = minAvailableQuestionCount + gap;
        setValue('questionCount', updateValue, {
          shouldDirty: true,
        });
      }
    } else {
      setValue('questionCount', officialQuestionCount, { shouldDirty: true });
    }
  };

  useEffect(() => {
    if (officialQuestionCount !== questionCount) {
      previousValue.current = questionCount;
    }
  }, [officialQuestionCount, questionCount]);

  return (
    <Box position="relative">
      <ResponsiveTooltip
        titleIcon={<ChatQuestionListIcon width={16} height={16} />}
        title={t(
          'createQuiz.duration.official.hint.title',
          'Same as Official Exam'
        )}
        content={
          <Trans
            i18nKey="quiz::createQuiz.duration.official.hint.content"
            default="Number of questions that you will practice will be the same as official exam."
            count={officialQuestionCount}
            components={{
              b: <b />,
            }}
          />
        }
        tooltipProps={{ followCursor: true }}
      >
        <Box sx={styles.switch}>
          <Switch
            disabled={disabled}
            onClick={() => toggleOfficialQuestion()}
            checked={
              isOfficial && officialQuestionCount <= maxAvailableQuestionCount
            }
          />
          <Box
            sx={[
              styles.officialQuestionTitle,
              sectionDisabled && { opacity: 0.5 },
            ]}
          >
            <ChatQuestionListIcon width={16} height={16} />

            <Typography variant="body2">
              {t('createQuiz.duration.official.toggle', {
                count: officialQuestionCount,
              })}
            </Typography>
          </Box>
        </Box>
      </ResponsiveTooltip>

      {notEnoughOfficialQuestion && (
        <ResponsiveTooltip
          titleIcon={<ChatQuestionListIcon width={16} height={16} />}
          title={t(
            'createQuiz.duration.official.hint.title',
            'Same as Official Exam'
          )}
          content={t(
            'createQuiz.duration.official.hint.content',
            'Unable to set the question count to match the official exam due to fewer available questions based on your current settings.',
            { context: 'less' }
          )}
          tooltipProps={{ followCursor: true }}
        >
          <Box sx={styles.tooltipDetect} />
        </ResponsiveTooltip>
      )}
    </Box>
  );
}
