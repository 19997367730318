import { useContext } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { useFilter } from '@app/web/src/hooks/utils/useFilter';
import FilterFields from '@lib/ia/src/filter/FilterFields';
import FilterQuery from '@lib/ia/src/filter/FilterQuery';
import {
  FilterConditionConfig,
  FilterConditionSelectedEvent,
  FilterField,
} from '@lib/ia/src/filter/types';

import FilterContext from '../context/FilterContext';
import useFilterActions from '../hooks/useFilterActions';

export default function FilterQueryContent() {
  const { goPage, reset } = useFilterActions();
  const [{ scope }] = useContext(FilterContext);

  const { filter, hasFilter, resetFilter } = useFilter(scope);
  const isEmpty = !hasFilter;
  const selectedFields = filter.conditions.map((item) => item.field.name);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleAddNewFilter = () => {
    goPage({ page: 'fieldList' });
  };

  const handleClearAll = () => {
    resetFilter();
    reset();
  };

  const handleFieldSelected = (field: FilterField) => {
    const newCondition: FilterConditionConfig = {
      field,
    };
    goPage({
      page: 'conditionDetail',
      selectedCondition: newCondition,
    });
  };

  const handleConditionSelected = (event: FilterConditionSelectedEvent) => {
    const selectedCondition: FilterConditionConfig = {
      id: event.id,
      field: event.field,
      operator: event.operator,
      values: event.values,
    };
    goPage({
      page: 'conditionDetail',
      selectedCondition,
    });
  };

  if (isEmpty) {
    return (
      <FilterFields
        fields={filter.fields}
        excludeNames={selectedFields}
        onSelect={handleFieldSelected}
        autoFocus
        focusDelay={
          300 /** right panel animation duration: 300s => avoid layout jumping */
        }
      />
    );
  }

  return (
    <FilterQuery
      conditions={filter.conditions}
      onAddFilter={handleAddNewFilter}
      onClearAll={mdUp ? handleClearAll : undefined}
      onSelect={handleConditionSelected}
    />
  );
}
