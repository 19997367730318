import { useQuizResultSummary } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';

import usePlaylistInfo from './usePlaylistInfo';

export default function usePlaylistSummary() {
  const {
    quizId,
    markId,
    tagId,
    sectionId,
    dynamicType,
    customDate,
    roundNo,
    isCommon,
  } = usePlaylistInfo();
  const clubSlug = useClubSlug();
  return useQuizResultSummary({
    quizId,
    tagId,
    dynamicType,
    sectionId,
    sourceId: markId,
    customDate,
    roundNo,
    isCommon,
    clubSlug,
  });
}
