import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import useAhaInvitation from '@app/web/src/hooks/utils/useAhaInvitation';
import useFloatingProfile from '@app/web/src/hooks/utils/useFloatingProfile';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { getSearchState, SearchState } from '@app/web/src/utils/search';
import { useLatestValueRef } from '@front/helper';
import {
  BaseLayoutRightPanel,
  LightbulbCard,
  SearchBar,
  SquareAvatar,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import {
  IconListLayoutItemHoverEvent,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';
import {
  apis,
  buildInfiniteHookMutate,
  FollowingFilterType,
  OrderByFollowType,
  useAuth,
  useSearchClubMembers,
} from '@lib/web/apis';
import {
  useClubSlug,
  useInfiniteScroll,
  useSearchStatus,
} from '@lib/web/hooks';
import { useFloatingProfileClick } from '@lib/web/ui';
import { call, emailRegex } from '@lib/web/utils';

import useAddFriendsTabDataset from '../hooks/useAddFriendsTabDataset';
import useAddFriendsTabLayoutConfig from '../hooks/useAddFriendsTabLayoutConfig';

const styles = {
  content: {
    flex: 1,
    position: 'relative',
  },
  searchBar: {
    px: { xs: '16px', md: '12px' },
  },
  emptyTip: {
    px: { xs: '16px', md: '12px' },
  },
  emptyContent: {
    px: { xs: '16px', md: '12px' },
    pt: 1,
  },
};

type AddFriendsTabContentProps = {
  selected?: IconListLayoutItemObj[];
  onSelectedChange?: (selected: IconListLayoutItemObj[]) => void;
};

export default function AddFriendsTabContent({
  selected = [],
  onSelectedChange,
}: AddFriendsTabContentProps) {
  const { t } = useTranslation('club');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const searchInputRef = useRef<HTMLInputElement>();
  const clubSlug = useClubSlug();
  const { member } = useAuth();
  const userId = member?.userId || '';
  const { openRightPanel, getRightParams } =
    useBaseRightPanel<GlobalPanelParams>();
  const { defaultSearch } = getRightParams(GlobalPanelKeys.GlobalAddFriends);
  const { pendingEmails, invitedHistory, inviteToAha, newUserFormatter } =
    useAhaInvitation();

  const { showUserProfile } = useFloatingProfile();

  const {
    focused,
    search,
    isSearching,
    debouncedSearch,
    setSearch,
    onChange,
    onBlur,
    onFocus,
  } = useSearchStatus(defaultSearch);

  const listState = getSearchState({
    isSearching,
    isFocused: focused,
    hasSelected: selected.length > 0,
  });

  const searchData = useSearchClubMembers({
    clubSlug,
    keyword: emailRegex.test(debouncedSearch) ? debouncedSearch : undefined,
    keywordFuzzy: !emailRegex.test(debouncedSearch)
      ? debouncedSearch
      : undefined,
    isGetRole: true,
    isExcludeMe: true,
    orderByFollowType: OrderByFollowType.FollowingFirst,
  });

  const {
    dataset: searchDataset,
    isLoading: searchLoading,
    totalCount: searchTotalCount,
    mutate: searchMutate,
  } = searchData;

  const {
    dataset: followingDataset,
    totalCount: followingTotalCount,
    mutate: followingMutate,
  } = useSearchClubMembers(
    {
      clubSlug,
      keyword: debouncedSearch || '',
      isJoined: false,
      followingFilterType: FollowingFilterType.FollowingOnly,
      isGetRole: true,
      isExcludeMe: true,
    },
    10
  );

  const displayDataset = useAddFriendsTabDataset({
    listState,
    followingDataset,
    searchDataset,
  });

  const dataset =
    listState === SearchState.Initial ? followingDataset : searchDataset;
  const totalCount =
    listState === SearchState.Initial ? followingTotalCount : searchTotalCount;
  const mutate =
    listState === SearchState.Initial ? followingMutate : searchMutate;

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );

  const isSelected = useCallback(
    (id: string) => {
      return selected.some((item) => item.id === id);
    },
    [selected]
  );

  const isDisabled = (id: string) =>
    id === userId ||
    invitedHistory.some(
      (item) => item.receiver.email === id && !item.resendAble
    );

  const getItemStatus = ({ id }: IconListLayoutItemObj) => {
    return {
      selected: isSelected(id),
      disabled: isDisabled(id),
      loading: pendingEmails.includes(id),
      focused: mdUp && suggestItem?.id === id,
    };
  };

  const newUser = useMemo(() => {
    const email =
      isSearching &&
      !searchDataset.find((d) => d.email === debouncedSearch) &&
      !searchLoading &&
      emailRegex.test(debouncedSearch)
        ? debouncedSearch
        : '';
    return newUserFormatter(email);
  }, [
    debouncedSearch,
    isSearching,
    newUserFormatter,
    searchDataset,
    searchLoading,
  ]);

  const configs = useAddFriendsTabLayoutConfig({
    displayDataset,
    totalCount,
    listState,
    newUser,
    isSelected,
    searchLoading,
    selected,
  });

  const handleUserClick = (value: IconListLayoutItemObj) => {
    const users = isSelected(value.id)
      ? selected.filter((item) => item.id !== value.id)
      : [...selected, value];
    onSelectedChange?.(users);
    setSearch('');
  };

  const handleChallengeClick = (
    item: IconListLayoutItemObj<BaseMemberInfo>
  ) => {
    if (!item.metadata) return;
    const challenger = {
      id: item.id,
      display: item.title,
      avatar: item.avatarUrl,
      memberInfo: item.metadata,
    };
    openRightPanel(GlobalPanelKeys.GlobalStartChallenge, {
      challengerSource: [challenger],
    });
  };

  const reloadMembers = useMemo(
    () => buildInfiniteHookMutate(mutate),
    [mutate]
  );

  const availableActions = {
    titleClick: {
      action: ({ id }: { id: string }) => {
        openRightPanel(GlobalPanelKeys.GlobalProfile, {
          userId: id,
        });
      },
    },
    follow: {
      action: async ({ id }: { id: string }) => {
        searchInputRef.current?.focus();
        await call(
          reloadMembers(apis.member.followUser(id), {
            optimisticData: dataset.map((d) =>
              d.userId === id ? { ...d, isFollowing: true } : d
            ),
            optimisticPageData: {
              totalCount,
            },
          })
        );
      },
    },
    challenge: {
      action: async (item: IconListLayoutItemObj<BaseMemberInfo>) => {
        searchInputRef.current?.focus();
        handleChallengeClick(item);
      },
    },
    selectChanged: {
      action: (value: IconListLayoutItemObj) => {
        handleUserClick(value);
      },
    },
    userHovered: {
      action: updateSuggestItem,
    },
    inviteToAha: {
      action: async ({ id }: { id: string }) => {
        inviteToAha([id]);
      },
    },
    titleHover: {
      action: (event: IconListLayoutItemHoverEvent<UserProfileObj>) => {
        if (!event.target.metadata) return;
        showUserProfile({
          anchorEl: event.anchorEl,
          userProfile: event.target.metadata,
        });
      },
    },
    avatarHover: {
      action: (event: IconListLayoutItemHoverEvent<UserProfileObj>) => {
        if (!event.target.metadata) return;
        showUserProfile({
          anchorEl: event.anchorEl,
          userProfile: event.target.metadata,
          options: {
            spacing: 8,
          },
        });
      },
    },
  };

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: searchData,
    enabled: isSearching,
  });

  const focusedRef = useLatestValueRef(focused);
  void useFloatingProfileClick(() => {
    if (focusedRef.current) {
      searchInputRef.current?.focus();
    }
  });

  const emptyTip = (
    <>
      {selected.length === 0 && displayDataset.length === 0 && !isSearching && (
        <Box sx={styles.emptyTip}>
          <LightbulbCard>
            {t(
              'club.RHS.addFriends.addFriendsTab.tip',
              'Invite new friends or add Aha users as your friend and invite them to the club by name, username, or email.'
            )}
          </LightbulbCard>
        </Box>
      )}
    </>
  );

  const emptyContent = (
    <>
      {selected.length === 0 &&
        displayDataset.length === 0 &&
        isSearching &&
        !newUser &&
        !searchLoading && (
          <Box sx={styles.emptyContent}>
            <Typography variant="body2" color="alpha.lightA64">
              {t('No results found')}
            </Typography>
            <Typography variant="body2" color="alpha.lightA64">
              {t(
                'club.RHS.addFriends.addFriendsTab.empty.content',
                'No users found with that search term.'
              )}
            </Typography>
          </Box>
        )}
    </>
  );

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <Box sx={styles.content}>
          <BaseLayoutRightPanel.ScrollArea
            scrollableNodeProps={{ ref: scrollRef }}
          >
            <Box sx={styles.searchBar}>
              <SearchBar
                inputRef={searchInputRef}
                placeholder={t(
                  'search.placeholder_inviteOrSearchExisting',
                  'Invite new or search existing...'
                )}
                value={search}
                loading={searchLoading}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                suggestText={suggestItem?.title}
                prefixIcon={
                  suggestItem && (
                    <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                      {suggestItem.title}
                    </SquareAvatar>
                  )
                }
              />
            </Box>
            {emptyTip}
            {configs && <IaLayouts layouts={configs} />}
            {emptyContent}
          </BaseLayoutRightPanel.ScrollArea>
        </Box>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
