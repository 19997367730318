import { ReactNode, useMemo } from 'react';
import { Box, Skeleton } from '@mui/material';
import { formatDateString } from '@front/helper';
import {
  StreakFireBW as StreakFireBWIcon,
  StreakFireColor as StreakFireColorIcon,
} from '@front/icon';
import { IaStreakViewSlug, useIaStreaks } from '@lib/web/apis';
import { useCurrentExam, useIaClubStatus } from '@lib/web/hooks';
import {
  addDays,
  endOfMonth,
  getDay,
  getDaysInMonth,
  isBefore,
  isSameDay,
  parseISO,
  startOfDay,
  startOfMonth,
} from 'date-fns';

import CalendarItem, { RenderDataItemParams } from './CalendarItem';

type StreakDataProps = {
  date: Date;
  selectedDate?: Date;
  onDateClick?: (date: Date) => void;
  selectedStyle?: 'todayAndSelected' | 'onlySelected';
  clubSlug?: string;
};

const styles = {
  skeleton: {
    height: 48,
    borderRadius: 1,
  },
  barWrap: {
    position: 'relative',
    height: 27,
    width: 14,
  },
  bar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,

    borderTopLeftRadius: 0.5,
    borderTopRightRadius: 0.5,
  },
  noStreak: {
    opacity: 0.3,
  },
};

type DataItem = {
  date: Date;
  value?: GetIaCalendarRhsStreakDefaultRes;
};

const renderDateItem: (
  ev: RenderDataItemParams<DataItem>,
  minDate?: Date
) => ReactNode = ({ item, sameMonth, date }, minDate) => {
  if (!sameMonth) return null;
  if (minDate && isBefore(date, startOfDay(minDate))) return null;

  const hasStreak = item.value && item.value.streakCount > 0;
  return (
    <Box sx={[!hasStreak && styles.noStreak]}>
      {hasStreak && <StreakFireColorIcon width={28} height={28} />}
      {!hasStreak && <StreakFireBWIcon width={28} height={28} />}
    </Box>
  );
};

export default function StreakData({
  selectedDate,
  date,
  onDateClick,
  selectedStyle,
  clubSlug,
}: StreakDataProps) {
  const { sectionId } = useCurrentExam(clubSlug);
  const startDate = formatDateString(startOfMonth(date));
  const endDate = formatDateString(endOfMonth(date));

  const { dataset, isLoading } = useIaStreaks({
    search: `sectionId:${sectionId};streakDate:${startDate},${endDate}`,
    searchFields: 'sectionId:eq;streakDate:between',
    viewSlug: IaStreakViewSlug.CalendarRhsStreakDefault,
  });
  const { club } = useIaClubStatus(clubSlug);
  const joinedDate = club?.joinedAt ? new Date(club.joinedAt) : undefined;

  const items = useMemo(() => {
    if (isLoading) return undefined;

    const daysInMonth = getDaysInMonth(date);
    const firstDate = startOfMonth(date);
    const daysOfPrevMonth = getDay(firstDate) - 1;
    const results = [];
    for (let i = daysOfPrevMonth; i > 0; i--) {
      const itemDate = addDays(firstDate, -i);
      results.push({
        date: itemDate,
        value: undefined,
      });
    }
    for (let i = 0; i < daysInMonth; i++) {
      const itemDate = addDays(firstDate, i);
      const value = dataset.find((item) =>
        isSameDay(parseISO(item.streakDate), itemDate)
      );
      results.push({
        date: itemDate,
        value,
      });
    }
    return results;
  }, [dataset, date, isLoading]);

  return (
    <>
      {!items && (
        <>
          <Skeleton sx={styles.skeleton} variant="rectangular" />
          <Skeleton sx={styles.skeleton} variant="rectangular" />
          <Skeleton sx={styles.skeleton} variant="rectangular" />
          <Skeleton sx={styles.skeleton} variant="rectangular" />
          <Skeleton sx={styles.skeleton} variant="rectangular" />
          <Skeleton sx={styles.skeleton} variant="rectangular" />
          <Skeleton sx={styles.skeleton} variant="rectangular" />
        </>
      )}
      {items?.map((item) => {
        return (
          <CalendarItem
            key={formatDateString(item.date)}
            item={item}
            date={date}
            itemDate={item.date}
            selectedDate={selectedDate}
            selectedStyle={selectedStyle}
            renderDateItem={(e) => renderDateItem(e, joinedDate)}
            onDateClick={onDateClick}
            disabled
          />
        );
      })}
    </>
  );
}
