import { useTranslation } from 'react-i18next';
import {
  ActionClear as ActionClearIcon,
  NFTFavorite as NFTFavoriteIcon,
} from '@front/icon';
import { BackIconType, BaseLayoutRightPanel, TipButton } from '@front/ui';

import EmojiPicker, { EmojiPickerProps } from './EmojiPicker';

const styles = {
  toolbarButton: {
    marginLeft: 'auto',
  },
};

type EmojiPickerPanelProps = EmojiPickerProps & {
  title?: string;
  backIcon?: BackIconType;
  onIconClick?: () => void;
  onClearEmoji?: () => void;
};
export default function EmojiPickerPanel({
  title = 'Add Emoji',
  backIcon,
  onIconClick,
  onClearEmoji,
  ...rest
}: EmojiPickerPanelProps) {
  const { t } = useTranslation();
  const { value: emojiValue } = rest;

  const toolComponent = (
    <TipButton
      title={t('toolbar.Clear')}
      sx={styles.toolbarButton}
      onClick={onClearEmoji}
      disabled={!emojiValue}
    >
      <ActionClearIcon />
    </TipButton>
  );
  return (
    <BaseLayoutRightPanel
      titleIcon={<NFTFavoriteIcon width="16" height="16" />}
      title={title}
      backIcon={backIcon}
      onIconClick={onIconClick}
      toolComponent={toolComponent}
    >
      <EmojiPicker {...rest} />
    </BaseLayoutRightPanel>
  );
}
