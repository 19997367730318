import { useCallback, useContext, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import useThreadRender from '@app/web/src/widgets/ThreadPage/hooks/useThreadRender';
import { BaseLayoutRightPanel, Scrollbar, useBaseRightPanel } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { useAuth } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import { ThreadComposerProvider } from '@lib/web/thread/contexts/threadComposerContext';
import { ThreadComposerCustomContext } from '@lib/web/thread/contexts/threadComposerCustomContext';
import { useCreateNewChannelWithMessage } from '@lib/web/thread/hooks/channel/useCreateNewChannelWithMessage';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useThreadComposer } from '@lib/web/thread/hooks/core/useThreadComposer';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { useLocationThreadViews } from '@lib/web/thread/hooks/views/useLocationThreadViews';
import ThreadComposer from '@lib/web/thread/ThreadComposer';
import { ThreadViewProperties } from '@lib/web/thread/ThreadProperties';
import { AgentMaterial } from '@lib/web/thread/types';

import { useAskForHelpAgentMaterials } from './hooks/useAskForHelpAgentMaterials';
import { useAskForHelpAvailableActions } from './hooks/useAskForHelpAvailableActions';
import { useAskForHelpMessagesConfig } from './hooks/useAskForHelpMessagesConfig';

const styles = {
  panel: {
    '& .bottom-action': {
      p: 0,
    },
  },
  container: {
    display: 'grid',
    gridTemplateRows: '1fr max-content',
    height: '100%',
  },
  messageList: {
    position: 'relative',
    '& > [data-simplebar]': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    '& .simplebar-wrapper': {
      position: 'relative',
      height: '100%',
    },
    '& .simplebar-content-wrapper': {
      height: '100%',
    },
    '& .simplebar-content': {
      minHeight: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
};

function ThreadCreatorFromAskForHelpPanelContent({
  threadMemberIds,
}: {
  threadMemberIds: string[];
}) {
  const { resetThreadChannelSortFilter } = useThread();
  const { openRightPanel, rightPanelHistory, rightPanelOpened } =
    useBaseRightPanel<GlobalPanelParams>();
  const { view } = useLocationThreadViews();
  const { channelData } = useThreadViewDetail(view);
  const { text, setText, sendPublicly, aiActionState } = useThreadComposer();

  const { createNewChannelWithMessage } = useCreateNewChannelWithMessage();
  const { getThreadComposerCustomProps } = useContext(
    ThreadComposerCustomContext
  );
  const { getAskForHelpAgentMaterials } = useAskForHelpAgentMaterials();

  const { isBlocking, blockerTip } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittingChipId, setSubmittingChipId] = useState<string | null>(null);

  const submitMessage = useCallback(
    async ({
      blocks,
      agentMaterials,
    }: {
      blocks: ComposerBlock[];
      agentMaterials?: AgentMaterial[];
    }) => {
      setIsSubmitting(true);
      setText('');

      const askForHelpAgentMaterials = getAskForHelpAgentMaterials();

      const newChannelResult = await createNewChannelWithMessage({
        message: text,
        blocks: blocks ?? [],
        sendPublicly,
        channelData: {
          ...channelData,
          members: threadMemberIds,
          internalTag: 'ask-for-help',
        },
        agentId: aiActionState.selectedAgentId,
        agentMaterials: [
          ...askForHelpAgentMaterials,
          ...(agentMaterials ?? []),
        ],
      });

      setIsSubmitting(false);

      if (!newChannelResult) return;

      resetThreadChannelSortFilter.current();

      rightPanelHistory.back();

      openRightPanel(GlobalPanelKeys.GlobalThreadChat, {
        channelCid: newChannelResult.newChannel.cid,
      });
    },
    [
      aiActionState.selectedAgentId,
      channelData,
      createNewChannelWithMessage,
      getAskForHelpAgentMaterials,
      openRightPanel,
      resetThreadChannelSortFilter,
      rightPanelHistory,
      sendPublicly,
      setText,
      text,
      threadMemberIds,
    ]
  );

  const handleSubmit = useCallback(
    (props: { blocks: ComposerBlock[] }) => {
      submitMessage(props);
    },
    [submitMessage]
  );

  const renders = useThreadRender();
  const config = useAskForHelpMessagesConfig({
    memberIds: threadMemberIds,
    isSubmitting,
    submittingChipId,
  });
  const availableActions = useAskForHelpAvailableActions({
    submitMessage,
    setSubmittingChipId,
  });
  const composerDisabled = isBlocking || isSubmitting;

  return (
    <BaseLayoutRightPanel
      resizable
      sx={styles.panel}
      actionComponent={
        rightPanelOpened && (
          <ThreadComposer
            autoFocus
            disabled={composerDisabled}
            disabledTip={blockerTip}
            onChange={setText}
            onSubmit={handleSubmit}
            {...getThreadComposerCustomProps({ view })}
          />
        )
      }
    >
      <ThreadViewProperties
        view={view}
        mode="expandableBrief"
        customMemberIds={threadMemberIds}
      />

      <Box sx={styles.container}>
        <Box sx={styles.messageList} className="thread-chat-message-list">
          <Scrollbar>
            <IaRenderContextProvider value={renders}>
              <IaActionContextProvider availableActions={availableActions}>
                <IaLayouts layouts={config} />
              </IaActionContextProvider>
            </IaRenderContextProvider>
          </Scrollbar>
        </Box>
      </Box>
    </BaseLayoutRightPanel>
  );
}

export default function ThreadCreatorFromAskForHelpPanel() {
  const [text, setText] = useState('');
  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();
  const { askForHelpMemberIds } = getRightParams(
    GlobalPanelKeys.GlobalThreadCreatorFromAskForHelpPanel
  );
  const { view } = useLocationThreadViews();
  const { viewMemberIds } = useThreadViewDetail(view);
  const threadMemberIds = useMemo(
    () => [...viewMemberIds, ...askForHelpMemberIds],
    [viewMemberIds, askForHelpMemberIds]
  );

  return (
    <ThreadComposerProvider
      text={text}
      setText={setText}
      sendPubliclyCheckboxEnable={false}
      sendPubliclyDefault={false}
      threadMemberIds={threadMemberIds}
    >
      <ThreadCreatorFromAskForHelpPanelContent
        threadMemberIds={threadMemberIds}
      />
    </ThreadComposerProvider>
  );
}
