import { ReactNode } from 'react';
import { Box, Theme } from '@mui/material';
import { Title } from '@lib/web/ui';

const styles = {
  root: {
    display: 'grid',
    gap: 2,
    gridTemplateRows: 'min-content 1fr',
    mt: 3,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  main: {
    display: 'grid',
  },
  action: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  }),
};

type ProfileSectionProps = {
  title: string;
  children: ReactNode;
  actionComponent?: ReactNode;
  visible?: boolean;
};

export default function ProfileSection({
  title,
  children,
  actionComponent,
  visible,
}: ProfileSectionProps) {
  if (!visible) return null;
  return (
    <Box sx={styles.root}>
      <Box sx={styles.title}>
        <Title variant="appH3">{title}</Title>
        {Boolean(actionComponent) && (
          <Box sx={styles.action}>{actionComponent}</Box>
        )}
      </Box>
      <Box sx={[styles.main]}>{children}</Box>
    </Box>
  );
}
