import { useEffect, useMemo, useRef } from 'react';
import Router, { useRouter } from 'next/router';
import { useLatestValueRef } from '@front/helper';
import { useClearRoute } from '@lib/web/hooks';

import { FilterChipItem } from '../types';

const FILTER_QUERY_PARAM_NAME = 'filter';

type Options = {
  enabled?: boolean;
  activeFilters?: FilterChipItem[];
  chipItems?: FilterChipItem[];
  isLoading?: boolean;
  actions: {
    resetFilter: () => void;
    toggleFilter: (item: FilterChipItem) => void;
    completeInit: () => void;
  };
};

export default function useFilterChipsRouterInteractive({
  enabled = false,
  activeFilters = [],
  isLoading,
  chipItems = [],
  actions,
}: Options) {
  const { clearRouteParams } = useClearRoute();
  const { pathname, query } = useRouter();
  const loaded = useRef(false);

  const activeFiltersRef = useLatestValueRef(activeFilters);

  const applyFilterChangeToRouter = (item?: FilterChipItem) => {
    if (!item) {
      clearRouteParams([FILTER_QUERY_PARAM_NAME]);
      return;
    }
    Router.push(
      { pathname, query: { ...query, [FILTER_QUERY_PARAM_NAME]: item.id } },
      undefined,
      {
        shallow: true,
      }
    );
  };

  const isFilterQueryMatched = (
    filters: FilterChipItem[],
    targetFilterId?: string
  ) => {
    if (!targetFilterId) return filters.length === 0;
    return filters.some((item) => item.id === targetFilterId);
  };

  const urlQueryFilter = (query.filter as string) || undefined;

  /**
   * if enabled, check the filter query parameter and the currently active filter
   * if they are out of sync => will take the filter query parameter and apply it to become the active filter
   */
  useEffect(() => {
    if (isLoading) return;
    setTimeout(() => {
      if (!enabled) {
        actions.completeInit();
        return;
      }
      loaded.current = true;

      const isMatched = isFilterQueryMatched(
        activeFiltersRef.current,
        urlQueryFilter
      );

      if (isMatched) {
        actions.completeInit();
        return;
      }

      if (!urlQueryFilter) {
        actions.resetFilter();
        actions.completeInit();
        return;
      }

      const filterItem = chipItems.find((item) => item.id === urlQueryFilter);
      if (filterItem) {
        actions.toggleFilter(filterItem);
        actions.completeInit();
      } else {
        // if has no chip is matched => that means the filter query is invalid
        // should clear it out
        applyFilterChangeToRouter();
        actions.completeInit();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlQueryFilter, isLoading]);

  /**
   * if enabled then whenever the current filter is changed => it should be applied to the URL as a query param
   */
  const activeFilterIds = activeFilters.map((item) => item.id).join('-');
  useEffect(() => {
    if (!enabled || !loaded.current) return;

    if (activeFiltersRef.current.length === 0) {
      applyFilterChangeToRouter();
      return;
    }
    const filter = activeFiltersRef.current[0];
    applyFilterChangeToRouter(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilterIds]);

  const isRouterSynced = useMemo(() => {
    if (!enabled) return true;
    // if loaded => no need to check it in real-time => to avoid delays and UI looks does not smooth
    if (loaded.current) return true;
    return isFilterQueryMatched(activeFilters, urlQueryFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlQueryFilter, activeFilterIds]);

  return {
    isRouterSynced,
  };
}
