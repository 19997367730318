import { useRef } from 'react';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { ProfilePlaylist as ProfilePlaylistIcon } from '@front/icon';
import { BaseLayoutRightPanel, useBaseRightPanel } from '@front/ui';
import { useCurrentExam } from '@lib/web/hooks';

import RedoCreateQuizPanels from '../../PlaylistDetailPage/PlaylistDetail/components/ActionsToolbar/RedoCreateQuizPanels';
import { PlaylistDetailProvider } from '../../PlaylistDetailPage/PlaylistDetail/context';
import usePlaylistSummary from '../../PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistSummary';
import FilterResponsivePanel from '../FilterResponsivePanel';

import ActionsToolbar from './components/ActionsToolbar/ActionsToolbar';
import PlaylistQuestions from './components/PlaylistQuestions';
import PlaylistTitle from './components/PlaylistTitle';
import Toolbars from './components/Toolbars';
import usePlaylistDetailRightPanelParams from './hooks/usePlaylistDetailRightPanelParams';
import usePlaylistTitle from './hooks/usePlaylistTitle';
import useSortFilterConfig from './hooks/useSortFilterConfig';

export const FILTER_SORT_SCOPE = 'playlist-details-rhs';

function PlaylistDetailPanel() {
  void useSortFilterConfig();

  const filterButtonRef = useRef<HTMLButtonElement>(null);

  const { getRightParams, setRightParams, rightPanelTarget } =
    useBaseRightPanel<GlobalPanelParams>();
  const { filterOrSortOpened } = getRightParams(
    GlobalPanelKeys.GlobalPlaylistDetail
  );

  const params = usePlaylistDetailRightPanelParams();
  const { tag } = params;

  const { data } = usePlaylistSummary();
  const playlistTitle = usePlaylistTitle();

  return (
    <>
      <BaseLayoutRightPanel
        titleIcon={!tag && <ProfilePlaylistIcon width="16" height="16" />}
        title={playlistTitle}
        maximizeWidth
        toolComponent={
          <Toolbars
            scope={FILTER_SORT_SCOPE}
            filterButtonRef={filterButtonRef}
          />
        }
        inactive={rightPanelTarget !== GlobalPanelKeys.GlobalPlaylistDetail}
      >
        <BaseLayoutRightPanel.ScrollArea>
          <PlaylistTitle />
          <ActionsToolbar />
          <PlaylistQuestions />
        </BaseLayoutRightPanel.ScrollArea>
      </BaseLayoutRightPanel>
      <FilterResponsivePanel
        scope={FILTER_SORT_SCOPE}
        anchorEl={filterButtonRef.current}
        open={filterOrSortOpened === 'filter'}
        onClose={() => setRightParams({ ...params, filterOrSortOpened: null })}
      />
      {data && (
        <RedoCreateQuizPanels
          panelKeyPrefix={GlobalPanelKeys.GlobalPlaylistDetail}
          selectedTag={tag?.tagCode}
        />
      )}
    </>
  );
}

export default function PlaylistDetailPanelRoot() {
  const { quizId, roundNo, tag, dynamicType } =
    usePlaylistDetailRightPanelParams();
  const { sectionId } = useCurrentExam();

  return (
    <PlaylistDetailProvider
      quizId={quizId}
      roundNo={roundNo}
      tagId={tag?.tagId}
      // dynamic
      dynamicType={dynamicType}
      sectionId={sectionId || undefined}
      markId={tag?.tagId}
    >
      <PlaylistDetailPanel />
    </PlaylistDetailProvider>
  );
}
