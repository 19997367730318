import { useRef } from 'react';
import { Box, Chip } from '@mui/material';
import {
  ActionClose as ActionCloseIcon,
  OtherError as OtherErrorIcon,
  OtherLoading as OtherLoadingIcon,
} from '@front/icon';
import { ResponsiveTooltip, ScrollHorizontalContainer } from '@front/ui';

import { ChipOption } from './types';

const styles = {
  container: {
    display: 'flex',
  },
  chips: {
    px: { xs: 2.5, md: '12px' },
    display: 'flex',
    gap: 1,
  },
};

export default function Chips<V>({
  options,
  value,
  unselectedValue,
  error = null,
  isLoading = false,
  onChange,
  clearTitle,
  clearTip,
}: {
  options: ChipOption<V>[];
  value: V;
  unselectedValue: V;
  onChange?: (value: V) => void;
  isLoading?: boolean;
  error?: any;
  clearTitle: string;
  clearTip: string;
}) {
  const scrollRef = useRef<HTMLDivElement>(null);
  return (
    <ScrollHorizontalContainer scrollableNodeProps={{ ref: scrollRef }}>
      <Box sx={styles.chips}>
        {(value !== unselectedValue ||
          (value === unselectedValue && (isLoading || !!error))) && (
          <ResponsiveTooltip
            titleIcon={<ActionCloseIcon width={16} height={16} />}
            title={clearTitle}
            content={clearTip}
            tooltipProps={{ followCursor: true }}
          >
            <Chip
              className={`chip-${unselectedValue}`}
              label={
                <Box display="flex" alignItems="center" justifyContent="center">
                  {error ? (
                    <OtherErrorIcon width={16} height={16} />
                  ) : (
                    <ActionCloseIcon width={16} height={16} />
                  )}
                </Box>
              }
              variant={value === unselectedValue ? 'filled' : 'outlined'}
              color={error && value === unselectedValue ? 'error' : 'default'}
              onClick={() => onChange?.(unselectedValue)}
            />
          </ResponsiveTooltip>
        )}

        {options.map((chip) => (
          <ResponsiveTooltip
            key={String(chip.value)}
            titleIcon={chip.icon}
            title={chip.display}
            content={chip.tip}
            tooltipProps={{ followCursor: true }}
          >
            <Chip
              className={`chip-${chip.value}`}
              data-testid={`${chip.value}-chip`}
              icon={
                error && value === chip.value ? (
                  <OtherErrorIcon width={16} height={16} />
                ) : isLoading ? (
                  <OtherLoadingIcon width={16} height={16} />
                ) : (
                  chip.icon
                )
              }
              label={chip.display}
              variant={value === chip.value ? 'filled' : 'outlined'}
              color={error && value === chip.value ? 'error' : 'default'}
              onClick={() => onChange?.(chip.value)}
            />
          </ResponsiveTooltip>
        ))}
      </Box>
    </ScrollHorizontalContainer>
  );
}
