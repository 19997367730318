import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { ThreadsThreadView as ThreadsThreadViewIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  BaseLayoutScrollContainer,
  Button,
  useBaseRightPanel,
} from '@front/ui';

import ThreadSearchUsers from '../../../components/ThreadSearchUsers';
import { ThreadSearchUserIconListLayoutItemObj } from '../../../components/ThreadSearchUsers/types';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    '.search-bar': {
      px: 1.5,
    },
  },
  scroll: {
    px: 0,
  },
};

export default function AskForHelpPanel() {
  const { t } = useTranslation('quiz');
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const [selected, setSelected] = useState<
    ThreadSearchUserIconListLayoutItemObj[]
  >([]);

  const handleActionClick = async () => {
    openRightPanel(GlobalPanelKeys.GlobalThreadCreatorFromAskForHelpPanel, {
      askForHelpMemberIds: selected.flatMap(
        (item) => item.metadata?.threadMemberIds || []
      ),
    });
  };

  return (
    <BaseLayoutRightPanel
      titleIcon={<ThreadsThreadViewIcon width={16} height={16} />}
      title={t('panel.askForHelp.title')}
      resizable
      actionComponent={
        <Button onClick={handleActionClick} disabled={selected.length === 0}>
          {t('button.AskForHelp')} ({selected.length})
        </Button>
      }
    >
      <Box sx={styles.main}>
        <BaseLayoutScrollContainer fullWidth sx={styles.scroll}>
          <ThreadSearchUsers
            sx={styles.content}
            selected={selected}
            setSelected={setSelected}
            enableSearchThreadGroups={false}
            enableSearchClubHelpAgent
            searchPlaceholder={t('askForHelp.searchPlaceholder')}
          />
        </BaseLayoutScrollContainer>
      </Box>
    </BaseLayoutRightPanel>
  );
}
