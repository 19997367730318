import { Theme, useMediaQuery } from '@mui/material';

import { useFilter } from './useFilter';
import { useSort } from './useSort';

export const useToolbarMoreButtonDot = (
  scope = 'global',
  position: 'centre' | 'rhs' = 'centre'
) => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { hasFilter } = useFilter(scope);
  const { hasSort } = useSort(scope);

  const filterAndSortAreInsideMoreButton = mdDown || position === 'rhs';

  return {
    moreButtonDot: filterAndSortAreInsideMoreButton && (hasFilter || hasSort),
  };
};
