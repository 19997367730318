import RedoButton from '../PlaylistDetail/components/ActionsToolbar/RedoButton';
import RedoCreateQuizPanels from '../PlaylistDetail/components/ActionsToolbar/RedoCreateQuizPanels';
import { PlaylistDetailProvider } from '../PlaylistDetail/context';

type RedoButtonRootProps =
  | {
      quizId?: string;
      roundNo: number;
      providerExcluded?: never;
      clubSlug?: string;
      panelKeyPrefix?: string;
    }
  | {
      providerExcluded: true;
      panelKeyPrefix: string;
      clubSlug?: string;
    };

export default function RedoButtonRoot(props: RedoButtonRootProps) {
  /**
   * in case of the button is already inside the PlaylistDetailProvider
   */
  if (props.providerExcluded) {
    return (
      <RedoButton
        panelKeyPrefix={props.panelKeyPrefix}
        clubSlug={props.clubSlug}
      />
    );
  }

  const { quizId, roundNo, clubSlug, panelKeyPrefix } = props;
  return (
    <PlaylistDetailProvider quizId={quizId} roundNo={roundNo}>
      <RedoButton panelKeyPrefix={panelKeyPrefix} clubSlug={clubSlug} />
      <RedoCreateQuizPanels
        panelKeyPrefix={panelKeyPrefix}
        clubSlug={clubSlug}
      />
    </PlaylistDetailProvider>
  );
}
