import { useCallback, useContext, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Theme, useMediaQuery } from '@mui/material';
import useAhaInvitation from '@app/web/src/hooks/utils/useAhaInvitation';
import useFloatingProfile from '@app/web/src/hooks/utils/useFloatingProfile';
import { getSearchState, SearchState } from '@app/web/src/utils/search';
import useOpenGlobalProfilePanel from '@app/web/src/widgets/CommonPanels/hooks/useOpenGlobalProfilePanel';
import { useLatestValueRef } from '@front/helper';
import { OtherFriendsChallenge as OtherFriendsChallengeIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  LightbulbCard,
  SearchBar,
  SquareAvatar,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import {
  IconListLayoutItemHoverEvent,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';
import {
  apis,
  buildInfiniteHookMutate,
  MemberSearchCategory,
  useMembersByCategory,
  useSearchClubMembers,
} from '@lib/web/apis';
import {
  useClubSlug,
  useInfiniteScroll,
  useSearchStatus,
} from '@lib/web/hooks';
import { useFloatingProfileClick } from '@lib/web/ui';
import { call, emailRegex, getMemberIndicator } from '@lib/web/utils';

import CreateQuizContext from '../../../context';
import useClosePanel from '../../../hooks/useClosePanel';
import {
  ChallengerIconListLayoutItemObj,
  CreateQuizFormValue,
} from '../../../type';
import getActiveQuizButtonEl from '../../../utils/getActiveQuizButtonEl';
import useAddChallenger from '../CreateQuizChallengersPanel/useAddChallenger';

import useAddFriendsLayoutConfig from './useAddFriendsLayoutConfig';
import useAddFriendsToDataset from './useAddFriendsToDataset';

const styles = {
  tips: {
    px: { xs: 2.5, md: '12px' },
  },
};

export default function CreateQuizAddFriendsPanel() {
  const { t } = useTranslation('quiz');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { rightPanelOpened, rightPanelHistory } = useBaseRightPanel();

  const { openProfile, defaultSearch } = useOpenGlobalProfilePanel();
  const [{ disabledParts, clubSlug: providedClubSlug }] =
    useContext(CreateQuizContext);
  const { watch, setValue } = useFormContext<CreateQuizFormValue>();
  const searchMode = watch(
    'memberSearchCategory',
    MemberSearchCategory.Followings
  );

  const disabled =
    disabledParts.includes('all') ||
    disabledParts.includes('challenge') ||
    disabledParts.includes('challenge-exclude-add-challengers');

  const routerClubSlug = useClubSlug();
  const clubSlug = providedClubSlug || routerClubSlug;

  const {
    focused,
    search,
    isSearching,
    debouncedSearch,
    onChange: onSearchChange,
    onBlur,
    onFocus,
  } = useSearchStatus(defaultSearch);

  const selected = watch('challengers', []);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const handleClosePanel = useClosePanel();
  const { pendingEmails, invitedHistory, inviteToAha, newUserFormatter } =
    useAhaInvitation();
  const { showUserIdProfile } = useFloatingProfile();

  const searchData = useSearchClubMembers({
    clubSlug,
    keyword: debouncedSearch || undefined,
    isGetRole: true,
    isExcludeMe: true,
  });

  const topScoreMembers = useMembersByCategory({
    clubSlug,
    category: MemberSearchCategory.TopScore,
  });

  const listState = getSearchState({
    isSearching,
    isFocused: focused,
    hasSelected: selected.length > 0,
  });

  const {
    dataset: searchDataset,
    isLoading: searchLoading,
    totalCount: searchTotalCount,
    mutate: searchMutate,
  } = searchData;

  const {
    dataset: topScoreMembersDataset,
    totalCount: topScoreMembersTotalCount,
    mutate: topScoreMembersMutate,
  } = topScoreMembers;

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: searchData,
    enabled: !disabled && rightPanelOpened && isSearching,
    dependencies: [searchMode],
  });

  const displayDataset = useAddFriendsToDataset({
    listState,
    topScorerDataset: topScoreMembersDataset,
    searchDataset: searchDataset,
    selectedIds: selected,
  });

  const newUser = useMemo(() => {
    const email =
      isSearching &&
      !searchDataset.find((d) => d.email === debouncedSearch) &&
      !searchLoading &&
      emailRegex.test(debouncedSearch)
        ? debouncedSearch
        : '';
    return newUserFormatter(email);
  }, [
    debouncedSearch,
    isSearching,
    newUserFormatter,
    searchDataset,
    searchLoading,
  ]);

  const dataset =
    listState === SearchState.Initial ? topScoreMembersDataset : searchDataset;
  const totalCount =
    listState === SearchState.Initial
      ? topScoreMembersTotalCount
      : searchTotalCount;
  const mutate =
    listState === SearchState.Initial ? topScoreMembersMutate : searchMutate;

  const selectUser = (_: any, method: 'add' | 'remove') => {
    if (method === 'add') {
      setValue('memberSearchCategory', MemberSearchCategory.NoSelect);
      rightPanelHistory.back();
    }
  };

  const isSelected = useCallback(
    (id: string) => {
      return selected.some((challengerId) => challengerId === id);
    },
    [selected]
  );

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );

  const handleToggleUser = useAddChallenger({
    isSelected,
    onToggleUser: selectUser,
  });

  const reloadMembers = useMemo(
    () => buildInfiniteHookMutate(mutate as any),
    [mutate]
  );

  const availableActions = {
    selectChanged: {
      action: (value: ChallengerIconListLayoutItemObj) => {
        handleToggleUser({
          id: value.id,
          display: value.title,
          avatar: value.avatarUrl,

          memberInfo: {
            userId: value.id,
            avatar: String(value.avatarUrl),
            displayName: value.title,
            distinctName: value.metadata?.userName || '',
            nftAvatar: String(value.avatarUrl),
            indicator: getMemberIndicator(value?.indicators),
          },
        });
      },
    },
    userHovered: {
      action: updateSuggestItem,
    },
    inviteToAha: {
      action: async (value: ChallengerIconListLayoutItemObj) => {
        inviteToAha([value.id], {
          toastOptions: { anchorEl: getActiveQuizButtonEl() },
        });
      },
    },
    titleClick: {
      action: (value: IconListLayoutItemObj) => {
        openProfile(value.id, search);
      },
    },
    titleHover: {
      action: (event: IconListLayoutItemHoverEvent<{ userId: string }>) => {
        if (!event.target.metadata) return;
        showUserIdProfile({
          anchorEl: event.anchorEl,
          userId: event.target.metadata.userId,
        });
      },
    },
    avatarHover: {
      action: (event: IconListLayoutItemHoverEvent<{ userId: string }>) => {
        if (!event.target.metadata) return;
        showUserIdProfile({
          anchorEl: event.anchorEl,
          userId: event.target.metadata.userId,
          options: {
            spacing: 8,
          },
        });
      },
    },
    follow: {
      action: async ({ id }: { id: string }) => {
        searchInputRef.current?.focus();
        await call(
          reloadMembers(apis.member.followUser(id), {
            optimisticData: dataset.map((d) =>
              d.userId === id ? { ...d, isFollowing: true } : d
            ) as any,
            optimisticPageData: {
              totalCount,
            },
          })
        );
      },
    },
  };

  const isDisabled = (id: string) =>
    invitedHistory.some(
      (item) => item.receiver.email === id && !item.resendAble
    );

  const getItemStatus = ({ id }: ChallengerIconListLayoutItemObj) => {
    return {
      selected: isSelected(id),
      disabled: isDisabled(id),
      loading: pendingEmails.includes(id),
      focused: mdUp && suggestItem?.id === id,
    };
  };

  const focusedRef = useLatestValueRef(focused);
  void useFloatingProfileClick(() => {
    if (focusedRef.current) {
      searchInputRef.current?.focus();
    }
  });

  const configs = useAddFriendsLayoutConfig({
    displayDataset,
    totalCount,
    listState,
    newUser,
    isSelected,
    searchLoading,
    selectedIds: selected,
  });

  const showTip =
    selected.length === 0 && displayDataset.length === 0 && !isSearching;

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <BaseLayoutRightPanel
          titleIcon={<OtherFriendsChallengeIcon width="16" height="16" />}
          title={t('createQuiz.challengers.addFriend.title', 'Add Challengers')}
          onIconClick={handleClosePanel}
        >
          <BaseLayoutRightPanel.SearchWrapper>
            <BaseLayoutRightPanel.SearchInput>
              <SearchBar
                inputRef={searchInputRef}
                placeholder={t(
                  'createQuiz.challengers.addFriend.search.placeholder'
                )}
                value={search}
                loading={searchLoading}
                onChange={onSearchChange}
                onBlur={onBlur}
                onFocus={onFocus}
                suggestText={suggestItem?.title}
                prefixIcon={
                  suggestItem && (
                    <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                      {suggestItem.title}
                    </SquareAvatar>
                  )
                }
              />
            </BaseLayoutRightPanel.SearchInput>
            <BaseLayoutRightPanel.SearchContent>
              <BaseLayoutRightPanel.ScrollArea
                scrollableNodeProps={{ ref: scrollRef }}
              >
                {showTip && (
                  <Box sx={styles.tips}>
                    <LightbulbCard>
                      {t('createQuiz.challengers.addFriend.search.tip')}
                    </LightbulbCard>
                  </Box>
                )}

                {configs && <IaLayouts layouts={configs} />}
              </BaseLayoutRightPanel.ScrollArea>
            </BaseLayoutRightPanel.SearchContent>
          </BaseLayoutRightPanel.SearchWrapper>
        </BaseLayoutRightPanel>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
