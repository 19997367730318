import { PropsWithChildren, ReactNode, RefObject } from 'react';
import { Box } from '@mui/material';
import { Scrollbar } from '@front/ui';

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    mt: 1,
  },
  scrollable: {
    height: 'calc(100% - 48px)',
    position: 'relative',
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  list: {
    flex: 1,
    mt: 1,
    px: {
      xs: '20px',
      md: 0,
    },
  },
  empty: {
    width: '100%',
  },
};

function Header({ children }: PropsWithChildren) {
  return (
    <Box sx={styles.header} className="rankings-tab-area-header">
      {children}
    </Box>
  );
}

type EmptyStateProps = {
  visible?: boolean;
  children: ReactNode;
};

function EmptyState({ visible, children }: EmptyStateProps) {
  if (!visible) return null;
  return (
    <Box sx={styles.empty} className="rankings-tab-area-empty">
      {children}
    </Box>
  );
}

type ListProps = {
  visible?: boolean;
  children: ReactNode;
};

function RankingList({ visible, children }: ListProps) {
  if (!visible) return null;
  return (
    <Box sx={styles.list} className="rankings-tab-area-list">
      {children}
    </Box>
  );
}

type RankingTabAreaProps = {
  scrollable?: boolean;
  scrollRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
};

export default function RankingTabArea({
  scrollable,
  scrollRef,
  children,
}: RankingTabAreaProps) {
  if (scrollable) {
    return (
      <Box sx={[styles.root, styles.scrollable]} className="rankings-tab-area">
        <Scrollbar sx={styles.scroll} scrollableNodeProps={{ ref: scrollRef }}>
          {children}
        </Scrollbar>
      </Box>
    );
  }

  return <Box sx={styles.root}>{children}</Box>;
}

RankingTabArea.Header = Header;
RankingTabArea.EmptyState = EmptyState;
RankingTabArea.List = RankingList;
