import React from 'react';
import { Box } from '@mui/material';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import { ResponsiveToolbar } from '@lib/web/ui';

export default function ManageAgentsToolbar() {
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();

  return (
    <Box ml="auto">
      <ResponsiveToolbar
        items={[
          {
            type: 'Search',
            hiddenInMore: true,
            onClick: () =>
              openRightPanel(GlobalPanelKeys.GlobalManageAgentsSearch),
          },
        ]}
      />
    </Box>
  );
}
