import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { MainProfileSolid as MainProfileSolidIcon } from '@front/icon';
import { BaseLayoutRightPanel, useBaseLayout } from '@front/ui';

import Profile from './components/Profile';

export default function ProfilePanels() {
  const { t } = useTranslation('profile');
  const { rightPanelTarget, getRightParams } =
    useBaseLayout<GlobalPanelParams>();

  const { userId, memberId } = getRightParams(GlobalPanelKeys.GlobalProfile);

  const cacheUserInfoRef = useRef({ userId, memberId });
  cacheUserInfoRef.current = {
    userId: userId || cacheUserInfoRef.current.userId,
    memberId: memberId || cacheUserInfoRef.current.memberId,
  };

  return (
    <BaseLayoutRightPanel
      titleIcon={<MainProfileSolidIcon width="16" height="16" />}
      title={t('Profile')}
      inactive={rightPanelTarget !== GlobalPanelKeys.GlobalProfile}
      maximizeWidth
    >
      <Profile
        userId={cacheUserInfoRef.current.userId}
        memberId={cacheUserInfoRef.current.memberId}
      />
    </BaseLayoutRightPanel>
  );
}
