import { useState } from 'react';
import { alpha, Theme } from '@mui/material/styles';
import { OtherLink as OtherLinkIcon } from '@front/icon';
import {
  Button,
  ButtonProps,
  EmphasizeButton,
  EmphasizeButtonProps,
} from '@front/ui';
import { useCopyToClipboard } from '@lib/web/hooks';

type CopyLinkButtonProps = (
  | (ButtonProps & {
      emphasize?: false;
      link: string;
    })
  | (EmphasizeButtonProps & {
      emphasize: true;
      link: string;
    })
) & {
  activeVariant?: ButtonProps['variant'];
  successText?: string;
  onSuccess?: () => void;
};

const styles = {
  buttonActive: {
    opacity: 1,
    color: 'text.primary',
    background: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? theme.palette.success.light
        : theme.palette.success.dark,
    boxShadow: 'none',
    '&.is-disabled': {
      color: 'background.darker',
    },
  },
  buttonFilledActive: {
    color: 'background.darker',
    borderColor: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? theme.palette.success.light
        : theme.palette.success.dark,
    '&.is-disabled': {
      opacity: 1,
      color: 'background.darker',
    },
  },
  emphasizeButtonActive: {
    opacity: 1,
    '& .em-button-base': {
      border: 'none',
      bgcolor: (theme: Theme) =>
        theme.palette.mode === 'dark' ? 'success.light' : 'success.dark',
      color: 'background.darker',

      boxShadow: (theme: Theme) =>
        theme.palette.mode === 'dark'
          ? `inset 0 0 0 1px ${theme.palette.success.light}`
          : `inset 0 0 0 1px ${theme.palette.success.dark}`,
    },
    '& .em-button-shadow': {
      borderColor: (theme: Theme) =>
        theme.palette.mode === 'dark' ? 'success.light' : 'success.dark',
      background: (theme: Theme) =>
        theme.palette.mode === 'dark'
          ? alpha(theme.palette.success.light, 0.3)
          : alpha(theme.palette.success.dark, 0.3),
    },
  },
};
export default function CopyLinkButton({
  variant = 'outlined',
  activeVariant = 'outlined',
  disabled,
  sx,
  children = 'Copy Link',
  link,
  emphasize,
  successText = 'Link Copied!',
  prefixIcon,
  onSuccess,
  ...rest
}: CopyLinkButtonProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const [, copy] = useCopyToClipboard();
  const [copySuccessText, setCopySuccessText] = useState('');
  const handleCopy = async () => {
    await copy(link);
    setCopySuccessText(successText);
    onSuccess?.();

    setTimeout(() => {
      setCopySuccessText('');
    }, 1000);
  };

  const Comp = emphasize ? EmphasizeButton : Button;
  const activeStyle = emphasize
    ? styles.emphasizeButtonActive
    : styles.buttonActive;
  return (
    <Comp
      variant={copySuccessText ? activeVariant : variant}
      sx={[
        ...sxProps,
        !!copySuccessText && activeStyle,
        !!copySuccessText &&
          activeVariant === 'filled' &&
          styles.buttonFilledActive,
      ]}
      disabled={!link || !!copySuccessText}
      onClick={handleCopy}
      prefixIcon={!copySuccessText && (prefixIcon || <OtherLinkIcon />)}
      {...rest}
    >
      {copySuccessText || children}
    </Comp>
  );
}
