import { useMemo } from 'react';
import { useQuizQuota } from '@lib/web/apis';

export default function useMemberTrialQuota() {
  const { data: quizQuotaData } = useQuizQuota();

  return useMemo(() => {
    if (!quizQuotaData) {
      return {
        isLoadingTrialQuota: true,
        questionQuotaLeft: 0,
        isRunOutOfQuestionQuota: false,
        hasInfinityQuestionQuota: false,
      };
    }

    const { questionCount, maxQuestionCount } = quizQuotaData.data;
    const questionQuotaLeft = maxQuestionCount - questionCount;

    return {
      isLoadingTrialQuota: false,
      questionQuotaLeft,
      isRunOutOfQuestionQuota: questionQuotaLeft === 0,
      hasInfinityQuestionQuota: questionQuotaLeft === -1,
    };
  }, [quizQuotaData]);
}
