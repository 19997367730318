import React, { useEffect } from 'react';
import { Router } from 'next/router';
import { useBaseRightPanel } from '@front/ui';
import { PaidFeatureBlocker } from '@lib/web/ui';

import { usePaidFeatureBlocker } from './hooks/usePaidFeatureBlocker';

export default function PaidFeatureBlockerControl() {
  const { currentBlockerType, currentPlacement, closeBlocker } =
    usePaidFeatureBlocker();

  const { rightPanelOpened, rightPanelTarget } = useBaseRightPanel();

  useEffect(() => {
    const handleRouteChange = () => {
      if (currentBlockerType) {
        closeBlocker();
      }
    };
    Router.events.on('routeChangeComplete', handleRouteChange);
    Router.events.on('routeChangeError', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
      Router.events.off('routeChangeError', handleRouteChange);
    };
  }, [currentBlockerType, closeBlocker]);

  useEffect(() => {
    if (!rightPanelOpened) {
      closeBlocker();
    }
  }, [rightPanelOpened, closeBlocker]);

  useEffect(() => {
    closeBlocker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightPanelTarget]);

  if (!currentPlacement || !currentBlockerType) return;

  return (
    <PaidFeatureBlocker
      type={currentBlockerType}
      placement={currentPlacement}
      onClose={closeBlocker}
    />
  );
}
