import { ReactNode } from 'react';

import IaRenderContext, { IaRenderContextValue } from './IaRenderContext';

type IaRenderContextProviderProps = {
  children: ReactNode;
  value: IaRenderContextValue;
};

export default function IaRenderContextProvider({
  value,
  children,
}: IaRenderContextProviderProps) {
  return (
    <IaRenderContext.Provider value={value}>
      {children}
    </IaRenderContext.Provider>
  );
}
