import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Typography } from '@mui/material';
import { alpha, Theme } from '@mui/system';
import { useDateFormat } from '@front/helper';

import usePlaylistSummary from '../../../PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistSummary';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.5,
    whiteSpace: 'nowrap',
    '& .MuiTypography-caption': {
      position: 'relative',
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
      '&:not(:first-child):before': {
        content: '""',
        position: 'absolute',
        width: '4px',
        height: '4px',
        left: '-8px',
        top: '50%',
        transform: 'translateY(-2px)',
        borderRadius: 1,
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
      },
    },
  },
};

function PlaylistInfoSkeleton() {
  return <Skeleton height={16} width={200} />;
}

export default function PlaylistInfo() {
  const { t } = useTranslation('playlist');
  const { data } = usePlaylistSummary();
  const { displayDateFormat } = useDateFormat();

  if (!data) return <PlaylistInfoSkeleton />;

  const { questionCount, lastTakenAt } = data.data.quiz;

  return (
    <Box sx={styles.root}>
      <Typography variant="caption">
        {t('detail.info.question', { count: questionCount })}
      </Typography>
      <Typography variant="caption">
        {displayDateFormat(lastTakenAt)}
      </Typography>
    </Box>
  );
}
