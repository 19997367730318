import { useTranslation } from 'react-i18next';
import { alpha, Box, ButtonBase, Theme, Typography } from '@mui/material';
import {
  ActionChevronRightSmall as ActionChevronRightSmallIcon,
  ActionClear as ActionClearIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';
import { getPropertyIcon } from '@front/ui';

import Icon from '../../components/Icon';
import { FilterConditionSelectedEvent, FilterConfig } from '../types';
import { filterConditionToString, getOperatorConfig } from '../utils';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    pt: 1,
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    px: '12px',
  },
  itemRow: {
    display: 'block',
    width: '100%',
    minWidth: 0,
  },
  item: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 1,
    cursor: 'pointer',
    border: (theme: Theme) => `1px solid ${theme.palette.text.primary}`,
    borderRadius: '20px',
    pl: '12px',
    pr: '6px',
    py: '1.5px',
    maxWidth: '100%',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        background: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
    height: { xs: '27px', md: '24px' },
  },
  disabledItem: {
    opacity: 0.5,
  },
  itemInner: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    fontSize: { xs: 16, md: 14 },
    overflow: 'hidden',
  },
  itemLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    fontSize: { xs: 16, md: 14 },
  },
  label: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  value: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
    fontSize: { xs: 16, md: 14 },
  },

  buttonWrapper: {
    width: '100%',
  },
  button: {
    gap: 1,
    justifyContent: 'flex-start',
    width: '100%',
    px: '12px',
    py: '3.5px',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
};

type FilterQueryProps = FilterConfig & {
  onSelect?: (event: FilterConditionSelectedEvent) => void;
  onAddFilter?: () => void;
  onClearAll?: () => void;
};
export default function FilterQuery({
  conditions = [],
  onSelect,
  onAddFilter,
  onClearAll,
}: FilterQueryProps) {
  const { t } = useTranslation();
  return (
    <Box sx={styles.root}>
      <Box sx={styles.items}>
        {conditions.map((condition, index) => {
          if (!condition.operator) return null;
          const operatorConfig = getOperatorConfig(t, condition.operator);
          if (operatorConfig.operandCount > 1 && !condition.values) return null;

          const propertyIcon = condition.field.customIcon ? (
            <Icon name={condition.field.customIcon} width={16} height={16} />
          ) : (
            getPropertyIcon(condition.field.type, 16)
          );
          const valueStr = filterConditionToString(t, condition);
          return (
            <Box key={index} sx={styles.itemRow}>
              <ButtonBase
                sx={[styles.item, !!condition.disabled && styles.disabledItem]}
                disabled={condition.disabled}
                onClick={() => onSelect?.(condition)}
              >
                <Box sx={styles.itemInner}>
                  <Box sx={styles.itemLabel}>
                    {propertyIcon}
                    <Typography sx={styles.label} variant="body2">
                      {condition.field.label}:
                    </Typography>
                  </Box>
                  <Typography sx={styles.value}>{valueStr}</Typography>
                </Box>
                <ActionChevronRightSmallIcon width={16} height={16} />
              </ButtonBase>
            </Box>
          );
        })}
      </Box>
      <Box sx={styles.buttonWrapper}>
        {onClearAll && (
          <ButtonBase sx={styles.button} onClick={onClearAll}>
            <ActionClearIcon width={16} height={16} />
            <Typography fontSize={16} lineHeight="24px">
              {t('button.Clear all')}
            </Typography>
          </ButtonBase>
        )}
        <ButtonBase sx={styles.button} onClick={onAddFilter}>
          <TestAddIcon width={16} height={16} />
          <Typography fontSize={16} lineHeight="24px">
            {t('button.Add Filter')}
          </Typography>
        </ButtonBase>
      </Box>
    </Box>
  );
}
