import { useMemo } from 'react';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

import AgentMessage from './components/AgentMessage';
import CtaMessage from './components/CtaMessage';
import GeneralMessage from './components/GeneralMessage';
import SystemMessage from './components/SystemMessage';

export type ThreadMessageProps = {
  channel: Channel;
  message: StreamMessage<StreamChatGenerics>;
  messages: StreamMessage<StreamChatGenerics>[];
  childChannel?: Channel;
  availableEventNames?: string[];
};

export default function ThreadMessage({
  channel,
  message,
  messages,
  childChannel,
  availableEventNames,
}: ThreadMessageProps) {
  const isAgentMessage = useMemo<boolean>(() => {
    return !!message.user?.id.startsWith('agent_');
  }, [message.user?.id]);

  if (message.type === 'system') {
    return <SystemMessage message={message} />;
  }

  if (message.customCtaType) {
    return <CtaMessage channel={channel} message={message} />;
  }

  if (isAgentMessage) {
    return (
      <AgentMessage
        channel={channel}
        message={message}
        messages={messages}
        childChannel={childChannel}
        availableEventNames={availableEventNames}
      />
    );
  }

  return (
    <GeneralMessage
      channel={channel}
      message={message}
      messages={messages}
      childChannel={childChannel}
      availableEventNames={availableEventNames}
    />
  );
}
