import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import CopyLinkButton from '@app/web/src/components/CopyLinkButton';
import useMemberClubRole from '@app/web/src/hooks/utils/useMemberClubRole';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  OtherAddFriend as OtherAddFriendIcon,
  PrivacyFriends as PrivacyFriendsIcon,
} from '@front/icon';
import {
  BaseLayoutRightPanel,
  Button,
  Tab,
  Tabs,
  useBaseRightPanel,
} from '@front/ui';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import { apis, ClubMemberRole, useClubInvitationLink } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';
import { callWithToast } from '@lib/web/utils';

import AddFriendsTabContent from './components/AddFriendsTabContent';
import ClubMembersTabContent from './components/ClubMembersTabContent';

const styles = {
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    px: { xs: 2.5, md: '12px' },
    pb: 1,
  },
  action: {
    display: 'flex',
    gap: 2,
    '& button': {
      px: 0,
      flex: 1,
      minWidth: 'unset',
      whiteSpace: 'nowrap',
    },
  },
};

export default function AddFriendsPanel() {
  const { t } = useTranslation('club');
  const clubSlug = useClubSlug();
  const { data: invitationLinkRes } = useClubInvitationLink(clubSlug);
  const invitationLink = invitationLinkRes?.data.url || '';

  const { getRightParams, openRightPanel } =
    useBaseRightPanel<GlobalPanelParams>();
  const { activeTab, ...rightParams } = getRightParams(
    GlobalPanelKeys.GlobalAddFriends
  );
  const tab = activeTab || 0;

  const { hasEditAuth } = useMemberClubRole();
  const [selected, setSelected] = useState<IconListLayoutItemObj[]>([]);
  const [sending, setSending] = useState(false);

  const sentInvitation = async () => {
    setSending(true);
    const [res] = await callWithToast(
      () =>
        !hasEditAuth
          ? apis.club.sendClubInvitation({
              clubSlug,
              userIds: selected.map((item) => item.id),
              emails: [],
            })
          : apis.club.addClubMembers({
              clubSlug,
              members: selected.map((item) => ({
                userId: item.id,
                role: ClubMemberRole.Member,
              })),
            }),
      {
        anchorEl: document.querySelector(
          '[data-testid="invite-to-club-button"]'
        ) as Element,
      }
    );

    if (res) {
      setSelected([]);
    }
    setSending(false);
  };

  const handleTabChange = (index: number) => {
    openRightPanel(GlobalPanelKeys.GlobalAddFriends, {
      ...rightParams,
      activeTab: index,
    });
  };

  return (
    <BaseLayoutRightPanel
      titleIcon={<PrivacyFriendsIcon width="16" height="16" />}
      title={t('club.RHS.addFriends.title')}
      actionComponent={
        tab === 0 && (
          <Box sx={styles.action}>
            <CopyLinkButton
              data-testid="copy-link-button"
              link={invitationLink}
            >
              {t('club.RHS.addFriends.invite.link.label')}
            </CopyLinkButton>
            <Button
              data-testid="invite-to-club-button"
              disabled={!selected.length}
              loading={sending}
              onClick={sentInvitation}
            >
              {t('club.RHS.addFriends.invite.sent.label', {
                count: selected.length,
              })}
            </Button>
          </Box>
        )
      }
    >
      <Box sx={styles.main}>
        <Box sx={styles.tabs}>
          <Tabs
            textColor="inherit"
            variant="scrollable"
            value={tab}
            onChange={(_, val) => handleTabChange(val)}
          >
            <Tab
              label={t('club.RHS.addFriends.addFriendsTab.title')}
              prefixIcon={<OtherAddFriendIcon width="16" height="16" />}
            />
            <Tab
              label={t('club.RHS.addFriends.clubMembersTab.title')}
              prefixIcon={<PrivacyFriendsIcon width="16" height="16" />}
            />
          </Tabs>
        </Box>
        {tab === 0 && (
          <AddFriendsTabContent
            selected={selected}
            onSelectedChange={setSelected}
          />
        )}
        {tab === 1 && <ClubMembersTabContent />}
      </Box>
    </BaseLayoutRightPanel>
  );
}
