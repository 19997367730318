import { useTranslation } from 'react-i18next';
import { MainApps as MainAppsIcon } from '@front/icon';
import { BaseLayoutRightPanel, useBaseLayout } from '@front/ui';
import { useMemberInfo } from '@lib/web/apis';

import Clubs from './components/Clubs';

export default function FollowersPanel() {
  const { t } = useTranslation('profile');
  const { rightPanelParams } = useBaseLayout();
  const { userId } = rightPanelParams;
  const { data: userData } = useMemberInfo(userId);

  const displayName =
    userData?.data.displayName || userData?.data.distinctName || '';

  const userFirstName = displayName.split(' ')[0];

  return (
    <BaseLayoutRightPanel
      titleIcon={<MainAppsIcon width="16" height="16" />}
      title={t('##s Clubs', { name: userFirstName })}
    >
      <Clubs userId={userId} />
    </BaseLayoutRightPanel>
  );
}
