import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseLayout } from '@front/ui';

import SortCriteriaContent from './SortCriteriaContent';
import SortFieldsContent from './SortFieldsContent';
import SortSummaryContent from './SortSummaryContent';

export default function FilterPanelContent() {
  const { getRightParams } = useBaseLayout<GlobalPanelParams>();
  const { page } = getRightParams(GlobalPanelKeys.GlobalSort);

  if (!page || page === 'sortSummary') return <SortSummaryContent />;
  if (page === 'criteriaDetail') return <SortCriteriaContent />;
  if (page === 'fieldList') return <SortFieldsContent />;
}
