import { MutableRefObject, useContext, useEffect } from 'react';

import CreateQuizContext from '../context';
import { ChallengeEvents, CreateQuizDisableStatus } from '../enums';

export default function useWatchDisabledStatus(
  eventBusRef: MutableRefObject<EventTarget>
) {
  const [createQuizSettings] = useContext(CreateQuizContext);

  useEffect(() => {
    if (createQuizSettings.availableQuestionCount === undefined) {
      eventBusRef.current.dispatchEvent(
        new CustomEvent(ChallengeEvents.DisableStatusChange, {
          detail: CreateQuizDisableStatus.Loading,
        })
      );

      return;
    }
    if (createQuizSettings.availableQuestionCount === 0) {
      eventBusRef.current.dispatchEvent(
        new CustomEvent(ChallengeEvents.DisableStatusChange, {
          detail: CreateQuizDisableStatus.NoQuestions,
        })
      );

      return;
    }

    eventBusRef.current.dispatchEvent(
      new CustomEvent(ChallengeEvents.DisableStatusChange, {
        detail: null,
      })
    );
  }, [createQuizSettings.availableQuestionCount, eventBusRef]);
}
