import { useTranslation } from 'react-i18next';
import { alpha, Box, ButtonBase, Theme, Typography } from '@mui/material';
import {
  ActionChevronRightSmall as ActionChevronRightSmallIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';
import { getPropertyIcon, Icon } from '@front/ui';

import {
  SortConfig,
  SortCriteriaConfig,
  SortCriteriaSelectedEvent,
} from '../types';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    pt: 1,
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    px: '12px',
  },
  itemRow: {
    display: 'block',
    width: '100%',
    minWidth: 0,
  },
  item: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 1,
    cursor: 'pointer',
    border: (theme: Theme) => `1px solid ${theme.palette.text.primary}`,
    borderRadius: '20px',
    pl: '12px',
    pr: '6px',
    py: '1.5px',
    maxWidth: '100%',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        background: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
    height: { xs: '27px', md: '24px' },
  },
  disabledItem: {
    opacity: 0.5,
  },
  itemInner: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    fontSize: 14,
    overflow: 'hidden',
  },
  itemLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    fontSize: { xs: 16, md: 14 },
  },
  label: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  value: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
    fontSize: { xs: 16, md: 14 },
  },

  buttonWrapper: {
    width: '100%',
  },
  button: {
    gap: 1,
    justifyContent: 'flex-start',
    width: '100%',
    px: '12px',
    py: '3.5px',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
};

type SortSummaryProps = SortConfig & {
  onSelect?: (event: SortCriteriaSelectedEvent) => void;
  onAddSort?: () => void;
};
export default function SortSummary({
  criteria = [],
  onSelect,
  onAddSort,
}: SortSummaryProps) {
  const { t } = useTranslation();
  const getDirectionLabel = (item: SortCriteriaConfig) => {
    if (item.direction === 'asc') return t('sort.ascending.label');
    if (item.direction === 'desc') return t('sort.descending.label');
    return item.field.customDirectionOptions?.find(
      (option) => option.value === item.direction
    )?.label;
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.items}>
        {criteria.map((item, index) => {
          if (!item.direction || !item.field) return null;
          const propertyIcon = item.field.customIcon ? (
            <Icon name={item.field.customIcon} width={16} height={16} />
          ) : (
            getPropertyIcon(item.field.type, 16)
          );
          return (
            <Box key={index} sx={styles.itemRow}>
              <ButtonBase
                sx={[styles.item, !!item.disabled && styles.disabledItem]}
                onClick={() => onSelect?.(item)}
                disabled={item.disabled}
              >
                <Box sx={styles.itemInner}>
                  <Box sx={styles.itemLabel}>
                    {propertyIcon}
                    <Typography sx={styles.label} variant="body2">
                      {item.field.label}:
                    </Typography>
                  </Box>
                  <Typography sx={styles.value}>
                    {getDirectionLabel(item)}
                  </Typography>
                </Box>
                <ActionChevronRightSmallIcon width={16} height={16} />
              </ButtonBase>
            </Box>
          );
        })}
      </Box>
      <Box sx={styles.buttonWrapper}>
        <ButtonBase sx={styles.button} onClick={onAddSort}>
          <TestAddIcon width={16} height={16} />
          <Typography fontSize={16} lineHeight="24px">
            {t('sort.buttons.addSort')}
          </Typography>
        </ButtonBase>
      </Box>
    </Box>
  );
}
