import React, { ReactNode } from 'react';
import { alpha, Box, Theme, Typography } from '@mui/material';
import { format, isFuture, isSameDay, isSameMonth, isToday } from 'date-fns';

const styles = {
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1px',
    borderRadius: 1,
    height: 48,
  },
  clickableItem: {
    cursor: 'pointer',
    '@media (hover: hover)': {
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
  borderStyle: {
    border: '2px solid',
    borderColor: 'primary.main',
  },
  bgColorStyle: {
    bgcolor: 'text.primary',
    color: 'background.default',
  },
  selectable: {
    cursor: 'pointer',
  },
};

export type RenderDataItemParams<T> = {
  item: T;
  date: Date;
  sameMonth: boolean;
  future: boolean;
  selected: boolean;
};

type CalendarItemProps<T> = {
  item: T;
  selectedDate?: Date;
  selectedStyle?: 'todayAndSelected' | 'onlySelected';
  date: Date;
  itemDate: Date;
  disabled?: boolean;
  renderDateItem: (ev: RenderDataItemParams<T>) => ReactNode;
  onDateClick?: (ev: Date) => void;
};

export default function CalendarItem<T>({
  selectedDate,
  selectedStyle = 'todayAndSelected',
  date,
  item,
  itemDate,
  disabled,
  onDateClick,
  renderDateItem,
}: CalendarItemProps<T>) {
  const sameMonth = isSameMonth(date, itemDate);
  const today = isToday(itemDate);
  const future = isFuture(itemDate) && !today;
  const selected = selectedDate
    ? isSameDay(selectedDate, itemDate) && sameMonth
    : false;

  const clickable = !future && sameMonth && !disabled;

  const itemSelectedSx =
    selectedStyle === 'todayAndSelected'
      ? [
          today && sameMonth && styles.borderStyle,
          selected && styles.bgColorStyle,
          selected && styles.selectable,
        ]
      : selectedStyle === 'onlySelected'
      ? [selected && styles.borderStyle]
      : [];

  return (
    <Box
      sx={[
        styles.item,
        clickable && !selected && styles.clickableItem,
        ...itemSelectedSx,
      ]}
      onClick={clickable ? () => onDateClick?.(itemDate) : undefined}
    >
      <Typography variant="caption">
        {sameMonth ? format(itemDate, 'd') : ''}
      </Typography>
      <Box>
        {renderDateItem({
          item,
          date: itemDate,
          sameMonth,
          future,
          selected,
        })}
      </Box>
    </Box>
  );
}
