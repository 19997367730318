export enum SetGoalFormFields {
  TargetDate = 'targetDate',
  TargetScore = 'targetScore',
  SetMaxScore = 'setMaxScore',
  DailyQuestions = 'questionNumbers',
  DailyTimes = 'practiceTimeMin',
  DailyMocks = 'mockExamRoundCount',
}

export type SetGoalFormValuesTypes = {
  [SetGoalFormFields.TargetDate]: Date;
  [SetGoalFormFields.TargetScore]: number;
  [SetGoalFormFields.SetMaxScore]: boolean;
  [SetGoalFormFields.DailyQuestions]: number;
  [SetGoalFormFields.DailyTimes]: number;
  [SetGoalFormFields.DailyMocks]: number;
};

export type SetGoalParams = {
  targetDate: string;
  targetScore: number;
  questionNumbers: number;
  practiceTimeMin: number;
  mockExamRoundCount: number;
};

export const setGoalFormSettings = {
  questions: {
    min: 1,
    max: 1000,
    default: 30,
  },
  times: {
    min: 10,
    max: 100,
    default: 30,
  },
  mock: {
    min: 1,
    max: 1000,
    default: 3,
  },
};
