import { useCallback, useMemo } from 'react';
import { alpha, Theme } from '@mui/material';
import useLayoutSetting from '@app/web/src/hooks/utils/useLayoutSetting';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { isString, useDateFormat } from '@front/helper';
import { useBaseRightPanel } from '@front/ui';
import { TableLayoutConfig } from '@lib/ia/src/layouts/TableLayout/types';
import { InfiniteHookResponse, useAuth } from '@lib/web/apis';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';
import { useChannelsHierarchyDisplay } from '@lib/web/thread/hooks/channels/useChannelsHierarchyDisplay';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useReadState } from '@lib/web/thread/hooks/message/useReadState';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { getChannelLocationDetail } from '@lib/web/thread/utils/locationUtils';
import { Channel, ChannelData } from 'stream-chat';

const styles = {
  unreadRow: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.error.dark, 0.1),

    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.error.dark, 0.15),
      },
    },

    '& .last-message-cell p': {
      fontWeight: 700,
    },
  },
};

export const useThreadViewTableLayoutConfig = ({
  channelsData,
  selectedRowId,
  expandedMap = {},
}: {
  channelsData: InfiniteHookResponse<Channel>;
  selectedRowId: string | null;
  expandedMap: Record<string, boolean>;
}) => {
  const { member } = useAuth();
  const { dataset: rawChannels } = channelsData;
  const { displayMessageTimeFormat } = useDateFormat();
  const layoutSetting = useLayoutSetting();
  const { isTargetPanelOpened, getRightParams } =
    useBaseRightPanel<GlobalPanelParams>();
  const { getThreadUser, getThreadLocationDisplay } = useThread();
  const { getChannelInformation } = useChannelInformation();
  const { getChannelReadState } = useReadState();
  const myMemberId = member?.memberId;
  const enableThread = member?.emailVerified === true;
  const openedChannelCid = useMemo(() => {
    return isTargetPanelOpened(GlobalPanelKeys.GlobalThreadChat)
      ? getRightParams(GlobalPanelKeys.GlobalThreadChat).channelCid
      : null;
  }, [getRightParams, isTargetPanelOpened]);

  const { firstLevelChannels: channels, parentCidToChildChannels } =
    useChannelsHierarchyDisplay({ channels: rawChannels });

  const channelMapToRow = useCallback(
    (channel: Channel<StreamChatGenerics>) => {
      const {
        lastMessageCreatedAt,
        lastMessageCreator,
        lastMessageText,
        channelEngagedMemberIds,
        replies,
      } = getChannelInformation(channel);
      const { isUnread } = getChannelReadState(channel);

      const lastMessageCreatorUser = getThreadUser(lastMessageCreator);

      const locationDetail = getChannelLocationDetail(
        channel.data as ChannelData
      );
      const locationDisplay = getThreadLocationDisplay(locationDetail);

      return {
        id: channel.cid,
        selected: openedChannelCid === channel.cid,
        sx: isUnread ? styles.unreadRow : undefined,
        expanded: expandedMap[channel.cid],
        cells: {
          thread: {
            type: 'avatarText',
            avatar: lastMessageCreatorUser?.image,
            avatarCount: channelEngagedMemberIds.length,
            avatarName: lastMessageCreatorUser?.name,
            ...(isString(lastMessageText)
              ? {
                  html: `${
                    lastMessageCreator === myMemberId ? 'You:&nbsp' : ''
                  }${lastMessageText}`,
                }
              : {
                  text:
                    lastMessageCreator === myMemberId
                      ? [{ type: 'text', value: 'You:' }, ...lastMessageText]
                      : lastMessageText,
                  textVariant: 'chatBody',
                }),
            avatarBadge: channel.type === 'team' ? 'private' : undefined,
            className: 'last-message-cell render-one-line',
          },
          activeMembers: {
            type: 'avatarGroup',
            avatars: channelEngagedMemberIds.map((memberId) => {
              const threadUser = getThreadUser(memberId);
              return {
                name: threadUser?.name || '',
                avatarUrl: threadUser?.image || '',
              };
            }),
            maxDisplayNumber: 5,
          },
          threadCount: {
            type: 'number',
            value: replies,
            fractionDigits: 0,
          },
          lastActivity: {
            type: 'text',
            text: displayMessageTimeFormat(lastMessageCreatedAt),
          },
          location: locationDisplay
            ? locationDisplay.type === 'icon'
              ? {
                  type: 'icon',
                  icon: locationDisplay.icon,
                  tooltip: {
                    type: 'simple',
                    content: locationDisplay.name,
                  },
                }
              : {
                  type: 'avatarGroup',
                  avatars: [
                    {
                      name: locationDisplay.name,
                      avatarUrl: locationDisplay.avatarUrl,
                    },
                  ],
                  tooltip: {
                    type: 'simple',
                    content: locationDisplay.name,
                  },
                }
            : {
                type: 'empty',
              },
        },
        clickAction: {
          type: 'event',
          value: 'viewThread',
        },
        moreActions: [
          {
            type: 'event',
            icon: 'ActionArrowRightUp',
            text: 'Open Thread',
            value: 'openThread',
          },
        ],
      };
    },
    [
      displayMessageTimeFormat,
      expandedMap,
      getChannelInformation,
      getChannelReadState,
      getThreadLocationDisplay,
      getThreadUser,
      myMemberId,
      openedChannelCid,
    ]
  );

  const childRows = useMemo(() => {
    return Object.keys(parentCidToChildChannels).reduce(
      (acc, parentCid) => ({
        ...acc,
        [parentCid]: parentCidToChildChannels[parentCid].map(channelMapToRow),
      }),
      {}
    );
  }, [channelMapToRow, parentCidToChildChannels]);

  return useMemo(() => {
    const { isEmpty, isLoadingMore, isLoadingInitialData, isReachingEnd } =
      channelsData;

    const isAddingNewThread = isTargetPanelOpened(
      GlobalPanelKeys.GlobalThreadCreatorFromViewPanel
    );

    const tableState =
      isEmpty && !isAddingNewThread
        ? 'empty'
        : isLoadingInitialData || isLoadingMore
        ? 'loading'
        : isReachingEnd
        ? 'reachedEnd'
        : 'notReachedEnd';

    return [
      {
        layout: 'table-layout',
        table: {
          columnOrder: [
            'thread',
            'activeMembers',
            'threadCount',
            'lastActivity',
            'location',
          ],
          gridTemplateColumns: '1fr 139px 32px 152px 56px',
          head: {
            cells: {
              thread: {
                type: 'default',
                icon: 'ChatThread',
                label: 'Thread',
              },
              activeMembers: {
                type: 'default',
                icon: 'TestRanking',
                label: 'Active Members',
              },
              threadCount: {
                type: 'default',
                icon: 'ThreadsThread',
              },
              lastActivity: {
                type: 'default',
                icon: 'TestClock',
                label: 'Last Activity',
              },
              location: {
                type: 'default',
                icon: 'TextEditorLineLocationPoint',
              },
            },
          },
          rows: [
            ...(isAddingNewThread
              ? [
                  {
                    id: selectedRowId,
                    selected: true,
                    cells: {
                      thread: {
                        type: 'empty',
                      },
                      activeMembers: {
                        type: 'empty',
                      },
                      threadCount: {
                        type: 'empty',
                      },
                      lastActivity: {
                        type: 'empty',
                      },
                      location: {
                        type: 'empty',
                      },
                    },
                  },
                ]
              : []),
            ...channels.map(channelMapToRow),
          ],
          childRows,
        },
        data: {
          state: tableState,
          pageSize: 10,
          totalCount: channels.length, // not using channelsData.totalCount because we should only get the 'first level' channel length here
          loadMoreRow: {
            text: '10 more threads',
          },
          emptyRow: {
            text: 'No threads.',
          },
          loadMorePagesAction: 'loadMore',
        },
        settings: {
          layoutSetting: {
            ...layoutSetting,
            position: 'centre',
          },
          bottomButtons: enableThread
            ? {
                addNewRow: {
                  text: 'New Thread',
                  action: 'newThread',
                },
              }
            : undefined,
          disableCellFocus: true,
          expandable: {
            expandAction: 'expandRow',
          },
          toolbar: {
            excludeActions: ['openThread'],
          },
        },
      } as TableLayoutConfig,
    ];
  }, [
    channelsData,
    isTargetPanelOpened,
    selectedRowId,
    channels,
    channelMapToRow,
    childRows,
    layoutSetting,
    enableThread,
  ]);
};
