import { useContext } from 'react';
import useCurrentUserId from '@app/web/src/hooks/utils/useCurrentUserId';
import { useUserFollow } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';

import { RankingsContext } from '../components/RankingsContextProvider';

type Options = {
  type: 'following' | 'follower';
  keyword?: string;
  disabled?: boolean;
};

export default function useFriendRankingList({
  type,
  keyword,
  disabled,
}: Options) {
  const { clubSlug: clubSlugProvided } = useContext(RankingsContext);
  const clubSlug = useClubSlug();
  const userId = useCurrentUserId();

  const currentClubSlug = clubSlugProvided || clubSlug;

  return useUserFollow({
    type,
    userId: !disabled ? userId : undefined,
    keyword,
    isGetLeagueInfo: true,
    clubSlug: currentClubSlug,
    isGetStatus: true,
    isIncludeMe: true,
  });
}
