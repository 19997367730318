import { useContext } from 'react';
import { useSort } from '@app/web/src/hooks/utils/useSort';
import SortFields from '@lib/ia/src/sort/SortFields';
import SortSummary from '@lib/ia/src/sort/SortSummary/SortSummary';
import {
  SortCriteriaConfig,
  SortCriteriaSelectedEvent,
  SortField,
} from '@lib/ia/src/sort/types';

import SortContext from '../context/SortContext';
import useSortActions from '../hooks/useSortActions';

export default function SortSummaryContent() {
  const { goPage } = useSortActions();
  const [{ scope }] = useContext(SortContext);

  const { sort, hasSort } = useSort(scope);
  const isEmpty = !hasSort;
  const selectedFields = sort.criteria.map((item) => item.field.name);

  const handleAddNewSort = () => {
    goPage({ page: 'fieldList' });
  };

  const handleFieldSelected = (field: SortField) => {
    const newCriteria: SortCriteriaConfig = {
      field,
      direction: 'asc',
    };
    goPage({
      page: 'criteriaDetail',
      selectedCriteria: newCriteria,
    });
  };

  const handleCriteriaSelected = (event: SortCriteriaSelectedEvent) => {
    const selectedCriteria: SortCriteriaConfig = {
      id: event.id,
      field: event.field,
      direction: event.direction,
    };
    goPage({
      page: 'criteriaDetail',
      selectedCriteria,
    });
  };

  if (isEmpty) {
    return (
      <SortFields
        fields={sort.fields}
        excludeNames={selectedFields}
        onSelect={handleFieldSelected}
        autoFocus
        focusDelay={
          300 /** right panel animation duration: 300s => avoid layout jumping */
        }
      />
    );
  }

  return (
    <SortSummary
      criteria={sort.criteria}
      onAddSort={handleAddNewSort}
      onSelect={handleCriteriaSelected}
    />
  );
}
