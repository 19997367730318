import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchState } from '@app/web/src/utils/search';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import {
  IconListLayoutItem,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';

export default function useEditClubMembersListConfig(
  state: SearchState | null,
  dataset: IconListLayoutItemObj[],
  selected: IconListLayoutItemObj[]
): IaLayoutConfig[] {
  const { t } = useTranslation('club');
  return useMemo(() => {
    let items: IconListLayoutItem[] = [];
    if (selected.length) {
      items = [
        t('create.RHS.members.search.##selected', {
          count: selected.length,
        }),
        ...selected,
      ];
    }

    const filteredDataset = selected.length
      ? dataset.filter((data) => !selected.some((s) => s.id === data.id))
      : dataset;
    if (state === SearchState.Result) {
      return [
        {
          layout: 'icon-list-layout',
          items,
        },
      ];
    }
    if (state === SearchState.Searching) {
      items = [
        ...items,
        t('create.RHS.members.search.##results', {
          count: filteredDataset.length,
        }),
        ...filteredDataset,
      ];
      return [
        {
          layout: 'icon-list-layout',
          items,
        },
      ];
    }
    if (state === SearchState.Initial) {
      if (filteredDataset.length === 0) {
        return [
          {
            layout: 'icon-list-layout',
            items,
          },
        ];
      }

      return [
        {
          layout: 'icon-list-layout',
          items: [
            ...items,
            t('create.RHS.members.search.initial'),
            ...filteredDataset,
          ],
        },
      ];
    }

    return [];
  }, [dataset, selected, state, t]);
}
