import { useTranslation } from 'react-i18next';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import {
  ActionArrowDown as ActionArrowDownIcon,
  ActionArrowUp as ActionArrowUpIcon,
} from '@front/icon';
import { LeagueZone } from '@lib/web/apis';

import RankingItem, { RankingItemProps } from './RankingItem';

const styles = {
  divider: {
    px: 1.5,
    py: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
  },
};

const getDividerStyle = (zone: LeagueZone) => {
  if (zone === LeagueZone.Promotion)
    return {
      color: 'success.light',
    };

  return {
    color: 'error.dark',
  };
};

type LeagueRankingDividerProps = {
  zone: LeagueZone;
};

function LeagueRankingDivider({ zone }: LeagueRankingDividerProps) {
  const { t } = useTranslation('club');
  return (
    <Box sx={[styles.divider, getDividerStyle(zone)]}>
      {zone === LeagueZone.Promotion && <ActionArrowUpIcon />}
      {zone === LeagueZone.Demotion && <ActionArrowDownIcon />}
      <Typography variant="subtitle2">
        {t('League Zone', { context: zone })}
      </Typography>
    </Box>
  );
}

type RankingItemsProps = {
  zone: LeagueZone;
  items: GetLeagueGroupItemRes[];
};

function RankingItems({ zone, items }: RankingItemsProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  if (!items || items.length === 0) return null;

  const hideCta = !mdUp;
  const ctaCol = !hideCta ? '16px 105px' : '';
  const gridTemplateColumns = `1fr 8px 32px 16px 52px 16px 34px ${ctaCol}`;

  const dataItems: RankingItemProps[] = items.map((item) => {
    return {
      userInfo: {
        userId: item.userId,
        memberId: item.memberId,
        avatarUrl: item.nftAvatar || item.avatar,
        displayName: item.displayName,
        distinctName: item.distinctName,
        indicator: item.indicator,
        isFollower: item.isFollower,
        isFollowing: item.isFollowing,
      },
      data: {
        leagueTier: item.leagueTier,
        rank: item.rank,
        longestStreaks: item.longestStreaks,
        score: item.score,
        zone: item.zone,
      },
      settings: {
        hideLeague: true,
        gridTemplateColumns,
      },
    };
  });

  return (
    <>
      {zone === LeagueZone.Demotion && (
        <LeagueRankingDivider zone={LeagueZone.Demotion} />
      )}
      <Box>
        {dataItems.map((item, index) => (
          <RankingItem key={index} {...item} />
        ))}
      </Box>
      {zone === LeagueZone.Promotion && (
        <LeagueRankingDivider zone={LeagueZone.Promotion} />
      )}
    </>
  );
}

type LeagueRankingListProps = {
  items: GetLeagueGroupItemRes[];
};

export default function LeagueRankingList({ items }: LeagueRankingListProps) {
  const promotionZoneItems =
    items.filter((item) => item.zone === LeagueZone.Promotion) || [];
  const stayZoneItems =
    items.filter((item) => item.zone === LeagueZone.Stay) || [];
  const demotionZoneItems =
    items.filter((item) => item.zone === LeagueZone.Demotion) || [];

  return (
    <Box>
      <RankingItems zone={LeagueZone.Promotion} items={promotionZoneItems} />
      <RankingItems zone={LeagueZone.Stay} items={stayZoneItems} />
      <RankingItems zone={LeagueZone.Demotion} items={demotionZoneItems} />
    </Box>
  );
}
