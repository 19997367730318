import React from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import Box from '@mui/material/Box';
import Avatars from '@app/web/src/components/Avatars';
import { NumberCard } from '@front/ui';

const styles = {
  card: { flex: 1, maxWidth: 255 },
  avatars: {
    width: '100%',
    height: 64,
    display: 'flex',
    alignItems: 'center',
  },
};

type ChallengeInvitationCardProps = {
  challengers: { userId: string; distinctName: string; avatar: string }[];
  link: string;
};
export default function ChallengeInvitationCard({
  challengers,
  link,
}: ChallengeInvitationCardProps) {
  const { t } = useTranslation('notification');
  const userIds = challengers.map((challenger) => challenger.userId);

  return (
    <NumberCard
      sx={styles.card}
      title={t('Challenge Invitation')}
      titleIcon="MainChallenge"
      onClick={() => Router.push(link)}
      actionIcon="ActionArrowRightUp"
    >
      <NumberCard.Value>
        <Box sx={styles.avatars}>
          <Avatars userIds={userIds} avatarBorderColor="#141519" />
        </Box>
      </NumberCard.Value>
      <NumberCard.Description>{t('Click to Accept')}</NumberCard.Description>
    </NumberCard>
  );
}
