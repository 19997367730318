import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { IconButton, SimpleTooltip } from '@front/ui';

const iconSizeMap = {
  md: 40,
  sm: 16,
};

export type ThreadEmojiPickerProps = {
  emoji?: GetMarkEmojiRes | null;
  onEmojiClick?: (e: MouseEvent) => void;
  size?: 'md' | 'sm';
};

export default function ThreadEmojiPicker({
  emoji,
  onEmojiClick,
  size = 'md',
}: ThreadEmojiPickerProps) {
  const { t } = useTranslation('thread');
  const iconSize = iconSizeMap[size];
  return (
    <SimpleTooltip
      title={
        <>
          <Typography>{emoji?.name}</Typography>
          <Typography variant="caption" sx={{ opacity: 0.64 }}>
            {t('club.emoji.tooltip.title')}
          </Typography>
        </>
      }
    >
      <IconButton customSize={iconSize} onClick={onEmojiClick}>
        {emoji?.code}
      </IconButton>
    </SimpleTooltip>
  );
}
