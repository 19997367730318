import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '@app/web/src/hooks/redux';
import {
  updateLayoutType,
  updateTableLayout,
} from '@app/web/src/reducers/iaLayoutReducer';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  EditorDynamicVariable as EditorDynamicVariableIcon,
  EditorTable as EditorTableIcon,
  MainChat as MainChatIcon,
} from '@front/icon';
import { BaseLayoutRightPanel, useBaseRightPanel } from '@front/ui';
import { featureEnable } from '@lib/web/thread/config/constants';

import LayoutCard from './components/LayoutCard';

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
  cardRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    my: 2,
  },
  settingRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 1,
    my: 2,
  },
};

/**
 * XXX: we can consider whether this component should be rendered through FormLayout
 */
export default function LayoutSettingPanel() {
  const { t } = useTranslation('editor');
  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();
  const { supportableLayouts } = getRightParams(GlobalPanelKeys.GlobalLayout);
  const {
    table: { showRowBorder, showColumnBorder },
  } = useAppSelector((st) => st.iaLayout);
  const type = useAppSelector((st) => st.iaLayout.type) || 'table';

  const dispatch = useAppDispatch();

  const hasMultipleLayoutsSupported =
    Object.values(supportableLayouts).filter((v) => v).length > 1;

  return (
    <BaseLayoutRightPanel
      titleIcon={<EditorDynamicVariableIcon width={16} height={16} />}
      title={t('Layout')}
    >
      <BaseLayoutRightPanel.Content>
        {hasMultipleLayoutsSupported && (
          <Box sx={styles.cardRow} pb="22px">
            <LayoutCard
              selected={type === 'table'}
              icon={<EditorTableIcon />}
              title="Table"
              onClick={() => dispatch(updateLayoutType('table'))}
            />
            <LayoutCard
              selected={type === 'channel'}
              icon={<MainChatIcon />}
              title="Channel"
              onClick={() => dispatch(updateLayoutType('channel'))}
            />
          </Box>
        )}

        {featureEnable.tableLayoutSettings && supportableLayouts.table && (
          <>
            <Box sx={styles.settingRow}>
              <Typography variant="body1">{t('Show Row Border')}</Typography>
              <Switch
                checked={showRowBorder}
                onChange={() =>
                  dispatch(updateTableLayout({ showRowBorder: !showRowBorder }))
                }
              />
            </Box>
            <Box sx={styles.settingRow}>
              <Typography variant="body1">{t('Show Column Border')}</Typography>
              <Switch
                checked={showColumnBorder}
                onChange={() =>
                  dispatch(
                    updateTableLayout({ showColumnBorder: !showColumnBorder })
                  )
                }
              />
            </Box>
          </>
        )}
      </BaseLayoutRightPanel.Content>
    </BaseLayoutRightPanel>
  );
}
