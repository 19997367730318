import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { OtherFriendsChallenge as OtherFriendsChallengeIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  Button,
  Scrollbar,
  useBaseRightPanel,
} from '@front/ui';
import { ChallengeCategoryStatus, useQuizResultSummary } from '@lib/web/apis';

import ChallengerRanking from './components/ChallengerRanking';

const styles = {
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

export default function ChallengersPanels() {
  const { t } = useTranslation('quiz');
  const { openRightPanel, getRightParams } =
    useBaseRightPanel<GlobalPanelParams>();
  const { quizId, challengeId } = getRightParams(
    GlobalPanelKeys.GlobalChallengeRank
  );

  const { data } = useQuizResultSummary({
    quizId,
  });

  const handleChallengeFriendsClick = () => {
    openRightPanel(GlobalPanelKeys.GlobalAddChallenger, { challengeId });
  };

  return (
    <BaseLayoutRightPanel
      titleIcon={<OtherFriendsChallengeIcon width="16" height="16" />}
      title="Challengers"
      actionComponent={
        data?.data.challenge?.status === ChallengeCategoryStatus.Ongoing && (
          <Box sx={styles.action}>
            <Button
              onClick={handleChallengeFriendsClick}
              prefixIcon={<OtherFriendsChallengeIcon />}
            >
              {t('button.Add Challengers')}
            </Button>
          </Box>
        )
      }
      maximizeWidth
    >
      <Scrollbar sx={styles.scroll}>
        <ChallengerRanking challengeId={challengeId} />
      </Scrollbar>
    </BaseLayoutRightPanel>
  );
}
