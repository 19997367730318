import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, Box, Theme, Typography, useMediaQuery } from '@mui/material';
import {
  OtherInfoSolid as OtherInfoSolidIcon,
  TestScoreTally as TestScoreTallyIcon,
  TestTrophy as TestTrophyIcon,
} from '@front/icon';
import { SimpleTooltip } from '@front/ui';
import {
  ChallengeMemberStatus,
  useAuth,
  useChallengeRank,
} from '@lib/web/apis';

import ChallengerRankingCard, {
  ChallengerRankingSkeletonCard,
} from './ChallengerRankingCard';

const styles = {
  rankTitle: {
    px: 1.5,
    py: '5.5px',
    mt: 1,
    lineHeight: '16px',
  },
  rankTitleContent: {
    fontSize: '12px',
    display: 'flex',
    gap: 0.5,
    alignItems: 'center',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
  rankTitleIcon: {
    display: 'flex',
    alignItems: 'center',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        color: (theme: Theme) => theme.palette.text.primary,
        '& *': {
          fillOpacity: 1,
        },
      },
    },
  },
  rankTitleTooltip: {
    maxWidth: '200px',
  },
  rankTableHeader: {
    px: 1.5,
  },
  tableHeaderContent: {
    py: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '32px',
  },
  tableHeaderName: {
    flex: 1,
    opacity: 0.5,
    fontSize: 12,
    fontWeight: 400,
  },
  tableHeaderTrophy: {
    flex: '0 0 32px',
    opacity: 0.5,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableHeaderScore: {
    flex: '0 0 34px',
    opacity: 0.5,
    display: 'flex',
    justifyContent: 'flex-end',
    ml: 2,
  },
  tableHeaderAction: {
    flex: '0 0 105px',
    ml: 2,
  },
  invitedTitle: {
    px: 1.5,
    py: '5.5px',
    mt: 1,
    lineHeight: '16px',
  },
};

type ChallengerRankingProps = {
  challengeId: string;
};

export default function ChallengerRanking({
  challengeId,
}: ChallengerRankingProps) {
  const { t } = useTranslation('quiz');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { data } = useChallengeRank(challengeId);

  const { member } = useAuth();
  const { joinedData, invitedData } = useMemo(() => {
    if (!data) return {};

    const joined: GetChallengeRankingRes[] = [];
    const invited: GetChallengeRankingRes[] = [];

    data.data.items.forEach((item) => {
      if (item.status !== ChallengeMemberStatus.Requested) joined.push(item);
      if (item.status === ChallengeMemberStatus.Requested) invited.push(item);
    });

    return {
      joinedData: joined,
      invitedData: invited,
    };
  }, [data]);

  const hideCta = !mdUp;

  return (
    <>
      <Box sx={styles.rankTitle}>
        <Typography variant="body1" sx={styles.rankTitleContent}>
          {t('## Challenger', { count: joinedData?.length || 0 })}
          <SimpleTooltip
            title={t('tooltip.Challenge Friends')}
            slotProps={{
              popper: {
                sx: styles.rankTitleTooltip,
              },
            }}
          >
            <Box sx={styles.rankTitleIcon}>
              <OtherInfoSolidIcon width={16} height={16} />
            </Box>
          </SimpleTooltip>
        </Typography>
      </Box>

      <Box sx={styles.rankTableHeader}>
        <Box sx={styles.tableHeaderContent}>
          <Typography variant="body2" sx={styles.tableHeaderName}>
            Name
          </Typography>
          <Box sx={styles.tableHeaderTrophy}>
            <TestTrophyIcon width={16} height={16} />
          </Box>
          <Box sx={styles.tableHeaderScore}>
            <TestScoreTallyIcon width={16} height={16} />
          </Box>
          {!hideCta && <Box sx={styles.tableHeaderAction} />}
        </Box>
      </Box>

      {!data && (
        <>
          <ChallengerRankingSkeletonCard />
          <ChallengerRankingSkeletonCard />
        </>
      )}

      {joinedData?.map((item) => (
        <ChallengerRankingCard
          key={item.userId}
          rank={item.rank}
          score={item.score}
          trend={item.trend}
          userProfile={item.floatingProfile}
          isMe={member?.userId === item.userId}
        />
      ))}

      {invitedData && invitedData?.length > 0 && (
        <Box sx={styles.invitedTitle}>
          <Typography variant="body1" sx={styles.rankTitleContent}>
            {t('## Invited to Join', { count: invitedData.length })}
          </Typography>
        </Box>
      )}

      {invitedData?.map((item) => (
        <ChallengerRankingCard
          key={item.userId}
          userProfile={item.floatingProfile}
          hideRank
          hideScore
        />
      ))}
    </>
  );
}
