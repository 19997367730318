import React from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import ThreadMembersList from '@app/web/src/widgets/CommonPanels/ThreadPanels/components/ThreadMembersList';
import { PrivacyFriends as PrivacyFriendsIcon } from '@front/icon';
import {
  BaseLayoutContainer,
  BaseLayoutRightPanel,
  BaseLayoutScrollArea,
  useBaseRightPanel,
} from '@front/ui';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';

const styles = {
  scroll: {
    height: '100%',
  },
  container: {
    px: 0,
  },
};

export default function ThreadActiveMembersPanel() {
  const { t } = useTranslation('thread');
  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();
  const { channel } = getRightParams(GlobalPanelKeys.GlobalThreadActiveMembers);
  const { getChannelInformation } = useChannelInformation();
  const { channelEngagedMemberIds } = getChannelInformation(channel);

  return (
    <BaseLayoutRightPanel
      resizable
      title={t('rhs.activeMember.title')}
      titleIcon={<PrivacyFriendsIcon width={16} height={16} />}
    >
      <BaseLayoutScrollArea sx={styles.scroll}>
        <BaseLayoutContainer sx={styles.container} fullWidth>
          <ThreadMembersList
            memberIds={channelEngagedMemberIds}
            enableRemoveMember={false}
          />
        </BaseLayoutContainer>
      </BaseLayoutScrollArea>
    </BaseLayoutRightPanel>
  );
}
