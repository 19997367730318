import { useFilter } from '@app/web/src/hooks/utils/useFilter';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseLayout } from '@front/ui';
import FilterFields from '@lib/ia/src/filter/FilterFields';
import { FilterConditionConfig, FilterField } from '@lib/ia/src/filter/types';

export default function FilterFieldsContent() {
  const { openRightPanel, rightPanelOpened } =
    useBaseLayout<GlobalPanelParams>();
  const { filter } = useFilter();
  const selectedFields = filter.conditions.map(
    (condition) => condition.field.name
  );

  const handleFieldSelected = (field: FilterField) => {
    const newCondition: FilterConditionConfig = {
      field,
    };
    openRightPanel(GlobalPanelKeys.GlobalFilter, {
      page: 'conditionDetail',
      selectedCondition: newCondition,
    });
  };

  return (
    <FilterFields
      fields={filter.fields}
      excludeNames={selectedFields}
      onSelect={handleFieldSelected}
      autoFocus={rightPanelOpened}
    />
  );
}
