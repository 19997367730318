import { useCallback, useEffect, useRef } from 'react';
import { useOnScreen, useVisibilityChange } from '@front/helper';
import { useAuth } from '@lib/web/apis';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';
import { useReadState } from '@lib/web/thread/hooks/message/useReadState';
import { Channel } from 'stream-chat';

export const useMaybeMarkChannelsRead = ({
  channels,
  refToCheckVisibility,
}: {
  channels: Channel[];
  refToCheckVisibility?: React.RefObject<HTMLElement>;
}) => {
  const { getChannelInformation } = useChannelInformation();
  const { getChannelReadState } = useReadState();
  const isMarkReadInProgress = useRef(false);
  const ref = useRef<HTMLElement>(null);
  const onScreen = useOnScreen(refToCheckVisibility || ref);
  const { member } = useAuth();
  const myMemberId = member?.memberId;

  const markNoReplyChannelsAsRead = useCallback(async () => {
    if (!myMemberId) return;

    const isDocumentVisible = document.visibilityState === 'visible';

    if (!isDocumentVisible || !onScreen || isMarkReadInProgress.current) return;
    isMarkReadInProgress.current = true;

    for (const channel of channels) {
      const { replies } = getChannelInformation(channel);

      // in the channel layout, user only see the first message, so if there are any replies, don't mark as read
      if (replies > 0) continue;

      const { isUnread } = getChannelReadState(channel);

      if (!isUnread) continue;

      if (!Object.keys(channel.state.watchers).includes(myMemberId)) {
        await channel.watch();
      }

      await channel.markRead();
    }

    isMarkReadInProgress.current = false;
  }, [
    channels,
    getChannelInformation,
    getChannelReadState,
    myMemberId,
    onScreen,
  ]);

  useEffect(() => {
    void markNoReplyChannelsAsRead();
  }, [markNoReplyChannelsAsRead]);

  useVisibilityChange({
    onVisibleChange: (isVisible: boolean) => {
      if (isVisible) {
        void markNoReplyChannelsAsRead();
      }
    },
  });

  return {
    ref,
  };
};
