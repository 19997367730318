import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalPanelKeys } from '@app/web/src/types/panel';
import RedoCreateQuizPanels from '@app/web/src/widgets/PlaylistDetailPage/PlaylistDetail/components/ActionsToolbar/RedoCreateQuizPanels';
import usePlaylistSummary from '@app/web/src/widgets/PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistSummary';
import { OtherPlay as OtherPlayIcon } from '@front/icon';
import {
  Button,
  DropdownButton,
  DropdownButtonOption,
  useBaseRightPanel,
} from '@front/ui';
import { QuizType } from '@lib/web/apis';

const styles = {
  button: {
    px: 2,
  },
};

export default function PracticeButton() {
  const { t } = useTranslation('quiz');
  const { openRightPanel, getRightParams } = useBaseRightPanel();
  const params = getRightParams(GlobalPanelKeys.GlobalPlaylistDetail);
  const { data } = usePlaylistSummary();

  const dropdownOptions = useMemo(() => {
    if (!data) return [];
    const { mistakeCount, type } = data.data.quiz;
    const hasMistakes = mistakeCount > 0;
    const options: DropdownButtonOption[] = [
      {
        icon: 'OtherPlay',
        display: t(
          'panel.playlistDetails.practice.start.label',
          'Start Practice'
        ),
        onClick: () => {
          openRightPanel(
            `${GlobalPanelKeys.GlobalPlaylistDetail}${RedoCreateQuizPanels.PanelKeys.PracticeAgain}`,
            { ...params }
          );
        },
      },
    ];
    if (hasMistakes && type !== QuizType.CreatorUserQuiz) {
      options.push({
        icon: 'OtherPlay',
        display: t(
          'panel.playlistDetails.practice.mistakesOnly.label',
          'Practice Mistakes Only'
        ),
        onClick: () => {
          openRightPanel(
            `${GlobalPanelKeys.GlobalPlaylistDetail}${RedoCreateQuizPanels.PanelKeys.MistakesOnly}`,
            { ...params }
          );
        },
      });
    }
    return options;
  }, [data, t, openRightPanel, params]);

  if (!data) return null;

  return (
    <DropdownButton
      options={dropdownOptions}
      dropdownProps={{ placement: 'bottom-end' }}
    >
      <Button
        variant="filled"
        prefixIcon={<OtherPlayIcon width={16} height={16} />}
        loading={!data}
        sx={styles.button}
      >
        {t('panel.playlistDetails.practice.label')}
      </Button>
    </DropdownButton>
  );
}
