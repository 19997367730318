import { useMemo, useRef } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { useCommonToolbarItems } from '@app/web/src/hooks/utils/useCommonToolbarItems';
import { ActionChevronRightSmall as ActionChevronRightSmallIcon } from '@front/icon';
import { useBaseRightPanel } from '@front/ui';
import ThreadChatToolbar from '@lib/web/thread/ThreadChat/ThreadChatToolbar';
import { ToolbarButtonItem } from '@lib/web/ui';

import { useFilter } from '../../hooks/utils/useFilter';
import { useSort } from '../../hooks/utils/useSort';
import { GlobalPanelKeys, GlobalPanelParams } from '../../types/panel';
import FilterResponsivePanel from '../CommonPanels/FilterResponsivePanel';
import SortResponsivePanel from '../CommonPanels/SortResponsivePanel';

export default function WebThreadChatToolbar({
  channelCid,
  scope,
}: {
  channelCid: string;
  scope: string;
}) {
  const sortButtonRef = useRef<HTMLButtonElement>(null);
  const filterButtonRef = useRef<HTMLButtonElement>(null);

  const { getRightParams, setRightParams } =
    useBaseRightPanel<GlobalPanelParams>();

  const params = getRightParams(GlobalPanelKeys.GlobalThreadChat);
  const { filterOrSortOpened } = params;
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { hasSort } = useSort(scope);
  const { hasFilter } = useFilter(scope);

  const commonToolbarItems = useCommonToolbarItems({
    enableThread: false,
    enableShare: false,
  });
  const toolbarItems: Array<boolean | ToolbarButtonItem> = [
    {
      type: 'Sort',
      onClick: () => {
        setRightParams({ ...params, channelCid, filterOrSortOpened: 'sort' });
      },
      onHover: () => {
        setRightParams({ ...params, channelCid, filterOrSortOpened: 'sort' });
      },
      selected: filterOrSortOpened === 'sort',
      highlight: hasSort,
      hiddenInMore: true,
      disabledAutoClose: mdUp,
      buttonRef: sortButtonRef,
      actionIcon: mdUp ? <ActionChevronRightSmallIcon /> : null,
    },
    {
      type: 'Filter',
      onClick: () => {
        setRightParams({ ...params, channelCid, filterOrSortOpened: 'filter' });
      },
      onHover: () => {
        setRightParams({ ...params, channelCid, filterOrSortOpened: 'filter' });
      },
      selected: filterOrSortOpened === 'filter',
      highlight: hasFilter,
      hiddenInMore: true,
      disabledAutoClose: mdUp,
      buttonRef: filterButtonRef,
      actionIcon: mdUp ? <ActionChevronRightSmallIcon /> : null,
    },
    ...commonToolbarItems,
  ];

  const shouldKeepOpened = useMemo(() => {
    return filterOrSortOpened !== null && mdUp;
  }, [filterOrSortOpened, mdUp]);

  return (
    <>
      <ThreadChatToolbar
        channelCid={channelCid}
        toolbarItems={toolbarItems}
        shouldKeepOpened={shouldKeepOpened}
      />

      <SortResponsivePanel
        scope={scope}
        anchorEl={sortButtonRef.current}
        open={filterOrSortOpened === 'sort'}
        onClose={() =>
          setRightParams({ ...params, channelCid, filterOrSortOpened: null })
        }
      />
      <FilterResponsivePanel
        scope={scope}
        anchorEl={filterButtonRef.current}
        open={filterOrSortOpened === 'filter'}
        onClose={() =>
          setRightParams({ ...params, channelCid, filterOrSortOpened: null })
        }
      />
    </>
  );
}
