import { ClubMemberRole, useAuth } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';

export default function useMemberClubRole() {
  const { club } = useCurrentIaClub();
  const { member } = useAuth();
  const clubCreatorSourceId = club?.clubCreator.clubCreatorSourceId;
  const isCreator = clubCreatorSourceId === member?.userId;
  const isAdmin = club?.role === ClubMemberRole.Admin;
  const isEditor = club?.role === ClubMemberRole.Editor;
  const isMember = club?.role === ClubMemberRole.Member;
  const isUnknown = club?.role === ClubMemberRole.Unknown;

  return {
    role: club?.role as ClubMemberRole | undefined,
    creatorId: clubCreatorSourceId,
    isCreator,
    isAdmin,
    isEditor,
    hasEditAuth: club && (isAdmin || isEditor),
    isMember: club && (isMember || isUnknown),
  };
}
