import { useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { SuggestionUser } from '@app/web/src/components/SuggestionPanel/types';
import { appConfig } from '@front/config';
import { useLatestValueRef } from '@front/helper';
import { apis } from '@lib/web/apis';
import { call } from '@lib/web/utils';

import CreateQuizContext from '../../../context';
import { CreateQuizFormValue } from '../../../type';

export default function useAddChallenger({
  isSelected,
  onToggleUser,
}: {
  isSelected: (id: string) => boolean;
  onToggleUser?: (value: SuggestionUser, method: 'add' | 'remove') => void;
}) {
  const [{ challengeId = '', disabledParts }, setCreateQuizSettings] =
    useContext(CreateQuizContext);
  const { watch, setValue } = useFormContext<CreateQuizFormValue>();
  const randomChallengerCount = watch('randomChallengerCount', 0);
  const selected = watch('challengers', []);

  const isSelectedRef = useLatestValueRef(isSelected);
  const onToggleUserRef = useLatestValueRef(onToggleUser);
  const onlyAddChallengers = disabledParts.includes(
    'challenge-exclude-add-challengers'
  );

  const addChallengerToExistChallenge = useCallback(
    async (id: string) => {
      await call(() =>
        apis.challenge.sentChallengeInvitation({
          challengeId,
          userIds: [id],
          emails: [],
        })
      );
    },
    [challengeId]
  );

  return useCallback(
    (value: SuggestionUser) => {
      if (onlyAddChallengers && challengeId) {
        addChallengerToExistChallenge(value.id);
      }

      const updatedChallengers = isSelectedRef.current(value.id)
        ? selected.filter((challengerId) => challengerId !== value.id)
        : [...selected, value.id];

      onToggleUserRef.current?.(
        value,
        isSelectedRef.current(value.id) ? 'remove' : 'add'
      );
      setCreateQuizSettings((st) => ({
        ...st,
        challengerMap: {
          ...st.challengerMap,
          [value.id]: value,
        },
      }));

      if (
        updatedChallengers.length + randomChallengerCount >
          appConfig.maxChallengersCount &&
        randomChallengerCount > 0
      ) {
        setValue(
          'randomChallengerCount',
          appConfig.maxChallengersCount - updatedChallengers.length,
          {
            shouldDirty: true,
          }
        );
      }
      setValue('challengers', updatedChallengers, { shouldDirty: true });
    },
    [
      addChallengerToExistChallenge,
      challengeId,
      isSelectedRef,
      onToggleUserRef,
      onlyAddChallengers,
      randomChallengerCount,
      selected,
      setCreateQuizSettings,
      setValue,
    ]
  );
}
