import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  BaseLayoutRightPanel,
  SearchBar,
  SquareAvatar,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import {
  IconListLayoutItem,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';
import {
  apis,
  buildInfiniteHookMutate,
  FollowingFilterType,
  useAuth,
  useSearchClubMembers,
  useUserProfileSocial,
} from '@lib/web/apis';
import {
  useClubSlug,
  useInfiniteScroll,
  useSearchStatus,
} from '@lib/web/hooks';
import { call, getIndicators } from '@lib/web/utils';

import useFollowingQuizList from '../../FollowingQuizPage/hooks/useFollowingQuizList';
import useOpenGlobalProfilePanel from '../hooks/useOpenGlobalProfilePanel';

const FOLLOWING_QUIZ_PATHNAME = '/club/[slug]/playlists/friends-playlists';

function useReloadAssociateData(userId?: string) {
  const { pathname } = useRouter();
  const inFollowingQuizPage = pathname === FOLLOWING_QUIZ_PATHNAME;
  const { mutate: socialMutate } = useUserProfileSocial(userId);
  const { mutate: followingQuizMutate } = useFollowingQuizList({
    disabled: !inFollowingQuizPage,
  });

  return useCallback(async () => {
    socialMutate();
    followingQuizMutate();
  }, [socialMutate, followingQuizMutate]);
}

export default function FollowMorePanel() {
  const { t } = useTranslation('club');
  const clubSlug = useClubSlug();

  const { openProfile, defaultSearch } = useOpenGlobalProfilePanel();
  const { isTargetPanelOpened } = useBaseRightPanel<GlobalPanelParams>();
  const searchInputRef = useRef<HTMLInputElement>();

  const { member } = useAuth();
  const userId = member?.userId || '';
  const reloadAssociateData = useReloadAssociateData(userId);

  const {
    focused,
    search,
    isSearching,
    debouncedSearch,
    onChange,
    onBlur,
    onFocus,
  } = useSearchStatus(defaultSearch);

  const clubMembersData = useSearchClubMembers({
    clubSlug,
    keyword: isSearching ? debouncedSearch : '',
    isExcludeMe: true,
    isJoined: true,
    isExistingPlaylist: true,
    followingFilterType: FollowingFilterType.UnfollowingOnly,
  });

  const {
    dataset,
    isLoading: searchLoading,
    totalCount,
    mutate,
  } = clubMembersData;

  const reloadFollower = useMemo(
    () => buildInfiniteHookMutate(mutate),
    [mutate]
  );

  const getFollowButtonText = useCallback(
    (item: GetSearchClubMembersRes) => {
      if (item.isFollower) {
        return item.isFollowing ? t('Following') : t('Follow Back');
      }
      return item.isFollowing ? t('Following') : t('Follow');
    },
    [t]
  );

  const displayDataset: IconListLayoutItemObj[] = useMemo(() => {
    return dataset.map((item) => ({
      id: item.userId || item.memberId,
      title: item.displayName,
      indicators: getIndicators(item.indicator),
      description: `@${item.userName}`,
      descriptionLines: 1,
      avatarUrl: item.nftAvatar || item.avatar,
      titleAction: { type: 'event', value: 'titleClick' },
      actionMap: {
        click: {
          actionType: 'emphasizeButton',
          buttonVariant: item.isFollowing ? 'outlined' : 'filled',
          buttonColor: item.isFollowing ? 'success' : 'default',
          text: getFollowButtonText(item),
          type: 'event',
          value: item.isFollowing ? 'unfollow' : 'follow',
          icon: item.isFollowing ? 'OtherFollowing' : 'OtherAddFriend',
        },
        hover: {
          value: 'userHovered',
        },
      },
    }));
  }, [dataset, getFollowButtonText]);

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: clubMembersData,
  });

  const config: IaLayoutConfig[] = useMemo(() => {
    if (isSearching && !searchLoading && displayDataset.length === 0)
      return [
        {
          layout: 'icon-list-layout',
          items: [t('No result found')],
        },
      ];
    if (isSearching)
      return [
        {
          layout: 'icon-list-layout',
          items: (
            [
              t('## Users in Club', {
                count: displayDataset.length,
              }),
            ] as IconListLayoutItem[]
          ).concat(displayDataset),
        },
      ];
    if (searchLoading && totalCount === undefined)
      return [
        {
          layout: 'icon-list-layout-skeleton',
        },
      ];
    return [
      {
        layout: 'icon-list-layout',
        items: (
          [
            t('## Users in Club', {
              count: totalCount,
            }),
          ] as IconListLayoutItem[]
        ).concat(displayDataset),
      },
    ];
  }, [isSearching, searchLoading, displayDataset, t, totalCount]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && suggestItem) {
      event.preventDefault();

      openProfile(suggestItem.id, search);
    }
  };

  const availableActions = {
    follow: {
      action: async ({ id }: { id: string }) => {
        await call(
          reloadFollower(apis.member.followUser(id), {
            optimisticData: dataset.map((d) =>
              (d.userId || d.memberId) === id ? { ...d, isFollowing: true } : d
            ),
            optimisticPageData: {
              totalCount,
            },
          })
        );
        reloadAssociateData();
      },
    },
    unfollow: {
      action: async ({ id }: { id: string }) => {
        await call(
          reloadFollower(apis.member.unfollowUser(id), {
            optimisticData: dataset.map((d) =>
              (d.userId || d.memberId) === id ? { ...d, isFollowing: false } : d
            ),
            optimisticPageData: {
              totalCount,
            },
          })
        );
        reloadAssociateData();
      },
    },
    userHovered: {
      action: updateSuggestItem,
    },
    titleClick: {
      action: (value: IconListLayoutItemObj) => {
        openProfile(value.id, search);
      },
    },
  };

  const getItemStatus = (ev: IconListLayoutItemObj) => {
    return {
      focused: ev.id === suggestItem?.id,
    };
  };

  const isRightPanelOpened = isTargetPanelOpened(
    GlobalPanelKeys.GlobalFollowMore
  );
  useEffect(() => {
    if (isRightPanelOpened) {
      searchInputRef.current?.focus();
    }
  }, [isRightPanelOpened]);

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <BaseLayoutRightPanel
          titleIcon="OtherAddFriend"
          title={t('Follow More')}
        >
          <BaseLayoutRightPanel.SearchWrapper>
            <BaseLayoutRightPanel.SearchInput>
              <SearchBar
                inputRef={searchInputRef}
                placeholder={t(
                  'search.placeholder_friends',
                  'Search friends...'
                )}
                value={search}
                loading={searchLoading}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={handleKeyDown}
                suggestText={suggestItem?.title}
                prefixIcon={
                  suggestItem && (
                    <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                      {suggestItem.title}
                    </SquareAvatar>
                  )
                }
              />
            </BaseLayoutRightPanel.SearchInput>
            <BaseLayoutRightPanel.SearchContent>
              <BaseLayoutRightPanel.ScrollArea
                scrollableNodeProps={{ ref: scrollRef }}
              >
                {config && <IaLayouts layouts={config} />}
              </BaseLayoutRightPanel.ScrollArea>
            </BaseLayoutRightPanel.SearchContent>
          </BaseLayoutRightPanel.SearchWrapper>
        </BaseLayoutRightPanel>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
