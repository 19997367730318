import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, Box, Theme } from '@mui/material';
import { IaRichText } from '@lib/ia/src/core/types';
import ChannelLayout from '@lib/ia/src/layouts/ChannelLayout';
import { useAuth } from '@lib/web/apis';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useMessageInformation } from '@lib/web/thread/hooks/message/useMessageInformation';

import { SystemMessageProps } from './SystemMessage';

const styles = {
  root: {
    '.thread-message-item_content': {
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    },
  },
};

export type MemberInvitedMessageProps = SystemMessageProps;

export default function AgentStatusErrorMessage({
  message,
}: MemberInvitedMessageProps) {
  const { t } = useTranslation('thread');
  const { member } = useAuth();
  const { getMessageInformation } = useMessageInformation();
  const { getThreadUser } = useThread();

  const agent = getThreadUser(
    message.customAgentId ? `agent_${message.customAgentId}` : undefined
  );

  const content = useMemo<IaRichText>(() => {
    return [
      {
        type: 'text',
        value: t(
          'message.agent.status.error.text',
          `{{name}}'s AI Model is currently unavailable. Please retry later.`,
          { name: agent?.name || '' }
        ),
      },
    ];
  }, [agent?.name, t]);

  const myMemberId = member?.memberId;
  const isSender = !!myMemberId && myMemberId === message.customUserId;

  if (!isSender) return null;

  const messageInformation = getMessageInformation(message);

  return (
    <Box sx={styles.root}>
      <ChannelLayout.MessageItem
        type="message"
        id={message.id}
        sender={{
          type: 'icon',
          name: message.user?.name ?? '',
          icon: 'CryptoCurrencyAha',
          bgColor: '#080808',
        }}
        content={content}
        contentVariant="chatBody"
        sentTime={messageInformation.messageCreatedAt}
        lastActivityTime={messageInformation.messageCreatedAt}
      />
    </Box>
  );
}
