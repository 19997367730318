import useIaFilterValues from '@app/web/src/hooks/utils/useIaFilterValues';
import useIaQueryHelperValues from '@app/web/src/hooks/utils/useIaQueryHelperValues';
import { useIaSearchValues } from '@app/web/src/hooks/utils/useIaSearchValues';
import useIaSortValues from '@app/web/src/hooks/utils/useIaSortValues';
import combineSearch from '@lib/ia/src/utils/combineSearch';
import { FollowingQuizViewSlug, useIaFollowingQuizList } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';

export default function useFollowingQuizList({
  disabled,
}: { disabled?: boolean } = {}) {
  const { sectionId } = useCurrentIaClub();
  const { searchQuery: filter } = useIaFilterValues();
  const { sort } = useIaSortValues();
  const { keyword } = useIaSearchValues();
  const { isInitializing } = useIaQueryHelperValues();

  const data = useIaFollowingQuizList(
    {
      viewSlug: FollowingQuizViewSlug.AlbumCenterIAFollowingQuizDefault,
      ...combineSearch(filter, [`sectionId:${sectionId}`, 'sectionId:eq']),
      ...sort,
      keyword,
      limit: 24,
    },
    {
      enable: !disabled && !!sectionId && !isInitializing,
    }
  );

  return data;
}
