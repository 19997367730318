import { useCallback } from 'react';
import { useLatestValueRef } from '@front/helper';
import {
  removeCriteria as removeCriteriaFn,
  resetSort as resetSortFn,
  setCriteria as setCriteriaFn,
  setFields as setFieldsFn,
  upsertCriteria as upsertCriteriaFn,
} from '@lib/ia/src/sort/reducers/sortReducer';
import { SortCriteriaConfig } from '@lib/ia/src/sort/types';
import { v4 } from 'uuid';

import useGlobalSortConfigs from '../../widgets/CommonPanels/SortPanel/hooks/useGlobalSortConfigs';
import { SortType } from '../../widgets/CommonPanels/SortPanel/types';
import { useAppDispatch, useAppSelector } from '../redux';

type Options = {
  scope?: string;
};

export function useIaSortActions({ scope = 'global' }: Options = {}) {
  const configs = useGlobalSortConfigs();
  const latestConfigRef = useLatestValueRef(configs);
  const dispatch = useAppDispatch();
  const sort = useAppSelector((st) => st.sort);

  const getCriteriaByField = useCallback(
    (fieldName: string) => {
      if (!sort.criteria[scope] || sort.criteria[scope].length === 0) {
        return;
      }
      return sort.criteria[scope].find((item) => item.field.name === fieldName);
    },
    [scope, sort.criteria]
  );

  const getFirstCriteria = useCallback(() => {
    if (!sort.criteria[scope] || sort.criteria[scope].length === 0) {
      return;
    }
    return sort.criteria[scope][0];
  }, [scope, sort.criteria]);

  const removeCriteriaByField = useCallback(
    (fieldName: string) => {
      if (!sort.criteria[scope] || sort.criteria[scope].length === 0) {
        return;
      }
      const criteria = sort.criteria[scope].find(
        (item) => item.field.name === fieldName
      );
      if (!criteria || !criteria.id) return;
      dispatch(removeCriteriaFn({ id: criteria.id, scope }));
    },
    [dispatch, scope, sort.criteria]
  );

  const replaceCriteria = useCallback(
    (sortType: SortType, fieldName: string, direction: 'asc' | 'desc') => {
      const field = configs?.[sortType]?.fields?.find(
        (f) => f.name === fieldName
      );
      if (!field) return;
      removeCriteriaByField(fieldName);

      const criteria: SortCriteriaConfig = {
        id: v4(),
        field,
        direction,
      };
      dispatch(upsertCriteriaFn({ criteria, scope }));
    },
    [configs, dispatch, removeCriteriaByField, scope]
  );

  const isCriteriaExist = useCallback(
    (fieldName: string, direction: 'asc' | 'desc') => {
      const existing = getCriteriaByField(fieldName);
      return (
        !!existing &&
        existing.field?.name === fieldName &&
        existing.direction === direction
      );
    },
    [getCriteriaByField]
  );

  const setCriteria = useCallback(
    (criteria: SortCriteriaConfig[]) => {
      dispatch(setCriteriaFn({ criteria, scope }));
    },
    [dispatch, scope]
  );

  const resetSort = useCallback(() => {
    dispatch(resetSortFn({ scope }));
  }, [dispatch, scope]);

  const upsertCriteria = useCallback(
    (criteria: SortCriteriaConfig) => {
      dispatch(upsertCriteriaFn({ criteria, scope }));
    },
    [dispatch, scope]
  );

  const removeCriteria = useCallback(
    (id: string) => {
      dispatch(removeCriteriaFn({ id, scope }));
    },
    [dispatch, scope]
  );

  const resetToDefault = useCallback(
    (type?: SortType) => {
      if (!type) return;
      dispatch(resetSortFn({ scope }));
      const sortConfig = latestConfigRef.current[type];
      dispatch(setFieldsFn({ fields: sortConfig?.fields || [], scope }));
    },
    [latestConfigRef, dispatch, scope]
  );

  return {
    getCriteriaByField,
    getFirstCriteria,
    removeCriteriaByField,
    replaceCriteria,
    isCriteriaExist,
    setCriteria,
    resetSort,
    upsertCriteria,
    removeCriteria,
    resetToDefault,
  };
}
