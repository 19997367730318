import { MouseEvent } from 'react';
import { alpha, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import {
  FollowButton,
  SendButton,
} from '@app/web/src/components/FriendButtons';
import useFloatingProfile from '@app/web/src/hooks/utils/useFloatingProfile';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { OtherIndicatorUp as OtherIndicatorUpIcon } from '@front/icon';
import { DeltaValue, useBaseRightPanel, UserAvatar } from '@front/ui';
import { getIndicators } from '@lib/web/utils';

const getRank = (rank?: number) => {
  if (rank === undefined) return '--';
  if (rank > 999) return '+999';
  return rank;
};
const getScore = (score?: number) => {
  if (score === undefined) return '--';
  return score.toFixed(0);
};

const getRankStyle = (rank?: number) => {
  if (rank !== undefined && rank < 1000)
    return {
      fontSize: 20,
    };

  return {
    fontSize: 16,
  };
};

type ChallengerRankingCardProps = {
  userProfile: UserProfileObj;
  rank?: number;
  score?: number;
  trend?: number;
  disabled?: boolean;
  isMe?: boolean;
  hideScore?: boolean;
  hideRank?: boolean;
};

const styles = {
  userCard: {
    height: 61,
    width: '100%',
    px: 1.5,
    justifyContent: 'flex-start',
    textAlign: 'left',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
  user: {
    position: 'relative',
    height: '100%',
    flex: 1,
    display: 'flex',
  },
  avatar: {
    width: 'auto',
    '& h6.MuiTypography-root': {
      fontSize: 16,
      fontWeight: 700,
    },
    '& .MuiTypography-caption': {
      fontSize: 14,
      lineHeight: 1.5,
    },
  },
  isMe: {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 1,
    p: 1,
    ml: 1,
    height: 26,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
  trend: {
    flex: '0 0 12px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    ml: 1,
  },
  rank: {
    typography: 'body1',
    ml: 1,
    flex: '0 0 32px',
    fontWeight: 700,
    fontSize: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  score: {
    flex: '0 0 34px',
    ml: 2,
    fontWeight: 400,
    fontSize: 20,
    opacity: 0.64,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  skeletonUserCard: {
    height: 58,
    width: '100%',
    px: 1.5,
    display: 'flex',
  },
  skeletonUser: {
    flex: 1,
    gap: 1.6,
    display: 'flex',
    alignItems: 'center',
  },
  skeletonScore: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.5,
  },
  rankHashtag: {
    fontSize: 12,
    opacity: 0.5,
    verticalAlign: 'middle',
  },

  action: {
    flex: '0 0 105px',
    ml: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  ctaButton: {
    minWidth: '105px',
    minHeight: '24px',
    px: 0,
    fontSize: '12px',
    borderWidth: '2px',
  },
};

type CtaProps = {
  userProfile: UserProfileObj;
  isMe?: boolean;
};

function Cta({ userProfile, isMe }: CtaProps) {
  return (
    <Box sx={styles.action}>
      {!isMe && (
        <>
          {userProfile.isFollowing && (
            <SendButton
              userId={userProfile.userId}
              memberId={userProfile.memberId}
              preloadedUserProfile={userProfile}
              size="sm"
              challengeDisabled
            />
          )}
          {!userProfile.isFollowing && (
            <FollowButton
              userId={userProfile.userId}
              isFollowing={userProfile.isFollowing}
              isFollower={userProfile.isFollower}
              size="sm"
            />
          )}
        </>
      )}
    </Box>
  );
}

export function ChallengerRankingSkeletonCard() {
  return (
    <Box sx={styles.skeletonUserCard}>
      <Box sx={styles.skeletonUser}>
        <Skeleton variant="circular" width={32} height={32} />
        <Box>
          <Skeleton width={60} />
          <Skeleton width={40} />
        </Box>
      </Box>
      <Box sx={styles.skeletonScore}>
        <Skeleton width={30} />
        <Skeleton width={30} />
      </Box>
    </Box>
  );
}
export default function ChallengerRankingCard({
  userProfile,
  rank,
  score,
  trend,
  disabled,
  isMe,
  hideScore,
  hideRank,
}: ChallengerRankingCardProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const { showUserProfile } = useFloatingProfile();

  const handleClick = () => {
    openRightPanel(GlobalPanelKeys.GlobalProfile, {
      userId: userProfile.userId,
    });
  };

  const handleMouseEnter = (ev: MouseEvent) => {
    showUserProfile({
      userProfile: userProfile,
      anchorEl: ev.currentTarget,
      options: {
        spacing: 8,
      },
    });
  };

  const hideCta = !mdUp;

  return (
    <ButtonBase sx={styles.userCard} disabled={disabled} onClick={handleClick}>
      <Box sx={styles.user}>
        <UserAvatar
          sx={styles.avatar}
          src={userProfile.nftAvatar || userProfile.avatar}
          title={userProfile.displayName || userProfile.distinctName}
          titleSuffix={
            isMe && (
              <Typography sx={styles.isMe} variant="body1">
                Me
              </Typography>
            )
          }
          subTitle={`@${userProfile.distinctName}`}
          size="sm"
          indicators={getIndicators(userProfile.indicator)}
          titleClassName="floating-avatar-anchor"
          className="floating-avatar-anchor"
          onTitleMouseEnter={handleMouseEnter}
          onMouseEnter={handleMouseEnter}
        />
      </Box>
      {!hideRank && (
        <>
          <Box sx={styles.trend}>
            <DeltaValue
              value={trend ?? 0}
              arrowIcon={<OtherIndicatorUpIcon width={12} height={12} />}
              fontVariant="caption"
              arrowIconSize={12}
              showIconOnly
            />
          </Box>
          <Box sx={[styles.rank, getRankStyle(rank)]}>
            <Typography component="span" sx={styles.rankHashtag}>
              #
            </Typography>
            {getRank(rank)}
          </Box>
        </>
      )}
      {!hideScore && (
        <Typography variant="appH3" sx={styles.score}>
          {getScore(score)}
        </Typography>
      )}
      {!hideCta && <Cta userProfile={userProfile} isMe={isMe} />}
    </ButtonBase>
  );
}
