import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@app/web/src/hooks/redux';
import useMemberSubscription from '@app/web/src/hooks/utils/useMemberSubscription';
import useMemberTrialQuota from '@app/web/src/hooks/utils/useMemberTrialQuota';
import {
  BlockerPlacement,
  BlockerType,
  clearBlocker,
  setBlocker,
} from '@app/web/src/reducers/blockerReducer';

export const usePaidFeatureBlocker = () => {
  const { isFreeUser, isLoading } = useMemberSubscription();
  const { isRunOutOfQuestionQuota } = useMemberTrialQuota();
  const currentBlockerType = useAppSelector(
    (state) => state.blocker.blockerType
  );
  const currentPlacement = useAppSelector((state) => state.blocker.placement);
  const dispatch = useAppDispatch();

  const openBlocker = useCallback(
    (type: BlockerType, placement: BlockerPlacement) => {
      dispatch(setBlocker({ blockerType: type, placement }));
    },
    [dispatch]
  );

  const closeBlocker = useCallback(() => {
    dispatch(clearBlocker());
  }, [dispatch]);

  const isFeatureBlocked = useMemo(
    () => ({
      selectIncognitoMode: isFreeUser,
      selectSuperLevelDifficulty: isRunOutOfQuestionQuota,
      viewSolution: isRunOutOfQuestionQuota,
    }),
    [isFreeUser, isRunOutOfQuestionQuota]
  );

  return {
    isLoading,
    isFeatureBlocked,
    openBlocker,
    closeBlocker,
    currentBlockerType,
    currentPlacement,
  };
};
