import { MutableRefObject, useEffect } from 'react';
import { UseFormWatch } from 'react-hook-form';

import { ChallengeEvents } from '../enums';
import { CreateQuizFormStatus, CreateQuizFormValue } from '../type';

export default function useWatchFormStatus(
  eventBusRef: MutableRefObject<EventTarget>,
  formState: CreateQuizFormStatus,
  formWatch: UseFormWatch<CreateQuizFormValue>
) {
  const challengerValue = formWatch('challengers', []);
  const randomChallengerCount = formWatch('randomChallengerCount', 0);

  const hasChallengers =
    challengerValue.length > 0 || randomChallengerCount > 0;
  useEffect(() => {
    eventBusRef.current.dispatchEvent(
      new CustomEvent(ChallengeEvents.FormStatusChange, {
        detail: {
          isDirty: formState.isDirty,
          isSubmitting: formState.isSubmitting,
          hasChallengers,
        },
      })
    );
  }, [formState.isDirty, formState.isSubmitting, hasChallengers, eventBusRef]);
}
