import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Box, BoxProps, Typography } from '@mui/material';
import { AwardBadgeDataItem } from '@app/web/src/ia/award/types';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { TextButton, useBaseRightPanel } from '@front/ui';
import { isEmpty } from 'lodash';

import AwardBadgeCard from '../AwardBadgeCard';

const styles = {
  root: {
    display: 'grid',
    gap: 2,
    '& .award-badge-card': {
      height: '100%',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 'bold',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(2, 1fr)',
      md: 'repeat(auto-fill, minmax(125px, 1fr))',
    },
    gap: 1,
  },
};

type AwardBadgeGroupProps = {
  title?: string;
  showAllHref?: string;
  showAllVisible?: boolean;
  containerSx?: BoxProps['sx'];
  items: AwardBadgeDataItem[];
};
export default function AwardBadgeGroup({
  title,
  showAllVisible = false,
  showAllHref,
  containerSx,
  items,
}: AwardBadgeGroupProps) {
  const { t } = useTranslation();
  const {
    openRightPanel,
    closeRightPanel,
    isTargetPanelOpened,
    getRightParams,
  } = useBaseRightPanel<GlobalPanelParams>();
  const { awardItem: selectedItem } = getRightParams(
    GlobalPanelKeys.GlobalAwardDetail
  );

  if (isEmpty(items)) return null;

  const isSelected = (item: AwardBadgeDataItem) => {
    const isRightPanelOpened = isTargetPanelOpened(
      GlobalPanelKeys.GlobalAwardDetail
    );
    return (
      isRightPanelOpened &&
      item.subType === selectedItem.subType &&
      item.levelUser.level === selectedItem.levelUser.level &&
      item.levelUser.status === selectedItem.levelUser.status
    );
  };

  const handleClick = (item: AwardBadgeDataItem) => {
    if (isSelected(item)) {
      closeRightPanel();
      return;
    }
    openRightPanel(GlobalPanelKeys.GlobalAwardDetail, {
      awardItem: item,
    });
  };

  const containerSxProps = Array.isArray(containerSx)
    ? containerSx
    : [containerSx];

  return (
    <Box sx={styles.root}>
      {title && (
        <Box sx={styles.header}>
          <Typography variant="appH3" sx={styles.title}>
            {title}
          </Typography>
          {showAllVisible && showAllHref && (
            <TextButton component={Link} href={showAllHref}>
              {t('button.Show All')}
            </TextButton>
          )}
        </Box>
      )}
      <Box sx={[styles.container, ...containerSxProps]}>
        {items.map((item, index) => (
          <AwardBadgeCard
            {...item}
            key={index}
            onClick={() => handleClick(item)}
            selected={isSelected(item)}
          >
            <AwardBadgeCard.Tooltip
              title={item.name}
              content={item.levelInfo.requirement || ''}
              {...item}
            />
          </AwardBadgeCard>
        ))}
      </Box>
    </Box>
  );
}
