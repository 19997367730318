import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Theme, Typography } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import { alpha } from '@mui/material/styles';
import { FollowButton } from '@app/web/src/components/FriendButtons';
import useFloatingProfile from '@app/web/src/hooks/utils/useFloatingProfile';
import useMutualClubs from '@app/web/src/hooks/utils/useMutualClubs';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import useOpenGlobalProfilePanel from '@app/web/src/widgets/CommonPanels/hooks/useOpenGlobalProfilePanel';
import {
  compactNumberFormat,
  ReverseTheme,
  SquareAvatarGroup,
  useBaseRightPanel,
} from '@front/ui';
import RichText from '@lib/ia/src/components/RichText';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import { IaHoverEvent, IaRichText } from '@lib/ia/src/core/types';
import { ThreadViewType, useAuth, useMemberInfo } from '@lib/web/apis';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { getClubIconPath, getIndicators } from '@lib/web/utils';

const styles = {
  root: {
    mt: 1,
    display: 'grid',
    gap: 1,
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
  },
  actions: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  followButton: {
    '&.MuiButtonBase-root': {
      px: 2,
    },
  },
  mutualClubs: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    borderRadius: 1,
    px: '4px',
    py: '1.5px',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
  mutualClubsSelected: {
    bgcolor: (theme: Theme) => theme.palette.background.darker,
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        bgcolor: (theme: Theme) => theme.palette.background.darker,
      },
    },
  },
  mutualClubTitle: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
  },
  clubAvatars: {
    '& .avatar-more-text': {
      fontSize: 8,
    },
  },
};

function getMoreText(num: number): string {
  if (num < 100) return `${num}`;
  return '99+';
}

type MutualClubsProps = {
  targetUserId?: string;
};

function MutualClubs({ targetUserId }: MutualClubsProps) {
  const { t } = useTranslation('thread');
  const { userId } = useAuth();
  const { data: mutualClubs } = useMutualClubs({
    userId,
    targetUserId,
  });
  const { toggleRightPanel, isTargetPanelOpened } =
    useBaseRightPanel<GlobalPanelParams>();

  const count = mutualClubs.length;
  if (!userId || !targetUserId || count === 0) return null;

  const clubAvatars = mutualClubs.map((club) => ({
    avatarUrl: club.iconPath || getClubIconPath(club.name),
    name: club.name,
  }));

  const selected = isTargetPanelOpened(GlobalPanelKeys.GlobalMutualClubs);
  const Wrapper = selected ? ReverseTheme : React.Fragment;

  return (
    <Wrapper>
      <ButtonBase
        sx={[styles.mutualClubs, selected && styles.mutualClubsSelected]}
        onClick={() =>
          toggleRightPanel(GlobalPanelKeys.GlobalMutualClubs, {
            userId,
            targetUserId,
          })
        }
      >
        <SquareAvatarGroup
          items={clubAvatars}
          max={5}
          maxIncludedMore
          moreType="avatar"
          size={16}
          moreTextFormatter={getMoreText}
          sx={styles.clubAvatars}
        />
        <Typography variant="body2" sx={styles.mutualClubTitle}>
          {t('oneOnOneDm.subtitle.Mutual Clubs', {
            count,
            value: compactNumberFormat(count),
          })}
        </Typography>
      </ButtonBase>
    </Wrapper>
  );
}

export function isDmOneOnOneWithPerson(
  viewType: ThreadViewType,
  viewMemberIds: string[],
  myMemberId?: string
) {
  if (viewType !== ThreadViewType.DmOneOnOne) return false;
  return viewMemberIds.some(
    (memberId) => memberId !== myMemberId && !memberId.startsWith('agent_')
  );
}

type OneOnOneDmSubtitleProps = {
  view: GetThreadViewRes;
};
export default function OneOnOneDmSubtitle({ view }: OneOnOneDmSubtitleProps) {
  const { t } = useTranslation('thread');
  const { viewType, viewMemberIds, myMemberId } = useThreadViewDetail(view);
  const targetMemberId = viewMemberIds.find(
    (memberId) => memberId !== myMemberId && !memberId.startsWith('agent_')
  );
  const { data } = useMemberInfo(null, targetMemberId, true);
  const { openProfile } = useOpenGlobalProfilePanel();
  const { showUserIdProfile } = useFloatingProfile();

  if (!isDmOneOnOneWithPerson(viewType, viewMemberIds, myMemberId)) return null;
  if (!data) {
    return (
      <Box sx={styles.root}>
        <Skeleton width={200} height={21} />
      </Box>
    );
  }
  const targetMember = data.data;
  const content: IaRichText = [
    {
      type: 'text',
      value: t(
        'oneOnOneDm.subtitle.part1',
        'This conversation is private between you and'
      ),
    },
    {
      type: 'user',
      id: targetMember.userId,
      name: targetMember.distinctName,
      src: targetMember.nftAvatar || targetMember.avatar || '',
      indicators: getIndicators(targetMember.indicator),
      className: 'floating-avatar-anchor',
      hoverAction: {
        value: 'showFloatingProfile',
      },
      action: {
        type: 'event',
        value: 'openProfile',
      },
    },
    {
      type: 'text',
      value: t(
        'oneOnOneDm.subtitle.part2',
        '. Visit their profile to learn more about them.'
      ),
    },
  ];

  const availableActions = {
    openProfile: {
      action: ({ userId }: { userId: string }) => {
        openProfile(userId);
      },
    },
    showFloatingProfile: {
      action: (event: IaHoverEvent<{ userId: string }>) => {
        showUserIdProfile({
          userId: event.target.userId,
          anchorEl: event.anchorEl,
        });
      },
    },
  };

  return (
    <IaActionContextProvider availableActions={availableActions}>
      <Box sx={styles.root}>
        <Box>
          <RichText text={content} inline />
        </Box>
        <Box sx={styles.actions}>
          <MutualClubs targetUserId={targetMember.userId} />
          <Box>
            <FollowButton
              userId={targetMember.userId}
              isFollowing={targetMember.isFollowing || false}
              isFollower={targetMember.isFollower || false}
              autoFetchSocialData
              size="sm"
              sx={styles.followButton}
            />
          </Box>
        </Box>
      </Box>
    </IaActionContextProvider>
  );
}
