import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownButtonOption, useBaseRightPanel } from '@front/ui';
import { QuizType } from '@lib/web/apis';

import RedoCreateQuizPanels from '../RedoCreateQuizPanels';

import BaseRedoButton from './BaseRedoButton';

type PracticeRedoButtonProps = {
  data: GetQuizResultSummaryRes;
  tooltip?: {
    icon: string;
    title: string;
    content: ReactNode;
  };
  panelKeyPrefix?: string;
};

export default function PracticeRedoButton({
  data,
  tooltip,
  panelKeyPrefix,
}: PracticeRedoButtonProps) {
  const { t } = useTranslation('playlist');
  const { openRightPanel } = useBaseRightPanel();

  const { mistakeCount, type } = data.quiz;
  const hasMistakes = mistakeCount > 0;

  const dropdownOptions = useMemo(() => {
    const options: DropdownButtonOption[] = [
      {
        icon: 'OtherRefresh',
        display: t('detail.redoDropdown.practiceAgain.label'),
        tooltip: {
          icon: 'OtherRefresh',
          title: t('detail.redoDropdown.practiceAgain.tooltip.title'),
          content: t('detail.redoDropdown.practiceAgain.tooltip.content'),
        },
        onClick: () => {
          openRightPanel(
            `${panelKeyPrefix}${RedoCreateQuizPanels.PanelKeys.PracticeAgain}`
          );
        },
      },
    ];
    if (hasMistakes && type !== QuizType.CreatorUserQuiz) {
      options.push({
        icon: 'OtherPlay',
        display: t('detail.redoDropdown.mistakeOnly.label'),
        tooltip: {
          icon: 'OtherPlay',
          title: t('detail.redoDropdown.mistakeOnly.tooltip.title'),
          content: t('detail.redoDropdown.mistakeOnly.tooltip.content'),
        },
        onClick: () => {
          openRightPanel(
            `${panelKeyPrefix}${RedoCreateQuizPanels.PanelKeys.MistakesOnly}`
          );
        },
      });
    }
    return options;
  }, [t, hasMistakes, openRightPanel, panelKeyPrefix, type]);

  return (
    <BaseRedoButton
      label={t('button.Redo')}
      tooltip={tooltip}
      dropdownOptions={dropdownOptions}
    />
  );
}
