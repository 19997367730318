import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import ChannelLayout from '@lib/ia/src/layouts/ChannelLayout';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useMessageInformation } from '@lib/web/thread/hooks/message/useMessageInformation';

import { SystemMessageProps } from './SystemMessage';

const styles = {
  root: {
    '.thread-message-item_content': {
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    },
  },
};

export type MemberRemovedMessageProps = SystemMessageProps;

export default function MemberRemovedMessage({
  message,
}: MemberRemovedMessageProps) {
  const { t } = useTranslation('thread');
  const { getThreadUser } = useThread();
  const { getMessageInformation } = useMessageInformation();

  const messageInformation = getMessageInformation(message);
  const removerUser = getThreadUser(message.customUserId as string | undefined);
  const messageCreatorUser = getThreadUser(messageInformation.messageCreator);

  return (
    <Box sx={styles.root}>
      <ChannelLayout.MessageItem
        type="message"
        id={message.id}
        sender={{
          type: 'avatar',
          name: messageCreatorUser?.name ?? '',
          avatarUrl: messageCreatorUser?.image ?? '',
        }}
        clickSenderAction={{
          type: 'event',
          value: 'openUserOrAgentProfile',
          metadata: { user: messageCreatorUser },
        }}
        content={t('systemMessage.memberRemoved', {
          name: removerUser?.name || '',
        })}
        contentVariant="chatBody"
        sentTime={messageInformation.messageCreatedAt}
        lastActivityTime={messageInformation.messageCreatedAt}
      />
    </Box>
  );
}
