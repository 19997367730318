export const CreateQuizPanelPrefix = 'create-quiz';
export const enum CreateQuizPanelKeys {
  Topic = `${CreateQuizPanelPrefix}-topic`,
  Challenger = `${CreateQuizPanelPrefix}-challenger`,
  ChallengeSettings = `${CreateQuizPanelPrefix}-challenge-settings`,
  AdvancedSettings = `${CreateQuizPanelPrefix}-advanced-settings`,
  AddFriends = `${CreateQuizPanelPrefix}-add-friends`,
}

export const enum ChallengeEvents {
  FormStatusChange = `${CreateQuizPanelPrefix}-form-status-change`,
  DisableStatusChange = `${CreateQuizPanelPrefix}-disabled-status-change`,
}

export const enum CreateQuizDisableStatus {
  Loading = 'Loading',
  NoQuestions = 'NoQuestions',
}
