import { useCallback, useEffect, useState } from 'react';
import Onboarding from '@app/web/src/components/Onboarding';
import useOnboardingData from '@app/web/src/hooks/utils/useOnboardingData';
import { PinArrowDirection } from '@front/ui';

function AddChallengersOnboarding({
  onHide,
  onFinish,
}: {
  onHide: () => void;
  onFinish: () => void;
}) {
  const [positions, setPositions] = useState<Record<
    number,
    {
      x?: number;
      y?: number;
      arrowOffsetY?: number;
      arrowOffsetX?: number;
      arrowDirection: PinArrowDirection;
    }
  > | null>(null);

  const getPositions = useCallback(() => {
    const autoChipDom = document.querySelector('[data-testid="auto-chip"]');

    const challengeSettingsDom = document.querySelector(
      '[data-testid="challenge-settings-button"]'
    );

    if (!autoChipDom || !challengeSettingsDom) {
      setPositions(null);
      return;
    }

    const autoChipRect = autoChipDom.getBoundingClientRect();
    const challengeSettingsRect = challengeSettingsDom.getBoundingClientRect();

    setPositions({
      1: {
        x: autoChipRect.x + 16,
        y: autoChipRect.y + 4,
        arrowDirection: 'right-bottom',
      },
      2: {
        arrowDirection: 'right-top',
        x: challengeSettingsRect.x + challengeSettingsRect.width / 2,
        y: challengeSettingsRect.y + challengeSettingsRect.height / 2,
        arrowOffsetY: 10,
      },
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', getPositions);
    getPositions();
    return () => window.removeEventListener('resize', getPositions);
  }, [getPositions]);

  if (!positions) return null;
  return (
    <Onboarding
      className="ignore-panel-close"
      target="start/challenge"
      positions={positions}
      onFinish={onFinish}
      onHide={onHide}
      onlyShowCurrentStep
    />
  );
}

export default function AddChallengersOnboardingRoot() {
  const onboardingData = useOnboardingData('start/challenge');
  const [hide, setHide] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    // wait for panel animation to finish
    const it = setTimeout(() => {
      setShow(true);
    }, 500);

    return () => {
      clearTimeout(it);
    };
  }, []);
  if (!onboardingData || onboardingData.finished || hide || !show) return null;

  return (
    <AddChallengersOnboarding
      onHide={() => setHide(true)}
      onFinish={() => setHide(true)}
    />
  );
}
