import { useContext, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { BaseLayoutRightPanel, useBaseLayout } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import {
  FormLayoutMethodsContext,
  FormLayoutMethodsProvider,
} from '@lib/ia/src/layouts/FormLayout';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { apis, useGoal, useGoalDaily } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';
import { call } from '@lib/web/utils';

import { SetGoalFormFields, SetGoalParams } from './config';
import useSetGoalActions from './useSetGoalActions';
import useSetGoalDefaultValues from './useSetGoalDefaultValues';
import useSetGoalLayoutConfig from './useSetGoalLayoutConfig';

function SetGoalForm({
  club,
  goalData,
  dailyGoalData,
  onUpdateGoal,
  focusField,
}: {
  club: GetIaClubProfileCenterDefaultViewRes;
  goalData?: GetGoalRes;
  dailyGoalData?: GetGoalDailyRes;
  onUpdateGoal: (ev: SetGoalParams) => void;
  focusField?: SetGoalFormFields;
}) {
  const defaultValues = useSetGoalDefaultValues(club, goalData, dailyGoalData);
  const config = useSetGoalLayoutConfig(club, defaultValues);
  const { methods } = useContext(FormLayoutMethodsContext);

  const actions = useSetGoalActions({
    defaultValues,
    fullScore: club.fullScore,
    bottomScore: club.bottomScore,
    onSubmit: onUpdateGoal,
  });

  useEffect(() => {
    if (!focusField || !methods.setFocus) return;
    methods.setFocus(focusField);
  }, [methods, focusField]);

  return (
    <IaActionContextProvider availableActions={actions}>
      <IaLayouts layouts={config} />
    </IaActionContextProvider>
  );
}
export default function SetGoalPanel() {
  const { t } = useTranslation('analytics');
  const { club } = useCurrentIaClub();
  const { isTargetPanelOpened, getRightParams } =
    useBaseLayout<GlobalPanelParams>();
  const opened = isTargetPanelOpened(GlobalPanelKeys.GlobalSetGoal);
  const { focusField, onSubmit } = getRightParams(
    GlobalPanelKeys.GlobalSetGoal
  );

  const {
    data: goalData,
    mutate: goalMutate,
    error: goalError,
  } = useGoal(club?.sectionId);
  const {
    data: dailyGoalData,
    mutate: dailyGoalMutate,
    error: dailyGoalError,
  } = useGoalDaily(club?.sectionId);

  // If the user does not set this goal, BE will return a 40X error
  const apiLoaded =
    !!(goalError || goalData) && !!(dailyGoalData || dailyGoalError);

  const handleSubmit = async (values: SetGoalParams) => {
    if (!club) return;

    const {
      targetDate,
      targetScore,
      mockExamRoundCount,
      questionNumbers,
      practiceTimeMin,
    } = values;

    await call([
      apis.goal.saveGoal({
        sectionId: club.sectionId,
        targetDate,
        targetScore,
      }),
      apis.goal.saveGoalDaily({
        sectionId: club.sectionId,
        mockExamRoundCount,
        questionNumbers,
        practiceTimeMin,
      }),
    ]);
    goalMutate();
    dailyGoalMutate();
    onSubmit?.();
  };
  return (
    <BaseLayoutRightPanel
      title={t('goal.RHS.title.text', 'Set Goals')}
      titleIcon="MainSetGoal"
    >
      <BaseLayoutRightPanel.ScrollArea>
        <BaseLayoutRightPanel.Content>
          {!!club && apiLoaded ? (
            <FormLayoutMethodsProvider>
              <SetGoalForm
                key={`${club.sectionId}-${opened}`}
                club={club}
                goalData={goalData?.data}
                dailyGoalData={dailyGoalData?.data}
                onUpdateGoal={handleSubmit}
                focusField={focusField}
              />
            </FormLayoutMethodsProvider>
          ) : (
            <IaLayouts
              layouts={[
                {
                  layout: 'form-layout-skeleton',
                },
              ]}
            />
          )}
        </BaseLayoutRightPanel.Content>
      </BaseLayoutRightPanel.ScrollArea>
    </BaseLayoutRightPanel>
  );
}
