import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAhaInvitation from '@app/web/src/hooks/utils/useAhaInvitation';
import useFloatingProfile from '@app/web/src/hooks/utils/useFloatingProfile';
import { OtherAddFriend as OtherAddFriendIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  LightbulbCard,
  SearchBar,
  SquareAvatar,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import {
  IconListLayoutItem,
  IconListLayoutItemHoverEvent,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';
import { useSearchMemberList } from '@lib/web/apis';
import { useInfiniteScroll, useSearchStatus } from '@lib/web/hooks';
import { emailRegex } from '@lib/web/utils';

import useOpenGlobalProfilePanel from '../hooks/useOpenGlobalProfilePanel';

export default function FindUserPanel() {
  const { t } = useTranslation('profile');
  const { openProfile, defaultSearch } = useOpenGlobalProfilePanel();
  const {
    focused,
    search,
    isSearching,
    debouncedSearch,
    setSearch,
    onChange,
    onBlur,
    onFocus,
  } = useSearchStatus(defaultSearch);
  const { showUserProfile } = useFloatingProfile();

  const memberData = useSearchMemberList(debouncedSearch, undefined, true);
  const { pendingEmails, inviteToAha, newUserFormatter } = useAhaInvitation();

  const { dataset, isLoading: searchLoading, totalCount } = memberData;

  const newUserEmail =
    isSearching &&
    !dataset.find((d) => d.email === debouncedSearch) &&
    !searchLoading &&
    emailRegex.test(debouncedSearch)
      ? debouncedSearch
      : '';

  const displayDataset: IconListLayoutItemObj[] = useMemo(() => {
    return dataset.map((item) => ({
      id: item.userId || item.memberId,
      title: item.displayName,
      titleAction: { type: 'event', value: 'titleClick' },
      avatarUrl: item.nftAvatar || item.avatar,
      action: {
        type: 'event',
        value: 'userClicked',
      },
      titleHoverAction: { value: 'titleHover' },
      titleClassName: 'floating-avatar-anchor',
      avatarHoverAction: { value: 'avatarHover' },
      avatarClassName: 'floating-avatar-anchor',
      metadata: item.floatingProfile,
    }));
  }, [dataset]);

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: memberData,
  });

  const config: IaLayoutConfig[] = useMemo(() => {
    if (isSearching && !searchLoading && displayDataset.length === 0) {
      const newUser = newUserFormatter(newUserEmail);
      if (newUser) {
        return [
          {
            layout: 'icon-list-layout',
            items: [
              t('Results', {
                count: 1,
              }),
              {
                id: newUser.email,
                title: newUser.email,
                actionMap: {
                  click: {
                    type: 'event' as const,
                    value: 'inviteToAha',
                    text: t(`find.user.invite.aha.label.${newUser.actionText}`),
                    actionType: 'textButton',
                    disabled:
                      newUser.actionText === 'accepted' ||
                      newUser.actionText === 'pending',
                  },
                },
              },
            ],
          },
        ];
      }
      return [
        {
          layout: 'icon-list-layout',
          items: [t('No result found')],
        },
      ];
    }

    if (isSearching) {
      return [
        {
          layout: 'icon-list-layout',
          items: (
            [
              t('Results', {
                count: totalCount,
              }),
            ] as IconListLayoutItem[]
          ).concat(displayDataset),
        },
      ];
    }

    return [];
  }, [
    isSearching,
    searchLoading,
    displayDataset,
    newUserFormatter,
    newUserEmail,
    t,
    totalCount,
  ]);

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && suggestItem) {
      event.preventDefault();

      openProfile(suggestItem.id);
      setSearch('');
    }
  };

  const availableActions = {
    userClicked: {
      action: ({ id }: IconListLayoutItemObj) => {
        openProfile(id);
      },
    },

    userHovered: {
      action: updateSuggestItem,
    },

    inviteToAha: {
      action: (value: IconListLayoutItemObj) => {
        inviteToAha([value.id]);
      },
    },
    titleClick: {
      action: (value: IconListLayoutItemObj) => {
        openProfile(value.id, search);
      },
    },
    titleHover: {
      action: (event: IconListLayoutItemHoverEvent<UserProfileObj>) => {
        if (!event.target.metadata) return;
        showUserProfile({
          anchorEl: event.anchorEl,
          userProfile: event.target.metadata,
        });
      },
    },
    avatarHover: {
      action: (event: IconListLayoutItemHoverEvent<UserProfileObj>) => {
        if (!event.target.metadata) return;
        showUserProfile({
          anchorEl: event.anchorEl,
          userProfile: event.target.metadata,
          options: {
            spacing: 8,
          },
        });
      },
    },
  };
  const getItemStatus = ({ id }: IconListLayoutItemObj) => {
    return {
      loading: pendingEmails.includes(id),
      focused: suggestItem?.id === id,
    };
  };

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <BaseLayoutRightPanel
          titleIcon={<OtherAddFriendIcon width="16" height="16" />}
          title={t('Add Friends')}
        >
          <BaseLayoutRightPanel.SearchWrapper>
            <BaseLayoutRightPanel.SearchInput>
              <SearchBar
                placeholder={t(
                  'search.placeholder_friends',
                  'Search friends...'
                )}
                value={search}
                loading={searchLoading}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={handleKeyDown}
                suggestText={suggestItem?.title}
                prefixIcon={
                  suggestItem && (
                    <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                      {suggestItem.title}
                    </SquareAvatar>
                  )
                }
              />
              {!focused && !isSearching && (
                <LightbulbCard>
                  {t('You can search other Aha user by name or username')}
                </LightbulbCard>
              )}
            </BaseLayoutRightPanel.SearchInput>
            <BaseLayoutRightPanel.SearchContent>
              <BaseLayoutRightPanel.ScrollArea
                scrollableNodeProps={{ ref: scrollRef }}
              >
                {config && <IaLayouts layouts={config} />}
              </BaseLayoutRightPanel.ScrollArea>
            </BaseLayoutRightPanel.SearchContent>
          </BaseLayoutRightPanel.SearchWrapper>
        </BaseLayoutRightPanel>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
