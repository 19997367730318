import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchState } from '@app/web/src/utils/search';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import {
  IconListLayoutItem,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';

const baseI18Keys = {
  selectedKey: '## Selected',
  resultsKey: '## Results',
  initialKey: '## Friends',
};
export default function useSearchLayoutConfig({
  state,
  dataset,
  selected,
  totalCount,

  i18n = baseI18Keys,
}: {
  state: SearchState | null;
  dataset: IconListLayoutItemObj[];
  selected: IconListLayoutItemObj[];
  totalCount: number;

  i18n?: {
    selectedKey: string;
    resultsKey: string;
    initialKey: string;
  };
}): IaLayoutConfig[] {
  const { t } = useTranslation();

  return useMemo(() => {
    let items: IconListLayoutItem[] = [];
    if (selected.length) {
      items = [
        t(i18n.selectedKey, {
          count: selected.length,
        }),
        ...selected,
      ];
    }

    if (
      (state === SearchState.Searching || state === SearchState.Initial) &&
      totalCount === undefined
    ) {
      return [
        ...(items.length
          ? [
              {
                layout: 'icon-list-layout' as const,
                items,
              },
            ]
          : ([] as IaLayoutConfig[])),
        {
          layout: 'icon-list-layout-skeleton',
        },
      ];
    }

    const filteredDataset = selected.length
      ? dataset.filter((data) => !selected.some((s) => s.id === data.id))
      : dataset;
    const excludeCount = dataset.length - filteredDataset.length;

    if (state === SearchState.Result) {
      return [
        {
          layout: 'icon-list-layout',
          items,
        },
      ];
    }
    if (state === SearchState.Searching) {
      items = [
        ...items,
        t(i18n.resultsKey, {
          count: totalCount - excludeCount,
        }),
        ...filteredDataset,
      ];
      return [
        {
          layout: 'icon-list-layout',
          items,
        },
      ];
    }
    if (state === SearchState.Initial) {
      if (filteredDataset.length === 0) {
        return [
          {
            layout: 'icon-list-layout',
            items,
          },
        ];
      }

      return [
        {
          layout: 'icon-list-layout',
          items: [
            ...items,
            t(i18n.initialKey, { count: totalCount - excludeCount }),
            ...filteredDataset,
          ],
        },
      ];
    }

    return [];
  }, [
    dataset,
    i18n.initialKey,
    i18n.resultsKey,
    i18n.selectedKey,
    selected,
    state,
    t,
    totalCount,
  ]);
}
