import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import { useDateFormat } from '@front/helper';
import {
  ActionRandomize as ActionRandomizeIcon,
  OtherFriendsChallenge as OtherFriendsChallengeIcon,
} from '@front/icon';
import {
  MaskIcon,
  ResponsiveTooltip,
  SquareAvatar,
  TooltipList,
  TooltipListItem,
  useBaseRightPanel,
} from '@front/ui';

import CreateQuizContext from '../../context';
import { CreateQuizPanelKeys } from '../../enums';
import { CreateQuizFormValue } from '../../type';

import Section from './Section';
import SectionItem from './SectionItem';

const styles = {
  challengers: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 1,
  },
  randomChallengersIcon: {
    position: 'relative',
  },
  randomChallengersCount: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: 'text.primary',
    color: 'background.darker',
    typography: 'body2',
  },
  randomIndicator: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 16,
    height: 16,
    borderRadius: '50%',
    bottom: '-2px',
    right: '-2px',
    bgcolor: 'background.darker',
    color: 'text.primary',
    p: '1.57px',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  tooltipItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
};
function Challengers() {
  const { watch } = useFormContext<CreateQuizFormValue>();
  const challengers = watch('challengers');
  const randomChallengerCount = watch('randomChallengerCount');

  const [{ challengerMap }] = useContext(CreateQuizContext);
  return (
    <Box sx={styles.challengers}>
      {challengers.map((challengerId) => {
        const challenger = challengerMap[challengerId];
        if (!challenger) return null;
        return (
          <Box key={challengerId}>
            <SquareAvatar src={challenger.avatar} size={32}>
              {challenger.display}
            </SquareAvatar>
          </Box>
        );
      })}

      {randomChallengerCount > 0 && (
        <Box sx={styles.randomChallengersIcon}>
          <MaskIcon sx={styles.randomChallengersCount}>
            {randomChallengerCount}
          </MaskIcon>
          <Box sx={styles.randomIndicator}>
            <ActionRandomizeIcon />
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default function ChallengeSection() {
  const { t } = useTranslation('quiz');
  const { isTargetPanelOpened, toggleRightPanel } = useBaseRightPanel();
  const { watch } = useFormContext<CreateQuizFormValue>();
  const challengers = watch('challengers', []);
  const randomChallengerCount = watch('randomChallengerCount', 0);
  const maxAttempt = watch('maxAttempt');
  const deadline = watch('deadline');
  const challengerCount = Number(randomChallengerCount) + challengers.length;
  const { dateFormat } = useDateFormat();
  const [{ panelKeyPrefix }] = useContext(CreateQuizContext);
  const challengerPanelKey = `${panelKeyPrefix}${CreateQuizPanelKeys.Challenger}`;

  const [createQuizSettings] = useContext(CreateQuizContext);
  const { disabledParts } = createQuizSettings;
  const disabled =
    disabledParts.includes('all') || disabledParts.includes('challenge');

  return (
    <Section title={t('createQuiz.challenge.title', 'Challenge')}>
      <ResponsiveTooltip
        title={t(
          'createQuiz.challenge.friend.hint.title',
          'Challenge a Friend'
        )}
        titleIcon={<OtherFriendsChallengeIcon width={16} height={16} />}
        content={
          <TooltipList content={t('createQuiz.challenge.friend.hint.content')}>
            <TooltipListItem
              name={{
                icon: 'OtherFriendsChallenge',
                text: t(
                  'createQuiz.challenge.friend.property.challengers',
                  'Challengers'
                ),
              }}
              value={{ type: 'text', text: `${challengerCount}` }}
            />
            <TooltipListItem
              name={{
                icon: 'OtherChallengeAttempt',
                text: t(
                  'createQuiz.challenge.settings.property.attempts',
                  'Attempts'
                ),
              }}
              value={
                maxAttempt === -1
                  ? { type: 'icon', icon: 'TestInfinity' }
                  : { type: 'text', text: `${maxAttempt}` }
              }
            />
            <TooltipListItem
              name={{
                icon: 'PropertyTypeDateOrTime',
                text: t(
                  'createQuiz.challenge.settings.property.deadline',
                  'Deadline'
                ),
              }}
              value={{ type: 'text', text: dateFormat(deadline) }}
            />
          </TooltipList>
        }
        tooltipProps={{ followCursor: true }}
      >
        <SectionItem
          data-testid="challenge-section"
          display={t(
            'createQuiz.challenge.friend.title',
            'Challenge a Friend',
            {
              count: challengerCount,
            }
          )}
          icon={<OtherFriendsChallengeIcon width={19.2} height={19.2} />}
          selected={isTargetPanelOpened(challengerPanelKey)}
          onClick={() => toggleRightPanel(challengerPanelKey)}
          disabled={disabled}
        >
          {challengerCount > 0 ? <Challengers /> : undefined}
        </SectionItem>
      </ResponsiveTooltip>
    </Section>
  );
}
