import { useEffect, useMemo, useRef, useState } from 'react';
import { Markdown } from '@front/ui';
import { rand } from '@ngneat/falso';

export default function TypingEffect({
  text,
  onTypingComplete,
}: {
  text: string;
  onTypingComplete: () => void;
}) {
  const currentTextRef = useRef('');
  const [displayedText, setDisplayedText] = useState('');
  const hasWordSpacing = text.includes(' ');
  const textSplit = useMemo(() => {
    return hasWordSpacing ? text.split(' ') : text.split('');
  }, [hasWordSpacing, text]);

  useEffect(() => {
    if (textSplit.length === 0) {
      return;
    }

    let currentIndex = 0;
    let timerId: NodeJS.Timeout;

    const type = () => {
      currentTextRef.current =
        currentTextRef.current +
        textSplit[currentIndex] +
        (hasWordSpacing ? ' ' : '');

      setDisplayedText(`${currentTextRef.current} ●`);

      if (++currentIndex === textSplit.length) {
        setDisplayedText((currText) => {
          if (currText.at(-1) === '●') {
            currText = currText.slice(0, -1);
          }
          onTypingComplete();
          return currText;
        });
      } else {
        timerId = setTimeout(type, rand([10, 50, 100])); // random typing speed to make it more natural
      }
    };

    type();

    return () => {
      clearTimeout(timerId);
    };
  }, [hasWordSpacing, onTypingComplete, textSplit]);

  return <Markdown className="markdown-message">{displayedText}</Markdown>;
}
