import { useSort } from '@app/web/src/hooks/utils/useSort';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseLayout } from '@front/ui';
import SortFields from '@lib/ia/src/sort/SortFields';
import { SortCriteriaConfig, SortField } from '@lib/ia/src/sort/types';

export default function SortFieldsContent() {
  const { openRightPanel, rightPanelOpened } =
    useBaseLayout<GlobalPanelParams>();
  const { sort } = useSort();
  const selectedFields = sort.criteria.map((item) => item.field.name);

  const handleFieldSelected = (field: SortField) => {
    const newSort: SortCriteriaConfig = {
      field,
      direction: 'asc',
    };
    openRightPanel(GlobalPanelKeys.GlobalSort, {
      page: 'criteriaDetail',
      selectedCriteria: newSort,
    });
  };

  return (
    <SortFields
      fields={sort.fields}
      excludeNames={selectedFields}
      onSelect={handleFieldSelected}
      autoFocus={rightPanelOpened}
    />
  );
}
