import { useCallback } from 'react';
import { QuizQuestionStatus } from '@lib/web/apis';
import { persistentStringToHtml } from '@lib/web/editor/EditorTextComposer';
import { useQuestionTextRender } from '@lib/web/hooks';

import { IaPlaylistQuestion } from './iaPlaylistQuestion';
import { IaPlaylistQuestionDataItem } from './types';

export const useIaPlaylistQuestion = () => {
  const { questionRender } = useQuestionTextRender({
    newFormatRender: persistentStringToHtml,
  });

  const selectIaPlaylistQuestion = useCallback(
    (question: IaPlaylistQuestionDataItem): IaPlaylistQuestion => {
      const isMistake = !question.isCorrect;
      const isRemoved =
        !!question.status &&
        ![QuizQuestionStatus.Enabled, QuizQuestionStatus.Internal].includes(
          question.status
        );

      return {
        number: {
          value: question.questionNumber,
        },
        isAnswered: {
          value: question.isAnswered,
        },
        lineColor: {
          value:
            question.isAnswered && !question.isSkipped
              ? isMistake
                ? 'error'
                : 'success'
              : 'grey',
        },
        overtime: {
          value: question.isOvertime ? 'overtime' : 'notOvertime',
        },
        content: {
          value: !isRemoved
            ? questionRender(question.questionContent || '')
            : '<i>Question removed by creator</i>',
        },
        note: {
          value: question.note || '',
        },
        emoji: question.emoji || null,
        difficulty: question.difficulty || null,
      };
    },
    [questionRender]
  );

  return {
    selectIaPlaylistQuestion,
  };
};
