import { testIdConfig } from '@front/config';

const RHS = '.base-layout-right-panel';
const START_BUTTON = `[data-testid="${testIdConfig.startPractice}"]`;

const START_BUTTON_UNDER_OPENED_RHS = `${RHS}.is-opened ${START_BUTTON}`;
const START_BUTTON_NOT_UNDER_RHS = `${START_BUTTON}:not(${RHS} ${START_BUTTON})`;

const getActiveQuizButtonEl = () => {
  return document.querySelector(
    `${START_BUTTON_UNDER_OPENED_RHS}, ${START_BUTTON_NOT_UNDER_RHS}`
  ) as Element;
};

export default getActiveQuizButtonEl;
