import { useTranslation } from 'react-i18next';
import { Box, Skeleton } from '@mui/material';
import AwardBadgeCard from '@app/web/src/components/AwardBadgeCard';
import { enabledAwardSubTypes } from '@app/web/src/config/awardConfig';
import useIaAwardBadgeData from '@app/web/src/ia/award/useIaAwardBadgeData';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { TextButton, useBaseLayout } from '@front/ui';
import { AwardBadgeViewSlug, useIaAwardBadgeList } from '@lib/web/apis';

import ProfileSection from './ProfileSection';

const LIMIT = 5;

const styles = {
  container: {
    display: 'flex',
    gap: 1,
    '& .award-badge-card': {
      p: 0.5,
    },
  },
};

type ProfileAwardsProps = {
  userId?: string;
};

export default function ProfileAwards({ userId }: ProfileAwardsProps) {
  const { t } = useTranslation('profile');
  const { openRightPanel } = useBaseLayout<GlobalPanelParams>();

  const { dataset, isLoading, isEmpty, totalCount } = useIaAwardBadgeList(
    {
      viewSlug: AwardBadgeViewSlug.ProfileCenterIaBadgeCategory,
      limit: LIMIT,
      filter: 'subType;goalType;awardBadgeLevel',
      search: `subType:${enabledAwardSubTypes.join(',')}`,
      searchFields: `subType:in`,
      userId,
    },
    {
      enable: !!userId,
    }
  );

  const handleShowAllClick = () => {
    openRightPanel(GlobalPanelKeys.GlobalProfileAwards, { userId });
  };

  const items = useIaAwardBadgeData({
    items: dataset || [],
    userId,
  });

  return (
    <ProfileSection
      title={t('Awards')}
      actionComponent={
        totalCount > LIMIT && (
          <TextButton onClick={handleShowAllClick}>
            {t('button.Show All')}
          </TextButton>
        )
      }
      visible={isLoading || !isEmpty}
    >
      <Box sx={styles.container}>
        {isLoading && (
          <>
            <Skeleton variant="circular" width={84} height={84} />
            <Skeleton variant="circular" width={84} height={84} />
            <Skeleton variant="circular" width={84} height={84} />
          </>
        )}
        {items.map((item, index) => {
          return (
            <AwardBadgeCard.SimpleCard key={index} {...item}>
              <AwardBadgeCard.Tooltip
                title={item.name}
                content={item.levelInfo.requirement || ''}
                {...item}
              />
            </AwardBadgeCard.SimpleCard>
          );
        })}
      </Box>
    </ProfileSection>
  );
}
