import { useContext } from 'react';
import { useSort } from '@app/web/src/hooks/utils/useSort';
import SortFields from '@lib/ia/src/sort/SortFields';
import { SortCriteriaConfig, SortField } from '@lib/ia/src/sort/types';

import SortContext from '../context/SortContext';
import useSortActions from '../hooks/useSortActions';

export default function SortFieldsContent() {
  const { goPage } = useSortActions();
  const [{ scope }] = useContext(SortContext);

  const { sort } = useSort(scope);
  const selectedFields = sort.criteria.map((item) => item.field.name);

  const handleFieldSelected = (field: SortField) => {
    const newSort: SortCriteriaConfig = {
      field,
      direction: 'asc',
    };
    goPage({
      page: 'criteriaDetail',
      selectedCriteria: newSort,
    });
  };

  return (
    <SortFields
      fields={sort.fields}
      excludeNames={selectedFields}
      onSelect={handleFieldSelected}
      autoFocus
    />
  );
}
