import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { toast, useBaseRightPanel } from '@front/ui';
import { useQuizQuestionCount } from '@lib/web/apis';

import CreateQuizContext from '../../context';
import { CreateQuizPanelPrefix } from '../../enums';
import { CreateQuizFormValue } from '../../type';
import getActiveQuizButtonEl from '../../utils/getActiveQuizButtonEl';

export default function WatchAvailableQuestionCount({
  sectionId,
  maxQuestionCount,
  minQuestionCount,
  officialQuestionCount,
  forceAvailableQuestionCount,
}: {
  sectionId: string;
  maxQuestionCount: number;
  minQuestionCount: number;
  officialQuestionCount: number;
  forceAvailableQuestionCount?: number;
}) {
  const [createQuizSettings, setCreateQuizSettings] =
    useContext(CreateQuizContext);
  const hashtagsDisabled =
    createQuizSettings.disabledParts.includes('all') ||
    createQuizSettings.disabledParts.includes('topics');
  const difficultyDisabled =
    createQuizSettings.disabledParts.includes('all') ||
    createQuizSettings.disabledParts.includes('difficulty');
  const appearingDisabled =
    createQuizSettings.disabledParts.includes('all') ||
    createQuizSettings.disabledParts.includes('settings');
  const { watch, getFieldState } = useFormContext<CreateQuizFormValue>();
  const { t } = useTranslation('quiz');
  const [availableQuestionCount, setAvailableQuestionCount] = useState<
    number | undefined
  >(forceAvailableQuestionCount);
  const { rightPanelOpened, rightPanelTarget } = useBaseRightPanel();
  const isPanelOpened =
    rightPanelOpened && rightPanelTarget.includes(CreateQuizPanelPrefix);
  const difficulties = watch('difficulties', []);
  const tags = watch('tags', []);
  const marks = watch('marks', []);
  const appearing = watch('appearing', []);
  const isDifficultyDirty = getFieldState('difficulties').isDirty;
  const isTagDirty = getFieldState('tags').isDirty;
  const isMarkDirty = getFieldState('marks').isDirty;
  const isRelatedFieldsDirty = isDifficultyDirty || isTagDirty || isMarkDirty;
  const appearingHashtags =
    appearing.length === 0 || appearing.length === 3 || appearingDisabled
      ? []
      : appearing;

  const { data, error } = useQuizQuestionCount(
    sectionId
      ? {
          sectionId,
          markCodeAry: hashtagsDisabled ? undefined : marks,
          tagCodeAry: hashtagsDisabled
            ? undefined
            : [...tags, ...appearingHashtags],

          difficultyAry:
            difficultyDisabled ||
            // user selected none
            difficulties.length === 0
              ? undefined
              : difficulties,
        }
      : undefined
  );

  useEffect(() => {
    if (forceAvailableQuestionCount) return;
    if (data?.data.totalCount === 0 && isRelatedFieldsDirty) {
      const activeQuizButtonEl = getActiveQuizButtonEl();
      if (activeQuizButtonEl) {
        toast.dismiss();
        toast.error(t('practice.adjust.question', { count: 0 }), {
          anchorEl: isPanelOpened ? undefined : activeQuizButtonEl,
        });
      }
    }

    if (data?.data.totalCount !== undefined) {
      setAvailableQuestionCount(data.data.totalCount);
    } else if (error) {
      setAvailableQuestionCount(0);
    }
  }, [
    forceAvailableQuestionCount,
    data?.data.totalCount,
    error,
    isPanelOpened,
    isRelatedFieldsDirty,
    t,
  ]);

  useEffect(() => {
    setCreateQuizSettings((st) => ({
      ...st,
      availableQuestionCount,
      officialQuestionCount,
      maxQuestionCount,
      minQuestionCount,
    }));
  }, [
    availableQuestionCount,
    maxQuestionCount,
    minQuestionCount,
    officialQuestionCount,
    setCreateQuizSettings,
  ]);

  return null;
}
