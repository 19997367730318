import { createContext, Dispatch, SetStateAction } from 'react';
import { SortCriteriaConfig } from '@lib/ia/src/sort/types';

export interface SortContextValue {
  selectedCriteria?: SortCriteriaConfig;
  page?: 'sortSummary' | 'fieldList' | 'criteriaDetail';
  history: { currentIndex: number; items: SortContextValue[] };
  scope: string;
}

export const initialValue = {
  history: { currentIndex: -1, items: [] as SortContextValue[] },
  scope: 'global',
};

export type SortContextType = [
  SortContextValue,
  Dispatch<SetStateAction<SortContextValue>>
];

const SortContext = createContext<SortContextType>([initialValue, () => {}]);

export default SortContext;
