import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseLayout } from '@front/ui';

import { EmojiPicker } from '../../EmojiPickerPanel';

type FilterEmojiPickerContentProps = {
  onEmojiSelected?: (ev: GetMarkEmojiRes | null) => void;
  visible: boolean;
};

export default function FilterEmojiPicker({
  onEmojiSelected,
  visible,
}: FilterEmojiPickerContentProps) {
  const { openRightPanel, getRightParams } = useBaseLayout<GlobalPanelParams>();
  const { selectedCondition } = getRightParams(GlobalPanelKeys.GlobalFilter);

  const backToConditionDetail = () => {
    openRightPanel(GlobalPanelKeys.GlobalFilter, {
      page: 'conditionDetail',
      selectedCondition,
      showEmojiPicker: false,
    });
  };

  const handleEmojiSelected = (emoji: GetMarkEmojiRes | null) => {
    onEmojiSelected?.(emoji);
    backToConditionDetail();
  };

  if (!visible) return null;
  return <EmojiPicker onChange={handleEmojiSelected} />;
}
