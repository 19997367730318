import { useContext } from 'react';
import { useLeagueGroupList } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';

import { RankingsContext } from '../components/RankingsContextProvider';

type Options = {
  disabled?: boolean;
};

export default function useLeagueRankingList({ disabled }: Options = {}) {
  const { clubSlug: clubSlugProvided } = useContext(RankingsContext);
  const clubSlug = useClubSlug();

  const currentClubSlug = clubSlugProvided || clubSlug;

  return useLeagueGroupList({
    clubSlug: !disabled ? currentClubSlug : null,
    isGetFollowInfo: true,
  });
}
