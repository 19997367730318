import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Box, Chip } from '@mui/material';
import {
  OtherPlay as OtherPlayIcon,
  OtherSkip as OtherSkipIcon,
} from '@front/icon';
import { ResponsiveTooltip } from '@front/ui';
import { ExamMode } from '@lib/web/apis';

import CreateQuizContext from '../../context';
import { CreateQuizFormValue } from '../../type';

import Section from './Section';

export default function ModeSection() {
  const { t } = useTranslation('quiz');
  const { control } = useFormContext<CreateQuizFormValue>();
  const [createQuizSettings] = useContext(CreateQuizContext);
  const disabled =
    createQuizSettings.disabledParts.includes('all') ||
    createQuizSettings.disabledParts.includes('mode');

  const mockDisabled =
    createQuizSettings.maxQuestionCount <
    createQuizSettings.officialQuestionCount;
  return (
    <Section title={t('createQuiz.mode.title', 'Mode')}>
      <Box display="flex" gap={1}>
        <Controller
          name="mode"
          control={control}
          render={({ field: { value, onChange } }) => (
            <>
              <ResponsiveTooltip
                titleIcon={<OtherSkipIcon width={16} height={16} />}
                title={t('createQuiz.mode.turbo.hint.title', 'Turbo')}
                content={t(
                  'createQuiz.mode.turbo.hint.content',
                  'Practice mode with instant feedback on each attempt.'
                )}
                tooltipProps={{ followCursor: true }}
              >
                <Chip
                  data-testid="turbo-chip"
                  icon={<OtherSkipIcon width={16} height={16} />}
                  label={t('createQuiz.mode.turbo.title', 'Turbo')}
                  variant={value === ExamMode.Practice ? 'filled' : 'outlined'}
                  onClick={() => onChange(ExamMode.Practice)}
                  disabled={disabled}
                />
              </ResponsiveTooltip>
              <ResponsiveTooltip
                titleIcon={<OtherPlayIcon width={16} height={16} />}
                title={t('createQuiz.mode.mock.hint.title', 'Mock')}
                content={t(
                  'createQuiz.mode.mock.hint.content',
                  'Simulated test environment to give you a feel of the actual exam.'
                )}
                tooltipProps={{ followCursor: true }}
              >
                <Chip
                  data-testid="mode-chip"
                  icon={<OtherPlayIcon width={16} height={16} />}
                  label={t('createQuiz.mode.mock.title', 'Mock')}
                  variant={value === ExamMode.MockExam ? 'filled' : 'outlined'}
                  onClick={() => onChange(ExamMode.MockExam)}
                  disabled={disabled || mockDisabled}
                />
              </ResponsiveTooltip>
            </>
          )}
        />
      </Box>
    </Section>
  );
}
