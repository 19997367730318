import { Box } from '@mui/material';
import usePlaylistInfo from '@app/web/src/widgets/PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistInfo';

import PlaylistFilterChips from './PlaylistFilterChips';
import PracticeButton from './PracticeButton';
import RedoButtons from './RedoButtons';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    px: '20px',
    pb: 2,
    flexDirection: {
      xs: 'column-reverse',
      md: 'row',
    },
    alignItems: {
      xs: 'flex-start',
      md: 'center',
    },
    gap: 2,
    width: '100%',
  },
  chips: {
    flex: 1,
    width: '100%',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
};

export default function ActionsToolbar() {
  const { tagId } = usePlaylistInfo();
  return (
    <Box sx={styles.root}>
      <Box sx={styles.chips}>
        <PlaylistFilterChips />
      </Box>
      <Box sx={styles.buttons}>
        {tagId ? <PracticeButton /> : <RedoButtons />}
      </Box>
    </Box>
  );
}
