import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IaPlaylistQuestionAvailableAction } from '@app/web/src/ia/playlistQuestion/useIaPlaylistQuestionAvailableActions';
import { IaAction } from '@lib/ia/src/core/types';

export const useIaPlaylistQuestionEvents = () => {
  const { t } = useTranslation('playlist'); // XXX: might need to move to a proper namespace other than editor

  const getPlaylistQuestionEvents = useCallback((): {
    [key in IaPlaylistQuestionAvailableAction]?: WithRequired<
      IaAction,
      'icon' | 'text'
    >;
  } => {
    return {
      [IaPlaylistQuestionAvailableAction.ChangeEmoji]: {
        type: 'event' as const,
        text: t('Reaction'),
        icon: 'EmojiFavorite',
        value: IaPlaylistQuestionAvailableAction.ChangeEmoji,
      },
    };
  }, [t]);

  return {
    getPlaylistQuestionEvents,
  };
};
