import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import AwardBadgeCard from '@app/web/src/components/AwardBadgeCard';
import AwardBadgeGroup from '@app/web/src/components/AwardBadgeGroup';
import useProfileAwardsData from '@app/web/src/ia/award/useProfileAwardsData';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { ProfileAwards as ProfileAwardsIcon } from '@front/icon';
import { BaseLayoutRightPanel, useBaseLayout } from '@front/ui';
import EmojiText from '@lib/ia/src/components/EmojiMessage/EmojiMessage';
import { useProfileSocial } from '@lib/web/apis';

const styles = {
  root: {
    pt: { xs: 2, sm: 1 },
    pb: 5,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    maxWidth: { md: 744 },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    px: 2,
    py: 1,
  },
  loadingContainer: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(2, 1fr)',
      md: 'repeat(4, 1fr)',
    },
    gap: 1,
  },
};

export default function ProfileAwardsPanel() {
  const { t } = useTranslation('profile');
  const { rightPanelTarget, getRightParams } =
    useBaseLayout<GlobalPanelParams>();
  const { userId } = getRightParams(GlobalPanelKeys.GlobalProfileAwards);

  const { data: socialData } = useProfileSocial(userId);

  const {
    challengesItems,
    closeYourRingsItems,
    limitedEditionItems,
    onFireItems,
    socialButterflyItems,
    isEmpty,
    isLoading,
    hasSearch,
  } = useProfileAwardsData({ userId });

  return (
    <BaseLayoutRightPanel
      titleIcon={<ProfileAwardsIcon width="16" height="16" />}
      title={t('profile.RHS.awards.title', { name: socialData?.data.fullName })}
      inactive={rightPanelTarget !== GlobalPanelKeys.GlobalProfileAwards}
    >
      <BaseLayoutRightPanel.ScrollArea>
        <Box sx={styles.container}>
          {isEmpty && (
            <EmojiText
              text={t(hasSearch ? 'award.noSearchResults' : 'award.emptyText')}
            />
          )}
          <AwardBadgeGroup
            title={t('award.category.title.1')}
            items={challengesItems}
          />
          <AwardBadgeGroup
            title={t('award.category.title.2')}
            items={closeYourRingsItems}
          />
          <AwardBadgeGroup
            title={t('award.category.title.3')}
            items={limitedEditionItems}
          />
          <AwardBadgeGroup
            title={t('award.category.title.4')}
            items={onFireItems}
          />
          <AwardBadgeGroup
            title={t('award.category.title.5')}
            items={socialButterflyItems}
          />
          {isLoading && (
            <Box sx={styles.loadingContainer}>
              <AwardBadgeCard.Skeleton />
              <AwardBadgeCard.Skeleton />
            </Box>
          )}
        </Box>
      </BaseLayoutRightPanel.ScrollArea>
    </BaseLayoutRightPanel>
  );
}
