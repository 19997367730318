import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import {
  OtherFollowers as OtherFollowersIcon,
  OtherFollowing as OtherFollowingIcon,
  TestTrophy as TestTrophyIcon,
} from '@front/icon';
import { Tab, Tabs } from '@front/ui';

import FriendRankingTabContent from './components/FriendRankingTabContent';
import LeagueRankingTabContent from './components/LeagueRankingTabContent';
import RankingsContextProvider from './components/RankingsContextProvider';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      px: {
        md: '12px',
        xs: '20px',
      },
    },
  },
};

type RankingsContentProps = {
  tab: number;
  changeTab: (tab: number) => void;
  size?: 'sm' | 'md';
  scrollRef?: RefObject<HTMLDivElement>;
  clubSlug?: string;
};

export default function RankingsContent({
  tab,
  changeTab,
  size = 'md',
  scrollRef,
  clubSlug,
}: RankingsContentProps) {
  const { t } = useTranslation('club');

  return (
    <RankingsContextProvider
      size={size}
      clubSlug={clubSlug}
      scrollRef={scrollRef}
    >
      <Box sx={styles.root}>
        <Tabs
          scrollButtons={false}
          value={tab}
          textColor="inherit"
          variant="scrollable"
          sx={styles.tabs}
          onChange={(_, index) => changeTab(index)}
          className="ranking-tabs"
        >
          <Tab
            prefixIcon={<TestTrophyIcon width={16} height={16} />}
            label={t('League')}
          />
          <Tab
            prefixIcon={<OtherFollowersIcon width={16} height={16} />}
            label={t('Followers')}
          />
          <Tab
            prefixIcon={<OtherFollowingIcon width={16} height={16} />}
            label={t('Following')}
          />
        </Tabs>
        {tab === 0 && <LeagueRankingTabContent />}
        {tab === 1 && <FriendRankingTabContent variant="follower" />}
        {tab === 2 && <FriendRankingTabContent variant="following" />}
      </Box>
    </RankingsContextProvider>
  );
}
