import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemberNftAvatar } from '@app/web/src/hooks/utils/useMemberNftAvatar';
import { useDateFormat } from '@front/helper';
import { SquareAvatarStatus } from '@front/ui';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import {
  ProfileLayoutConfig,
  ProfileLayoutLoadingConfig,
} from '@lib/ia/src/layouts/ProfileLayout/types';
import { AgentIsAdd } from '@lib/web/apis';
import { getAgentStatus, useOnlineStatus } from '@lib/web/hooks';
import {
  getAgentIconPath,
  getClubIconPath,
  isAhaDefaultAgent,
} from '@lib/web/utils';

export const useAgentProfilePanelLayoutConfig = ({
  agent,
}: {
  agent?: GetIaAgentProfileRhsAgentDefaultRes;
}): IaLayoutConfig[] => {
  const { t } = useTranslation('common');
  const isDefaultAgent = isAhaDefaultAgent(agent);
  const creator = useMemberNftAvatar({
    userId: isDefaultAgent ? undefined : agent?.agentCreator,
  });
  const { displayDateFormat } = useDateFormat();

  const { getAgentOnlineStatus } = useOnlineStatus();
  const status = getAgentStatus(getAgentOnlineStatus());

  return useMemo(() => {
    if (!agent) {
      return [
        {
          layout: 'profile-layout-skeleton',
        } as ProfileLayoutLoadingConfig,
      ];
    }

    return [
      {
        layout: 'profile-layout',
        areas: [
          {
            areaType: 'avatar',
            key: 'avatar',
            avatar: {
              url: getAgentIconPath(agent),
              title: agent.agentName,
              subtitle: agent.agentUserName,
              showIndicator: true,
              status,
              statusInfo: {
                [SquareAvatarStatus.Away]: t(
                  'agent.status.away',
                  '{{name}}’s AI Model is currently unavailable',
                  { name: agent.agentName }
                ),
              },
            },
            properties: [
              {
                name: 'Users',
                value: agent.userCount,
              },
            ],
            buttons:
              agent.isAdd === AgentIsAdd.IsAdded
                ? [
                    {
                      key: 'removeAgentButton',
                      type: 'event',
                      value: 'removeMyAgent',
                      buttonVariant: 'outlined',
                      buttonColor: 'default',
                      icon: 'OtherAddFriend',
                      text: 'Following',
                      emphasize: false,
                      suffixIconName: 'ActionChevronDown',
                      buttonDropdownOptions: [
                        {
                          display: 'Unfollow',
                          value: 'removeMyAgent',
                          iconName: 'OtherUnfollow',
                        },
                      ],
                    },
                    {
                      key: 'directMessageButton',
                      type: 'event',
                      value: 'directMessage',
                      buttonVariant: 'filled',
                      buttonColor: 'default',
                      icon: 'ThreadsDirectMessages',
                      text: 'Message',
                      emphasize: false,
                      tooltip: t(
                        'agent::detail.message.tooltip',
                        "Sending a message to an AI you haven't follow will result in an automatic follow."
                      ),
                    },
                  ]
                : [
                    {
                      key: 'addAgentButton',
                      type: 'event',
                      value: 'addMyAgent',
                      buttonVariant: 'filled',
                      buttonColor: 'default',
                      icon: 'OtherAddFriend',
                      text: 'Follow',
                      emphasize: false,
                    },
                    {
                      key: 'directMessageButton',
                      type: 'event',
                      value: 'directMessage',
                      buttonVariant: 'filled',
                      buttonColor: 'default',
                      icon: 'ThreadsDirectMessages',
                      text: 'Message',
                      emphasize: false,
                      tooltip: t(
                        'agent::detail.message.tooltip',
                        "Sending a message to an AI you haven't follow will result in an automatic follow."
                      ),
                    },
                  ],
          },
          ...(isDefaultAgent
            ? []
            : [
                {
                  areaType: 'detail',
                  key: 'detail',
                  propertyTypography: 'body1',
                  properties: [
                    {
                      key: 'club',
                      iconType: 'image',
                      icon:
                        agent.agentClub.clubLogo ||
                        getClubIconPath(agent.agentClub.clubName),
                      text: agent.agentClub.clubName,
                    },
                    {
                      key: 'intro',
                      iconType: 'emoji',
                      icon: '👋',
                      text: agent.shortDesc,
                    },
                    {
                      key: 'createdAt',
                      iconType: 'emoji',
                      icon: '🗓',
                      text: `Created ${displayDateFormat(agent.createdAt)}`,
                    },
                    {
                      key: 'createdBy',
                      iconType: 'emoji',
                      icon: '🤓',
                      text: [
                        {
                          type: 'text',
                          value: 'Created by',
                        },
                        {
                          type: 'user',
                          id: agent.agentCreator,
                          name: creator.nftDisplay,
                          src: creator.nftAvatar,
                          indicators: creator.indicators,
                          action: {
                            type: 'event',
                            value: 'openUserProfile',
                          },
                        },
                      ],
                    },
                    {
                      key: 'longDesc',
                      text: agent.longDesc,
                    },
                  ],
                },
                {
                  areaType: 'detail',
                  key: 'shortcuts',
                  title: 'Shortcuts',
                  titleTypography: 'body1',
                  propertyTypography: 'body2',
                  properties:
                    agent.agentPrompts?.map((prompt) => ({
                      key: prompt.promptId,
                      iconType: 'emoji',
                      icon: prompt.promptIcon,
                      text: prompt.promptName,
                    })) || [],
                },
              ]),
        ],
      } as ProfileLayoutConfig,
    ];
  }, [
    agent,
    creator.indicators,
    creator.nftAvatar,
    creator.nftDisplay,
    displayDateFormat,
    isDefaultAgent,
    status,
    t,
  ]);
};
