import Router from 'next/router';
import { Theme, useMediaQuery } from '@mui/material';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import { useClubSlug } from '@lib/web/hooks';

function useQuizHashtagAndEmojiParams() {
  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();

  const { title, tagId, category } = getRightParams(
    GlobalPanelKeys.GlobalHashtagsDetail
  );
  return {
    id: tagId,
    title,
    category,
  };
}

export default function useQuizHashtagAndEmojiActions() {
  const slug = useClubSlug();
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { closeRightPanel } = useBaseRightPanel<GlobalPanelParams>();

  const { title, category } = useQuizHashtagAndEmojiParams();

  const goPlaylistPage = () => {
    const FILTER_QUERY_MAP = {
      'total-wrong': 'mistake',
      overtime: 'overtime',
      'out-of-practice': '',
    };
    const handleGoPlaylist = () => {
      Router.push(
        `/club/${slug}/playlists/hashtags/${title}?title=${title}&filter=${
          FILTER_QUERY_MAP[
            category as 'total-wrong' | 'overtime' | 'out-of-practice'
          ]
        }`
      );
    };

    if (mdDown) {
      closeRightPanel();
    }
    handleGoPlaylist();
  };

  return { goPlaylistPage };
}
