import React from 'react';
import { GlobalPanelKeys } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';

import EditClubInviteMembersPanel from '../ClubsPage/EditClub/EditClubLayout/components/RightPanels/EditClubInviteMembersPanel';

// The process here seems to be the same as EditClubInviteMembersPanel,
// so I'll directly use that component and see if there are any issues.
export default function ClubInvitationPanel() {
  const { rightPanelTarget } = useBaseRightPanel();
  return (
    <EditClubInviteMembersPanel
      inactive={rightPanelTarget !== GlobalPanelKeys.GlobalClubInvitation}
    />
  );
}
