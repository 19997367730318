import { useContext, useMemo } from 'react';
import {
  apis,
  buildHookMutate,
  PinChatType,
  PinChatUserStatus,
  PinChatViewSlug,
  useAuth,
  useIaPinChats,
} from '@lib/web/apis';
import { SharedUserContext } from '@lib/web/contexts';
import { call } from '@lib/web/utils';

export default function useOnboardingData(
  target:
    | 'practice'
    | 'practice/result'
    | 'playlist'
    | 'start'
    | 'start/challenge'
) {
  const { member } = useAuth();
  const { sid } = useContext(SharedUserContext);
  const userId = member?.userId;
  const { data, mutate } = useIaPinChats(
    {
      viewSlug: PinChatViewSlug.PinChatOverlayDefault,
      limit: 0,
    },
    { enable: !sid }
  );
  const mutateOnboarding = useMemo(() => buildHookMutate(mutate), [mutate]);

  return useMemo(() => {
    if (sid)
      return {
        finished: true,
        pins: [],
        defaultStepNum: 1,
        mutate,
        viewPin: async () => {},
      };

    if (!data) return undefined;

    const pins = data.data.items.filter(
      (item) =>
        item.referenceId === target && item.type === PinChatType.Onboarding
    );

    const defaultStepNum =
      pins.find((pin) =>
        pin.pinChatUsers.some(
          (user) =>
            user.userId === userId &&
            user.status === PinChatUserStatus.NotChecked
        )
      )?.stepNum ?? 1;

    return {
      finished: pins.every((pin) =>
        pin.pinChatUsers.some(
          (user) =>
            user.userId === userId && user.status === PinChatUserStatus.Checked
        )
      ),
      pins,
      defaultStepNum,
      viewPin: async (pinChatId: string) => {
        await mutateOnboarding(
          call(
            apis.pinChat.updatePinChatStatus({
              pinChatId,
              status: 1,
            })
          ),
          {
            optimisticData: data
              ? {
                  ...data.data,
                  items: data.data.items.map((item) =>
                    item.pinChatId === pinChatId
                      ? {
                          ...item,
                          pinChatUsers: item.pinChatUsers.map((user) =>
                            user.userId === userId
                              ? { ...user, status: PinChatUserStatus.Checked }
                              : user
                          ),
                        }
                      : item
                  ),
                }
              : undefined,
            revalidate: false,
          }
        );
      },
    };
  }, [sid, mutate, data, target, userId, mutateOnboarding]);
}
