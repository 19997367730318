import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { InfoTooltip, Scrollbar, SearchBar, Tab, Tabs } from '@front/ui';
import { MarkGroupEnum } from '@lib/web/apis';
import { useEmojiSource, useRecentMarks } from '@lib/web/hooks';
import slugify from 'slugify';

import { EMOJI_GROUP_MAP } from './constant';

const GROUP_TITLE_HEIGHT = 26;
const styles = {
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  main: { position: 'relative', flex: 1 },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  content: {
    px: { xs: 2.5, md: '12px' },
    pb: 2.5,
  },
  top: {
    px: { xs: 2.5, md: '12px' },
    display: 'grid',
    gap: 1,
  },
  tabs: {
    minHeight: 36,
    mx: { xs: -2.5, md: '-12px' },

    '& .MuiTabs-flexContainer': {
      gap: 0,
      px: { xs: 2.5, md: '12px' },
      width: 'min-content',
    },
    button: { flex: 1, minHeight: 36, minWidth: 48 },
  },
  container: {
    display: 'grid',
    gap: 2,
    pt: 2,
  },
  groups: {
    display: 'grid',
    gap: 2,
  },
  group: {
    h6: {
      m: 0,
      mb: '2px',
      typography: 'caption',
      opacity: 0.5,
      lineHeight: `${GROUP_TITLE_HEIGHT}px`,
    },
  },
  groupContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    columnGap: 0.5,
    rowGap: 1,
    button: {
      p: 0,
      m: 0,
      minWidth: 32,
      height: 32,
      bgcolor: 'transparent',
      border: 'unset',
      fontSize: 28,
      lineHeight: 1,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: '6px',
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
      },
    },
  },
  hide: {
    visibility: 'hidden',
  },
  searchItems: {
    py: '12px',
  },
  popper: {
    '& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom': {
      ml: 4.5,
      mt: -1,
    },
  },
  tooltipText: {
    opacity: 0.64,
  },
  skeleton: {
    minHeight: 36,
    borderRadius: 0.5,
  },
};

const groups = Object.values(EMOJI_GROUP_MAP);
type EmojiGroupType = Record<MarkGroupEnum, GetMarkEmojiRes[]>;

export type EmojiPickerProps = {
  value?: GetMarkEmojiRes | null;
  onChange?: (ev: GetMarkEmojiRes | null) => void;
  enableRemove?: boolean;
};
export default function EmojiPicker({
  value,
  onChange,
  enableRemove = true,
}: EmojiPickerProps) {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const groupRef = useRef<HTMLDivElement[]>([]);
  const groupTopRef = useRef<number[]>([]);
  const { emojiSource } = useEmojiSource();
  const { recentMarks: recentEmojis, mutateRecentMarksWithNewMark } =
    useRecentMarks();

  const handleClick = (emoji: GetMarkEmojiRes | null) => {
    if (emoji) {
      setSearch('');
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: 0,
        });
      }

      mutateRecentMarksWithNewMark(emoji);
    }

    onChange?.(emoji);
  };
  const handleTabChange = (_: any, index: number) => {
    setSearch('');
    const currentTarget = groupRef.current[index];

    if (currentTarget && scrollRef.current) {
      scrollRef.current.scrollTo({
        top: currentTarget.offsetTop + 1,
      });
    }
    setTab(index);
  };

  const emojis: EmojiGroupType = useMemo(() => {
    if (!emojiSource.length) return {} as EmojiGroupType;

    return emojiSource.reduce(
      (acc: EmojiGroupType, cur: GetMarkEmojiRes) => ({
        ...acc,
        [cur.type]: acc[cur.type as MarkGroupEnum]
          ? (acc[cur.type as MarkGroupEnum] as GetMarkEmojiRes[]).concat(cur)
          : [cur],
      }),
      {} as EmojiGroupType
    );
  }, [emojiSource]);

  const searchItems: GetMarkEmojiRes[] = useMemo(() => {
    if (!search || !emojiSource.length) return [];
    return emojiSource.filter(
      (d: GetMarkEmojiRes) =>
        d.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        d.code.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  }, [emojiSource, search]);

  useEffect(() => {
    const scrollTarget = scrollRef.current;
    const handleScroll = (ev: Event) => {
      const target = ev.target as HTMLDivElement;
      if (target) {
        const activeIndex = groupTopRef.current.findIndex(
          (number, i) =>
            target.scrollTop >= number &&
            (!groupTopRef.current[i + 1] ||
              groupTopRef.current[i + 1] > target.scrollTop)
        );

        setTab(activeIndex === -1 ? 0 : activeIndex);
      }
    };

    if (scrollTarget) {
      scrollTarget.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollTarget)
        scrollTarget.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const itemLength = emojiSource.length + recentEmojis.length;

  useEffect(() => {
    groupTopRef.current = groupRef.current.map(
      (groupDom) => groupDom.offsetTop
    );
  }, [itemLength, value]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.top}>
        <SearchBar
          placeholder={t('common::Type to Search')}
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
        />
        <Tabs
          sx={styles.tabs}
          value={tab}
          onChange={handleTabChange}
          textColor="inherit"
          variant="scrollable"
          scrollButtons={false}
        >
          {groups.map((group) => (
            <Tab key={group.code} label={group.icon} />
          ))}
        </Tabs>
      </Box>
      <Box sx={styles.main}>
        {!!search && (
          <Scrollbar sx={styles.scroll}>
            <Box sx={[styles.content, styles.searchItems]}>
              <Box sx={styles.groupContent}>
                {searchItems.map((emoji) => (
                  <button
                    key={emoji.name}
                    type="button"
                    onClick={() => handleClick(emoji)}
                  >
                    {emoji.code}
                  </button>
                ))}
              </Box>
            </Box>
          </Scrollbar>
        )}
        <Scrollbar
          sx={[styles.scroll, !!search && styles.hide]}
          scrollableNodeProps={{ ref: scrollRef }}
        >
          <Box sx={styles.content}>
            {emojiSource.length === 0 && (
              <Box sx={styles.container}>
                <Box sx={styles.groupContent}>
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                  <Skeleton sx={styles.skeleton} variant="rectangular" />
                </Box>
              </Box>
            )}
            {emojiSource.length > 0 && (
              <Box sx={styles.container}>
                {!!value && (
                  <Box sx={styles.group}>
                    <h6>Selected Emoji</h6>

                    <Box sx={styles.groupContent}>
                      <InfoTooltip
                        slotProps={{
                          popper: {
                            sx: styles.popper,
                          },
                        }}
                        title={
                          <Box>
                            <Typography variant="caption" display="block">
                              {value.name}
                            </Typography>
                            {enableRemove && (
                              <Typography
                                sx={styles.tooltipText}
                                variant="numberCaption"
                              >
                                Click to remove emoji
                              </Typography>
                            )}
                          </Box>
                        }
                      >
                        <button
                          type="button"
                          onClick={() => enableRemove && handleClick(null)}
                          data-testid="emoji-picker-remove-emoji"
                        >
                          {value.code}
                        </button>
                      </InfoTooltip>
                    </Box>
                  </Box>
                )}
                <Box sx={[styles.groups, !!search && styles.hide]}>
                  {groups.map((group, i) => (
                    <Box
                      key={group.code}
                      ref={(el: HTMLDivElement) => {
                        groupRef.current[i] = el;
                      }}
                      sx={styles.group}
                    >
                      {(group.code === 0 ||
                        !!emojis[group.code as MarkGroupEnum]) && (
                        <>
                          <h6>{group.title}</h6>
                          <Box
                            sx={styles.groupContent}
                            data-testid={`emoji-picker-group-${slugify(
                              group.title,
                              '_'
                            )}`}
                          >
                            {(group.code === 0
                              ? recentEmojis
                              : emojis[group.code as MarkGroupEnum]
                            ).map((emoji) => (
                              <button
                                key={emoji.name}
                                type="button"
                                onClick={() => handleClick(emoji)}
                              >
                                {emoji.code}
                              </button>
                            ))}
                          </Box>
                        </>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Scrollbar>
      </Box>
    </Box>
  );
}
