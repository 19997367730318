import { useCallback, useMemo } from 'react';
import { usePathname } from 'next/navigation';
import { Theme, useMediaQuery } from '@mui/material';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import { useAuth } from '@lib/web/apis';
import { useGetHelpUrl } from '@lib/web/hooks';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useThreadViewDetails } from '@lib/web/thread/hooks/view/useThreadViewDetails';
import { useLocationThreadViews } from '@lib/web/thread/hooks/views/useLocationThreadViews';
import { isChannelInTheSameFilter } from '@lib/web/thread/utils/channelUtils';
import {
  ResponsiveToolbarProps,
  ToolbarButtonItem,
  useRouteChangeOpenRightPanel,
} from '@lib/web/ui';
import { ParsedUrlQuery } from 'querystring';

import { useIaSearchActions } from './useIaSearchActions';
import { useIaSearchValues } from './useIaSearchValues';

type ReturnItems = Exclude<ResponsiveToolbarProps['items'], undefined>;
type ManageAgentsSettings =
  GlobalPanelParams[GlobalPanelKeys.GlobalManageAgents];

export const useCommonToolbarItems = ({
  enableManageAgents = true,
  enableGetHelp = true,
  enableSearch = false,
  enableThread = true,
  enableShare = true,
  enableLayout = false,
  searchPlaceholder,
  shareVirtualRouter,
  shareLinks,
}: {
  enableManageAgents?: boolean | ManageAgentsSettings;
  enableGetHelp?: boolean;
  enableSearch?: boolean;
  enableThread?: boolean;
  enableShare?: boolean;
  enableLayout?: boolean | 'table' | 'channel';
  searchPlaceholder?: string;
  shareVirtualRouter?: VirtualRouter;
  shareLinks?: ToolbarButtonItem['shareLinks'];
} = {}): ReturnItems => {
  const { isLogged } = useAuth();
  const { openRightPanel, isTargetPanelOpened } =
    useBaseRightPanel<GlobalPanelParams>();
  const getHelpUrl = useGetHelpUrl();
  const pathname = usePathname();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { keyword } = useIaSearchValues();
  const { toggleSearch, setKeyword } = useIaSearchActions();
  const { unreadChannels } = useThread();
  const { view } = useLocationThreadViews();
  const { getThreadViewDetail } = useThreadViewDetails();

  const hasUnreadChannelsForThisLocationView = useMemo(() => {
    const { channelFilters } = getThreadViewDetail(view);
    return unreadChannels.some((channel) =>
      isChannelInTheSameFilter(channel, channelFilters)
    );
  }, [getThreadViewDetail, unreadChannels, view]);

  useRouteChangeOpenRightPanel({
    whenUrlPanelKey: GlobalPanelKeys.GlobalManageAgents,
    panelParamsGetter: (query: ParsedUrlQuery) => ({
      highlightAgentId: query.agentId,
    }),
    clearParams: ['agentId'],
  });

  const handleOnSearch = useCallback(
    (value?: string) => {
      setKeyword(value);
    },
    [setKeyword]
  );

  const handleSearchClick = useCallback(() => {
    if (mdUp) return;
    toggleSearch(searchPlaceholder);
  }, [mdUp, searchPlaceholder, toggleSearch]);

  return useMemo(() => {
    const items: ReturnItems = [];

    if (isLogged && enableManageAgents) {
      const params =
        typeof enableManageAgents !== 'boolean' ? enableManageAgents : {};
      items.push({
        type: 'ManageAgents' as const,
        onClick: () => {
          openRightPanel(GlobalPanelKeys.GlobalManageAgents, params);
        },
        selected: isTargetPanelOpened(GlobalPanelKeys.GlobalManageAgents),
      });
    }

    if (isLogged && enableSearch) {
      items.push({
        type: 'Search' as const,
        onSearch: handleOnSearch,
        keyword,
        placeholder: searchPlaceholder,
        onClick: handleSearchClick,
      });
    }

    if (isLogged && enableThread) {
      items.push({
        type: 'ThreadView',
        onClick: () => {
          openRightPanel(GlobalPanelKeys.GlobalThreadView);
        },
        selected: isTargetPanelOpened(GlobalPanelKeys.GlobalThreadView),
        highlight: hasUnreadChannelsForThisLocationView,
      });
    }

    if (isLogged && enableGetHelp) {
      items.push({
        type: 'Help',
        onClick: () => {
          window.open(getHelpUrl, '_blank', 'noreferrer');
        },
      });
    }

    if (enableLayout) {
      items.push({
        type: 'Layout',
        onClick: () => {
          openRightPanel(GlobalPanelKeys.GlobalLayout, {
            supportableLayouts: {
              channel: enableLayout === true || enableLayout === 'channel',
              table: enableLayout === true || enableLayout === 'table',
            },
          });
        },
        selected: isTargetPanelOpened(GlobalPanelKeys.GlobalLayout),
      });
    }
    if (enableShare && !pathname?.includes('/form')) {
      items.push({
        type: 'Share',
        shareVirtualRouter,
        shareLinks,
      });
    }
    return items;
  }, [
    isLogged,
    enableManageAgents,
    enableSearch,
    enableThread,
    enableGetHelp,
    enableLayout,
    enableShare,
    pathname,
    isTargetPanelOpened,
    openRightPanel,
    handleOnSearch,
    keyword,
    searchPlaceholder,
    handleSearchClick,
    hasUnreadChannelsForThisLocationView,
    getHelpUrl,
    shareVirtualRouter,
    shareLinks,
  ]);
};
