import { useController, useFormContext } from 'react-hook-form';
import { alpha, Box, ButtonBase, Theme, Typography } from '@mui/material';
import { TestAdd as TestAddIcon } from '@front/icon';
import { InfoTooltip } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { EmojiOption, FilterField } from '../../types';

const styles = {
  root: {
    display: 'grid',
    gap: 2,
    width: '100%',
  },
  buttonWrapper: {
    width: '100%',
  },
  button: {
    gap: 1,
    px: 1,
    justifyContent: 'flex-start',
  },
  emojis: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 1,
    width: '100%',
  },
  emoji: {
    fontSize: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '33.5px',
    height: '33.5px',
    cursor: 'pointer',
    borderRadius: '6px',
    '&:hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    },
  },
  popper: {
    '& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom': {
      ml: 1.5,
      mt: 2,
    },
  },
  tooltipText: {
    opacity: 0.64,
  },
};

type IconFilterProps = {
  item: FilterField;
};
export default function IconFilter({ item }: IconFilterProps) {
  const { getIaAction } = useIaAction();
  const openEmojiPicker = getIaAction('openEmojiPicker');
  const removeEmoji = getIaAction<number>('removeEmoji');

  const { control } = useFormContext();
  const {
    field: { value },
  } = useController({
    control,
    name: item.name,
    rules: { required: true },
  });

  const selectedEmojis = (value || []) as EmojiOption[];

  const handleRemoveEmoji = (index: number) => {
    removeEmoji?.action(index);
  };

  const handleAddEmojiClick = () => {
    openEmojiPicker?.action();
  };

  return (
    <Box sx={styles.root}>
      {selectedEmojis && selectedEmojis.length > 0 && (
        <Box sx={styles.emojis}>
          {selectedEmojis.map((emoji, index) => (
            <InfoTooltip
              key={index}
              slotProps={{
                popper: {
                  sx: styles.popper,
                },
              }}
              followCursor
              title={
                <Box>
                  <Typography variant="caption" display="block">
                    {emoji.name}
                  </Typography>
                  <Typography sx={styles.tooltipText} variant="numberCaption">
                    Click to remove emoji
                  </Typography>
                </Box>
              }
            >
              <Typography
                sx={styles.emoji}
                variant="button"
                onClick={() => handleRemoveEmoji(index)}
              >
                {emoji.value}
              </Typography>
            </InfoTooltip>
          ))}
        </Box>
      )}
      <Box sx={styles.buttonWrapper}>
        <ButtonBase
          sx={styles.button}
          disableRipple
          onClick={handleAddEmojiClick}
        >
          <TestAddIcon width={16} height={16} />
          <Typography fontWeight={700} fontSize={14}>
            Select
          </Typography>
        </ButtonBase>
      </Box>
    </Box>
  );
}
