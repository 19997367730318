import { TagSelect } from '@front/ui';
import { useSelectFilter } from '@lib/ia/src/filter/FilterCondition/hooks/useSelectFilter';

import { FilterOptionsField } from '../../types';

type OptionSelectFilterProps = {
  item: FilterOptionsField;
  multiple?: boolean;
  showCheckbox?: boolean;
};

export default function OptionSelectFilter({
  item,
  multiple,
  showCheckbox,
}: OptionSelectFilterProps) {
  const {
    desktopAutocompleteProps,
    mobileAutocompleteProps,
    mobileBottomSheetProps,
    scrollHeight,
    filteredOptions,
    handleOptionClick,
  } = useSelectFilter({ item, multiple });

  return (
    <TagSelect
      options={filteredOptions}
      placeholder={item.placeholder}
      scrollHeight={scrollHeight}
      showCheckbox={showCheckbox}
      handleClick={handleOptionClick}
      desktopAutocompleteProps={desktopAutocompleteProps}
      mobileAutocompleteProps={mobileAutocompleteProps}
      mobileBottomSheetProps={mobileBottomSheetProps}
    />
  );
}
