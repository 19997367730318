import { TooltipListItemType } from '@front/ui';
import { TFunction } from 'i18next';

import { OrganizedTagType } from '../components/panels/CreateQuizHashtagsPanel/types';

export const getTopicTooltipContent = (
  t: TFunction,
  name: string,
  tab?: keyof GetAnalyticsWeaknessTopicRes
) => {
  if (!tab) {
    return t('quiz::createQuiz.topic.customize.hint.content', {
      context: 'default',
      tag: name,
    });
  }
  return t('quiz::createQuiz.topic.customize.hint.content', {
    context: tab === 'notPracticedYet' ? 'notPracticed' : tab,
    tag: name,
  });
};
export const getTopicTooltipProperties = (
  t: TFunction,
  item: OrganizedTagType,
  tab?: keyof GetAnalyticsWeaknessTopicRes
): TooltipListItemType[] => {
  const createTooltip = (
    icon: string,
    textKey: string,
    value: number
  ): TooltipListItemType => ({
    name: {
      icon,
      text: t(textKey),
    },
    value: {
      type: 'text',
      text: `${value}`,
    },
  });

  const tooltipsMap: Record<string, TooltipListItemType> = {
    mistake: createTooltip(
      'ActionCloseThick',
      'quiz::createQuiz.topic.customize.property.mistake',
      item.mistake.count
    ),
    overtime: createTooltip(
      'TestClockSolid',
      'quiz::createQuiz.topic.customize.property.overtime',
      item.overtime.count
    ),
    notPracticedYet: createTooltip(
      'OtherOutOfPractice',
      'quiz::createQuiz.topic.customize.property.notPracticed',
      item.notPracticedYet.count
    ),
  };

  const result: TooltipListItemType[] = [];

  if (!tab) {
    result.push(tooltipsMap.mistake);
    result.push(tooltipsMap.overtime);
    result.push(tooltipsMap.notPracticedYet);
  } else {
    result.push(tooltipsMap[tab]);
  }

  result.push(
    createTooltip(
      'ChatQuestionList',
      'quiz::createQuiz.topic.customize.property.totalQuestion',
      item.total
    )
  );

  return result;
};
