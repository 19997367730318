import { useMemo } from 'react';
import { Trans } from 'next-i18next';
import { LeagueZone, useClubLeagueInfo } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';
import { differenceInDays } from 'date-fns';

import useLeagueRankingList from './useLeagueRankingList';

export default function useNextLeagueHint() {
  const { club } = useCurrentIaClub();
  const { data: leagueInfoData } = useClubLeagueInfo(club?.clubSlug || null);
  const { data: rankingListData } = useLeagueRankingList();
  const leagueInfo = leagueInfoData?.data;
  const rankingList = rankingListData?.data;

  return useMemo(() => {
    if (!leagueInfo || !rankingList) return '';

    const daysLeft = leagueInfo.endAt
      ? differenceInDays(new Date(leagueInfo.endAt), new Date())
      : 0;

    if (rankingList.length >= 10) {
      const promotionCount = rankingList.filter(
        (item) => item.zone === LeagueZone.Promotion
      ).length;

      return (
        <Trans
          i18nKey="club::Top {{count}} advance to the next league in {{days}} days"
          components={{ span: <span /> }}
          values={{
            count: promotionCount,
            days: daysLeft,
          }}
        />
      );
    }

    return (
      <Trans
        i18nKey="club::Score {{score}} to advance to the next league in {{days}} days"
        components={{ span: <span /> }}
        values={{
          score: club?.fullScore || 0,
          days: daysLeft,
        }}
      />
    );
  }, [club?.fullScore, leagueInfo, rankingList]);
}
