import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { getClubIconPath } from '@app/web/src/utils/clubs';
import { BaseLayoutRightPanel } from '@front/ui';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import {
  IconListLayoutItem,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';
import { useProfileSocial, useUserJoinedClubs } from '@lib/web/apis';

type ClubsProps = {
  userId: string;
};

export default function Clubs({ userId }: ClubsProps) {
  const { data: clubData } = useUserJoinedClubs(userId);
  const { data: userData } = useProfileSocial(userId);

  const { t } = useTranslation('profile');

  const config: IaLayoutConfig[] = useMemo(() => {
    if (!clubData)
      return [
        {
          layout: 'icon-list-layout-skeleton',
        },
      ];

    const unlistedCount = userData?.data.numJoinedUnlistedClubs;
    const joinedCount = userData?.data.numJoinedClubs;

    const displayDataset: IconListLayoutItemObj[] = clubData.data.map(
      (item) => ({
        id: item.slug,
        title: item.name,
        description: item.apps[0]?.extra.latestRoundScore || '',
        avatarUrl: item.iconPath || getClubIconPath(item.name),
        action: {
          type: 'link',
          value: `/club/${item.slug}`,
        },
        actionMap: {
          hover: {
            value: 'clubHovered',
          },
        },
      })
    );

    return [
      {
        layout: 'icon-list-layout',
        items: (
          [
            unlistedCount
              ? t('ClubsWithUnlisted', {
                  count: joinedCount,
                  unlistedCount,
                })
              : t('Clubs', {
                  count: joinedCount,
                }),
          ] as IconListLayoutItem[]
        ).concat(displayDataset),
      },
    ];
  }, [
    clubData,
    t,
    userData?.data.numJoinedClubs,
    userData?.data.numJoinedUnlistedClubs,
  ]);

  return (
    <BaseLayoutRightPanel.ScrollArea>
      {config && <IaLayouts layouts={config} />}
    </BaseLayoutRightPanel.ScrollArea>
  );
}
