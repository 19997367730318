import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { buildSearchQuery } from '@lib/ia/src/filter/utils';

import { useAppSelector } from '../redux';

type Options = {
  scope?: string;
};

export default function useIaFilterValues({ scope = 'global' }: Options = {}) {
  const filter = useAppSelector((st) => st.filter);
  const { t } = useTranslation();

  return useMemo(
    () => ({
      hasFilter:
        !!filter.conditions[scope] && filter.conditions[scope].length > 0,

      hasActiveFilter:
        !!filter.conditions[scope] &&
        filter.conditions[scope].some((item) => !item.disabled),

      resetFilterDisabled:
        filter.conditions[scope] &&
        filter.conditions[scope].filter((condition) => !condition.disabled)
          .length === 0,

      filter: {
        conditions: filter.conditions[scope] || [],
        fields: filter.fields[scope] || [],
      },

      searchQuery: buildSearchQuery(t, filter.conditions[scope]),
    }),
    [t, filter.conditions, filter.fields, scope]
  );
}
