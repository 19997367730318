import React, { ReactNode } from 'react';
import { SimpleTooltip } from '@front/ui';
import { useAuth } from '@lib/web/apis';

export default function AuthGuard({
  children,
  additionalBlockerTip,
}: {
  children: ((ev: { disabled: boolean }) => ReactNode) | ReactNode;
  additionalBlockerTip?: string;
}) {
  const { isBlocking, blockerTip } = useAuth();

  if (!isBlocking && !additionalBlockerTip)
    return typeof children === 'function'
      ? children({ disabled: false })
      : children;

  return (
    <SimpleTooltip title={blockerTip || additionalBlockerTip}>
      <span>
        {typeof children === 'function'
          ? children({ disabled: true })
          : React.Children.map(children, (child) => {
              if (!React.isValidElement(child)) {
                return null;
              }

              return React.cloneElement(child, {
                ...child.props,
                disabled: true,
              });
            })}
      </span>
    </SimpleTooltip>
  );
}
