import { useCallback, useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import SuggestionPanel from '@app/web/src/components/SuggestionPanel';
import { SuggestionUser } from '@app/web/src/components/SuggestionPanel/types';
import { appConfig } from '@front/config';
import { useDateFormat } from '@front/helper';
import {
  ActionApplyModifier as ActionApplyModifierIcon,
  OtherAddFriend as OtherAddFriendIcon,
  OtherChallengeSettings as OtherChallengeSettingsIcon,
  OtherFriendsChallenge as OtherFriendsChallengeIcon,
} from '@front/icon';
import {
  Button,
  IconButton,
  ResponsiveTooltip,
  TextButton,
  TooltipList,
  TooltipListItem,
  useBaseRightPanel,
} from '@front/ui';
import { MemberSearchCategory } from '@lib/web/apis';

import CreateQuizContext from '../../../context';
import { CreateQuizPanelKeys } from '../../../enums';
import useClosePanel from '../../../hooks/useClosePanel';
import { CreateQuizFormValue } from '../../../type';
import PanelStartButton from '../../parts/PanelStartButton';

import AddChallengersOnboarding from './AddChallengersOnboarding';
import AutoMatchPane from './AutoMatchPane';
import useAddChallenger from './useAddChallenger';

const styles = {
  settingsButton: {
    marginLeft: 'auto',
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addFriendButton: {
    mt: 1,
    color: 'text.primary',
  },
};

const enabledSearchMode = [
  MemberSearchCategory.Followers,
  MemberSearchCategory.Followings,
  MemberSearchCategory.SameDreamSchool,
  MemberSearchCategory.SameTargetScore,
];

export default function CreateQuizChallengersPanel() {
  const { t } = useTranslation('quiz');

  const { openRightPanel, rightPanelOpened } = useBaseRightPanel();
  const { dateFormat } = useDateFormat();
  const [{ disabledParts, challengerMap }] = useContext(CreateQuizContext);
  const { watch, setValue, formState } = useFormContext<CreateQuizFormValue>();
  const [{ panelKeyPrefix, formId, variant }] = useContext(CreateQuizContext);
  const searchMode = watch(
    'memberSearchCategory',
    MemberSearchCategory.Followings
  );
  const challengeSettingPanelKey = `${panelKeyPrefix}${CreateQuizPanelKeys.ChallengeSettings}`;

  const disabled =
    disabledParts.includes('all') ||
    disabledParts.includes('challenge') ||
    disabledParts.includes('challenge-exclude-add-challengers');
  const disableRemoveUser = disabledParts.includes(
    'challenge-exclude-add-challengers'
  );

  const challengerValue = watch('challengers', []);
  const randomChallengerCount = watch('randomChallengerCount');
  const maxAttempt = watch('maxAttempt');
  const deadline = watch('deadline');
  const challengerCount =
    Number(randomChallengerCount) + challengerValue.length;
  const panelKey = `${panelKeyPrefix}${CreateQuizPanelKeys.AddFriends}`;
  const { toggleRightPanel } = useBaseRightPanel();

  const handleClosePanel = useClosePanel();

  const selectedChallengers = useMemo(() => {
    const result: SuggestionUser[] = [];
    challengerValue.forEach((id) => {
      const challenger = challengerMap[id];

      if (challenger) {
        result.push(challenger);
      }
    });
    return result;
  }, [challengerMap, challengerValue]);

  const isSelected = useCallback(
    (id: string) => {
      return challengerValue.some((challengerId) => challengerId === id);
    },
    [challengerValue]
  );
  const isDisabled = useCallback(
    (id: string) => {
      return (
        disabled ||
        (isSelected(id) && disableRemoveUser) ||
        challengerValue.length + randomChallengerCount >=
          appConfig.maxChallengersCount
      );
    },
    [
      challengerValue.length,
      disableRemoveUser,
      disabled,
      isSelected,
      randomChallengerCount,
    ]
  );

  const handleToggleUser = useAddChallenger({
    isSelected,
  });

  const handleToggle = useCallback(
    (value: SuggestionUser) => {
      if (value) handleToggleUser(value);
    },
    [handleToggleUser]
  );

  const settingsButton = (
    <ResponsiveTooltip
      title={t(
        'createQuiz.challenge.settings.hint.title',
        'Challenge Settings'
      )}
      titleIcon={<OtherChallengeSettingsIcon width={16} height={16} />}
      content={
        <TooltipList content={t('createQuiz.challenge.settings.hint.content')}>
          <TooltipListItem
            name={{
              icon: 'OtherChallengeAttempt',
              text: t(
                'createQuiz.challenge.settings.property.attempts',
                'Attempts'
              ),
            }}
            value={
              maxAttempt === -1
                ? { type: 'icon', icon: 'TestInfinity' }
                : { type: 'text', text: `${maxAttempt}` }
            }
          />
          <TooltipListItem
            name={{
              icon: 'PropertyTypeDateOrTime',
              text: t(
                'createQuiz.challenge.settings.property.deadline',
                'Deadline'
              ),
            }}
            value={{ type: 'text', text: dateFormat(deadline) }}
          />
        </TooltipList>
      }
      tooltipProps={{ followCursor: true }}
    >
      <IconButton
        data-testid="challenge-settings-button"
        onClick={() => openRightPanel(challengeSettingPanelKey)}
        sx={styles.settingsButton}
        customSize={24}
      >
        <OtherChallengeSettingsIcon />
      </IconButton>
    </ResponsiveTooltip>
  );

  const otherChipOptions = useMemo(() => {
    return [
      {
        icon: <ActionApplyModifierIcon width={16} height={16} />,
        display: t('createQuiz.challengers.chip.autoMatch.text'),
        tip: t('createQuiz.challengers.chip.autoMatch.tip'),
        value: 'auto' as const,
      },
    ];
  }, [t]);

  const renderModePane = useCallback(
    (mode: 'auto' | MemberSearchCategory) => {
      if (mode === 'auto') return <AutoMatchPane disabled={disabled} />;
      return undefined;
    },
    [disabled]
  );
  return (
    <>
      <SuggestionPanel
        panelProps={{
          title: t('createQuiz.challengers.title', 'Add Challengers'),
          titleIcon: <OtherFriendsChallengeIcon width="16" height="16" />,
          maximizeWidth: false,
          toolComponent: settingsButton,
          onIconClick: handleClosePanel,
          actionComponent:
            variant === 'panel' ? (
              <Box sx={styles.action}>
                <Button
                  type="submit"
                  form={formId}
                  prefixIcon={<OtherFriendsChallengeIcon />}
                  loading={formState.isSubmitting}
                  disabled={!challengerCount}
                >
                  {t('button.Create Challenge')}
                </Button>
              </Box>
            ) : (
              <PanelStartButton />
            ),
        }}
        searchPlaceholder={t(
          'createQuiz.challengers.search.placeholder',
          'Find a friend to challenge...'
        )}
        selectedItems={selectedChallengers}
        enabledSearchMode={enabledSearchMode}
        selectedSearchMode={searchMode}
        otherOptions={otherChipOptions}
        lightbulbText={t(
          'challenge.settings.searchChallengers.tip',
          'Challenge friends using their username, email, or name.'
        )}
        onSearchModeChange={(value) => setValue('memberSearchCategory', value)}
        renderEmptyText={(mode) => {
          return (
            <Box typography="body2">
              <Trans
                i18nKey="quiz::createQuiz.challengers.search.empty"
                components={{
                  br: <br />,
                }}
                context={`${mode}`}
              />
              {(mode === MemberSearchCategory.Followings ||
                mode === MemberSearchCategory.Followers) && (
                <Box sx={styles.addFriendButton}>
                  <TextButton
                    prefixIcon={<OtherAddFriendIcon />}
                    onClick={() => toggleRightPanel(panelKey)}
                  >
                    {t('createQuiz.challengers.cta_addFriends')}
                  </TextButton>
                </Box>
              )}
            </Box>
          );
        }}
        renderModePane={renderModePane}
        isSelected={isSelected}
        isDisabled={isDisabled}
        onToggleItem={handleToggle}
      />
      {rightPanelOpened && <AddChallengersOnboarding />}
    </>
  );
}
