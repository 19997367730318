import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import {
  MainChallenge as MainChallengeIcon,
  OtherPlay as OtherPlayIcon,
} from '@front/icon';
import { Button } from '@front/ui';

import CreateQuizContext from '../../context';
import { CreateQuizFormValue } from '../../type';

export default function PanelStartButton() {
  const [{ formId }] = useContext(CreateQuizContext);
  const { t } = useTranslation('quiz');
  const { formState, watch } = useFormContext<CreateQuizFormValue>();

  const challengerValue = watch('challengers', []);
  const randomChallengerCount = watch('randomChallengerCount', 0);

  const hasChallengers =
    challengerValue.length > 0 || randomChallengerCount > 0;

  if (!formState.isDirty) return undefined;
  return (
    <Button
      loading={formState.isSubmitting}
      sx={{ ml: { md: 'auto' } }}
      type="submit"
      form={formId}
      prefixIcon={hasChallengers ? <MainChallengeIcon /> : <OtherPlayIcon />}
    >
      {hasChallengers
        ? t('createQuiz.buttons.startChallenge', 'Start Challenge')
        : t('createQuiz.buttons.start', 'Start Practice')}
    </Button>
  );
}
