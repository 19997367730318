import { useTranslation } from 'react-i18next';
import { GlobalPanelKeys } from '@app/web/src/types/panel';
import { OtherFollowers as OtherFollowersIcon } from '@front/icon';
import { BaseLayoutRightPanel, useBaseLayout } from '@front/ui';
import { useMemberInfo } from '@lib/web/apis';

import UserList from '../components/ProfilePanelContent/UserList';

export default function FollowersPanel() {
  const { t } = useTranslation('profile');
  const { openRightPanel, rightPanelParams } = useBaseLayout();
  const { userId } = rightPanelParams;
  const { data: userData } = useMemberInfo(userId);

  const displayName =
    userData?.data.displayName || userData?.data.distinctName || '';

  const userFirstName = displayName.split(' ')[0];

  const handleClick = (targetId: string) => {
    openRightPanel(GlobalPanelKeys.GlobalProfile, {
      userId: targetId,
    });
  };

  return (
    <BaseLayoutRightPanel
      titleIcon={<OtherFollowersIcon width="16" height="16" />}
      title={t('##s Followers', { name: userFirstName })}
    >
      <UserList userId={userId} variant="follower" onClick={handleClick} />
    </BaseLayoutRightPanel>
  );
}
