import { useEffect, useState } from 'react';
import { Box, MenuItem } from '@mui/material';
import { formatDateString } from '@front/helper';
import {
  ActionChevronFilledLeft as ActionChevronFilledLeftIcon,
  ActionChevronFilledRight as ActionChevronFilledRightIcon,
} from '@front/icon';
import { DatePicker, Select, TextField } from '@front/ui';
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

import {
  RelativeToTodayDateFilterValue,
  TimeOrientation,
  TimePeriod,
} from '../../../types';

const styles = {
  root: {
    display: 'grid',
    gap: '4px',
  },
  options: {
    display: 'flex',
    gap: 2,
  },
  picker: {
    width: '100%',
  },
};

function getDateValues(
  orientation: TimeOrientation,
  period: TimePeriod,
  amount: number
): Date[] {
  const today = startOfDay(new Date());
  if (orientation === 'this') {
    switch (period) {
      case 'day':
        return [today];
      case 'week':
        return [startOfWeek(today), endOfWeek(today)];
      case 'month':
        return [startOfMonth(today), endOfMonth(today)];
      case 'year':
        return [startOfYear(today), endOfYear(today)];
    }
  }
  if (orientation === 'past') {
    switch (period) {
      case 'day':
        return [subDays(today, amount), today];
      case 'week':
        return [subWeeks(today, amount), today];
      case 'month':
        return [subMonths(today, amount), today];
      case 'year':
        return [subYears(today, amount), today];
    }
  }
  switch (period) {
    case 'day':
      return [today, addDays(today, amount)];
    case 'week':
      return [today, addWeeks(today, amount)];
    case 'month':
      return [today, addMonths(today, amount)];
    case 'year':
      return [today, addYears(today, amount)];
  }
}

type RelativeToTodayFilterProps = {
  value: RelativeToTodayDateFilterValue | null;
  onChange: (value: RelativeToTodayDateFilterValue | null) => void;
};
export default function RelativeToTodayFilter({
  value,
  onChange,
}: RelativeToTodayFilterProps) {
  const [orientation, setOrientation] = useState<TimeOrientation>(
    value?.options.orientation || 'this'
  );
  const [period, setPeriod] = useState<TimePeriod>(
    value?.options.period || 'day'
  );
  const [amount, setAmount] = useState(value?.options.amount || 1);

  const updateValue = (
    newValue: Date[] | null,
    options: {
      orientation: TimeOrientation;
      period: TimePeriod;
      amount: number;
    }
  ) => {
    if (!newValue) {
      onChange?.(null);
      return;
    }

    onChange?.({
      type: 'date',
      dateType: 'relativeToToday',
      value: newValue?.map((date) => formatDateString(date)),
      options: {
        orientation: options.orientation,
        period: options.period,
        amount: options.orientation !== 'this' ? options.amount : null,
      },
    });
  };

  const handleAmountChange = (newValue: number) => {
    if (newValue > 0) {
      setAmount(newValue);
      const newDateValues = getDateValues(orientation, period, newValue);
      updateValue(newDateValues, { orientation, period, amount: newValue });
    }
  };

  const handleOrientationChange = (newValue: TimeOrientation) => {
    setOrientation(newValue);
    const newDateValues = getDateValues(newValue, period, amount);
    updateValue(newDateValues, { orientation: newValue, period, amount });
  };

  const handlePeriodChange = (newValue: TimePeriod) => {
    setPeriod(newValue);
    const newDateValues = getDateValues(orientation, newValue, amount);
    updateValue(newDateValues, { orientation, period: newValue, amount });
  };

  useEffect(() => {
    if (!value) {
      const today = startOfDay(new Date());
      updateValue([today], { orientation, period, amount });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const dates = value?.value?.map((d) => startOfDay(new Date(d)));
  return (
    <>
      <Box sx={styles.root}>
        <Box sx={styles.options}>
          <Select
            labelIcon={null}
            value={orientation}
            onChange={(ev) =>
              handleOrientationChange(ev.target.value as TimeOrientation)
            }
            size="rwd"
          >
            <MenuItem value="past">Past</MenuItem>
            <MenuItem value="next">Next</MenuItem>
            <MenuItem value="this">This</MenuItem>
          </Select>
          {orientation !== 'this' && (
            <TextField
              labelIcon={null}
              value={amount}
              onChange={(e) => handleAmountChange(+e.target.value)}
              type="number"
            />
          )}
          <Select
            labelIcon={null}
            value={period}
            onChange={(ev) => handlePeriodChange(ev.target.value as TimePeriod)}
            size="rwd"
          >
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
            <MenuItem value="year">Year</MenuItem>
          </Select>
        </Box>
        <DatePicker
          value={dates}
          prevIcon={<ActionChevronFilledLeftIcon />}
          nextIcon={<ActionChevronFilledRightIcon />}
          sx={styles.picker}
          type="range"
        />
      </Box>
    </>
  );
}
