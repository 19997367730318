import { alpha, Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { Icon } from '@front/ui';

const styles = {
  root: {
    display: 'grid',
    px: {
      xs: '20px',
      md: '12px',
    },
    py: 1,
  },
  cell: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
  textCell: {
    justifyContent: 'flex-start',
  },
};

type CellType = {
  icon?: string;
  content?: string;
};

function getCells({
  hideStreak = false,
  hideCta = false,
}: {
  hideStreak?: boolean;
  hideCta?: boolean;
}) {
  return [
    { content: 'Name' },
    { icon: 'TestTrophy' },
    { icon: 'TestScoreTally' },
    !hideStreak && { icon: 'EmojiFire' },
    !hideCta && {},
  ].filter((cell) => cell !== false) as CellType[];
}

function Spacing() {
  return <Box />;
}

type RankingListHeaderProps = {
  gridTemplateColumns: string;
};

export default function RankingListHeader({
  gridTemplateColumns,
}: RankingListHeaderProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const hideCta = !mdUp;
  const cells = getCells({ hideCta });

  return (
    <Box sx={[styles.root, { gridTemplateColumns }]}>
      {cells.map((cell, index) => (
        <>
          {index !== 0 && <Spacing />}
          <Box
            key={index}
            sx={[styles.cell, !!cell.content && styles.textCell]}
          >
            {cell.content && (
              <Typography variant="caption">{cell.content}</Typography>
            )}
            {cell.icon && <Icon name={cell.icon} width={16} height={16} />}
          </Box>
        </>
      ))}
    </Box>
  );
}
