import { useContext, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import FilterContext, { FilterContextValue } from '../context/FilterContext';

function updateState(state: FilterContextValue) {
  const history = { ...state.history };
  const items = history.items || [];

  history.items = [
    ...items.slice(0, history.currentIndex + 1),
    cloneDeep(state),
  ];
  history.currentIndex = history.items.length - 1;

  return { ...state, history };
}

function backState(state: FilterContextValue): FilterContextValue {
  const history = { ...state.history };
  const items = history.items || [];
  const historyIndex = history.currentIndex ?? -1;

  if (items.length === 0 || historyIndex - 1 < 0) {
    return state;
  }

  history.currentIndex = historyIndex - 1;
  const current = items[historyIndex - 1];
  return { ...(current || state), history };
}

function forwardState(state: FilterContextValue): FilterContextValue {
  const history = { ...state.history };
  const items = history.items || [];
  const historyIndex = history.currentIndex ?? -1;

  if (items.length === 0 || historyIndex + 1 >= items.length) {
    return state;
  }

  history.currentIndex = historyIndex + 1;
  const current = items[historyIndex + 1];
  return { ...(current || state), history };
}

export default function useFilterActions() {
  const [, setSettings] = useContext(FilterContext);

  return useMemo(() => {
    return {
      goPage: (params: Partial<FilterContextValue>) => {
        setSettings((prev) => updateState({ ...prev, ...params }));
      },
      back: () => {
        setSettings((prev) => backState(prev));
      },
      forward: () => {
        setSettings((prev) => forwardState(prev));
      },
      reset: () => {
        setSettings((prev) => ({
          ...prev,
          history: prev.history.items.length
            ? { currentIndex: 0, items: [prev.history.items[0]] }
            : prev.history,
        }));
      },
      clear: () => {
        setSettings((prev) => ({
          scope: prev.scope,
          history: { currentIndex: -1, items: [] },
        }));
      },
    };
  }, [setSettings]);
}
