import {
  EmojiActivity,
  EmojiAnimal,
  EmojiFace,
  EmojiFlag,
  EmojiFood2,
  EmojiObject,
  EmojiTravel2,
  TestClock,
} from '@front/icon';
import { MarkGroupEnum } from '@lib/web/apis';

// eslint-disable-next-line import/prefer-default-export
export const EMOJI_GROUP_MAP = {
  0: {
    code: 0,
    title: 'Recently Used',
    icon: <TestClock />,
  },
  [MarkGroupEnum.EmojiSmileysAndPeople]: {
    code: MarkGroupEnum.EmojiSmileysAndPeople,
    title: 'Smileys & people',
    icon: <EmojiFace />,
  },
  [MarkGroupEnum.EmojiAnimalsAndNature]: {
    code: MarkGroupEnum.EmojiAnimalsAndNature,
    title: 'Animals & Nature',
    icon: <EmojiAnimal />,
  },
  [MarkGroupEnum.EmojiFoodAndDrink]: {
    code: MarkGroupEnum.EmojiFoodAndDrink,
    title: 'Food & Drink',
    icon: <EmojiFood2 />,
  },
  [MarkGroupEnum.EmojiActivity]: {
    code: MarkGroupEnum.EmojiActivity,
    title: 'Activity',
    icon: <EmojiActivity />,
  },
  [MarkGroupEnum.EmojiTravelAndPlaces]: {
    code: MarkGroupEnum.EmojiTravelAndPlaces,
    title: 'Travel & Places',
    icon: <EmojiTravel2 />,
  },
  [MarkGroupEnum.EmojiObjects]: {
    code: MarkGroupEnum.EmojiObjects,
    title: 'Objects',
    icon: <EmojiObject />,
  },
  [MarkGroupEnum.EmojiFlags]: {
    code: MarkGroupEnum.EmojiFlags,
    title: 'Flags',
    icon: <EmojiFlag />,
  },
};
