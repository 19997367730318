import { Theme } from '@mui/material';
import { isDate } from '@front/helper';
import ChannelLayout from '@lib/ia/src/layouts/ChannelLayout';
import {
  MessageItemEvent,
  NotificationIconConfig,
} from '@lib/ia/src/layouts/ChannelLayout/types';
import { useAuth } from '@lib/web/apis';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { getTrackElementAttributes } from '@lib/web/utils';
import { Channel, DefaultGenerics } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

const styles = {
  button: {
    background:
      'linear-gradient(92.61deg, #00D1FF 0%, #0051F9 48.44%, #7000FF 100%)',
    borderRadius: 1,
    border: 'none',
    color: (theme: Theme) => theme.palette.text.primary,
  },
  content: {
    color: 'alpha.lightA50',
  },
};

type UpgradeCtaMessageProps = {
  channel: Channel<DefaultGenerics>;
  message: StreamMessage<StreamChatGenerics>;
};

export default function UpgradeCtaMessage({
  channel,
  message,
}: UpgradeCtaMessageProps) {
  const { member } = useAuth();
  const { getThreadUser } = useThread();
  const { customUserId: messageUserId, customCtaStatus: status } = message;

  if (
    !messageUserId ||
    !member?.memberId ||
    messageUserId !== member.memberId
  ) {
    return null;
  }

  const threadUser = getThreadUser(message.user?.id);
  const senderName = threadUser?.name || 'Aha AI';
  const senderIcon: NotificationIconConfig = threadUser?.image
    ? {
        avatarUrl: threadUser.image,
        name: threadUser.name ?? '',
      }
    : {
        name: 'CryptoCurrencyAha',
        bgColor: '#080808',
      };

  const actionBarEvents: MessageItemEvent[] = [
    {
      type: 'event',
      value: 'tryAgain',
      text: 'Try Again',
      icon: 'ActionTryAgain',
      metadata:
        message.customCtaStatus === 'completed'
          ? {
              channelType: channel.type,
              channelId: channel.id,
              message,
            }
          : undefined,
    },
  ];

  return (
    <ChannelLayout.NotificationItem
      type="notification"
      id={message.id}
      name={senderName}
      icon={senderIcon}
      content={message.text || ''}
      sentTime={
        isDate(message.created_at)
          ? message.created_at.toISOString()
          : message.created_at ?? ''
      }
      cta={
        status === 'pending'
          ? [
              {
                type: 'button',
                label: 'Upgrade',
                action: {
                  type: 'link',
                  value: '/extend-trial',
                },
                buttonSx: styles.button,
                HTMLAttributes: getTrackElementAttributes({
                  elementName: 'upgrade-button',
                  fromLocation: 'run-out-of-token',
                }),
              },
            ]
          : []
      }
      contentSxProps={styles.content}
      helperText="This message is only visible to you"
      actionBarEvents={actionBarEvents}
    />
  );
}
