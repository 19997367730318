import { forwardRef, PropsWithChildren, ReactNode } from 'react';
import { alpha, Box, ButtonBase, Theme, Typography } from '@mui/material';
import { MaskIcon } from '@front/ui';

const styles = {
  root: {
    mx: -1,
  },
  button: {
    width: '100%',
    p: 1,
    textAlign: 'left',
    display: 'grid',
    gridTemplateColumns: '32px 1fr',
    gap: 1,
    borderRadius: 1,
    alignItems: 'flex-start',
    '&:not(:disabled)': {
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
    '&:disabled': {
      opacity: 0.64,
    },
  },
  icon: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
};

type SectionItemProps = PropsWithChildren<{
  icon: ReactNode;
  display: string;
  content?: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}>;
const SectionItem = forwardRef(
  (
    {
      children,
      selected = false,
      disabled = false,
      icon,
      display,
      content,
      onClick,
      ...rest
    }: SectionItemProps,
    ref
  ) => {
    const hasValue = !!children;
    return (
      <Box sx={styles.root} {...rest} ref={ref}>
        <ButtonBase
          sx={[
            styles.button,
            selected && styles.selected,
            !hasValue && { alignItems: 'center' },
          ]}
          onClick={onClick}
          disabled={disabled}
        >
          <Box sx={[hasValue && { pt: 0.5 }]}>
            <MaskIcon>
              <Box sx={styles.icon}>{icon}</Box>
            </MaskIcon>
          </Box>
          <Box display="grid" gap={hasValue ? 0.5 : 0}>
            <Typography variant="body2" fontWeight="bold">
              {display}
            </Typography>
            {content && <Typography variant="body2">{content}</Typography>}
            {children}
          </Box>
        </ButtonBase>
      </Box>
    );
  }
);

SectionItem.displayName = 'SectionItem';
export default SectionItem;
