import { AwardBadgeCategory, AwardBadgeSubType } from '@lib/web/apis';

type AwardSubTypeConfig = {
  nameId: string | null;
};

/**
 * there are some sub types has not enabled yet cause they have not designed yet
 * details [here](https://www.notion.so/rootdomain/c8d6323875b045f09d7ac7eb868df4db?v=9f44969d19744700b91118a575fcad72)
 */
export const enabledAwardSubTypes: AwardBadgeSubType[] = [
  /**
   * Phase 1:
   */
  AwardBadgeSubType.bulls_eye__goal_ring_complete_day_count,
  AwardBadgeSubType.scholar__practice_question_taken_count,
  AwardBadgeSubType.challenger__challenge_start_count,
  AwardBadgeSubType.participant__challenge_taken_count,
  AwardBadgeSubType.sharpshooter__no_mistake_practice_taken_count,
  AwardBadgeSubType.winner__challenge_taken_rank_1_count,
  AwardBadgeSubType.friendly__social_friend_count,
  AwardBadgeSubType.new_user__user_signed_up,
  AwardBadgeSubType.wild_fire__streak_count,

  /**
   * Phase 2: Not enabled yet
   */
  // AwardBadgeSubType.champion__league_rank_1,
  // AwardBadgeSubType.legend__league_advanced_tier,
  // AwardBadgeSubType.unrivaled__league_commandus_rank_1,
  // AwardBadgeSubType.influencer__social_refer_count,
];

/**
 * to get which awards are supported for now
 * details [here](https://www.notion.so/rootdomain/c8d6323875b045f09d7ac7eb868df4db?v=9f44969d19744700b91118a575fcad72)
 */
export const awardSubTypeIdsMap: Record<AwardBadgeSubType, AwardSubTypeConfig> =
  {
    [AwardBadgeSubType.cupids_study_session__special_date_practice]: {
      nameId: '1',
    },
    [AwardBadgeSubType.eco_friendly_expert__special_date_practice]: {
      nameId: '2',
    },
    [AwardBadgeSubType.egg_cellent_student__special_date_practice]: {
      nameId: '3',
    },
    [AwardBadgeSubType.jolly_study_session__special_date_practice]: {
      nameId: '4',
    },
    [AwardBadgeSubType.mothers_day__special_date_practice]: {
      nameId: '5',
    },
    [AwardBadgeSubType.new_years_nerd__special_date_practice]: {
      nameId: '6',
    },
    [AwardBadgeSubType.party_and_practice__special_date_practice]: {
      nameId: '7',
    },
    [AwardBadgeSubType.photogenic__add_profile_picture]: {
      nameId: '8',
    },
    [AwardBadgeSubType.pot_o_knowledge__special_date_practice]: {
      nameId: '9',
    },
    [AwardBadgeSubType.slice_of_study_pie__special_date_practice]: {
      nameId: '10',
    },
    [AwardBadgeSubType.spooky_scholar__special_date_practice]: {
      nameId: '11',
    },
    [AwardBadgeSubType.stars_stripes_and_study__special_date_practice]: {
      nameId: '12',
    },
    [AwardBadgeSubType.bulls_eye__goal_ring_complete_day_count]: {
      nameId: '13',
    },
    [AwardBadgeSubType.perfect_week_all__goal_ring_perfect_week_all_count]: {
      nameId: '14',
    },
    [AwardBadgeSubType.perfect_week_mock__goal_ring_perfect_week_mock_count]: {
      nameId: '15',
    },
    [AwardBadgeSubType.perfect_week_practice__goal_ring_perfect_week_practice_count]:
      {
        nameId: '16',
      },
    [AwardBadgeSubType.perfect_week_problems__goal_ring_perfect_week_question_count]:
      {
        nameId: '17',
      },
    [AwardBadgeSubType.practice_goal_2x__goal_ring_goal_multiply_2]: {
      nameId: '18',
    },
    [AwardBadgeSubType.practice_goal_3x__goal_ring_goal_multiply_3]: {
      nameId: '19',
    },
    [AwardBadgeSubType.scholar__practice_question_taken_count]: {
      nameId: '20',
    },
    [AwardBadgeSubType.challenger__challenge_start_count]: {
      nameId: '21',
    },
    [AwardBadgeSubType.champion__league_rank_1]: {
      nameId: '22',
    },
    [AwardBadgeSubType.legend__league_advanced_tier]: {
      nameId: '23',
    },
    [AwardBadgeSubType.participant__challenge_taken_count]: {
      nameId: '24',
    },
    [AwardBadgeSubType.sharpshooter__no_mistake_practice_taken_count]: {
      nameId: '25',
    },
    [AwardBadgeSubType.unrivaled__league_commandus_rank_1]: {
      nameId: '26',
    },
    [AwardBadgeSubType.winner__challenge_taken_rank_1_count]: {
      nameId: '27',
    },
    [AwardBadgeSubType.friendly__social_friend_count]: {
      nameId: '28',
    },
    [AwardBadgeSubType.influencer__social_refer_count]: {
      nameId: '29',
    },
    [AwardBadgeSubType.new_user__user_signed_up]: {
      nameId: '30',
    },
    [AwardBadgeSubType.longest_practice_streak__streak_new_record]: {
      nameId: '31',
    },
    [AwardBadgeSubType.wild_fire__streak_count]: {
      nameId: '32',
    },
    [AwardBadgeSubType.club_mock_master__taken_count]: {
      nameId: null,
    },
    [AwardBadgeSubType.first_club_action__mock]: {
      nameId: null,
    },
    [AwardBadgeSubType.first_club_action_practice]: {
      nameId: null,
    },
  };

export const awardCategoryPathMap: Record<AwardBadgeCategory, string | null> = {
  [AwardBadgeCategory.Unknown]: null,
  [AwardBadgeCategory.Challenges]: 'challenges',
  [AwardBadgeCategory.CloseYourRings]: 'close-your-rings',
  [AwardBadgeCategory.LimitedEdition]: 'limited-edition',
  [AwardBadgeCategory.OnFire]: 'on-fire',
  [AwardBadgeCategory.SocialButterfly]: 'social-butterfly',
};
