import { Box, Popper, PopperProps, Theme, Typography } from '@mui/material';
import { useOutsideClick } from '@front/helper';

import FilterContent from './FilterContent';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    bgcolor: 'background.menu',
    zIndex: 'modal',
    py: '6px',
    borderRadius: 1,
    width: 265,
    border: (theme: Theme) =>
      theme.palette.mode === 'light'
        ? '1px solid rgba(8, 8, 8, 0.05)'
        : '1px solid rgba(255, 255, 255, 0.1)',
  },
  title: {
    opacity: 0.5,
    lineHeight: '27px',
    px: '12px',
  },
  popper: {
    px: 2.5,
    zIndex: 2,
  },
};

export default function FilterPopup({
  anchorEl,
  open,
  onClose,
}: {
  open: boolean;
  anchorEl: PopperProps['anchorEl'];
  onClose: () => void;
}) {
  const ref = useOutsideClick(onClose);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      sx={styles.popper}
      placement="left-start"
      disablePortal
    >
      <Box ref={ref} sx={styles.root}>
        <Typography sx={styles.title} variant="caption">
          Filter
        </Typography>
        <FilterContent />
      </Box>
    </Popper>
  );
}
