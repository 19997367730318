import { forwardRef } from 'react';
import { useCurrentIaClub } from '@lib/web/hooks';
import { v4 } from 'uuid';

import CreateQuizSkeleton from './components/CreateQuizSkeleton';
import { CreateQuizProvider, CreateQuizProviderProps } from './context';
import CreateQuiz, { CreateQuizHandler, CreateQuizProps } from './CreateQuiz';

const CreateQuizByClub = forwardRef<
  CreateQuizHandler,
  Omit<
    CreateQuizProps,
    | 'minQuestionCount'
    | 'maxQuestionCount'
    | 'officialQuestionCount'
    | 'sectionId'
  > &
    CreateQuizProviderProps
>((props, ref) => {
  const {
    challengerSource,
    panelKeyPrefix,
    disabledParts,
    defaultValues,
    challengeMode,
    formId = v4(),
    clubSlug,
    ...rest
  } = props;

  const { club } = useCurrentIaClub(clubSlug);

  if (!club) return <CreateQuizSkeleton variant={rest.variant} />;

  return (
    <CreateQuizProvider
      variant={rest.variant}
      challengerSource={challengerSource}
      disabledParts={disabledParts}
      panelKeyPrefix={panelKeyPrefix}
      challengeMode={challengeMode}
      formId={formId}
      clubSlug={clubSlug}
    >
      <CreateQuiz
        ref={ref}
        formId={formId}
        key={club.clubSlug}
        sectionId={club.sectionId}
        defaultValues={{
          questionCount: Math.min(
            club.officialQuestionCount,
            club.totalQuestionCount
          ),
          ...defaultValues,
        }}
        minQuestionCount={1}
        maxQuestionCount={club.totalQuestionCount}
        officialQuestionCount={club.officialQuestionCount}
        {...rest}
      />
    </CreateQuizProvider>
  );
});

CreateQuizByClub.displayName = 'CreateQuizByClub';
export default CreateQuizByClub;
