import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Box, FilterOptionsState, Theme, useMediaQuery } from '@mui/material';
import { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { formatDateString } from '@front/helper';
import {
  ActionChevronFilledLeft as ActionChevronFilledLeftIcon,
  ActionChevronFilledRight as ActionChevronFilledRightIcon,
} from '@front/icon';
import {
  BottomDropdown,
  DatePicker,
  NewAutocomplete,
  TextField,
} from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import {
  addDays,
  addMonths,
  addWeeks,
  format,
  isMatch,
  parse,
  startOfDay,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { isNil } from 'lodash';

import { SingleDateFilterValue } from '../../../types';

const styles = {
  root: {
    display: 'grid',
    gap: '4px',
  },
  picker: {
    width: '100%',
  },
  paper: {
    '& .MuiAutocomplete-option': {
      pt: '1px !important',
      pb: '1px !important',
      height: '28px',
    },
    '& .MuiAutocomplete-noOptions': {
      display: 'none',
    },
  },
};

type Option = {
  label: string;
  value: string;
  customDisplay?: string;
};

const OPTIONS: Option[] = [
  { label: 'Today', value: 'today' },
  { label: 'Tomorrow', value: 'tomorrow' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'One week ago', value: 'one_week_ago' },
  { label: 'One week from now', value: 'one_week_from_now' },
  { label: 'One moth ago', value: 'one_moth_ago' },
  { label: 'One moth from now', value: 'one_moth_from_now' },
  { label: '', value: 'custom', customDisplay: 'Custom date' },
];

function getDateValue(option: Option) {
  const today = startOfDay(new Date());
  switch (option.value) {
    case 'today':
      return today;
    case 'yesterday':
      return subDays(today, 1);
    case 'tomorrow':
      return addDays(today, 1);
    case 'one_week_ago':
      return subWeeks(today, 1);
    case 'one_week_from_now':
      return addWeeks(today, 1);
    case 'one_moth_ago':
      return subMonths(today, 1);
    case 'one_moth_from_now':
      return addMonths(today, 1);
    case 'custom':
    default:
      return null;
  }
}

function customOption(dateStr: string): Option {
  return { value: dateStr, label: dateStr };
}

function getOption(
  value: SingleDateFilterValue | null,
  dateFormat: string
): Option | null {
  if (!value) return null;
  const option = OPTIONS.find((item) => item.value === value.option);
  if (option) return option;
  if (value.value) {
    const date = new Date(value.value);
    const valueStr = format(date, dateFormat);
    return customOption(valueStr);
  }
  return null;
}

type AutocompleteProps = MuiAutocompleteProps<any, any, any, any>;

type SingleDateFilterProps = {
  dateFormat?: string;
  value: SingleDateFilterValue | null;
  onChange: (value: SingleDateFilterValue | null) => void;
  placeholder?: string;
};
export default function SingleDateFilter({
  value,
  onChange,
  dateFormat = 'dd/MM/yyyy',
  placeholder,
}: SingleDateFilterProps) {
  const { getIaAction } = useIaAction();
  const onEnter = getIaAction('onEnter');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [open, setOpen] = useState(false);

  const [selectedValue, setSelectedValue] = useState<Option | null>(
    getOption(value, dateFormat)
  );

  const isDateValid = (newValue: string) => {
    return (
      newValue.length === dateFormat.length && isMatch(newValue, dateFormat)
    );
  };

  const handleInputKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      onEnter?.action();
    }
  };

  const updateValue = (newValue: Date | null, option?: Option | null) => {
    if (!newValue) {
      onChange?.(null);
      return;
    }
    onChange?.({
      type: 'date',
      dateType: 'singleDate',
      value: newValue ? formatDateString(newValue) : null,
      option: option?.value || null,
    });
  };

  const handleOptionChange = (newValue: Option | null) => {
    setSelectedValue(newValue);
    setOpen(false);
    if (!newValue) {
      updateValue(null);
      return;
    }

    // in case of users type a valid date
    if (isDateValid(newValue.value)) {
      const date = parse(newValue.value, dateFormat, new Date());
      updateValue(date, newValue);
      return;
    }

    const newDateValue = getDateValue(newValue);
    updateValue(newDateValue, newValue);
    if (newValue.value === 'custom') {
      setSelectedValue(null);
    }
  };

  const handlePickerChange = (newValue: Date) => {
    updateValue(newValue, selectedValue);
    setSelectedValue(customOption(format(newValue, dateFormat)));
  };

  const handleInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const newValue = ev.target.value;
    if (isDateValid(newValue)) {
      const newDateValue = parse(newValue, dateFormat, new Date());
      updateValue(newDateValue, selectedValue);
      setSelectedValue(customOption(newValue));
    }
  };

  const handleFilterOptions = (
    items: Option[],
    state: FilterOptionsState<Option>
  ) => {
    return items.filter((option) => option?.label.includes(state.inputValue));
  };

  const isOptionEqualToValue = (option: Option | null, val: Option | null) => {
    if (OPTIONS.some((o) => o.value === val?.value)) {
      return option?.value === val?.value;
    }
    return option?.value === 'custom';
  };

  const renderInput: AutocompleteProps['renderInput'] = ({
    InputProps,
    inputProps,
    InputLabelProps,
    size,
    ...params
  }) => (
    <TextField
      ref={InputProps.ref}
      endAdornment={InputProps.endAdornment}
      startAdornment={InputProps.startAdornment}
      {...params}
      inputProps={inputProps}
      labelIcon={null}
      placeholder={placeholder || 'Select one or more options...'}
      size="rwd"
      autoFocus
      onChange={handleInputChange}
      onKeyDown={handleInputKeyDown}
    />
  );

  const date = value?.value ? startOfDay(new Date(value?.value)) : null;
  return (
    <>
      <Box sx={styles.root}>
        <NewAutocomplete
          disablePortal
          options={OPTIONS}
          onChange={(ev, option) => handleOptionChange(option)}
          slotProps={{ paper: { sx: styles.paper } }}
          componentsProps={{ popper: { sx: { marginTop: '-20px!important' } } }}
          renderInput={renderInput}
          value={selectedValue}
          isOptionEqualToValue={isOptionEqualToValue}
          filterOptions={handleFilterOptions}
          renderOption={(props, option) => (
            <li {...props}>
              {isNil(option.customDisplay)
                ? option.label
                : option.customDisplay}
            </li>
          )}
          open={mdUp && open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
        />
        <DatePicker
          value={date}
          onChange={handlePickerChange}
          prevIcon={<ActionChevronFilledLeftIcon />}
          nextIcon={<ActionChevronFilledRightIcon />}
          sx={styles.picker}
        />
      </Box>
      <BottomDropdown
        open={!mdUp && open}
        onClose={() => setOpen(false)}
        options={OPTIONS.map((option) => ({
          display: option.customDisplay || option.label,
          value: option.value,
        }))}
        onClick={(option) =>
          handleOptionChange({ label: option.display, value: option.value })
        }
      />
    </>
  );
}
