import {
  IaPropertyAttribute,
  IaPropertyBoolean,
  IaPropertyColor,
  IaPropertyEmoji,
  IaPropertyLongText,
  IaPropertyNumber,
  IaPropertySelect,
} from '@lib/ia/src/core/types';
import { Difficulty } from '@lib/web/apis';

type DifficultyProperty = {
  isDynamicDifficulty: boolean;
  dynamicDifficulty: Difficulty;
  staticDifficulty: Difficulty;
};

export type IaPlaylistQuestion = {
  number: IaPropertyNumber;
  lineColor: IaPropertyColor;
  difficulty: DifficultyProperty | null;
  overtime: IaPropertySelect<'overtime' | 'notOvertime'>;
  content: IaPropertyLongText;
  note: IaPropertyLongText;
  emoji: IaPropertyEmoji | null;
  isAnswered: IaPropertyBoolean;
};

export const iaPlaylistQuestionAttributes: Record<
  keyof IaPlaylistQuestion,
  IaPropertyAttribute
> = {
  number: {
    name: 'NO',
    icon: 'ChatHashtag',
  },
  lineColor: {
    name: '',
    icon: '',
  },
  difficulty: {
    name: 'Difficulty',
    icon: 'EditorDifficulty',
  },
  overtime: {
    name: 'OT',
    icon: 'TestClock',
  },
  content: {
    name: 'Question Content',
    icon: 'EditorParaphrase',
  },
  note: {
    name: 'Notes',
    icon: 'OtherNotes',
  },
  emoji: {
    name: 'Emoji',
    icon: 'EmojiFavorite',
  },
  isAnswered: {
    // This one is not listed on ia document
    name: 'Answered',
    icon: 'TestCheck',
  },
};
