import React from 'react';
import { useTranslation } from 'next-i18next';
import { alpha, Box, Theme } from '@mui/material';
import useVerifyEmailButton from '@app/web/src/widgets/AccountPage/hooks/useVerifyEmailButton';
import { timeFormat } from '@front/helper';
import { OtherResend as OtherResendIcon } from '@front/icon';
import { Button } from '@front/ui';
import { useAuth } from '@lib/web/apis';

const styles = {
  success: {
    background: (theme: Theme) => alpha(theme.palette.success.light, 0.15),
  },
};

export default function ResendVerifyEmailButton() {
  const { seconds, sent, verifyEmail } = useVerifyEmailButton({
    countdownDelay: 2000,
  });
  const { t } = useTranslation('notification');
  const { member } = useAuth();

  if (member?.emailVerified === false)
    return (
      <Box display="grid" gap={1}>
        <Button
          prefixIcon={sent ? undefined : <OtherResendIcon />}
          onClick={verifyEmail}
          disabled={sent}
          color={sent && !seconds ? 'success' : undefined}
          sx={[sent && !seconds && styles.success]}
        >
          {seconds
            ? timeFormat(seconds)
            : sent
            ? t('email.verify.cta.sent')
            : t('email.verify.cta.text')}
        </Button>
      </Box>
    );

  return null;
}
