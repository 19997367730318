import { forwardRef } from 'react';
import Router from 'next/router';
import { useTranslation } from 'next-i18next';
import { OtherPlay as OtherPlayIcon } from '@front/icon';
import { BaseLayoutRightPanel, toast, useBaseLayout } from '@front/ui';
import { useCurrentExam, useCurrentIaClub } from '@lib/web/hooks';

import { PrivatePanelKeys } from '../../types/panel';

import { CreateQuizProviderProps } from './context';
import { CreateQuizHandler, CreateQuizProps } from './CreateQuiz';
import CreateQuizByClub from './CreateQuizByClub';
import { CreateQuizPanelPrefix } from './enums';

const styles = {
  hide: {
    visibility: 'hidden',
    maxHeight: 0,
    overflow: 'hidden',
  },
  scroll: {
    '& .simplebar-content, & .simplebar-content > div, form': {
      height: '100%',
    },
    '& .simplebar-content > div': {
      pb: 0,
    },
  },
};

export type CreateQuizByPanelProps = Partial<
  Pick<
    CreateQuizProps,
    | 'defaultValues'
    | 'onGetQuizUrl'
    | 'onBeforeGetQuiz'
    | 'forceAvailableQuestionCount'
  > &
    Omit<CreateQuizProviderProps, 'variant'>
> & { startPanelKey?: string };
const CreateQuizByPanel = forwardRef<CreateQuizHandler, CreateQuizByPanelProps>(
  ({ startPanelKey = PrivatePanelKeys.CreateQuiz, ...props }, ref) => {
    const { club } = useCurrentIaClub(props.clubSlug);
    const { section } = useCurrentExam(props.clubSlug);
    const { t } = useTranslation('quiz');
    const { rightPanelTarget } = useBaseLayout();
    const { panelKeyPrefix = '' } = props;
    if (
      rightPanelTarget !== `${panelKeyPrefix}${startPanelKey}` &&
      !rightPanelTarget.includes(`${panelKeyPrefix}${CreateQuizPanelPrefix}`)
    )
      return null;

    if (!club || !section) return null;

    // TODO: check exist quiz
    // TODO: check selected topics

    const handleRedirect = async (url: string | null) => {
      if (url === null) {
        toast.error('Something went wrong, please try again', undefined, {
          id: 'question-alert',
        });
        return;
      } else if (url) await Router.push(url);
    };

    return (
      <BaseLayoutRightPanel
        titleIcon={<OtherPlayIcon width="16" height="16" />}
        title={t('createQuiz.title', 'Start Practice')}
        resizable
        inactive={rightPanelTarget !== `${panelKeyPrefix}${startPanelKey}`}
      >
        <BaseLayoutRightPanel.ScrollArea sx={styles.scroll}>
          <BaseLayoutRightPanel.Content>
            <CreateQuizByClub
              ref={ref}
              onGetQuizUrl={handleRedirect}
              {...props}
              variant="panel"
            />
          </BaseLayoutRightPanel.Content>
        </BaseLayoutRightPanel.ScrollArea>
      </BaseLayoutRightPanel>
    );
  }
);

CreateQuizByPanel.displayName = 'CreateQuizByPanel';
export default CreateQuizByPanel;
