import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
  ActionClose as ActionCloseIcon,
  OtherResend as OtherResendIcon,
} from '@front/icon';
import { TextButton } from '@front/ui';

const styles = {
  root: {
    px: '12px',
    py: 1,
    display: 'grid',
    gap: 1,
  },
  action: {
    display: 'flex',
    gap: 2,
  },
  dismissAction: {
    px: 0,
  },
};
export default function ErrorResult({
  error,
  isLoading,
  onRetry,
}: {
  error?: string;
  isLoading?: boolean;
  onRetry?: () => void;
}) {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setShowError(!!error);
  }, [error]);

  if (!showError) return null;

  return (
    <Box sx={styles.root}>
      <Typography color="error.dark" variant="body2">
        {error}
      </Typography>
      <Box sx={styles.action}>
        <TextButton
          onClick={() => setShowError(false)}
          prefixIcon={<ActionCloseIcon />}
          sx={styles.dismissAction}
        >
          Dismiss
        </TextButton>
        <TextButton
          onClick={onRetry}
          prefixIcon={<OtherResendIcon />}
          loading={isLoading}
        >
          Retry
        </TextButton>
      </Box>
    </Box>
  );
}
