import { useMemo } from 'react';

import { OrganizedTagType } from './types';

export default function useQuizTopicRowItems({
  data,
  search,
  tab,
}: {
  data: OrganizedTagType[];
  search: string;
  tab?: keyof GetAnalyticsWeaknessTopicRes;
}): OrganizedTagType[] {
  return useMemo(() => {
    const trimSearch = search.trim().toLowerCase();
    const filterItems = tab ? data.filter((d) => d[tab].count > 0) : data;
    return filterItems
      .filter(
        (item) => !trimSearch || item.name.toLowerCase().includes(trimSearch)
      )
      .sort((a, b) => (tab ? b[tab].count - a[tab].count : b.total - a.total));
  }, [data, search, tab]);
}
