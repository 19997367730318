export enum SearchState {
  Initial,
  Searching,
  Result,
  Loading,
}

export const getSearchState = ({
  isSearching,
  isFocused,
  hasSelected,
}: {
  isSearching: boolean;
  isFocused: boolean;
  hasSelected: boolean;
}) => {
  if (isSearching) return SearchState.Searching;
  if (hasSelected && !isFocused) return SearchState.Result;
  if (isFocused) return SearchState.Initial;

  return null;
};
