import { useMemo } from 'react';
import { ThreadSearchUserIconListLayoutItemObj } from '@app/web/src/components/ThreadSearchUsers';
import useCurrentUserId from '@app/web/src/hooks/utils/useCurrentUserId';
import {
  AgentUserViewSlug,
  ClubBotType,
  useAgentUsers,
  useClubBots,
} from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';
import { getAgentIconPath } from '@lib/web/utils';

const apiDataToIconListLayoutItemObj = (
  agent: {
    agentId: string;
    agentName: string;
    agentPhoto: string;
    agentUserName: string;
  },
  preselectedMemberIds: string[] = [],
  preselectedDescription?: string
): ThreadSearchUserIconListLayoutItemObj => {
  const threadMemberId = `agent_${agent.agentId}`;

  return {
    id: agent.agentId,
    title: agent.agentName,
    titleAction: { type: 'event' as const, value: 'agentTitleClick' },
    description: preselectedMemberIds.includes(threadMemberId)
      ? preselectedDescription
      : undefined,
    avatarUrl: getAgentIconPath(agent),
    titleSuffixIcon: 'ProfileClubAgent',
    titleSuffixTooltipTitle: 'AI',
    actionMap: {
      select: {
        value: 'itemSelected',
      },
      hover: {
        value: 'itemHovered',
      },
    },
    metadata: {
      category: 'agent',
      threadMemberIds: [threadMemberId],
    },
  };
};

export const useSearchAgents = ({
  isSearching,
  debouncedSearch,
  preselectedMemberIds,
  preselectedDescription,
  enableSearchClubHelpAgent = false,
}: {
  isSearching: boolean;
  debouncedSearch: string;
  preselectedMemberIds?: string[];
  preselectedDescription?: string;
  enableSearchClubHelpAgent?: boolean;
}) => {
  const clubSlug = useClubSlug();
  const userId = useCurrentUserId();

  const { dataset: initialApiDataset, isLoading: initialApiLoading } =
    useAgentUsers({
      viewSlug: AgentUserViewSlug.SearchAgentUserDefault,
      search: `userId:${userId}`,
      searchFields: 'userId:eq',
      orderBy: 'order',
      sortBy: 'asc',
    });

  const { data: helpBots } = useClubBots({
    clubSlug,
    agentType: ClubBotType.Help,
    enable: enableSearchClubHelpAgent,
  });
  const helpBot = helpBots?.data.items.find((item) => item.isDefault);

  const { dataset: searchingApiDataset, isLoading: searchingApiLoading } =
    useAgentUsers(
      {
        viewSlug: AgentUserViewSlug.SearchAgentUserDefault,
        search: `userId:${userId}`,
        searchFields: 'userId:eq',
        expandSearch: `agent:agentName:${debouncedSearch}`,
        expandSearchFields: 'agent:agentName:contain',
        orderBy: 'order',
        sortBy: 'asc',
        limit: 50,
      },
      {
        enable: isSearching,
      }
    );

  const initialDataset = useMemo(
    () => [
      ...(helpBot
        ? [
            apiDataToIconListLayoutItemObj(
              {
                agentId: helpBot.id,
                agentName: helpBot.displayName,
                agentUserName: helpBot.userName,
                agentPhoto: helpBot.photoPath,
              },
              preselectedMemberIds,
              preselectedDescription
            ),
          ]
        : []),
      ...initialApiDataset
        .filter((data) => data.agent.agentId !== helpBot?.id)
        .map((data) =>
          apiDataToIconListLayoutItemObj(
            data.agent,
            preselectedMemberIds,
            preselectedDescription
          )
        ),
    ],
    [helpBot, initialApiDataset, preselectedDescription, preselectedMemberIds]
  );

  const searchingDataset = useMemo(
    () =>
      searchingApiDataset.map((data) =>
        apiDataToIconListLayoutItemObj(
          data.agent,
          preselectedMemberIds,
          preselectedDescription
        )
      ),
    [preselectedDescription, preselectedMemberIds, searchingApiDataset]
  );

  return useMemo(
    () => ({
      initialDataset,
      searchingDataset,
      initialApiLoading,
      searchingApiLoading,
    }),
    [initialApiLoading, initialDataset, searchingApiLoading, searchingDataset]
  );
};
