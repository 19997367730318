import { useTranslation } from 'react-i18next';
import QuickFilterChips from '@app/web/src/components/QuickFilterChips';
import { FilterChipItem } from '@app/web/src/components/QuickFilterChips/types';
import useFilterPlaylist from '@app/web/src/widgets/PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistFilter';
import usePlaylistSummary from '@app/web/src/widgets/PlaylistDetailPage/PlaylistDetail/hooks/usePlaylistSummary';
import { ActionClose as ActionCloseIcon } from '@front/icon';

import { FILTER_SORT_SCOPE } from '../../PlaylistDetailPanel';

export default function PlaylistFilterChips() {
  const { t } = useTranslation('playlist');

  const { data } = usePlaylistSummary();
  const quiz = data?.data.quiz;
  const challenge = data?.data.challenge;
  const { setFilter } = useFilterPlaylist();

  const filterChips: (FilterChipItem | false)[] = quiz
    ? [
        {
          id: 'mistakes',
          label: t('detail.filterChips.mistake.label', 'Mistakes', {
            count: quiz.mistakeCount,
          }),
          tooltip: {
            title: t('detail.filterChips.mistake.tooltip.title', 'Mistakes'),
            content: t(
              'detail.filterChips.mistake.tooltip.content',
              'Show Mistake questions only.'
            ),
            icon: 'ActionCloseThick',
          },
          icon: 'ActionCloseThick',
          conditions: [
            {
              fieldName: 'practiceResult',
              operator: 'IsSelectIn',
              values: [
                {
                  label: 'Mistake',
                  value: -1,
                },
              ],
            },
          ],
        },
        {
          id: 'overtime',
          label: t('detail.filterChips.overtime.label', 'Overtime', {
            count: quiz.overtimeCount,
          }),
          tooltip: {
            title: t('detail.filterChips.overtime.tooltip.title', 'Overtime'),
            content: t(
              'detail.filterChips.overtime.tooltip.content',
              'Show Overtime questions only.'
            ),
            icon: 'TestClockSolid',
          },
          icon: 'TestClockSolid',
          conditions: [
            {
              fieldName: 'overtimeStatus',
              operator: 'Is',
              values: [
                {
                  label: 'Overtime',
                  value: -1,
                  color: 'warning',
                },
              ],
            },
          ],
        },
      ]
    : [];

  if (challenge && !challenge.isTaken) return null;

  const handleFilterChange = (values?: FilterChipItem[]) => {
    setFilter('mistakes', false);
    setFilter('overtime', false);
    if (values?.some((item) => item.id === 'mistakes')) {
      setFilter('mistakes', true);
    }
    if (values?.some((item) => item.id === 'overtime')) {
      setFilter('overtime', true);
    }
  };

  return (
    <QuickFilterChips
      scope={FILTER_SORT_SCOPE}
      filterType="playlistDetailRhs"
      sortType="playlistDetailRhs"
      items={filterChips}
      allItem={{
        title: <ActionCloseIcon width={16} height={16} />,
        tooltip: {
          title: t('detail.filterChips.clear.tooltip.title', 'Clear'),
          content: t(
            'detail.filterChips.clear.tooltip.content',
            'Click to clear selection.'
          ),
          icon: 'ActionClose',
        },
        autoHide: true,
      }}
      isInitLoading={!data}
      multiple
      onChange={handleFilterChange}
    />
  );
}
