import React, { PropsWithChildren, useState } from 'react';

import FilterContext, { initialValue } from './FilterContext';

export type FilterProviderProps = PropsWithChildren<{
  scope: string;
}>;
export default function FilterProvider({
  scope,
  children,
}: FilterProviderProps) {
  const providerValue = useState({ ...initialValue, scope });
  return (
    <FilterContext.Provider value={providerValue}>
      {children}
    </FilterContext.Provider>
  );
}
