import { useState } from 'react';
import { Box } from '@mui/material';
import { formatDateString } from '@front/helper';
import {
  ActionChevronFilledLeft as ActionChevronFilledLeftIcon,
  ActionChevronFilledRight as ActionChevronFilledRightIcon,
} from '@front/icon';
import { DatePicker, TextField } from '@front/ui';
import { format, isMatch, parse, startOfDay } from 'date-fns';

import { DateRangeFilterValue } from '../../../types';

const styles = {
  root: {
    display: 'grid',
    gap: '4px',
  },
  inputs: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 2,
  },
  picker: {
    width: '100%',
  },
  paper: {
    '& .MuiAutocomplete-option': {
      pt: '1px !important',
      pb: '1px !important',
      height: '28px',
    },
    '& .MuiAutocomplete-noOptions': {
      display: 'none',
    },
  },
};

type DateRangeFilterProps = {
  dateFormat?: string;
  value: DateRangeFilterValue | null;
  onChange: (value: DateRangeFilterValue | null) => void;
};
export default function DateRangeFilter({
  value,
  onChange,
  dateFormat = 'dd/MM/yyyy',
}: DateRangeFilterProps) {
  const dateValues = value?.value?.map((str) => new Date(str));
  const [first, second] = dateValues || [];
  const [startDate, setStartDate] = useState(
    first ? format(first, dateFormat) : ''
  );
  const [endDate, setEndDate] = useState(
    second ? format(second, dateFormat) : ''
  );

  const isDateValid = (newValue?: string) => {
    return (
      !!newValue &&
      newValue.length === dateFormat.length &&
      isMatch(newValue, dateFormat)
    );
  };

  const handleStartDateChange = (newValue: string) => {
    setStartDate(newValue);
  };

  const updateValue = (newValue: Date[] | null) => {
    if (!newValue) {
      onChange?.(null);
      return;
    }
    onChange?.({
      type: 'date',
      dateType: 'dateRange',
      value: newValue?.map((date) => formatDateString(date)),
    });
  };

  const handleStartDateBlur = (newValue: string) => {
    const [, currentEndDate] = dateValues || [];

    if (!newValue) {
      // clear start date value
      updateValue(currentEndDate ? [currentEndDate] : null);
      return;
    }
    if (isDateValid(newValue)) {
      // start date is valid
      const newStartDate = parse(newValue, dateFormat, new Date());
      if (currentEndDate) {
        updateValue([newStartDate, currentEndDate]);
        return;
      }
      updateValue([newStartDate]);
    }
  };

  const handleEndDateChange = (newValue: string) => {
    setEndDate(newValue);
  };

  const handleEndDateBlur = (newValue: string) => {
    const [currentStartDate] = dateValues || [];
    const startDateVal =
      currentStartDate && startDate ? currentStartDate : null;

    if (!newValue) {
      // clear end date value
      updateValue(startDateVal ? [startDateVal] : null);
      return;
    }
    if (isDateValid(newValue)) {
      // end date is valid
      const newEndDate = parse(newValue, dateFormat, new Date());
      if (startDateVal) {
        updateValue([startDateVal, newEndDate]);
        return;
      }
      updateValue([newEndDate]);
    }
  };

  const handlePickerChange = (newValues: Date[]) => {
    updateValue(newValues);
    if (newValues.length == 1) {
      setStartDate(format(newValues[0], dateFormat));
      setEndDate('');
    }
    if (newValues.length === 2) {
      setStartDate(format(newValues[0], dateFormat));
      setEndDate(format(newValues[1], dateFormat));
    }
  };

  const dates = value?.value?.map((d) => startOfDay(new Date(d)));
  return (
    <Box sx={styles.root}>
      <Box sx={styles.inputs}>
        <TextField
          placeholder="Starting"
          label="Starting Date"
          value={startDate}
          onChange={(e) => handleStartDateChange(e.target.value)}
          onBlur={(e) => handleStartDateBlur(e.target.value)}
        />
        <TextField
          placeholder="Ending"
          label="Ending Date"
          value={endDate}
          onChange={(e) => handleEndDateChange(e.target.value)}
          onBlur={(e) => handleEndDateBlur(e.target.value)}
        />
      </Box>
      <DatePicker
        value={dates}
        onChange={handlePickerChange}
        prevIcon={<ActionChevronFilledLeftIcon />}
        nextIcon={<ActionChevronFilledRightIcon />}
        sx={styles.picker}
        type="range"
      />
    </Box>
  );
}
