import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  EmojiFire as EmojiFireIcon,
  OtherCalendarDay as OtherCalendarDayIcon,
} from '@front/icon';
import { BaseLayoutRightPanel, useBaseRightPanel } from '@front/ui';
import { useClubSlug, useIaClub } from '@lib/web/hooks';
import { addMonths, compareDesc, startOfMonth, subMonths } from 'date-fns';
import { TFunction } from 'i18next';

import Calendar from './Calendar';

const styles = {
  content: {
    display: 'grid',
    gap: 2,
  },
};

const getCalendarSettings = (
  t: TFunction<'club', undefined>,
  params?: GlobalPanelParams[GlobalPanelKeys.GlobalStreaks]
) => {
  if (params?.type === 'streak' || params?.type === 'longestStreak')
    return {
      title: t('Streak'),
      Icon: EmojiFireIcon,
    };

  return {
    title: t('toolbar.Calendar'),
    Icon: OtherCalendarDayIcon,
  };
};

export default function StreaksPanel() {
  const { t } = useTranslation('club');
  const [dates, setDates] = useState<Date[]>([]);

  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();
  const panelParams = getRightParams(GlobalPanelKeys.GlobalStreaks);
  const routerClubSlug = useClubSlug();
  const clubSlug = panelParams.clubSlug || routerClubSlug;

  const scrollRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const timerId = useRef<NodeJS.Timeout>();
  const loaded = useRef(false);
  const availableScroll = useRef(false);
  const { club } = useIaClub(clubSlug);
  const minDate = club?.joinedAt
    ? startOfMonth(new Date(club.joinedAt))
    : startOfMonth(new Date());
  availableScroll.current =
    dates.length > 1 && compareDesc(minDate, dates[0]) === 1;

  const { initDate, selectedDate } = useMemo(() => {
    if (panelParams.type === 'longestStreak') {
      return {
        initDate: panelParams.longestStreakEndDate || new Date(),
        selectedDate: panelParams.longestStreakEndDate,
      };
    }
    if (panelParams.type === 'selectedStreak') {
      return {
        initDate: panelParams.selectedStreakDate || new Date(),
        selectedDate: panelParams.selectedStreakDate,
      };
    }
    return {
      initDate: new Date(),
      selectedDate: new Date(),
    };
  }, [
    panelParams.longestStreakEndDate,
    panelParams.selectedStreakDate,
    panelParams.type,
  ]);

  const loadCalendar = useCallback((newDate: Date) => {
    if (loaded.current) return;
    setDates((st) => [...st, newDate]);

    timerId.current = setTimeout(() => {
      const scrollTarget = scrollRef.current;
      const contentTarget = contentRef.current;
      if (scrollTarget && contentTarget) {
        if (scrollTarget.clientHeight >= contentTarget.clientHeight) {
          loadCalendar(addMonths(newDate, 1));
        } else {
          loaded.current = true;
        }
      }
    }, 300);
  }, []);

  useEffect(() => {
    loaded.current = false;
    const newDate = startOfMonth(new Date(initDate));
    setDates([newDate]);
    loadCalendar(addMonths(newDate, 1));
  }, [initDate, loadCalendar]);

  useEffect(() => {
    const scrollTarget = scrollRef.current;
    let shouldUpdate = true;
    const handleScroll = (ev: Event) => {
      if (!shouldUpdate) return;

      const target = ev.target as HTMLDivElement;
      if (loaded.current && target) {
        const { scrollTop } = target;
        if (scrollTop <= 100) {
          if (!availableScroll.current) return;

          shouldUpdate = false;
          setTimeout(() => {
            shouldUpdate = true;
          }, 300);
          setDates((st) => [subMonths(st[0], 1), ...st]);
        }
      }
    };

    const handleWheel = (ev: WheelEvent) => {
      if (!shouldUpdate || !availableScroll.current) return;
      const target = ev.target as HTMLDivElement;
      if (ev.deltaY < 0 && target.scrollTop === 0) {
        shouldUpdate = false;
        setTimeout(() => {
          shouldUpdate = true;
        }, 300);

        setDates((st) => [subMonths(st[0], 1), ...st]);
      }
    };

    if (scrollTarget) {
      scrollTarget.addEventListener('scroll', handleScroll);
      scrollTarget.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (scrollTarget) {
        scrollTarget.removeEventListener('scroll', handleScroll);
        scrollTarget.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  const { title, Icon } = getCalendarSettings(t, panelParams);
  return (
    <BaseLayoutRightPanel
      title={title}
      titleIcon={<Icon width={16} height={16} />}
    >
      <BaseLayoutRightPanel.ScrollArea scrollableNodeProps={{ ref: scrollRef }}>
        <BaseLayoutRightPanel.Content>
          <Box ref={contentRef} sx={styles.content}>
            {dates.map((date) => (
              <Calendar
                key={date.toString()}
                date={date}
                type={panelParams.type}
                selectedDate={selectedDate}
                clubSlug={clubSlug}
              />
            ))}
          </Box>
        </BaseLayoutRightPanel.Content>
      </BaseLayoutRightPanel.ScrollArea>
    </BaseLayoutRightPanel>
  );
}
